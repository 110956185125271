import { useAppSelector } from "@app/hooks"
import { LoadingButton } from "@mui/lab"
import { Box, BoxProps, Button, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../../../theme/appTheme"
import StaleCourseModel from "./staleCourseModel"
import useComputeStaleCourseContainers from "./useComputeStaleCourseContainers"

export type StaleCourseCardProps = {
  staleCourse: StaleCourseModel
  onViewClicked: () => void
  onUpdateClicked: () => void
  sx?: BoxProps["sx"]
}

function StaleCourseCard(props: StaleCourseCardProps) {
  const { staleCourse, onViewClicked, onUpdateClicked } = props

  const model = useStaleCourseCardViewModel(staleCourse)

  if (!model) {
    return null
  }

  const { title, subtitle, status } = model

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        borderTop: `1px solid ${appTheme.palette.other.divider}`,
        padding: "14px 24px",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor:
          status === "updated" ? appTheme.palette.action.hover : undefined,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          variant="body2_sb"
          sx={{
            color:
              status === "updated"
                ? appTheme.palette.text.secondary
                : undefined,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: appTheme.palette.text.secondary,
          }}
        >
          {subtitle}
        </Typography>
      </Box>
      {(() => {
        switch (status) {
          case "review":
          case "pending":
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <Button variant="outlined" size="small" onClick={onViewClicked}>
                  View
                </Button>
                <LoadingButton
                  loading={status === "pending"}
                  variant="contained"
                  size="small"
                  onClick={onUpdateClicked}
                >
                  Update
                </LoadingButton>
              </Box>
            )
          case "updated":
            return (
              <SvgIcon
                inheritViewBox
                component={appIcons.check}
                sx={{
                  fill: "none",
                  stroke: appTheme.palette.icon.black,
                }}
              />
            )
        }
      })()}
    </Box>
  )
}

function useStaleCourseCardViewModel(staleCourse: {
  syllabusId: string
  unitId: string
  courseId: string
}):
  | {
      title: string
      subtitle: string
      status: "updated" | "pending" | "review"
    }
  | undefined {
  const staleCourseModel = useComputeStaleCourseContainers(staleCourse)
  const status = useStaleCourseCardState(staleCourse)

  if (!staleCourseModel || !status) {
    return undefined
  }

  const { unit, course } = staleCourseModel

  return {
    title: course.title || `Untitled course`,
    subtitle: `${unit.shortTitle}${unit.unitNumber}`,
    status: status,
  }
}

function useStaleCourseCardState(staleCourse: {
  syllabusId: string
  unitId: string
  courseId: string
}): "updated" | "pending" | "review" | undefined {
  const popupState = useAppSelector(
    (state) => state.syllabusEditor.reviewStaleCoursesPopup,
  )

  if (!popupState) {
    return undefined
  }

  return popupState.statuses[staleCourse.courseId]
}

export default StaleCourseCard
