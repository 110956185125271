import { useAppDispatch } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { archiveCourse } from "@features/coursesMenu/coursesMenuSlice"
import { popupClosed } from "@features/ui/uiSlice"

function ArchiveCourseConfirmationPopup(props: {
  courseId: string
  onClose: () => void
}) {
  const dispatch = useAppDispatch()
  const onArchive = () => {
    dispatch(popupClosed())
    dispatch(archiveCourse(props.courseId))
  }
  const { onClose } = props

  return (
    <GenericDialog
      open
      onClose={onClose}
      size="xs"
      title="Archive this course?"
      subtitle={
        "The course will no longer be visible to all users in the main" +
        "repository. You can restore it at any time."
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Archive",
          onClick: onArchive,
        },
      ]}
    />
  )
}

export default ArchiveCourseConfirmationPopup
