import { Syllabus } from "@features/syllabus/syllabusSelectors"
import { Alert, Box, Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useSearchParams } from "react-router-dom"
import EmptyStateContainer from "../components/emptyStateContainer"
import { AddProjectMenu } from "./addProjectMenu"

export const NoProjectsView = (props: {
  programId: string
  syllabus: Syllabus | undefined
}) => {
  const disabled = props.syllabus === undefined
  return (
    <Box>
      {disabled && <NoSyllabusBanner />}
      <EmptyStateContainer
        title="No Projects yet"
        subtitle="Add and manage Projects for this program"
        icon={appIcons.codeBrowser}
        action={
          <AddProjectMenu
            programId={props.programId}
            disabled={disabled}
            unitsInSyllabus={props.syllabus?.units ?? []}
            icon="chevronDown"
          />
        }
      />
    </Box>
  )
}

const NoSyllabusBanner = () => {
  const [, setSearchParams] = useSearchParams()

  const onConnectSyllabusClicked = () => {
    setSearchParams({ tab: "syllabus" }, { replace: true })
  }
  return (
    <Alert
      severity="info"
      sx={{ p: 2, "& .MuiAlert-action": { mr: 0 } }}
      icon={
        <SvgIcon
          component={appIcons.infoCircle}
          inheritViewBox
          sx={{ fill: "none", width: 20, height: 20 }}
        />
      }
      action={
        <Button
          variant="contained"
          size="small"
          startIcon={
            <SvgIcon
              component={appIcons.link01}
              inheritViewBox
              sx={{
                stroke: "currentColor",
                fill: "none",
                width: "20px",
                height: "20px",
              }}
            />
          }
          onClick={onConnectSyllabusClicked}
        >
          Connect to a syllabus
        </Button>
      }
    >
      Projects can only be added after a syllabus has been connected to a
      program
    </Alert>
  )
}
