import { Box, Typography, Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"

function UploadingCSVInstructions() {
  const createCSV = () => {
    const csvContent = "Email,First Name,Last Name\nemail,name,lastName"
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "data.csv"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        bgcolor: "eTypes.sand25",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <Typography variant="body3" textAlign="center">
        Upload a CSV file with columns for Email, First name, Last name, and
        Program. Program options include Cybersecurity, Software Engineering,
        Data Analytics, Mentor Training, Chip Design, Verification, and Compass.
      </Typography>
      <Button
        size="small"
        variant="text"
        startIcon={
          <SvgIcon
            inheritViewBox
            component={appIcons.download02}
            sx={{
              color: "icon.black",
              stroke: "currentcolor",
              fill: "none",
            }}
          />
        }
        onClick={createCSV}
      >
        Download format
      </Button>
    </Box>
  )
}

export default UploadingCSVInstructions
