import { useEffect, useRef } from "react"

type Options = {
  saveOnlyDefined: boolean
}

function usePrevious<T>(
  value: T | undefined,
  options: Options = { saveOnlyDefined: false },
) {
  const ref = useRef<T>()
  useEffect(() => {
    if (!options.saveOnlyDefined || value !== undefined) {
      ref.current = value
    }
  }, [value, options.saveOnlyDefined])
  return ref.current
}

export default usePrevious
