import useRemoveSearchParams from "@utils/hooks/useRemoveSearchParams"
import useRemoveElement from "./useRemoveElement"

/*
 * Similar to useRemoveElement, this hook is used to remove an element from the course editor.
 * However, it also resets the search parameters to the default values.
 */
function useOnDelete() {
  const removeElement = useRemoveElement()
  const resetSearchParams = useRemoveSearchParams(["elementId"], true)

  return ({ topicId, elementId }: { topicId?: string; elementId?: string }) => {
    removeElement({ topicId, elementId })
    resetSearchParams()
  }
}

export default useOnDelete
