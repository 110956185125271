import { useAppDispatch } from "@app/hooks"
import { CourseDto, CourseStatus } from "@masterschool/course-builder-api"
import { useNavigate } from "react-router-dom"
import { createNewCourseVersion } from "@features/coursesMenu/coursesMenuSlice"

export function useEditCourseClicked(syllabusId?: string) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const pathPrefix = syllabusId
    ? `/syllabus-edit/${syllabusId}`
    : "/course-edit"

  return (course: CourseDto) => {
    if (course.status === CourseStatus.Draft) {
      navigate(`${pathPrefix}/${course.id}`)
    } else if (course.status === CourseStatus.Published) {
      dispatch(createNewCourseVersion(course)).then(() => {
        navigate(`${pathPrefix}/${course.id}`)
      })
    }
  }
}
