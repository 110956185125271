import { useAppDispatch } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { deleteSyllabus } from "@features/syllabusEditor/syllabusEditorSlice"
import { popupClosed } from "@features/ui/uiSlice"
import { useNavigate } from "react-router-dom"

function SyllabusDeleteConfirmationPopup(props: { syllabusId: string }) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const onDelete = () => {
    dispatch(deleteSyllabus(props.syllabusId)).then(() => {
      dispatch(popupClosed())
      navigate("/syllabuses")
    })
  }

  const onCloseClicked = () => {
    dispatch(popupClosed())
  }

  return (
    <GenericDialog
      open
      onClose={onCloseClicked}
      size="xs"
      title="Delete this syllabus?"
      subtitle={
        "It will be removed from existence. Note that courses created for this" +
        "syllabus will not be deleted."
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onCloseClicked,
        },
        {
          type: "danger",
          text: "Delete",
          onClick: onDelete,
        },
      ]}
    />
  )
}

export default SyllabusDeleteConfirmationPopup
