import { ProgramDto, UserDto } from "@masterschool/course-builder-api"
import { formatDate } from "@masterschool/foundations"
import { domainToDisplayName } from "@utils/domainUtils"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

export function programDisplayName(program: ProgramDto) {
  return `${domainToDisplayName(program.domain)} ${formatDate(
    program.startDate,
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  )}`
}

export function getProgramNoSyllabusWarning(program: ProgramDto) {
  if (program.syllabusId || !program.startDate) {
    return undefined
  }

  const programStartDate = new Date(program.startDate)
  const timeToProgramStart = programStartDate.getTime() - Date.now()
  const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000
  if (timeToProgramStart > SEVEN_DAYS) {
    return undefined
  }

  let title =
    "This program has started, but no syllabus has been connected yet."
  if (timeToProgramStart > 0) {
    const timeToStart = dayjs().to(dayjs(programStartDate))
    title = `This program begins ${timeToStart}, but no syllabus has been connected yet.`
  }

  return title
}

export function getProgramHasUnassignedStudentsWarning(
  program: ProgramDto,
  students: UserDto[],
) {
  if (!program.startDate || students.length === 0) {
    return undefined
  }
  if (students.every((student) => student.mentor !== undefined)) {
    return undefined
  }

  const programStartDate = new Date(program.startDate)
  const timeToProgramStart = programStartDate.getTime() - Date.now()
  const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000
  if (timeToProgramStart > SEVEN_DAYS) {
    return undefined
  }

  let title =
    "This program has started, but some students weren't assigned to a mentor yet."
  if (timeToProgramStart > 0) {
    const timeToStart = dayjs().to(dayjs(programStartDate))
    title = `This program begins ${timeToStart}, but some students weren't assigned to a mentor yet.`
  }

  return title
}
