import {
  CourseSyllabusLiveEvent,
  Topic,
} from "@masterschool/course-builder-api"
import { SvgIconProps } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { useAppSelector } from "@app/hooks"
import { selectIsLiveEventInvalid } from "../../features/courseEditor/courseValidationsSelectors"
import appTheme from "../../theme/appTheme"
import appIcons from "@utils/appIcons"
import { CourseLiveEventDescriptor } from "../courseLiveEventDescriptior"

export function CourseSyllabusLiveEventView(props: {
  liveEvent: CourseSyllabusLiveEvent
  topic: Topic
  index: number
  readonly?: boolean
  availableTopics?: Topic[]
}) {
  const { liveEvent, topic, index, readonly, availableTopics } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const isSelected = searchParams.get("elementId") === liveEvent.id
  const isInvalid = useAppSelector(selectIsLiveEventInvalid(liveEvent))

  const borderColor = isSelected
    ? appTheme.palette.info.main
    : isInvalid
    ? appTheme.palette.error.main
    : appTheme.palette.other.outlineBorder

  const extraIcons: (SvgIconProps & { id: string })[] = [
    {
      id: "hidden",
      component: appIcons.eyeOff,
      sx: { stroke: appTheme.palette.icon.disabled },
    },
  ]

  if (isInvalid) {
    extraIcons.push({
      id: "invalid",
      component: appIcons.alertCircle,
      sx: {
        stroke: appTheme.palette.error.main,
      },
    })
  }
  const isEditMode = readonly !== true

  const onClick = isEditMode
    ? () => {
        searchParams.set("elementId", liveEvent.id)
        setSearchParams(searchParams, {
          replace: true,
        })
      }
    : () => {
        return
      }

  return (
    <CourseLiveEventDescriptor
      key={liveEvent.id}
      index={index}
      topic={topic}
      liveEvent={liveEvent}
      editMode={isEditMode}
      onClick={onClick}
      sx={{
        backgroundColor: isSelected ? "#2196F314" : appTheme.palette.grey["50"],
        borderColor,
      }}
      leftIcon={isEditMode ? appIcons.dotsGrid : undefined}
      leftIconSx={{
        cursor: "grab",
      }}
      extraIcons={extraIcons}
      availableTopics={availableTopics}
    />
  )
}
