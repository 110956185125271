import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import TitleTextField from "../../components/titleTextField"
import UrlTextField from "../../components/urlTextField"
import { useEditTest } from "../custom-hooks/useEditElementItem"
import { useSelectedTest } from "../custom-hooks/useSelectedElementItem"
import { Topic } from "@masterschool/course-builder-api"

function TestEditor(props: { topic: Topic }) {
  const { topic } = props
  const test = useSelectedTest()
  const editTest = useEditTest()

  if (!test) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <TitleTextField
          elementId={test.id}
          value={test.title}
          debounce
          onChange={(e) => {
            editTest(test, "title", e.target.value, topic)
          }}
        />
        <TimeEstimationTextField
          elementId={test.id}
          value={test.estimatedDuration}
          debounce
          onChange={(e) => {
            editTest(test, "estimatedDuration", parseInt(e.target.value), topic)
          }}
        />
        <UrlTextField
          elementId={test.id}
          value={test.url}
          debounce
          onChange={(e) => {
            editTest(test, "url", e.target.value, topic)
          }}
        />
      </Box>
    </Box>
  )
}

export default TestEditor
