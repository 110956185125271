import { Role } from "@features/login/loginSlice"

const roleDisplayNames = (role: Role | string): string => {
  switch (role) {
    case Role.LmsAdmin:
      return "Admin"
    case Role.MasterschoolEmployee:
      return "Employee"
    case Role.ProgramManager:
      return "Program owner"
    case Role.CareerSuccessAdvisor:
      return "CSA"
    case Role.Instructor:
    default:
      return role
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b\w/g, (firstChar) => firstChar.toUpperCase())
  }
}

export default roleDisplayNames
