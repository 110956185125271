import { useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import HighlightableScrollRef from "@cmp/highlightableScrollRef"
import { selectPublishValidations } from "@features/courseEditor/courseValidationsSelectors"
import {
  CourseSyllabusElementTypeEnum,
  CourseraLesson,
  OtherLesson,
  Topic,
} from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  SvgIcon,
  TextField,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { useEffect, useRef, useState } from "react"
import appTheme from "../../../theme/appTheme"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import TitleTextField from "../../components/titleTextField"
import UrlTextField from "../../components/urlTextField"
import { useEditGenericLesson } from "../custom-hooks/useEditElementItem"
import {
  useSelectedCourseraLesson,
  useSelectedOtherLesson,
} from "../custom-hooks/useSelectedElementItem"
import EditLessonType from "./EditLessonType"
import OtherLessonTasksEditor from "./task-editors/otherLessonTasksEditor"

function OtherLessonEditor(props: {
  lessonType: "other" | "coursera"
  topic: Topic
}) {
  const { lessonType, topic } = props
  const otherLesson = useSelectedOtherLesson()
  const courseraLesson = useSelectedCourseraLesson()
  const lesson = props.lessonType === "other" ? otherLesson : courseraLesson
  const editLesson = useEditGenericLesson(
    lessonType === "other"
      ? CourseSyllabusElementTypeEnum.OtherLesson
      : CourseSyllabusElementTypeEnum.CourseraLesson,
  )

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <TitleTextField
          elementId={lesson.id}
          value={lesson.title}
          debounce
          onChange={(e) => {
            editLesson(lesson, "title", e.target.value, topic)
          }}
        />
        <EditLessonType topic={topic} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <UrlTextField
          elementId={lesson.id}
          value={lesson.url}
          debounce
          onChange={(e) => {
            editLesson(lesson, "url", e.target.value, topic)
          }}
        />
        <TimeEstimationTextField
          elementId={lesson.id}
          value={lesson.estimatedDuration}
          debounce
          onChange={(e) => {
            editLesson(
              lesson,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
          sx={{ minWidth: "180px" }}
        />
      </Box>
      <TasksEditorContainer
        lesson={lesson}
        lessonType={props.lessonType}
        topic={topic}
      />
    </Box>
  )
}

const TasksEditorContainer = (props: {
  lesson: OtherLesson | CourseraLesson
  lessonType: "other" | "coursera"
  topic: Topic
}) => {
  const { lesson, lessonType, topic } = props
  const editLesson = useEditGenericLesson(
    lessonType === "other"
      ? CourseSyllabusElementTypeEnum.OtherLesson
      : CourseSyllabusElementTypeEnum.CourseraLesson,
  )
  const tasksEditorRef = useRef<HighlightableScrollRef>(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false)
  const [isBulkEditing, setIsBulkEditing] = useState(false)
  const validations = useAppSelector(selectPublishValidations)
  const isInvalidBecauseMissingTasks =
    validations?.[lesson.id]?.includes("MISSING_TASKS") &&
    lesson.tasks.length === 0

  useEffect(() => {
    if (shouldScrollToBottom) {
      tasksEditorRef.current?.scrollToBottomAndHighlightLastTask()
    }
    setShouldScrollToBottom(false)
  }, [shouldScrollToBottom])

  const makeTask =
    lessonType === "other"
      ? () => SyllabusElementFactory.makeOtherLessonTask()
      : () => SyllabusElementFactory.makeCourseraTask()

  const addTaskText =
    lesson.tasks.length === 0 ? "Add task (optional)" : "Add task"

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "start",
          gap: "16px",
          overflow: "hidden",
        }}
      >
        <OtherLessonTasksEditor ref={tasksEditorRef} topic={topic} />
        <Box>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
            }}
          >
            <Button
              variant="text"
              size="small"
              onClick={() => {
                editLesson(
                  lesson,
                  "tasks",
                  lesson.tasks.concat(makeTask()),
                  topic,
                )
                setShouldScrollToBottom(true)
              }}
              startIcon={
                <SvgIcon
                  component={appIcons.plusCircle}
                  inheritViewBox
                  sx={{
                    width: "20px",
                    height: "20px",
                    fill: "none",
                    stroke: isInvalidBecauseMissingTasks
                      ? appTheme.palette.error.main
                      : appTheme.palette.icon.black,
                  }}
                />
              }
              sx={{
                color: isInvalidBecauseMissingTasks
                  ? appTheme.palette.error.main
                  : appTheme.palette.icon.black,
              }}
            >
              {addTaskText}
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setIsBulkEditing(true)
              }}
              startIcon={
                <SvgIcon
                  component={appIcons.edit05}
                  inheritViewBox
                  sx={{
                    width: "20px",
                    height: "20px",
                    stroke: appTheme.palette.icon.black,
                    fill: "none",
                  }}
                />
              }
            >
              Bulk edit
            </Button>
            <FormHelperText
              error={isInvalidBecauseMissingTasks}
              variant="standard"
              sx={{
                textTransform: "none",
                paddingTop: "4px",
                paddingLeft: "14px",
              }}
            >
              {isInvalidBecauseMissingTasks
                ? "Lesson must have at least one task"
                : ""}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      {isBulkEditing && (
        <BulkEditingDialog
          lessonType={lessonType}
          onClose={() => {
            setIsBulkEditing(false)
          }}
          topic={topic}
        />
      )}
    </>
  )
}

function BulkEditingDialog(props: {
  lessonType: "other" | "coursera"
  onClose: () => void
  topic: Topic
}) {
  const { lessonType, onClose, topic } = props

  const otherLesson = useSelectedOtherLesson()
  const courseraLesson = useSelectedCourseraLesson()
  const lesson = props.lessonType === "other" ? otherLesson : courseraLesson
  const editLesson = useEditGenericLesson(
    props.lessonType === "other"
      ? CourseSyllabusElementTypeEnum.OtherLesson
      : CourseSyllabusElementTypeEnum.CourseraLesson,
  )
  const [tasks, setTasks] = useState<string>("")

  if (!lesson) {
    return undefined
  }

  const makeTask =
    lessonType === "other"
      ? (url?: string) => SyllabusElementFactory.makeOtherLessonTask(url)
      : (url?: string) => SyllabusElementFactory.makeCourseraTask(url)

  return (
    <GenericDialog
      open
      title="Bulk edit"
      subtitle="Separate URLs using line breaks to add tasks in bulk."
      onClose={onClose}
      size="sm"
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Save",
          onClick: () => {
            if (!tasks) {
              return
            }
            const tasksUrls = tasks.split("\n")
            const newTasks = tasksUrls.map((url) => {
              return makeTask(url)
            })
            editLesson(lesson, "tasks", lesson.tasks.concat(newTasks), topic)
            onClose()
          },
          disabled: !tasks,
        },
      ]}
      content={
        <TextField
          label="Tasks URL"
          multiline
          rows={6}
          fullWidth
          variant="outlined"
          value={tasks}
          onChange={(event) => {
            setTasks(event.target.value)
          }}
          sx={{
            width: "100%",
            paddingBottom: "24px",
          }}
        />
      }
    />
  )
}

export default OtherLessonEditor
