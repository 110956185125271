import { ProgramClient } from "@clients/programClient"
import { ProjectClient } from "@clients/projectClient"
import GenericDialog from "@cmp/genericDialog"
import { ProgramProjectDto, ProjectDto } from "@masterschool/course-builder-api"
import { Typography } from "@mui/material"

type DeleteProjectDialogProps = {
  project: ProjectDto | ProgramProjectDto
  open: boolean
  onClose: () => void
}

export const DeleteProjectDialog = (props: DeleteProjectDialogProps) => {
  const { project, open, onClose } = props

  const onSubmitClicked = async () => {
    if ("programId" in project) {
      return ProgramClient.deleteProject(project.id, project.programId).finally(
        onClose,
      )
    } else {
      return ProjectClient.deleteProject(project.id).finally(onClose)
    }
  }
  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Delete Project"
      size="xs"
      content={
        <Typography>
          Are you sure you want to delete the project <b>"{project.name}"</b>?
        </Typography>
      }
      buttons={[
        { type: "secondary", text: "Cancel", onClick: onClose },
        { type: "danger", text: "Delete", onClick: onSubmitClicked },
      ]}
    />
  )
}
