import {
  AccessConditionType,
  DateCondition,
  SectionAccessCondition,
} from "@masterschool/course-builder-api"
import appIcons from "@utils/appIcons"

export enum UnlockStrategy {
  OnSprintStart,
  OnCustomDate,
  OnPreviousSectionCompletion,
  AlwaysOpen,
}

export const unlockStrategies = [
  UnlockStrategy.OnSprintStart,
  UnlockStrategy.OnPreviousSectionCompletion,
  UnlockStrategy.AlwaysOpen,
  UnlockStrategy.OnCustomDate,
] as const

export function iconComponentForUnlockStrategy(strategy: UnlockStrategy) {
  switch (strategy) {
    case UnlockStrategy.OnSprintStart:
      return appIcons.calendar
    case UnlockStrategy.OnPreviousSectionCompletion:
      return appIcons.calendarCheck
    case UnlockStrategy.AlwaysOpen:
      return appIcons.calendarMinusLock
    case UnlockStrategy.OnCustomDate:
      return appIcons.calendarMinusTime
  }
}

export function unlockStrategyDisplayName(
  unlockStrategy: UnlockStrategy,
  sectionType: "unit" | "sprint",
  accessCondition?: SectionAccessCondition,
) {
  switch (unlockStrategy) {
    case UnlockStrategy.OnSprintStart:
      return "On sprint start date (default)"
    case UnlockStrategy.OnPreviousSectionCompletion:
      return `On completing prior ${sectionType}`
    case UnlockStrategy.AlwaysOpen:
      return "Always unlocked"
    case UnlockStrategy.OnCustomDate:
      const date = dateFromCondition(accessCondition)
      if (date) {
        return (
          "Opens on " +
          new Date(date).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          })
        )
      }
      return "On custom date"
  }
}

export function unlockStrategyFromCondition(
  condition: SectionAccessCondition | undefined,
): UnlockStrategy {
  if (!condition) return UnlockStrategy.OnSprintStart

  switch (condition.type) {
    case AccessConditionType.Date:
      if ((condition as DateCondition).startDate !== undefined) {
        return UnlockStrategy.OnCustomDate
      } else {
        return UnlockStrategy.OnSprintStart
      }
    case AccessConditionType.Progress:
      return UnlockStrategy.OnPreviousSectionCompletion
    case AccessConditionType.Truthy:
      return UnlockStrategy.AlwaysOpen
    case AccessConditionType.And:
    case AccessConditionType.Or:
      console.error("Unsupported access condition type: ", condition.type)
      return UnlockStrategy.OnSprintStart
  }
}

export function dateFromCondition(
  condition: SectionAccessCondition | undefined,
) {
  if (!condition) return null
  if (condition.type !== AccessConditionType.Date) return null
  return (condition as DateCondition).startDate
}

export function conditionFromFormFields(
  strategy: UnlockStrategy,
  customDate?: string | null,
): SectionAccessCondition | null {
  switch (strategy) {
    case UnlockStrategy.OnSprintStart:
      return { type: AccessConditionType.Date }
    case UnlockStrategy.OnPreviousSectionCompletion:
      return { type: AccessConditionType.Progress }
    case UnlockStrategy.AlwaysOpen:
      return { type: AccessConditionType.Truthy }
    case UnlockStrategy.OnCustomDate:
      if (!customDate) return null
      return {
        type: AccessConditionType.Date,
        startDate: customDate,
      } as DateCondition
  }
}
