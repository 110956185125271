import GenericDialog from "@cmp/genericDialog"
import { SelectSyllabusTable } from "../syllabus/popups/selectSyllabusPopup"

function ProgramClassSyllabusSelectionDialog(props: {
  selectedSyllabusId: string | undefined
  open: boolean
  onClose: () => void
  onClickSyllabus: (syllabusId: string) => void
}) {
  const { open, onClose, onClickSyllabus } = props

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Select a syllabus"
      size="lg"
      fullHeight
      disableContentPadding
      content={<SelectSyllabusTable onSelect={(s) => onClickSyllabus(s.id)} />}
    />
  )
}

export default ProgramClassSyllabusSelectionDialog
