import { useAppDispatch } from "@app/hooks"
import { RootState } from "@app/store"
import { ShiftsClient } from "@clients/shiftsClient"
import BackButton from "@cmp/buttons/backButton"
import {
  availableShiftsSelector,
  selectConsultant,
  selectConsultantShifts,
} from "@features/sales-management/salesManagement.selectors"
import {
  updateConsultantAcceptedQtfs,
  updateConsultantAvailability,
  updateShifts,
} from "@features/sales-management/salesManagement.slice"
import { CreateShiftRequest, ShiftDto } from "@masterschool/course-builder-api"
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { ConsultantShifts } from "./consultantShifts"
import { CreateOrAttachShift } from "./createOrAttachShift"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"

export const ShiftManagement = () => {
  const { consultantId } = useParams()
  const dispatch = useAppDispatch()
  const selectConsultantShiftsMemo = useMemo(() => {
    return (state: RootState) => selectConsultantShifts(state, consultantId)
  }, [consultantId])
  const selectConsultantMemo = useMemo(() => {
    return (state: RootState) => selectConsultant(state, consultantId)
  }, [consultantId])

  const shifts = useSelector(selectConsultantShiftsMemo)
  const consultant = useSelector(selectConsultantMemo)
  const availableShifts = useSelector(availableShiftsSelector)
  const filteredAvailableShifts = availableShifts.msUnique(
    (shift1, shift2) => shift1.id === shift2.id,
  )

  const [acceptedQtfsState, setAcceptedQtfsState] = useState<
    number[] | undefined
  >(undefined)

  useEffect(() => {
    if (!consultant) {
      return
    }

    setAcceptedQtfsState(consultant.acceptedQtfs)
  }, [setAcceptedQtfsState, consultant])

  const updateConsultantQtfs = (
    consultantId: string,
    acceptedQtfs: number[],
  ) => {
    dispatch(updateConsultantAcceptedQtfs({ consultantId, acceptedQtfs }))
  }

  const navigate = useNavigate()
  const handleAvailableChange = (params: {
    consultantId: string
    newIsAvailable: boolean
  }) => {
    dispatch(
      updateConsultantAvailability({
        consultantId: params.consultantId,
        isAvailable: params.newIsAvailable,
      }),
    )
  }
  const onDeleteShift = (shiftId: string) => {
    if (!consultantId) {
      return
    }
    ShiftsClient.unattach(shiftId, consultantId).then(() => {
      dispatch(updateShifts())
    })
  }

  const onAttachShift = (shiftDto: ShiftDto) => {
    if (!consultantId) {
      return
    }
    ShiftsClient.attach(shiftDto.id, consultantId).then(() => {
      dispatch(updateShifts())
    })
  }

  const onCreateCustomShift = (createShiftRequest: CreateShiftRequest) => {
    if (!consultantId) {
      return
    }

    ShiftsClient.createAndAttach(createShiftRequest, consultantId).then(() => {
      dispatch(updateShifts())
    })
  }

  const calculateNewAcceptedQtfs = (
    currentState: number[],
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.checked && !currentState.includes(parseInt(e.target.value))) {
      return [...currentState, parseInt(e.target.value)]
    } else if (
      !e.target.checked &&
      currentState.includes(parseInt(e.target.value))
    ) {
      return currentState.filter((qtf) => qtf !== parseInt(e.target.value))
    }
    return currentState
  }

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!consultantId || acceptedQtfsState === undefined) {
      return
    }
    const newState = calculateNewAcceptedQtfs(acceptedQtfsState, e)
    setAcceptedQtfsState(newState)
    updateConsultantQtfs(consultantId, newState)
  }

  if (!consultant) {
    return <Navigate to="/sales-management" />
  }

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      paddingX="48px"
      paddingBottom="24px"
    >
      <Box display="flex" flexDirection="column" gap="32px" overflow="scroll">
        <Box paddingTop="32px">
          <BackButton
            onClick={() => {
              navigate("/sales-management")
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          paddingTop="32px"
        >
          <Typography variant="h4" textAlign="left" textTransform="capitalize">
            {consultant.name}
          </Typography>
          <Box display="flex" alignItems="center">
            <Switch
              checked={consultant.isAvailable}
              onChange={(e) =>
                handleAvailableChange({
                  consultantId: consultant.id,
                  newIsAvailable: e.target.checked,
                })
              }
            />
            <Typography variant="body1" color="textSecondary">
              Available
            </Typography>
          </Box>
        </Box>
        {!consultant.isAvailable && (
          <Alert severity="error">
            {consultant.name} is set as unavailable so he won't receive any new
            leads
          </Alert>
        )}
        <Box display="flex" flexDirection="row" gap="24px" width="100%">
          <ConsultantShifts shifts={shifts} onDeleteShift={onDeleteShift} />
          <CreateOrAttachShift
            availableShifts={filteredAvailableShifts}
            onAttachShift={onAttachShift}
            onCreateShift={onCreateCustomShift}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          bgcolor="white"
          gap="24px"
          padding="24px"
        >
          <Typography variant="h6">Preferences</Typography>
          <Box display="flex">
            <Box display="flex" flexDirection="column" flex="1">
              <Typography variant="body1" paddingBottom="8px">
                Select the QTFs you would like this consultant to receive
              </Typography>
              {acceptedQtfsState === undefined ? (
                <LoadingComponent loadedComponents="" />
              ) : (
                <FormGroup row>
                  {[1, 2, 3, 4, 5].map((qtf) => (
                    <FormControlLabel
                      key={qtf}
                      control={
                        <Checkbox
                          value={qtf}
                          checked={acceptedQtfsState.includes(qtf)}
                          onChange={(e) => onCheckboxChange(e)}
                        />
                      }
                      label={qtf}
                    />
                  ))}
                </FormGroup>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
