import { useAppSelector } from "@app/hooks"
import { selectUserLoginState } from "@features/login/loginSelectors"
import { Box, Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { Link, useNavigate } from "react-router-dom"
import appTheme from "../../theme/appTheme"
import { GeneralNavigationSideBar } from "./generalNavigationSidebar"
import { useEffect } from "react"
import {
  TabIdentifier,
  tabIdentifierToRouteDescriptor,
  tabIdentifierToTitle,
  tabsSelector,
} from "@features/app-tabs/tabs.selector"

function MainSidebar(props: { selectedTab: TabIdentifier | undefined }) {
  const navigate = useNavigate()

  const login = useAppSelector(selectUserLoginState)
  const tabs = useAppSelector(tabsSelector)
  const selectedTab = props.selectedTab

  useEffect(() => {
    if (tabs === "unknown") {
      return
    }
    if (!selectedTab) {
      const mainRoute = tabIdentifierToRouteDescriptor[tabs[0]][0]
      navigate(mainRoute.path)
    }
  }, [selectedTab, tabs, navigate])

  const header = (
    <Stack
      component={Link}
      sx={{
        my: 4,
        mx: 3,
        alignItems: "flex-start",
        textDecoration: "none",
        color: "primary.main",
      }}
      to="/"
    >
      <SvgIcon
        component={appIcons.masterschoolWithText}
        sx={{ height: 20, width: "auto" }}
        inheritViewBox
      />
      <Typography variant="body3" ml="26px">
        BackOffice
      </Typography>
    </Stack>
  )

  const sideBarItems =
    tabs !== "unknown"
      ? tabs.map((tab) => {
          const routesDescriptor = tabIdentifierToRouteDescriptor[tab]
          const mainRoute = routesDescriptor[0]
          return (
            <SidebarItem
              text={tabIdentifierToTitle[tab]}
              isSelected={selectedTab === tab}
              onClick={() => {
                navigate("/" + mainRoute.path)
              }}
              key={mainRoute.path}
            />
          )
        })
      : []

  if (login !== "logged-in" || tabs === "unknown") {
    return null
  }
  return selectedTab ? (
    <GeneralNavigationSideBar
      tabs={sideBarItems}
      header={header}
      sx={{
        width: "240px",
        maxWidth: "240px",
        minWidth: "240px",
        minHeight: "100%",
        bgcolor: appTheme.palette.eTypes.sand25,
      }}
      listSx={{
        display: "flex",
        flexDirection: "column",
        mt: "0px",
        padding: "0px",
      }}
    />
  ) : null
}

function SidebarItem(props: {
  text: string
  isSelected: boolean
  onClick: () => void
}) {
  const { text, isSelected, onClick } = props
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "16px 24px",
        cursor: "pointer",
        bgcolor: isSelected ? appTheme.palette.eTypes.sand50 : "transparent",
      }}
      onClick={onClick}
    >
      <Typography variant={isSelected ? "body1_sb" : "body1"}>
        {text}
      </Typography>
    </Box>
  )
}

export default MainSidebar
