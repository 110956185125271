import { snackbarContentClasses } from "@mui/material"
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles"

const MuiSnackbarContentOverrides: {
  defaultProps?: ComponentsProps["MuiSnackbarContent"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiSnackbarContent"]
  variants?: ComponentsVariants["MuiSnackbarContent"]
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.eTypes.evergreen,
      padding: "4px 24px",
      flexGrow: 0,
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        minWidth: "fit-content",
      },
      [`& .${snackbarContentClasses.message}`]: {
        display: "flex",
        alignItems: "center",
      },
    }),
  },
}

export { MuiSnackbarContentOverrides }
