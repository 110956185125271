import { Box } from "@mui/material"
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react"

export interface ScrollableRef {
  scrollToChild: (index: number) => void
}

type CBGridProps = { children: JSX.Element[]; elementWidth: number }

const CBGrid = forwardRef<ScrollableRef, CBGridProps>((props, exportedRef) => {
  const ref = useRef<HTMLDivElement>(null)
  const [columns, setColumns] = useState(0)
  const childrenRefs = useRef<HTMLDivElement[]>([])

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    const resizeObserver = new ResizeObserver((callback) => {
      const width = callback[0]?.contentRect.width
      if (width) {
        setColumns(Math.floor(width / props.elementWidth))
      }
    })

    resizeObserver.observe(ref.current)

    return () => resizeObserver.disconnect()
  }, [props.elementWidth])

  const scrollToChild = useCallback((index: number) => {
    const child = childrenRefs.current[index]
    if (!child) {
      return
    }
    child.scrollIntoView({ behavior: "smooth" })
  }, [])

  useImperativeHandle(
    exportedRef,
    () => ({
      scrollToChild: scrollToChild,
    }),
    [scrollToChild],
  )

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
        gap: "16px",
        overflowY: "auto",
        height: "fit-content",
      }}
    >
      {props.children.map((child, index) => (
        <div
          key={index}
          ref={(el) => {
            if (el) {
              childrenRefs.current[index] = el
            }
          }}
        >
          {child}
        </div>
      ))}
    </Box>
  )
})

export default CBGrid
