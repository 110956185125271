import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  onboardPopupClosed,
  onboardSingleStudentFormUpdated,
  onboardStudentClassSelected,
} from "@features/program/programSlice"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import {
  fetchClasses,
  registerStudent,
} from "@features/program/programSliceThunks"
import { UserDto } from "@masterschool/course-builder-api"
import { Stack, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import EditorTextField from "../../../editor/components/editorTextField"
import ProgramClassSelector from "./programClassSelector"

function OnboardSingleStudentPopup() {
  const { programId } = useParams()
  const model = useAppSelector(
    (state) => state.program.studentsPage.singleStudentOnboardPopup,
  )
  const dispatch = useAppDispatch()
  const classes = useAppSelector(selectClassesForProgram(programId))

  const formKeys: (keyof Omit<
    UserDto,
    "id" | "roles" | "careerSuccessAdvisor"
  >)[] = ["firstName", "lastName", "email"]

  const onAddStudent = () => {
    if (!model || !model.classId) {
      return
    }

    dispatch(
      registerStudent({
        classId: model.classId,
        student: model.form,
      }),
    ).then(() => {
      if (!programId) {
        return
      }
      dispatch(
        fetchClasses({
          programId: programId,
        }),
      )
    })
  }

  return (
    <GenericDialog
      open={model !== undefined}
      onClose={() => dispatch(onboardPopupClosed())}
      size="xs"
      title="Add a student"
      content={
        <Stack gap={2}>
          {model?.isClassEditable && (
            <ProgramClassSelector
              classes={classes}
              selectedClass={classes.find((c) => c.id === model.classId)}
              size="medium"
              allowNone={false}
              onClassChange={(classId) => {
                dispatch(onboardStudentClassSelected({ classId }))
              }}
              placeholder="Choose a class"
            />
          )}
          {formKeys.map((key) => (
            <EditorTextField
              key={key}
              debounce
              label={labelForKey(key)}
              size="medium"
              value={model?.form?.[key] ?? ""}
              onChange={(e) =>
                dispatch(
                  onboardSingleStudentFormUpdated({
                    key: key,
                    value: e.target.value.trim(),
                  }),
                )
              }
              error={key === "email" && model?.errorMessage !== undefined}
              helperText={
                key === "email" ? (
                  <HelperTextNode text={model?.errorMessage} />
                ) : undefined
              }
            />
          ))}
        </Stack>
      }
      buttons={[
        {
          type: "primary",
          text: "Add student",
          onClick: onAddStudent,
          disabled:
            model?.classId === undefined ||
            model?.form.email === "" ||
            model?.form.firstName === "" ||
            model?.form.lastName === "",
          loading: model?.state === "pending",
        },
      ]}
    />
  )
}

function labelForKey(
  key:
    | "email"
    | "firstName"
    | "lastName"
    | "slackId"
    | "mentor"
    | "careerSuccessAdvisor",
) {
  switch (key) {
    case "firstName":
      return "First name"
    case "lastName":
      return "Last name"
    case "email":
      return "Email"
    case "slackId":
      return "Slack ID"
    case "mentor":
      return "Mentor"
  }
}

const HelperTextNode = (props: { text: string | undefined }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  if (!props.text?.includes("in program")) {
    return props.text
  }
  const wordsInText = props.text.split(" ")
  const programId = wordsInText[wordsInText.length - 1].replaceAll("'", "")
  const textBeforeProgramId = props.text.split(`'${programId}'`)[0]

  return (
    <Typography variant="body3" color="text.warning">
      {textBeforeProgramId}
      <Typography
        component="span"
        variant="body3"
        color="text.warning"
        onClick={() => {
          dispatch(onboardPopupClosed())
          navigate(`/program/${programId}?tab=students`)
        }}
        sx={{ cursor: "pointer", textDecoration: "underline" }}
      >
        '{programId}' (click to navigate)
      </Typography>
    </Typography>
  )
}

export default OnboardSingleStudentPopup
