import { useAppDispatch, useAppSelector } from "@app/hooks"
import Droppable from "@cmp/droppable"
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { Box } from "@mui/material"
import { forwardRef, useCallback, useImperativeHandle, useRef } from "react"
import { createPortal } from "react-dom"
import {
  courseDraggedOver,
  courseRemoved,
  dragCourseEnded,
  dragCourseStarted,
} from "@features/syllabusEditor/syllabusEditorSlice"
import CourseRemovalButton from "./courseRemovalButton"

export interface UnitCoursesListRef {
  scrollToBottom: () => void
}

const UnitCoursesList = forwardRef<UnitCoursesListRef>(
  (props, forwardedRef) => {
    const dispatch = useAppDispatch()
    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 8,
        },
      }),
    )
    const popupState = useAppSelector(
      (state) => state.syllabusEditor.coursesSelectionPopup,
    )
    const draggedCourse = popupState?.selectedCourses.find(
      (cd) => cd.courseId === popupState.draggedCourseId,
    )
    const ref = useRef<HTMLDivElement>(null)

    const scrollToBottom = useCallback(() => {
      if (!ref.current) {
        return
      }

      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior: "smooth",
      })
    }, [])

    useImperativeHandle(
      forwardedRef,
      () => ({
        scrollToBottom: scrollToBottom,
      }),
      [scrollToBottom],
    )

    if (!popupState) {
      return null
    }

    return (
      <div
        style={{
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Box
          ref={ref}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            paddingTop: "16px",
            overflow: "auto",
          }}
        >
          <DndContext
            onDragStart={(start) => {
              dispatch(
                dragCourseStarted({ courseId: start.active.id.toString() }),
              )
            }}
            onDragEnd={() => {
              dispatch(dragCourseEnded())
            }}
            onDragCancel={() => {
              dispatch(dragCourseEnded())
            }}
            onDragOver={({ active, over }) => {
              if (!active || !over) {
                return
              }
              dispatch(
                courseDraggedOver({
                  sourceIndex: active.data.current?.sortable?.index,
                  targetIndex: over.data.current?.sortable?.index,
                }),
              )
            }}
            sensors={sensors}
          >
            <Droppable
              id={popupState.unitId}
              items={popupState.selectedCourses.map((cd, index) => {
                return {
                  element: (
                    <CourseRemovalButton
                      courseDescriptor={cd}
                      onRemoveClick={() => {
                        dispatch(
                          courseRemoved({
                            courseId: cd.courseId,
                          }),
                        )
                      }}
                      sx={{
                        opacity:
                          cd.courseId === popupState.draggedCourseId ? 0 : 1,
                        padding: "0px 24px 0 24px",
                      }}
                    />
                  ),
                  identifier: cd.courseId,
                }
              })}
              placeholder={<></>}
            />
            {ref.current &&
              createPortal(
                <DragOverlay
                  dropAnimation={{
                    duration: 300,
                    easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                  }}
                  style={{
                    zIndex: 9999999,
                  }}
                >
                  {draggedCourse ? (
                    <CourseRemovalButton
                      courseDescriptor={draggedCourse}
                      onRemoveClick={() => {}}
                      sx={{
                        transform: "scale(0.95, 1)",
                        padding: "0px 24px 0 24px",
                      }}
                    />
                  ) : null}
                </DragOverlay>,
                ref.current,
              )}
          </DndContext>
        </Box>
      </div>
    )
  },
)

export default UnitCoursesList
