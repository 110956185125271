import { useAppDispatch } from "@app/hooks"
import { EmailAlreadyTakenError, UsersClient } from "@clients/usersClient"
import { fetchUsersWithRoles } from "@features/account-management/accountManagementSlice"
import { fetchStudentByFetchingPrograms } from "@features/program/programSliceThunks"
import { SnackbarDuration, showSnackbarItem } from "@features/ui/uiSlice"
import { LoadingButton } from "@mui/lab"
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  SvgIcon,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { isEmail } from "class-validator"
import { useState } from "react"
import EditorTextField from "../../editor/components/editorTextField"

export function EditUserDetailsPopupForm(props: {
  userId: string
  userDetailsBeforeEdit: {
    firstName: string
    lastName: string
    email: string
  }
  isEmployee: boolean
  onClose: () => void
}) {
  const dispatch = useAppDispatch()
  const submitDetails = useSubmitUserDetails(
    props.userId,
    props.userDetailsBeforeEdit,
    props.isEmployee,
  )
  const [emailError, setEmailError] = useState<
    "emailAlreadyTaken" | "invalidAddress" | undefined
  >(undefined)

  const onSubmit = async (
    firstName: string,
    lastName: string,
    email: string,
  ) => {
    if (!isEmail(email)) {
      setEmailError("invalidAddress")
      return
    }
    await submitDetails(firstName, lastName, email)
      .then(() => {
        props.onClose()
        dispatch(
          showSnackbarItem({
            message: "Changes saved",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
      })
      .catch((err) => {
        if (err instanceof EmailAlreadyTakenError) {
          setEmailError("emailAlreadyTaken")
          return
        }
        throw err
      })
  }

  return (
    <UserDetailsForm
      detailsBeforeEdit={props.userDetailsBeforeEdit}
      onSubmit={onSubmit}
      emailError={emailError}
    />
  )
}

function UserDetailsForm(props: {
  detailsBeforeEdit: {
    firstName: string
    lastName: string
    email: string
  }
  emailError: "emailAlreadyTaken" | "invalidAddress" | undefined
  onSubmit: (
    firstName: string,
    lastName: string,
    email: string,
  ) => Promise<void>
}) {
  const { data, updateData, canSubmitForm, shouldConfirmEmail } =
    useDetailsFormLogic({
      ...props.detailsBeforeEdit,
      hasConfirmedEmail: false,
    })

  return (
    <Stack gap={"24px"} pt={"24px"}>
      <FirstNameTextField
        value={data.firstName}
        onChange={(value) => updateData("firstName", value)}
      />
      <LastNameTextField
        value={data.lastName}
        onChange={(value) => updateData("lastName", value)}
      />
      <EmailTextField
        value={data.email}
        onChange={(value) => updateData("email", value)}
        error={props.emailError}
      />
      <Box
        sx={{
          display: "flex",
          height: shouldConfirmEmail ? "110px" : "0px",
          transition: "all 0.3s ease-in-out",
          overflow: "hidden",
        }}
      >
        <EmailConfirmationAlertWithCheckbox
          hasConfirmedEmail={data.hasConfirmedEmail}
          setHasConfirmedEmail={(value) =>
            updateData("hasConfirmedEmail", value)
          }
        />
      </Box>

      <SubmitButton
        onSubmit={() =>
          props.onSubmit(data.firstName, data.lastName, data.email)
        }
        enabled={canSubmitForm}
      />
    </Stack>
  )
}

const FirstNameTextField = (props: {
  value: string
  onChange: (value: string) => void
}) => {
  return (
    <EditorTextField
      key="firstName"
      debounce
      label="First name"
      size="medium"
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value)
      }}
      error={false}
      helperText={undefined}
    />
  )
}

const LastNameTextField = (props: {
  value: string
  onChange: (value: string) => void
}) => {
  return (
    <EditorTextField
      key="lastName"
      debounce
      label="Last name"
      size="medium"
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value)
      }}
      error={false}
      helperText={undefined}
    />
  )
}

const EmailTextField = (props: {
  value: string
  onChange: (value: string) => void
  error: "emailAlreadyTaken" | "invalidAddress" | undefined
}) => {
  const errorMessage =
    props.error === "emailAlreadyTaken"
      ? "This email address is already taken."
      : props.error === "invalidAddress"
      ? "Please enter a valid email address."
      : undefined

  return (
    <EditorTextField
      key="email"
      debounce
      label="Email"
      size="medium"
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value)
      }}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  )
}

const EmailConfirmationAlertWithCheckbox = (props: {
  hasConfirmedEmail: boolean
  setHasConfirmedEmail: (value: boolean) => void
}) => {
  return (
    <Stack gap={"11px"}>
      <Alert
        severity="info"
        icon={
          <SvgIcon
            component={appIcons.infoCircle}
            inheritViewBox
            sx={{ fill: "none", width: 20, height: 20 }}
          />
        }
      >
        Please confirm the new email address is correct. Incorrect information
        will prevent the student from accessing campus services.
        <FormControlLabel
          control={
            <Checkbox
              value={props.hasConfirmedEmail}
              onChange={(e) => {
                props.setHasConfirmedEmail(e.target.checked)
              }}
            />
          }
          label="I have verified this."
        />
      </Alert>
    </Stack>
  )
}

const SubmitButton = (props: {
  onSubmit: () => Promise<void>
  enabled: boolean
}) => {
  const { onSubmit, enabled } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(false)

  return (
    <>
      <LoadingButton
        onClick={async () => {
          setError(false)
          setIsSubmitting(true)
          await onSubmit().catch(() => setError(true))
          setIsSubmitting(false)
        }}
        variant="contained"
        size="medium"
        sx={{ alignSelf: "flex-end" }}
        disabled={!enabled}
        loading={isSubmitting}
      >
        Save Changes
      </LoadingButton>
      {error && (
        <Alert
          severity="error"
          icon={
            <SvgIcon
              component={appIcons.infoCircle}
              inheritViewBox
              sx={{ fill: "none", width: 20, height: 20, rotate: "180deg" }}
            />
          }
        >
          An error occurred. Please try again later.
        </Alert>
      )}
    </>
  )
}

function useSubmitUserDetails(
  studentId: string,
  prevDetails: {
    firstName: string
    lastName: string
    email: string
  },
  isEmployee: boolean,
) {
  const dispatch = useAppDispatch()
  return async (firstName: string, lastName: string, email: string) => {
    if (hasNewValue(prevDetails.email, email)) {
      await UsersClient.editUserEmail(studentId, email)
    }
    if (
      hasNewValue(prevDetails.firstName, firstName) ||
      hasNewValue(prevDetails.lastName, lastName)
    ) {
      await UsersClient.editUserName(studentId, {
        firstName,
        lastName,
      })
    }
    dispatch(fetchStudentByFetchingPrograms({ studentId }))
    if (isEmployee) {
      dispatch(fetchUsersWithRoles())
    }
  }
}

type UserDetailsFormFields = {
  firstName: string
  lastName: string
  email: string
  hasConfirmedEmail: boolean
}

const hasNewValue = (prevValue: string, newValue: string) => {
  return !!newValue && prevValue !== newValue
}

function useDetailsFormLogic(studentDetailsBeforeEdit: UserDetailsFormFields) {
  const [details, setDetails] = useState(studentDetailsBeforeEdit)
  function updateData<K extends keyof UserDetailsFormFields>(
    key: K,
    value: UserDetailsFormFields[K],
  ) {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }))
  }
  const shouldConfirmEmail = hasNewValue(
    studentDetailsBeforeEdit.email,
    details.email,
  )
  const missingEmailConfirmation =
    shouldConfirmEmail && !details.hasConfirmedEmail

  const hasNewValues =
    hasNewValue(studentDetailsBeforeEdit.firstName, details.firstName) ||
    hasNewValue(studentDetailsBeforeEdit.lastName, details.lastName) ||
    hasNewValue(studentDetailsBeforeEdit.email, details.email)

  const canSubmitForm = hasNewValues && !missingEmailConfirmation

  return {
    data: details,
    updateData,
    shouldConfirmEmail,
    canSubmitForm,
  }
}

export default EditUserDetailsPopupForm
