import { store } from "@app/store"

export class CampusClient {
  static async get<T>(endpoint: string): Promise<T> {
    const request = CampusClient.makeRequest("GET")
    return CampusClient.fetch(endpoint, request)
  }

  static async post<T>(endpoint: string, body: any): Promise<T> {
    const request = CampusClient.makeRequest("POST", body)
    return CampusClient.fetch(endpoint, request)
  }

  static async put<T>(endpoint: string, body: any): Promise<T> {
    const request = CampusClient.makeRequest("PUT", body)
    return CampusClient.fetch(endpoint, request)
  }

  static async patch<T>(endpoint: string, body: any): Promise<T> {
    const request = CampusClient.makeRequest("PATCH", body)
    return CampusClient.fetch(endpoint, request)
  }

  static async delete<T>(endpoint: string, body?: any): Promise<T> {
    const request = CampusClient.makeRequest("DELETE", body)
    return CampusClient.fetch(endpoint, request)
  }

  private static fetch<T>(
    endpoint: string,
    requestInit: RequestInit,
  ): Promise<T> {
    const url = CampusClient.urlFromEndpoint(endpoint)

    return fetch(url, requestInit).then((res) =>
      CampusClient.handleResponse<T>(res),
    )
  }

  private static urlFromEndpoint(endpoint: string): string {
    let ep = endpoint.startsWith("/") ? endpoint : `/${endpoint}`
    const baseUrl = import.meta.env.VITE_ADMISSIONS_BE_URL + "/api"
    return baseUrl + ep
  }

  private static makeRequest(
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    body?: BodyInit,
  ): RequestInit {
    const accessToken = store.getState().login.accessToken
    const requestInit: RequestInit = {
      method,
    }
    if (accessToken !== null) {
      requestInit.headers = {
        Authorization: `Bearer ${accessToken}`,
      }
    }
    if (body) {
      requestInit.body = JSON.stringify(body)
      requestInit.headers = {
        ...requestInit.headers,
        "Content-Type": "application/json",
      }
    }
    return requestInit
  }

  private static handleResponse<T>(response: Response): Promise<T> {
    if (response.status < 400) {
      return response.text().then((data) => (data ? JSON.parse(data) : {}))
    } else {
      return new Promise<T>((res, rej) => {
        response.json().then(rej)
      })
    }
  }
}
