import { store } from "@app/store"
import {
  Configuration,
  CreateProjectRequest,
  ProjectApi,
  ProjectDto,
} from "@masterschool/course-builder-api"
import { selectAccessToken } from "@features/login/loginSelectors"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { fetchProjects } from "@features/projects-tab/projectsTabSlice"

export class ProjectClient {
  static api = new ProjectApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static listProjects() {
    return ProjectClient.api
      .projectControllerList(ProjectClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static createProject(project: CreateProjectRequest) {
    const req = ProjectClient.api
      .projectControllerCreate(project, ProjectClient.makeHeaders())
      .then((response) => response.data)
    return ProjectClient.executeWithSnackbar(req, "create")
  }

  static updateProject(project: ProjectDto) {
    const req = ProjectClient.api
      .projectControllerUpdate(project, ProjectClient.makeHeaders())
      .then((response) => response.data)
    return ProjectClient.executeWithSnackbar(req, "update")
  }

  static deleteProject(id: string) {
    const req = ProjectClient.api
      .projectControllerDelete(id, ProjectClient.makeHeaders())
      .then((response) => response.data)
    return ProjectClient.executeWithSnackbar(req, "delete")
  }

  private static executeWithSnackbar(req: Promise<any>, action: string) {
    const dispatch = store.dispatch
    return req
      .then(() => {
        dispatch(showSuccessSnackbar(`Project ${action}d successfully`))
        dispatch(fetchProjects())
      })
      .catch(() => {
        dispatch(showErrorSnackbar(`Failed to ${action} project`))
      })
  }
}
