import {
  AsyncThunkPayloadCreator,
  AsyncThunkOptions,
  AsyncThunk,
  createAsyncThunk,
  Dispatch,
} from "@reduxjs/toolkit"
import type { RootState } from "./store"

type AsyncThunkConfig = {
  state?: unknown
  dispatch?: Dispatch
  extra?: unknown
  rejectValue?: unknown
  serializedErrorType?: unknown
  pendingMeta?: unknown
  fulfilledMeta?: unknown
  rejectedMeta?: unknown
}
type TypedCreateAsyncThunk<ThunkApiConfig extends AsyncThunkConfig> = <
  Returned,
  ThunkArg = void,
  RejectedValue = unknown,
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
) => AsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig & { rejectValue: RejectedValue }
>

export const createAppAsyncThunk: TypedCreateAsyncThunk<{
  state: RootState
}> = createAsyncThunk
