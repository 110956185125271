import { useAppDispatch, useAppSelector } from "@app/hooks"
import { ProgramStaffMember } from "@clients/programStaffClient"
import { UsersClient } from "@clients/usersClient"
import CustomTooltip from "@cmp/customTooltip"
import { MultipleOptionsSelector } from "@cmp/form-components/multipleOptionsSelector"
import GenericDialog from "@cmp/genericDialog"
import { Role } from "@features/login/loginSlice"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import {
  addProgramStaffMemberPopupAccepted,
  editProgramStaffMemberPopupAccepted,
} from "@features/program/programSliceThunks"
import { UserDto } from "@masterschool/course-builder-api"
import {
  Alert,
  Box,
  Chip,
  FormControl,
  MenuItem,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { stringAsCamelCase } from "@utils/syllabusTags"
import { useEffect, useMemo, useState } from "react"

// used for custom styling
import "./manageProgramStaffDialog.css"
import { AssignedClassesSelector } from "./assignedClassesSelector"
import { EmployeeSelector } from "./employeeSelector"
import roleDisplayNames from "../../../account-management/roleDisplayName"
import { selectCanAccessAccountManagementTab } from "@features/ui/uiSelector"

export function AddStaffMemberDialog(props: {
  open: boolean
  onClose: () => void
  programId: string
  programCurrentStaff: ProgramStaffMember[]
}) {
  const { open, onClose, programId } = props

  return (
    <ManageStaffMemberDialog
      open={open}
      onClose={onClose}
      programId={programId}
      programCurrentStaff={props.programCurrentStaff}
    />
  )
}

export function EditStaffMemberDialog(props: {
  open: boolean
  onClose: () => void
  programId: string
  staffMember: ProgramStaffMember
}) {
  const { open, onClose, programId, staffMember } = props

  return (
    <ManageStaffMemberDialog
      open={open}
      onClose={onClose}
      programId={programId}
      staffMember={staffMember}
    />
  )
}

function ManageStaffMemberDialog(props: {
  open: boolean
  onClose: () => void
  programId: string
  staffMember?: ProgramStaffMember | undefined
  programCurrentStaff?: ProgramStaffMember[]
}) {
  const { open, onClose, programId, staffMember } = props

  const [msEmployees, setMSEmployees] = useState<UserDto[] | undefined>(
    undefined,
  )
  const [selectedEmployee, setSelectedEmployee] = useState<
    UserDto | undefined
  >()
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    staffMember?.roles ?? [],
  )
  const unselectedMentorRole =
    selectedEmployee?.roles.includes(Role.Mentor) &&
    !selectedRoles.includes(Role.Mentor)
  const [selectedClassesIDs, setSelectedClassesIDs] = useState<string[]>(
    staffMember?.properties?.assignedClasses ?? [],
  )

  const programClasses = useAppSelector(selectClassesForProgram(programId))
  const programStudents = useMemo(
    () => programClasses.flatMap((programClass) => programClass.students),
    [programClasses],
  )
  const [assignedStudentsIDs, setAssignedStudentsIDs] = useState<string[]>([])
  const dispatch = useAppDispatch()

  const popupHeader = staffMember
    ? `Edit ${staffMember.name}'s roles`
    : "Add staff member"
  let cta = staffMember ? "Save changes" : "Add staff member"

  const onCTAClick = () => {
    const assignedClasses = selectedRoles.includes("instructor")
      ? selectedClassesIDs
      : []
    if (staffMember) {
      const originallyAssignedStudents = filterAssignedStudentsIDs(
        programStudents,
        staffMember.userClientId,
      )
      const studentsToAssign = unselectedMentorRole
        ? []
        : assignedStudentsIDs.filter(
            (studentId) => !originallyAssignedStudents.includes(studentId),
          )
      const studentsToUnassign = unselectedMentorRole
        ? originallyAssignedStudents
        : originallyAssignedStudents.filter(
            (studentId) => !assignedStudentsIDs.includes(studentId),
          )
      dispatch(
        editProgramStaffMemberPopupAccepted({
          programId,
          userClientId: staffMember.userClientId,
          roles: selectedRoles,
          properties: { assignedClasses },
          studentsToAssign,
          studentsToUnassign,
        }),
      ).then(() => onClose())
    } else {
      if (!selectedEmployee) return
      dispatch(
        addProgramStaffMemberPopupAccepted({
          programId,
          userClientId: selectedEmployee.id,
          roles: selectedRoles,
          properties: { assignedClasses },
          studentsToAssign: assignedStudentsIDs,
        }),
      ).then(() => onClose())
    }
  }

  useEffect(() => {
    if (open === false) {
      setSelectedRoles([])
      setSelectedEmployee(undefined)
      setMSEmployees(undefined)
      setAssignedStudentsIDs([])
      setSelectedClassesIDs([])
      return
    }
    if (staffMember) {
      UsersClient.findUserById(staffMember.userClientId).then((user) => {
        setSelectedEmployee(user)
        setSelectedRoles(staffMember.roles)
        setAssignedStudentsIDs(
          filterAssignedStudentsIDs(programStudents, staffMember.userClientId),
        )
      })
      return
    }
    UsersClient.getUsersWithRoles([
      Role.Mentor,
      Role.Instructor,
      Role.ProgramManager,
    ])
      .then((employees) => {
        return employees.map((e) => ({
          ...e,
          name: e.firstName + " " + e.lastName,
        }))
      })
      .then((employees) => {
        employees.sort((a, b) => a.name.localeCompare(b.name))
        return employees
      })
      .then((employees) => setMSEmployees(employees))
  }, [open, staffMember, programStudents])

  const showClassesSelection = selectedRoles.includes("instructor")
  const showMenteesSelection = selectedRoles.includes("mentor")
  const isEditMode = staffMember !== undefined
  const isAddNewMemberMode = !isEditMode
  const isRemovingStaffMember = isEditMode && selectedRoles.length === 0
  if (isRemovingStaffMember) cta = "Remove staff member"
  const isSaveDisabled =
    isAddNewMemberMode &&
    (selectedRoles.length === 0 || selectedEmployee === undefined)

  return (
    <>
      <GenericDialog
        open={open}
        onClose={onClose}
        size="sm"
        title={popupHeader}
        content={
          <Stack gap={2}>
            {staffMember === undefined && (
              <EmployeeSelector
                employees={msEmployees ?? []}
                loading={msEmployees === undefined}
                selectedEmployee={selectedEmployee}
                onEmployeeChange={setSelectedEmployee}
                programCurrentStaff={props.programCurrentStaff ?? []}
              />
            )}
            <StaffRoleSelector
              selectedRoles={selectedRoles}
              onRolesChange={setSelectedRoles}
              selectedEmployee={selectedEmployee}
            />
            {isRemovingStaffMember && (
              <RoleRemovalWarning
                title="You're removing a staff member"
                body="They will be removed from all program slack channels."
              />
            )}
            {unselectedMentorRole && assignedStudentsIDs.length > 0 && (
              <RoleRemovalWarning body="Removing the mentor role will unassign any students from this staff member." />
            )}
            {showClassesSelection && (
              <AssignedClassesSelector
                selectedClassesIDs={selectedClassesIDs}
                onSelectedClassesChange={setSelectedClassesIDs}
                programClasses={programClasses}
                helperText="Can be assigned later"
                label={"Select classes for instructor"}
              />
            )}
            {showMenteesSelection && (
              <AssignedStudentsSelector
                selectedStudentIDs={assignedStudentsIDs}
                onSelectedStudentsChange={setAssignedStudentsIDs}
                programStudents={programStudents}
              />
            )}
          </Stack>
        }
        buttons={[
          {
            type: "secondary",
            text: "Cancel",
            onClick: onClose,
          },
          {
            type: isRemovingStaffMember ? "danger" : "primary",
            text: cta,
            onClick: onCTAClick,
            disabled: isSaveDisabled,
          },
        ]}
      />
    </>
  )
}

type StaffRole = Role.Instructor | Role.ProgramManager | Role.Mentor

function StaffRoleSelector(props: {
  selectedRoles?: string[] | undefined
  onRolesChange: (roles: string[]) => void
  selectedEmployee: UserDto | undefined
}) {
  const { selectedRoles, onRolesChange, selectedEmployee } = props
  const getOptionDisabled = (role: StaffRole) => {
    if (selectedRoles?.includes(role)) return false
    if (!selectedEmployee) return true
    return !selectedEmployee.roles.includes(role)
  }
  const canAccessAccountManager = useAppSelector(
    selectCanAccessAccountManagementTab,
  )
  const title = (role: string) => (
    <Typography color="white">
      This staff member was not defined as '{role}'. You can change this in the{" "}
      {canAccessAccountManager ? (
        <a
          href="/account-management"
          target="_blank"
          style={{ color: "white" }}
        >
          Account management
        </a>
      ) : (
        "Account management"
      )}{" "}
      tab.
    </Typography>
  )

  return (
    <FormControl>
      <MultipleOptionsSelector
        options={[Role.Instructor, Role.Mentor, Role.ProgramManager]}
        label="Select roles"
        selectedOptions={selectedRoles ?? []}
        onChange={(selectedRoles) => onRolesChange(selectedRoles)}
        getOptionLabel={roleDisplayNames}
        renderOption={(props, role) => {
          return (
            <MenuItem {...props} key={role}>
              <CustomTooltip
                title={title(role)}
                placement="right"
                leaveDelay={0}
                disableTooltip={!getOptionDisabled(role)}
              >
                <Typography variant="body1" width="50%">
                  {roleDisplayNames(role)}
                </Typography>
              </CustomTooltip>
            </MenuItem>
          )
        }}
        disabled={selectedEmployee === undefined}
        getOptionDisabled={getOptionDisabled}
        renderTag={(role, index, getTagProps) => (
          <Chip
            label={stringAsCamelCase(role)}
            size="small"
            sx={{ width: "fit-content" }}
            {...getTagProps({ index })}
          />
        )}
      />
    </FormControl>
  )
}

const AssignedStudentsSelector = (props: {
  selectedStudentIDs: string[]
  onSelectedStudentsChange: (studentsIDs: string[]) => void
  programStudents: UserDto[]
}) => {
  const { selectedStudentIDs, onSelectedStudentsChange, programStudents } =
    props
  const students = programStudents.map((student) => ({
    ...student,
    name: student.firstName + " " + student.lastName,
  }))
  const noOptionsText =
    students.length > 0
      ? "Student not found in program"
      : "No students were added yet"

  return (
    <FormControl>
      <MultipleOptionsSelector
        options={students}
        label="Assign students to mentor"
        selectedOptions={selectedStudentIDs}
        onChange={onSelectedStudentsChange}
        helperText="Can be assigned later"
        disableCloseOnSelect
        noOptionsText={noOptionsText}
        renderOption={(props, student) => {
          return (
            <MenuItem {...props} key={student.id}>
              <Box display="flex" width="100%" justifyContent="space-between">
                <Typography variant="body1">
                  {student.firstName} {student.lastName}
                </Typography>
                {student.mentor && (
                  <Typography variant="body3" color="text.secondary">
                    Current mentor:{" "}
                    {student.mentor.firstName + " " + student.mentor.lastName}
                  </Typography>
                )}
              </Box>
            </MenuItem>
          )
        }}
        getOptionLabel={(student) => student.name}
        renderTag={(student, index, getTagProps) => (
          <Chip
            label={student.name}
            size="small"
            sx={{ width: "fit-content" }}
            {...getTagProps({ index })}
          />
        )}
      />
    </FormControl>
  )
}

function filterAssignedStudentsIDs(
  students: UserDto[],
  mentorClientId: string,
): string[] {
  return students
    .filter((student) => student.mentor?.userClientId === mentorClientId)
    .map((student) => student.id)
}

const RoleRemovalWarning = (props: { title?: string; body: string }) => {
  return (
    <Alert
      severity="warning"
      icon={
        <SvgIcon
          component={appIcons.alertTriangle}
          inheritViewBox
          sx={{ fill: "none", width: 20, height: 20 }}
        />
      }
    >
      {props.title && (
        <Typography variant="body1_sb" color="currentcolor">
          {props.title}
        </Typography>
      )}
      <Typography variant="body2" color="currentcolor">
        {props.body}
      </Typography>
    </Alert>
  )
}
