import { ProgramClient } from "@clients/programClient"
import { showErrorSnackbar } from "@features/ui/uiSlice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export type ProgramProjectsState = {
  projects: any[]
  loadingState: "idle" | "loading" | "failed"
}

export const getInitialState: () => ProgramProjectsState = () => {
  return {
    projects: [],
    loadingState: "idle",
  }
}

export const programProjectsSlice = createSlice({
  name: "programProjects",
  initialState: getInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgramProjects.pending, (state) => {
        state.loadingState = "loading"
      })
      .addCase(fetchProgramProjects.rejected, (state) => {
        state.loadingState = "failed"
      })
      .addCase(fetchProgramProjects.fulfilled, (state, action) => {
        state.loadingState = "idle"
        state.projects = action.payload
      })
  },
})

export const fetchProgramProjects = createAsyncThunk(
  "programProjects/fetchProgramProjects",
  async (programId: string, thunkAPI) => {
    return ProgramClient.listProjects(programId).catch((e) => {
      thunkAPI.dispatch(showErrorSnackbar("Failed to fetch projects"))
      return []
    })
  },
)

export default programProjectsSlice.reducer
