import { Box } from "@mui/material"
import SnackbarPresenter from "../main/snackbars/snackbarPresenter"
import PopupPresenter from "../popups/infra/popupPresenter"
import appTheme from "../theme/appTheme"
import AppHeader from "./header/appHeader"
import { useAppSelector } from "@app/hooks"
import { selectShouldShowProductionWarning } from "@features/ui/uiSelector"
import { TabIdentifier } from "@features/app-tabs/tabs.selector"
import MainSidebar from "./sidebar/mainSidebar"

export function PageContainer(props: {
  selectedTab: TabIdentifier | undefined
  children?: React.ReactNode
}) {
  const shouldShowFrame = useAppSelector(selectShouldShowProductionWarning)

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          bgcolor: appTheme.palette.background.default,
          border: shouldShowFrame ? "red solid 10px" : "none",
        }}
      >
        <MainSidebar selectedTab={props.selectedTab} />
        <Box
          sx={{
            maxHeight: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AppHeader />
          {props.children}
        </Box>
      </Box>
      <SnackbarPresenter />
      <PopupPresenter />
    </>
  )
}
