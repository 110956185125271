import { ProgramClassDto } from "@masterschool/course-builder-api"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import appTheme from "../../../theme/appTheme"

function ProgramClassSelector(props: {
  classes: ProgramClassDto[]
  selectedClass?: ProgramClassDto | undefined
  onClassChange: (classId?: string) => void
  placeholder?: string
  size: "small" | "medium"
  allowNone?: boolean
}) {
  const {
    classes,
    selectedClass,
    onClassChange,
    placeholder,
    size,
    allowNone,
  } = props

  return (
    <FormControl>
      {placeholder && (
        <InputLabel
          sx={{
            fontSize: "17px",
          }}
        >
          {placeholder}
        </InputLabel>
      )}
      <Select
        label={placeholder}
        displayEmpty={allowNone}
        sx={{
          bgcolor: appTheme.palette.primary.contrast,
        }}
        size={size}
        value={
          selectedClass && classes.find((c) => c.id === selectedClass.id)
            ? selectedClass.name
            : ""
        }
        onChange={(e) => onClassChange(e.target.value)}
        renderValue={(value) => value || "All classes"}
      >
        {allowNone && <MenuItem value="">All classes</MenuItem>}
        {classes.map((c) => (
          <MenuItem
            key={c.id}
            value={c.id}
            sx={{
              bgcolor: appTheme.palette.primary.contrast,
            }}
          >
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ProgramClassSelector
