import appIcons from "@utils/appIcons"
import OptionsButton from "@cmp/buttons/optionsButton"

export enum AddUnitItemType {
  ExistingCourse = "Existing course",
  NewCourse = "New course",
}

export function LinkCourseButton(props: {
  onClick: (itemType: AddUnitItemType) => void
}) {
  const { onClick } = props
  const sxItem = { height: 20, width: 20 }

  return (
    <OptionsButton
      button={{
        variant: "outlined",
        size: "small",
        text: "Link course",
        sx: {
          width: "100%",
        },
        leftIcon: appIcons.plus,
        iconSx: {
          fill: "none",
          stroke: "black",
        },
      }}
      items={[
        {
          text: "Existing course",
          icon: appIcons.course,
          sxIcon: sxItem,
          onSelect: () => {
            onClick(AddUnitItemType.ExistingCourse)
          },
        },
        {
          text: "New course",
          icon: appIcons.plus,
          sxIcon: sxItem,
          onSelect: () => {
            onClick(AddUnitItemType.NewCourse)
          },
        },
      ]}
    />
  )
}

export default LinkCourseButton
