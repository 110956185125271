import GenericDialog from "@cmp/genericDialog"
import { LoadingButton } from "@mui/lab"
import { useState } from "react"

export const RevertButton = (props: {
  doRevert: () => Promise<void>
  shouldVerify: boolean
}) => {
  const { doRevert, shouldVerify } = props
  const [isReverting, setIsReverting] = useState(false)
  const [showRevertVerification, setShowRevertVerification] = useState(false)

  const onRevertClicked = async () => {
    if (shouldVerify) {
      setShowRevertVerification(true)
    } else {
      setIsReverting(true)
      await doRevert().finally(() => setIsReverting(false))
    }
  }

  return (
    <>
      {showRevertVerification && (
        <RevertVerificationDialog
          onClose={() => setShowRevertVerification(false)}
          onVerifiedRevert={doRevert}
        />
      )}
      <LoadingButton
        loading={isReverting}
        variant="outlined"
        size="small"
        sx={{
          width: "fit-content",
          alignSelf: "flex-end",
        }}
        onClick={onRevertClicked}
      >
        Revert to this version
      </LoadingButton>
    </>
  )
}

const RevertVerificationDialog = (props: {
  onClose: () => void
  onVerifiedRevert: () => void
}) => {
  const { onClose, onVerifiedRevert } = props
  return (
    <GenericDialog
      open
      size="xs"
      title="Revert to this version?"
      subtitle="Any changes you’ve made to the course will be deleted."
      onClose={onClose}
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: onClose,
        },
        {
          text: "Revert",
          type: "primary",
          onClick: () => {
            onVerifiedRevert()
            onClose()
          },
        },
      ]}
    />
  )
}
