import { DeleteIcon } from "@cmp/buttons/deleteButton"
import { Theme } from "@emotion/react"
import { MasterschoolTask } from "@masterschool/course-builder-api"
import { Box, SxProps, Typography } from "@mui/material"
import appTheme from "../../../../theme/appTheme"
import TimeEstimationTextField from "../../../components/timeEstimationTextField"
import TitleTextField from "../../../components/titleTextField"
import UrlTextField from "../../../components/urlTextField"
import DragTaskIcon from "./dragTaskIcon"
import { taskCardBGColor } from "./taskCardBGColor"

function MasterschoolTaskEditor(props: {
  task: MasterschoolTask
  index: number
  onChange: <K extends keyof MasterschoolTask>(
    key: K,
    value: MasterschoolTask[K],
  ) => void
  onDeleteClicked: () => void
  sx?: SxProps<Theme>
}) {
  const { task, index, onChange, onDeleteClicked, sx } = props

  if (!task) {
    return undefined
  }

  return (
    <Box
      key={`task ${index}`}
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all 0.2s ease-in-out",
        padding: "8px",
        gap: "8px",
        cursor: "default",
        ...sx,
      }}
    >
      <DragTaskIcon />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          borderRadius: "8px",
          padding: "16px 8px",
          alignItems: "center",
          gap: "8px",
          bgcolor: taskCardBGColor,
        }}
      >
        <Typography variant="body3" color="text.disabled" paddingX="7px">
          {isNaN(index) ? `??` : `${index + 1}.`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <TitleTextField
            elementId={task.id}
            value={task.title}
            debounce
            onChange={(e) => {
              onChange("title", e.target.value)
            }}
            label="Task name"
          />
          <Box
            display="flex"
            gap="8px"
            width="100%"
            justifyContent="space-between"
          >
            <UrlTextField
              elementId={task.id}
              value={task.url}
              debounce
              onChange={(e) => {
                onChange("url", e.target.value)
              }}
            />
            <TimeEstimationTextField
              elementId={task.id}
              value={task.estimatedDuration}
              debounce
              onChange={(e) => {
                onChange("estimatedDuration", parseInt(e.target.value))
              }}
              sx={{ width: "50%" }}
            />
          </Box>
        </Box>
        <DeleteIcon onClick={onDeleteClicked} />
      </Box>
    </Box>
  )
}

export default MasterschoolTaskEditor
