import AlertCircleIcon from "@icons/alert-circle.svg?react"
import AlertTriangleIcon from "@icons/alert-triangle.svg?react"
import ArrowLeftIcon from "@icons/arrow-left.svg?react"
import ArrowRightIcon from "@icons/arrow-right.svg?react"
import ArrowSmRight01Icon from "@icons/arrow-sm-right-01.svg?react"
import Atom02Icon from "@icons/atom-02.svg?react"
import BankIcon from "@icons/bank.svg?react"
import BookOpen01Icon from "@icons/book-open-01.svg?react"
import Building06Icon from "@icons/building-06.svg?react"
import CalendarCheckIcon from "@icons/calendar-check.svg?react"
import CalendarMinusLockIcon from "@icons/calendar-minus-lock.svg?react"
import CalendarMinusTimeIcon from "@icons/calendar-minus-time.svg?react"
import CalendarPlusIcon from "@icons/calendar-plus.svg?react"
import CalendarIcon from "@icons/calendar.svg?react"
import CheckIcon from "@icons/check.svg?react"
import ChevronDownIcon from "@icons/chevron-down.svg?react"
import ChevronRightIcon from "@icons/chevron-right.svg?react"
import ClockIcon from "@icons/clock.svg?react"
import CodeBrowserIcon from "@icons/code-browser.svg?react"
import CodeSquare01Icon from "@icons/code-square-01.svg?react"
import Copy06Icon from "@icons/copy-06.svg?react"
import CourseFilledIcon from "@icons/course-filled.svg?react"
import CourseIcon from "@icons/course.svg?react"
import DataIcon from "@icons/data.svg?react"
import DotsGridIcon from "@icons/dots-grid.svg?react"
import DotsHorizontalIcon from "@icons/dots-horizontal.svg?react"
import DotsIcon from "@icons/dots.svg?react"
import Download02Icon from "@icons/download-02.svg?react"
import Edit02Icon from "@icons/edit-02.svg?react"
import Edit03Icon from "@icons/edit-03.svg?react"
import Edit05Icon from "@icons/edit-05.svg?react"
import EyeOffIcon from "@icons/eye-off.svg?react"
import EyeIcon from "@icons/eye.svg?react"
import File02Icon from "@icons/file-02.svg?react"
import FunnelIcon from "@icons/funnel.svg?react"
import GlobeIcon from "@icons/globe.svg?react"
import GoogleIconIcon from "@icons/google-icon.svg?react"
import Home05Icon from "@icons/home-05.svg?react"
import InfoCircleIcon from "@icons/info-circle.svg?react"
import Link01Icon from "@icons/link-01.svg?react"
import LinkBroken01Icon from "@icons/link-broken-01.svg?react"
import LockUnlocked01Icon from "@icons/lock-unlocked-01.svg?react"
import MasterschoolBackOfficeIcon from "@icons/masterschool-backOffice.svg?react"
import MasterschoolLmsIcon from "@icons/masterschool-lms.svg?react"
import MasterschoolWithTextIcon from "@icons/masterschool-with-text.svg?react"
import MinusIcon from "@icons/minus.svg?react"
import PencilLineIcon from "@icons/pencil-line.svg?react"
import PlaceholderIcon from "@icons/placeholder.svg?react"
import PlayCircleIcon from "@icons/play-circle.svg?react"
import PlusCircleIcon from "@icons/plus-circle.svg?react"
import PlusIcon from "@icons/plus.svg?react"
import RefreshCcwIcon from "@icons/refresh-ccw.svg?react"
import RemoveIconIcon from "@icons/remove-icon.svg?react"
import ReverseLeftIcon from "@icons/reverse-left.svg?react"
import RugbyBallIcon from "@icons/rugby-ball.svg?react"
import SaveIcon from "@icons/save.svg?react"
import SearchSmIcon from "@icons/search-sm.svg?react"
import Settings01Icon from "@icons/settings-01.svg?react"
import SlackGreyscaleIcon from "@icons/slack-greyscale.svg?react"
import SlackIconIcon from "@icons/slack-icon.svg?react"
import SplitSprintIcon from "@icons/split-sprint.svg?react"
import Star01Icon from "@icons/star-01.svg?react"
import SyllabusesFilledIcon from "@icons/syllabuses-filled.svg?react"
import SyllabusesIcon from "@icons/syllabuses.svg?react"
import Trash03Icon from "@icons/trash-03.svg?react"
import UploadCloud01Icon from "@icons/upload-cloud-01.svg?react"
import UserPlus01Icon from "@icons/user-plus-01.svg?react"
import Users02Icon from "@icons/users-02.svg?react"
import VacationTreeIcon from "@icons/vacation-tree.svg?react"
import XCloseIcon from "@icons/x-close.svg?react"

const appIcons = {
  alertCircle: AlertCircleIcon,
  alertTriangle: AlertTriangleIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowSmRight01: ArrowSmRight01Icon,
  atom02: Atom02Icon,
  bank: BankIcon,
  bookOpen01: BookOpen01Icon,
  building06: Building06Icon,
  calendarCheck: CalendarCheckIcon,
  calendarMinusLock: CalendarMinusLockIcon,
  calendarMinusTime: CalendarMinusTimeIcon,
  calendarPlus: CalendarPlusIcon,
  calendar: CalendarIcon,
  check: CheckIcon,
  chevronDown: ChevronDownIcon,
  chevronRight: ChevronRightIcon,
  clock: ClockIcon,
  codeBrowser: CodeBrowserIcon,
  codeSquare01: CodeSquare01Icon,
  copy06: Copy06Icon,
  courseFilled: CourseFilledIcon,
  course: CourseIcon,
  data: DataIcon,
  dotsGrid: DotsGridIcon,
  dotsHorizontal: DotsHorizontalIcon,
  dots: DotsIcon,
  download02: Download02Icon,
  edit02: Edit02Icon,
  edit03: Edit03Icon,
  edit05: Edit05Icon,
  eyeOff: EyeOffIcon,
  eye: EyeIcon,
  file02: File02Icon,
  funnel: FunnelIcon,
  globe: GlobeIcon,
  googleIcon: GoogleIconIcon,
  home05: Home05Icon,
  infoCircle: InfoCircleIcon,
  link01: Link01Icon,
  linkBroken01: LinkBroken01Icon,
  lockUnlocked01: LockUnlocked01Icon,
  masterschoolBackOffice: MasterschoolBackOfficeIcon,
  masterschoolLms: MasterschoolLmsIcon,
  masterschoolWithText: MasterschoolWithTextIcon,
  minus: MinusIcon,
  pencilLine: PencilLineIcon,
  placeholder: PlaceholderIcon,
  playCircle: PlayCircleIcon,
  plusCircle: PlusCircleIcon,
  plus: PlusIcon,
  refreshCcw: RefreshCcwIcon,
  removeIcon: RemoveIconIcon,
  reverseLeft: ReverseLeftIcon,
  rugbyBall: RugbyBallIcon,
  save: SaveIcon,
  searchSm: SearchSmIcon,
  settings01: Settings01Icon,
  slackGreyscale: SlackGreyscaleIcon,
  slackIcon: SlackIconIcon,
  splitSprint: SplitSprintIcon,
  star01: Star01Icon,
  syllabusesFilled: SyllabusesFilledIcon,
  syllabuses: SyllabusesIcon,
  trash03: Trash03Icon,
  uploadCloud01: UploadCloud01Icon,
  userPlus01: UserPlus01Icon,
  users02: Users02Icon,
  vacationTree: VacationTreeIcon,
  xClose: XCloseIcon,
}

export default appIcons
