import { useAppDispatch, useAppSelector } from "@app/hooks"
import { pickPublishedSyllabusPopupOpened } from "@features/program/programSlice"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { Alert, Button, Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import SyllabusCard from "./syllabusCard/syllabusCard"
import { ProgramSyllabusData } from "./useGetAllProgramSyllabusesData"
import { useState } from "react"

const ProgramSyllabusesView = ({
  programSyllabuses,
  classId,
  defaultSyllabusId,
  programId,
}: {
  programSyllabuses: ProgramSyllabusData[]
  classId: string | undefined
  defaultSyllabusId: string | undefined
  programId: string
}) => {
  const programClasses = useAppSelector(selectClassesForProgram(programId))
  const classesWithSyllabus = programSyllabuses
    .flatMap((s) => s.classes)
    .msCompactMap((c) => c.id)
  const classesWithoutSyllabus = programClasses.filter(
    (c) => !classesWithSyllabus.includes(c.id),
  )
  const thisClass = classId
    ? programClasses.find((c) => c.id === classId)
    : undefined

  const classView = !!classId
  const showClassesWithSyllabusWarn =
    classesWithoutSyllabus.length > 0 && !classView
  const showThisClassHasDefaultSyllabusInfo =
    thisClass && !thisClass.offeringSyllabusId

  return (
    <Stack flex={1} gap={3} overflow="auto">
      <Stack mx="auto" width={1}>
        <Typography variant="h5">
          {classView
            ? "Class syllabus"
            : `Program syllabuses (${programSyllabuses.length})`}
        </Typography>
      </Stack>
      <Stack overflow="auto" pb={3}>
        <Stack m="auto" gap={3} width={1} maxWidth={{ xs: 600, md: 1 }}>
          {showClassesWithSyllabusWarn && <ClassesWithoutSyllabusWarning />}
          {showThisClassHasDefaultSyllabusInfo && (
            <ThisClassIsConnectedToDefaultSyllabusInfo />
          )}
          {!defaultSyllabusId && (
            <NoDefaultSyllabusMessage programId={programId} />
          )}
          {programSyllabuses.map((syllabus, index) => {
            return (
              <SyllabusCard
                key={index}
                programSyllabusData={syllabus}
                programId={programId}
                classId={classId}
              />
            )
          })}
        </Stack>
      </Stack>
    </Stack>
  )
}

const NoDefaultSyllabusMessage = ({ programId }: { programId: string }) => {
  const dispatch = useAppDispatch()
  const showSelectSyllabusPopup = () => {
    dispatch(pickPublishedSyllabusPopupOpened({ programId }))
  }

  return (
    <Stack
      gap={2}
      p={3}
      border="1px solid"
      borderColor="other.outlineBorder"
      borderRadius={2}
      bgcolor="primary.contrast"
      alignItems="center"
    >
      <Stack textAlign="center">
        <Typography variant="body2_sb">No default syllabus</Typography>
        <Typography>
          This program is not connected to a default syllabus
        </Typography>
      </Stack>
      <Button
        onClick={showSelectSyllabusPopup}
        variant="contained"
        color="primary"
        size="small"
        startIcon={
          <SvgIcon
            inheritViewBox
            component={appIcons.link01}
            sx={{
              fill: "none",
              stroke: "currentcolor",
              color: "eTypes.white",
            }}
          />
        }
      >
        Connect a syllabus
      </Button>
    </Stack>
  )
}

const ClassesWithoutSyllabusWarning = () => (
  <Alert
    icon={
      <SvgIcon
        component={appIcons.alertTriangle}
        inheritViewBox
        sx={{ fill: "none", width: 20, height: 20 }}
      />
    }
    severity="warning"
  >
    Some classes in this program have not yet been connected to a syllabus.
  </Alert>
)

const ThisClassIsConnectedToDefaultSyllabusInfo = () => {
  const [dismissed, setDismissed] = useState(false)
  if (dismissed) return null
  return (
    <Alert
      icon={
        <SvgIcon
          component={appIcons.infoCircle}
          inheritViewBox
          sx={{ stroke: "currentColor", fill: "none", width: 20, height: 20 }}
        />
      }
      severity="info"
      action={<Button onClick={() => setDismissed(true)}>Dismiss</Button>}
    >
      This class has been auto connected to the program’s default syllabus.
    </Alert>
  )
}

export default ProgramSyllabusesView
