import { CourseStatus } from "@masterschool/course-builder-api"

const courseStatusTextAndColor = (
  courseStatus: CourseStatus,
): {
  text: string
  color: "success" | "error" | undefined
} => {
  switch (courseStatus) {
    case CourseStatus.Draft:
      return {
        text: "Drafts",
        color: undefined,
      }
    case CourseStatus.Published:
      return {
        text: "Published",
        color: "success",
      }
    case CourseStatus.Archived:
      return {
        text: "Archived",
        color: "error",
      }
  }
}

export { courseStatusTextAndColor }
