import useSpecificSearchParam from "@utils/hooks/useSpecificSearchParams"

function useSelectElement() {
  const [, setElementId] = useSpecificSearchParam("elementId")

  return (elementId: string) => {
    setElementId(elementId, { replace: true })
  }
}

export default useSelectElement
