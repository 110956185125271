import { ConsultantDto } from "@masterschool/course-builder-api"
import { Avatar, Box, Typography } from "@mui/material"
import appTheme from "../theme/appTheme"

const colors = [
  appTheme.palette.eTypes.lilac,
  appTheme.palette.eTypes.pink,
  appTheme.palette.eTypes.grassGreen,
  appTheme.palette.eTypes.evergreen,
]

export const ConsultantCell = (props: {
  consultant: ConsultantDto
  index: number
}) => {
  const colorPicker = () => {
    if (!props.consultant.isAvailable) {
      return appTheme.palette.grey[400]
    }

    return colors[props.index % colors.length]
  }
  return (
    <Box display="flex" gap="8px">
      <Avatar sx={{ bgcolor: colorPicker(), width: "32px", height: "32px" }}>
        <Typography variant="body3" color="white">
          {props.consultant.name.substring(0, 2).toUpperCase()}{" "}
        </Typography>
      </Avatar>
      <Typography
        textAlign="center"
        alignSelf="center"
        textTransform="capitalize"
        variant="body2"
      >
        {props.consultant.name}
      </Typography>
    </Box>
  )
}
