import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { deleteProgramPopupClosed } from "@features/program/programSlice"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { deleteProgramConfirmed } from "@features/program/programSliceThunks"

function DeleteProgramPopup() {
  const model = useAppSelector((state) => state.program.deleteProgramPopup)
  const classes = useAppSelector(selectClassesForProgram(model?.programId))

  if (classes.length > 0) {
    return <RemoveAllClassesNeededPopup />
  } else {
    return <DeleteProgramConfirmationPopup />
  }
}

function RemoveAllClassesNeededPopup() {
  const model = useAppSelector((state) => state.program.deleteProgramPopup)
  const dispatch = useAppDispatch()
  const onClose = () => dispatch(deleteProgramPopupClosed())

  return (
    <GenericDialog
      onClose={onClose}
      open={model !== undefined}
      size="xs"
      title="Can’t delete program"
      subtitle="Remove all classes from the program to delete it."
      buttons={[
        {
          type: "primary",
          text: "Got it",
          onClick: onClose,
        },
      ]}
    />
  )
}

function DeleteProgramConfirmationPopup() {
  const model = useAppSelector((state) => state.program.deleteProgramPopup)
  const dispatch = useAppDispatch()
  const onClose = () => dispatch(deleteProgramPopupClosed())
  const onConfirm = () => dispatch(deleteProgramConfirmed())

  return (
    <GenericDialog
      open={model !== undefined}
      onClose={onClose}
      title="Delete program"
      content="Deleted programs can’t be restored."
      size="xs"
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "danger",
          text: "Delete program",
          onClick: onConfirm,
        },
      ]}
    />
  )
}

export default DeleteProgramPopup
