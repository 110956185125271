import { Box, Button, Typography } from "@mui/material"

import { useAppDispatch } from "@app/hooks"
import {
  createNewChannelPopupOpened,
  fetchProgramSlackChannels,
} from "@features/slack/slackSlice"
import { selectProgramSlackChannels } from "@features/slack/slackSliceSelector"
import appIcons from "@utils/appIcons"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import EmptyStateContainer from "../components/emptyStateContainer"
import { ChannelsTable } from "./channelsTable"
import SlackTabDialogPresenter from "./slackDialogs/slackTabDialogPresenter"

export type SlackChannelData = {
  name: string
  url: string
  id: string
  offeringGroupId: string
  classes: ProgramClass[]
}

export type ProgramClass = {
  id: number
  clientId: string
  startDate: string
  domain: string
  syllabusId: string
  description: string
}

export const SlackChannelsPage = (props: { programId: string }) => {
  const { programId } = props
  const dispatch = useAppDispatch()
  const channels = useSelector(selectProgramSlackChannels(programId))

  useEffect(() => {
    dispatch(fetchProgramSlackChannels(programId))
  }, [programId, dispatch])

  return (
    <Box>
      <SlackTabDialogPresenter />
      {channels.length > 0 ? (
        <Box display="flex" flexDirection="column" gap="16px">
          <Header
            channelsNumber={channels.length}
            onCreateClicked={() =>
              dispatch(createNewChannelPopupOpened({ programId }))
            }
          />
          <ChannelsTable channels={channels} programId={programId} />
        </Box>
      ) : (
        <EmptyView
          onCreateClicked={() =>
            dispatch(createNewChannelPopupOpened({ programId }))
          }
        />
      )}
    </Box>
  )
}

const Header = (props: {
  channelsNumber: number
  onCreateClicked: () => void
}) => {
  const { channelsNumber, onCreateClicked } = props

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h5" component="span">
        Slack channels{" "}
        <Typography variant="h5" component="span" color="text.disabled">
          ({channelsNumber})
        </Typography>
      </Typography>
      <Button variant="contained" size="small" onClick={onCreateClicked}>
        Create new
      </Button>
    </Box>
  )
}

const EmptyView = (props: { onCreateClicked: () => void }) => {
  const { onCreateClicked } = props

  return (
    <EmptyStateContainer
      title="No Slack channels yet"
      subtitle="Add and manage Slack channels for this program"
      icon={appIcons.slackGreyscale}
      preventOverrideIconColor
      action={
        <Button variant="contained" onClick={onCreateClicked}>
          Create new
        </Button>
      }
    />
  )
}
