import { useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { selectLastVersionSyllabuses } from "@features/syllabus/syllabusSelectors"
import { Link, List, ListItem, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const SyllabusDependenciesPopup = (props: {
  syllabusIDs: string[]
  onClose: () => void
}) => {
  const { syllabusIDs, onClose } = props
  const syllabusesMetadata = useAppSelector(selectLastVersionSyllabuses)
  const syllabuses = syllabusesMetadata.filter((syllabus) =>
    syllabusIDs.includes(syllabus.id),
  )
  const navigate = useNavigate()
  const onSyllabusClicked = (syllabusId: string) => {
    navigate(`/syllabus/${syllabusId}`)
    onClose()
  }

  return (
    <GenericDialog
      open
      onClose={onClose}
      size="xs"
      title="Dependent syllabuses"
      content={
        <Stack>
          <Typography>
            {syllabuses.length > 0
              ? "This course is used in:"
              : "This course is not used in any syllabus"}
          </Typography>
          <List>
            {syllabuses.map((syllabus) => (
              <ListItem sx={{ listStyle: "disc" }}>
                <Link
                  key={syllabus.id}
                  href={`/syllabus/${syllabus.id}`}
                  variant="body2"
                  onClick={() => {
                    onSyllabusClicked(syllabus.id)
                  }}
                >
                  {syllabus.name || "Unnamed syllabus"}
                </Link>
              </ListItem>
            ))}
          </List>
        </Stack>
      }
    />
  )
}

export default SyllabusDependenciesPopup
