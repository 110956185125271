import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { Box, Typography } from "@mui/material"
import { selectTurnOnOffAppFeatureFlagFormPresentation } from "./appFeatureFlagsSelectors"
import {
  turnOnOffAppFeatureFlagPopupClosed,
  turnOnOffAppFeatureFlag,
  fetchAppFeatureFlags,
} from "./appFeatureFlagsSlice"

function TurnOnOffFeatureFlagPopup() {
  const dispatch = useAppDispatch()
  const state = useAppSelector(selectTurnOnOffAppFeatureFlagFormPresentation)

  return (
    <GenericDialog
      title={state ? `Turn ${state.state ? "off" : "on"} ${state.name}?` : ""}
      onClose={() => dispatch(turnOnOffAppFeatureFlagPopupClosed())}
      open={state !== undefined}
      size="xs"
      content={
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography variant="body1">
            {`Are you sure you want to turn ${state?.state ? "off" : "on"} ${
              state?.name
            }?`}
          </Typography>
        </Box>
      }
      buttons={[
        {
          type: "primary",
          text: state?.state ? "Turn off" : "Turn on",
          onClick: () => {
            dispatch(turnOnOffAppFeatureFlag()).then(() => {
              dispatch(fetchAppFeatureFlags())
            })
          },
        },
      ]}
    />
  )
}

export default TurnOnOffFeatureFlagPopup
