import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { ConsultantClient } from "@clients/consultantClient"
import { ConsultantDto, ShiftDto } from "@masterschool/course-builder-api"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import PendingDataState from "@utils/pendingDataState"
import { ShiftsClient } from "@clients/shiftsClient"
import AppObserver from "@app/middlewares/appObserver"

export type ConsultantToShifts = Record<string, ShiftDto[]>
export type SalesManagementSliceState = {
  consultants: PendingDataState<ConsultantDto[]>
  shifts: PendingDataState<ConsultantToShifts>
  createdShifts: PendingDataState<ShiftDto[]>
}

export const getInitialState: () => SalesManagementSliceState = () => {
  return {
    consultants: { status: "idle" },
    shifts: { status: "idle" },
    createdShifts: { status: "idle" },
  }
}

export const salesManagementSlice = createSlice({
  name: "salesManagementSlice",
  initialState: getInitialState(),
  reducers: {
    resetShifts: (state) => {
      state.shifts = { status: "idle" }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConsultants.pending, (state) => {
      state.consultants = { status: "pending" }
    })
    builder.addCase(
      fetchConsultants.fulfilled,
      (state, action: PayloadAction<ConsultantDto[]>) => {
        state.consultants = {
          status: "success",
          data: action.payload,
        }
      },
    )
    builder.addCase(fetchConsultants.rejected, (state, action) => {
      state.consultants = {
        status: "error",
        error: action.error.message,
      }
    })
    builder.addCase(fetchShifts.pending, (state) => {
      if (state.shifts.status === "success") {
        return
      }
      state.shifts = { status: "pending" }
    })
    builder.addCase(fetchShifts.fulfilled, (state, action) => {
      if (action.payload !== "RESET_SHIFTS") {
        state.shifts = {
          status: "success",
          data: action.payload.reduce((acc, { consultantId, shifts }) => {
            acc[consultantId] = shifts
            return acc
          }, {} as ConsultantToShifts),
        }
      }
    })
    builder.addCase(fetchAvailableShifts.pending, (state) => {
      state.createdShifts = { status: "pending" }
    })
    builder.addCase(fetchAvailableShifts.fulfilled, (state, action) => {
      state.createdShifts = {
        status: "success",
        data: action.payload,
      }
    })
    builder.addCase(updateConsultantAvailability.pending, (state, action) => {
      if (state.consultants.status !== "success" || !state.consultants.data) {
        return
      }
      const { consultantId, isAvailable } = action.meta.arg
      const consultant = state.consultants.data?.find(
        (consultant) => consultant.id === consultantId,
      )
      if (consultant) {
        consultant.isAvailable = isAvailable
      }
    })

    builder.addCase(updateConsultantAcceptedQtfs.pending, (state, action) => {
      if (state.consultants.status !== "success" || !state.consultants.data) {
        return
      }
      const consultantId = action.meta.arg.consultantId
      const acceptedQtfs = action.meta.arg.acceptedQtfs
      const consultant = state.consultants.data?.find(
        (consultant) => consultant.id === consultantId,
      )
      if (consultant) {
        consultant.acceptedQtfs = acceptedQtfs
      }
    })

    builder.addCase(updateConsultantAvailability.rejected, (state, action) => {
      if (state.consultants.status !== "success" || !state.consultants.data) {
        return
      }
      const consultantId = action.meta.arg.consultantId
      const consultant = state.consultants.data?.find(
        (consultant) => consultant.id === consultantId,
      )
      if (consultant) {
        consultant.isAvailable = !consultant.isAvailable
      }
    })
  },
})

export const fetchConsultants = createAppAsyncThunk(
  "salesManageement/fetchConsultants",
  async () => {
    return ConsultantClient.getConsultants()
  },
)

export const fetchShifts = createAppAsyncThunk(
  "salesManageement/fetchShifts",
  async (_, thunkAPI) => {
    const consultants = thunkAPI.getState().salesManagement.consultants
    if (consultants.status !== "success" || !consultants.data) {
      thunkAPI.dispatch(salesManagementSlice.actions.resetShifts())
      return "RESET_SHIFTS"
    }
    return consultants.data.msCompactMapAsync(async (consultant) => {
      const shifts = await ShiftsClient.getConsultantShifts(consultant.id)
      return { consultantId: consultant.id, shifts }
    })
  },
)

export const updateConsultantAvailability = createAppAsyncThunk(
  "salesManagement/updateConsultantAvailablity",
  async (params: { isAvailable: boolean; consultantId: string }) => {
    return ConsultantClient.updateConsultantAvailability(
      params.consultantId,
      params.isAvailable,
    )
  },
)

export const updateConsultantAcceptedQtfs = createAppAsyncThunk(
  "salesManagement/updateConsultantAcceptedQtfs",
  async (
    params: { acceptedQtfs: number[]; consultantId: string },
    thunkAPI,
  ) => {
    return ConsultantClient.updateConsultantAcceptedQtfs(
      params.consultantId,
      params.acceptedQtfs,
    ).catch((error) => {
      thunkAPI.dispatch(fetchConsultants())
    })
  },
)

export const fetchAvailableShifts = createAppAsyncThunk(
  "salesManagement/fetchAvailableShifts",
  async () => {
    return ShiftsClient.list()
  },
)

export const updateShifts = createAppAsyncThunk(
  "salesManagement/updateShifts",
  async (_, thunkApi) => {
    thunkApi.dispatch(fetchShifts())
    thunkApi.dispatch(fetchAvailableShifts())
  },
)

export const updateConsultants = createAppAsyncThunk(
  "salesManagement/updateConsultants",
  async (_, thunkApi) => {
    thunkApi.dispatch(fetchConsultants())
  },
)

export const salesManagementShiftsObserver: AppObserver = {
  didUpdate: (previousState, currentState, _, dispatch) => {
    const previousConsultants = previousState.salesManagement.consultants
    const currentConsultants = currentState.salesManagement.consultants
    if (previousConsultants !== currentConsultants) {
      dispatch(fetchShifts())
    }
  },
}

export const consultantsObserver: AppObserver = {
  didUpdate: (previousState, currentState, _, dispatch) => {
    if (currentState.salesManagement.consultants.status === "idle") {
      dispatch(fetchConsultants())
    }

    if (currentState.salesManagement.createdShifts.status === "idle") {
      dispatch(fetchAvailableShifts())
    }
  },
}
export default salesManagementSlice.reducer
