import { useAppSelector } from "@app/hooks"
import { selectCourseVersion } from "@features/coursesMenu/coursesSelectors"
import appTheme from "../../../../theme/appTheme"
import { Box, BoxProps, Button, SvgIcon, Typography } from "@mui/material"
import { UnitCourseDescriptor } from "@masterschool/course-builder-api"
import appIcons from "@utils/appIcons"

function CourseSelectionButton(props: {
  courseDescriptor: UnitCourseDescriptor
  onAddClick: () => void
  disabled: boolean
  sx?: BoxProps["sx"]
}) {
  const { courseDescriptor, onAddClick, disabled } = props
  const course = useAppSelector(
    selectCourseVersion(courseDescriptor.courseId, courseDescriptor.version),
  )

  if (!course) return null

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderTop: `1px solid ${appTheme.palette.other.divider}`,
        padding: "17px 24px",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1_sb" color={appTheme.palette.text.primary}>
          {course.title}
        </Typography>
        <Typography variant="body2" color={appTheme.palette.text.secondary}>
          {course.name}
        </Typography>
      </Box>
      <Button
        size="small"
        startIcon={
          <SvgIcon
            component={appIcons.plus}
            inheritViewBox
            sx={{
              stroke: disabled
                ? appTheme.palette.action.disabled
                : appTheme.palette.icon.black,
            }}
          />
        }
        variant="outlined"
        disabled={disabled}
        onClick={onAddClick}
      >
        Add
      </Button>
    </Box>
  )
}

export default CourseSelectionButton
