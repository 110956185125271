import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { editUserPopupClosed } from "@features/account-management/accountManagementSlice"
import EditUserDetailsPopupForm from "../popups/popups/editUserDetailsPopupForm"

function EditEmployeeDetailsDialog() {
  const model = useAppSelector((state) => state.accountManagement.editUserPopup)
  const detailsBeforeEdit = model?.user
  const userId = model?.user.id
  const isOpen = model !== undefined
  const dispatch = useAppDispatch()

  if (!isOpen || !userId || !detailsBeforeEdit) {
    return null
  }

  const onClose = () => {
    dispatch(editUserPopupClosed())
  }

  return (
    <GenericDialog
      open
      size="sm"
      onClose={onClose}
      title={`Edit user`}
      content={
        <EditUserDetailsPopupForm
          userId={userId}
          userDetailsBeforeEdit={detailsBeforeEdit}
          onClose={onClose}
          isEmployee={true}
        />
      }
      buttons={[]}
    />
  )
}

export default EditEmployeeDetailsDialog
