import { ProgramClassDto } from "@masterschool/course-builder-api"
import { Divider, Stack, Typography } from "@mui/material"
import { ProgramSyllabusData } from "../useGetAllProgramSyllabusesData"
import SyllabusCardActions from "./actions"
import SyllabusCardHeader from "./header"

const SyllabusCard = ({
  programSyllabusData,
  programId,
  classId,
}: {
  programSyllabusData: ProgramSyllabusData
  programId: string
  classId: string | undefined
}) => {
  const { syllabus, isDefault, classes } = programSyllabusData
  const classView = !!classId

  return (
    <Stack
      border="1px solid"
      borderColor="other.outlineBorder"
      borderRadius={2}
      bgcolor="primary.contrast"
    >
      <SyllabusCardHeader syllabus={syllabus} isDefaultSyllabus={isDefault} />
      {!classView && <ConnectedClasses classes={classes} />}
      <SyllabusCardActions
        syllabus={syllabus}
        programId={programId}
        classId={classId}
        isDefaultSyllabus={isDefault}
        connectedClasses={classes}
      />
    </Stack>
  )
}

const ConnectedClasses = ({ classes }: { classes: ProgramClassDto[] }) => {
  if (classes.length === 0) return null

  return (
    <Stack
      direction={{ md: "row" }}
      gap={2}
      p={2}
      mx={3}
      bgcolor="eTypes.sand15"
    >
      <Typography variant="body2_sb" flex={{ md: 1 }}>
        Connected classes ({classes.length})
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Stack gap={1} flex={{ md: 1 }}>
        {classes.map((programClass) => (
          <Typography key={programClass.id} variant="body2">
            {programClass.name}
          </Typography>
        ))}
      </Stack>
    </Stack>
  )
}

export default SyllabusCard
