import { useAppDispatch } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  discardDraftConfirmationClosed,
  discardDraftConfirmed,
} from "@features/courseEditor/courseEditorSlice"
import { useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

function DiscardDraftConfirmationPopup() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const version = parseInt(searchParams.get("version") ?? "2")
  const previousVersion = version - 1
  const [isLoading, setIsLoading] = useState(false)

  const title = "Revert to published version?"
  const subtitle = "This will delete any changes you’ve made to this course."

  return (
    <GenericDialog
      open={true}
      title={title}
      size="xs"
      subtitle={subtitle}
      onClose={() => dispatch(discardDraftConfirmationClosed())}
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: () => dispatch(discardDraftConfirmationClosed()),
        },
        {
          text: "Revert",
          type: "primary",
          loading: isLoading,
          onClick: () => {
            setIsLoading(true)
            dispatch(discardDraftConfirmed()).then(() =>
              navigate(`/course/${courseId}?version=${previousVersion}`),
            )
          },
        },
      ]}
    />
  )
}

export default DiscardDraftConfirmationPopup
