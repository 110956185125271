import { useAppDispatch } from "@app/hooks"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import { Syllabus } from "@features/syllabus/syllabusSelectors"
import {
  duplicate,
  renameClicked,
} from "@features/syllabus/syllabusesMenuSlice"
import { PopupType, popupItemClicked } from "@features/ui/uiSlice"
import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"
import { MenuItemProps, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useNavigate } from "react-router-dom"

function SyllabusOptionsMenu(props: { syllabus: Syllabus }) {
  const items = useSyllabusMenuItems(props.syllabus)

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon
              component={appIcons.dotsHorizontal}
              inheritViewBox
              sx={{ color: "primary.main" }}
            />
          ),
        },
      }}
      items={items}
    />
  )
}

function useSyllabusMenuItems(syllabus: Syllabus): {
  type: "menu-item"
  props: MenuItemProps
}[] {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onOpenClicked = () => {
    navigate(`/syllabus/${syllabus.id}`)
  }

  const onPreviewInCampusClicked = () => {
    dispatch(
      popupItemClicked({
        type: PopupType.CampusSyllabusPreview,
        syllabus: syllabus,
        programId: syllabus.programId,
      }),
    )
  }

  const onRenameClicked = () => {
    dispatch(
      renameClicked({
        syllabusId: syllabus.id,
      }),
    )
  }

  const onDuplicateClicked = () => {
    dispatch(duplicate(syllabus.id))
  }

  const onEditClicked = () => {
    navigate(`/syllabus-edit/${syllabus.id}`)
  }

  const onArchiveClicked = () => {
    dispatch(
      popupItemClicked({
        syllabusId: syllabus.id,
        type: PopupType.ArchiveSyllabusConfirmation,
      }),
    )
  }

  const onDeleteClicked = () => {
    dispatch(
      popupItemClicked({
        type: PopupType.SyllabusDeleteConfirmation,
        syllabusId: syllabus.id,
      }),
    )
  }

  const onVersionHistoryClicked = () => {
    dispatch(
      popupItemClicked({
        syllabusId: syllabus.id,
        type: PopupType.SyllabusVersionsHistory,
      }),
    )
  }
  const openItem = {
    type: "menu-item" as const,
    props: {
      key: "open",
      children: "Open",
      onClick: onOpenClicked,
    },
  }

  const editItem = {
    type: "menu-item" as const,
    props: {
      key: "edit",
      children: "Edit",
      onClick: onEditClicked,
    },
  }

  const renameItem = {
    type: "menu-item" as const,
    props: {
      key: "rename",
      children: "Rename",
      onClick: onRenameClicked,
    },
  }

  const previewItem = {
    type: "menu-item" as const,
    props: {
      key: "preview",
      children: "Preview in campus",
      onClick: onPreviewInCampusClicked,
    },
  }

  const duplicateItem = {
    type: "menu-item" as const,
    props: {
      key: "duplicate",
      children: "Duplicate",
      onClick: onDuplicateClicked,
    },
  }

  const archiveItem = {
    type: "menu-item" as const,
    props: {
      key: "archive",
      children: "Archive",
      onClick: onArchiveClicked,
    },
  }

  const deleteItem = {
    type: "menu-item" as const,
    props: {
      key: "delete",
      children: "Delete",
      onClick: onDeleteClicked,
    },
  }
  const versionHistoryItem = {
    type: "menu-item" as const,
    props: {
      key: "versionHistory",
      children: "Version history",
      onClick: onVersionHistoryClicked,
    },
  }

  if (syllabus.isActive) {
    return [openItem, previewItem, editItem, duplicateItem, versionHistoryItem]
  }

  switch (syllabus.status) {
    case SyllabusDtoStatusEnum.Draft:
      return [openItem, renameItem, previewItem, duplicateItem, archiveItem]
    case SyllabusDtoStatusEnum.Archived:
      return [openItem, deleteItem]
    case SyllabusDtoStatusEnum.Published:
      return [
        openItem,
        previewItem,
        editItem,
        duplicateItem,
        archiveItem,
        versionHistoryItem,
      ]
  }
}

export default SyllabusOptionsMenu
