import { SvgIcon, SvgIconProps } from "@mui/material"
import appTheme from "../theme/appTheme"
import { IconType } from "./iconType"

function AppIcon(props: { icon: IconType; props?: SvgIconProps }) {
  return (
    <SvgIcon
      inheritViewBox
      component={props.icon}
      sx={{
        fill: "none",
        width: "20px",
        height: "20px",
        stroke: appTheme.palette.icon.black,
        ...props.props?.sx,
      }}
      {...props.props}
    />
  )
}

export default AppIcon
