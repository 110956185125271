import BackButton from "@cmp/buttons/backButton"
import OptionsButton from "@cmp/buttons/optionsButton"
import CourseEditorStatus from "@cmp/courseEditorStatus"
import {
  selectActiveCourse,
  selectCanPreview,
  useCanPublish,
} from "@features/courseEditor/courseEditorSelectors"
import {
  closedRequestedWithUnpublishedChanges,
  validateCourse,
} from "@features/courseEditor/courseEditorSlice"
import { selectLatestPublishedCourse } from "@features/coursesMenu/coursesSelectors"
import { CourseDto } from "@masterschool/course-builder-api"
import { LoadingButton } from "@mui/lab"
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material"
import { createSelector } from "@reduxjs/toolkit"
import appIcons from "@utils/appIcons"
import { areCourseDtosEqual } from "@utils/courseUtils"
import {
  courseSyllabusDuration,
  durationFormatting,
} from "@utils/syllabus+duration"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import useCourseMenuActions from "../main/descriptor/useCourseMenuActions"
import appTheme from "../theme/appTheme"

export function CourseEditorHeaderContainer() {
  const course = useAppSelector(selectActiveCourse)
  const { syllabusId } = useParams()
  if (!course) return null

  const isInSyllabusEditor = syllabusId !== undefined

  if (isInSyllabusEditor) {
    return null
  } else {
    return <CourseEditorHeader course={course} />
  }
}

function CourseEditorHeader(props: { course: CourseDto }) {
  const { course } = props

  const showSaveLabel = useAppSelector(
    (state) =>
      state.courseEditor.save.status === "pending" &&
      state.courseEditor.save.userRequested,
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px 16px",
        width: "100%",
        borderBottom: `1px solid ${appTheme.palette.divider}`,
        paddingRight: "60px",
        bgcolor: appTheme.palette.primary.contrast,
      }}
    >
      <GoBackButton course={course} />
      <Box
        sx={{
          width: "34%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <CourseTitle course={course} />
        <CourseEditorStatus course={course} />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "33%",
          justifyContent: "end",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {showSaveLabel && <Chip label="Saving..." size="small" />}
        <HeaderMenu course={course} />
        <DiscardChangesMenu course={course} />
        <PreviewButton course={course} />
        <PublishButton course={course} />
      </Box>
    </Box>
  )
}

const CourseTitle = (props: { course: CourseDto }) => {
  const { course } = props
  const hasTitle = course.title && course.title?.length > 0

  return (
    <Typography
      variant="body1"
      color={hasTitle ? "text.primary" : "text.disabled"}
    >
      {hasTitle ? course.title : "Unnamed course"}
      <span
        style={{
          color: appTheme.palette.text.disabled,
        }}
      >
        {` (${durationFormatting(courseSyllabusDuration(course.syllabus))})`}
      </span>
    </Typography>
  )
}

const GoBackButton = (props: { course: CourseDto }) => {
  const { course } = props
  const canPublish = useCanPublish()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  async function onBackClicked() {
    if (canPublish && (course.version ?? 1) > 1) {
      dispatch(closedRequestedWithUnpublishedChanges())
      return
    }
    navigate("/courses")
  }

  return (
    <Box width="33%" justifyContent="start">
      <BackButton onClick={onBackClicked} />
    </Box>
  )
}

const PreviewButton = (props: { course: CourseDto }) => {
  const { course } = props
  const { onPreviewInCampusClicked } = useCourseMenuActions(course)
  const canPreview = useAppSelector(selectCanPreview)

  return (
    <Tooltip
      title={
        <Typography
          variant="body2"
          sx={{
            padding: "8px 16px",
            color: "white",
          }}
        >
          Add course info and content to preview.
        </Typography>
      }
      disableHoverListener={canPreview}
    >
      <div>
        <Button
          onClick={onPreviewInCampusClicked}
          variant="outlined"
          size="small"
          disabled={!canPreview}
        >
          Preview
        </Button>
      </div>
    </Tooltip>
  )
}

const PublishButton = (props: { course: CourseDto }) => {
  const { course } = props
  const canPublish = useCanPublish()
  const publishValidationState = useAppSelector(
    (state) => state.courseEditor.publishValidations,
  )
  const dispatch = useAppDispatch()

  return (
    <Tooltip
      title={
        <Typography
          variant="body2"
          sx={{
            padding: "8px 16px",
            color: "white",
          }}
        >
          {course.version === 1
            ? "Add course info and content to publish."
            : "Change course info or content to publish."}
        </Typography>
      }
      disableHoverListener={canPublish}
    >
      <div>
        <LoadingButton
          loading={publishValidationState === "pending"}
          onClick={() => {
            dispatch(validateCourse())
          }}
          variant="contained"
          disabled={!canPublish}
          size="small"
        >
          {course.version > 1 ? "Publish changes" : "Publish"}
        </LoadingButton>
      </div>
    </Tooltip>
  )
}

const HeaderMenu = (props: { course: CourseDto }) => {
  const {
    onDuplicateClicked,
    onArchiveClicked,
    onDeleteClicked,
    onDiscardChangesClicked,
  } = useCourseMenuActions(props.course)
  const hasChanges = useAppSelector(selectShowDiscardChanges(props.course.id))

  const items = [{ text: "Duplicate", onSelect: onDuplicateClicked }]
  if (props.course.status === "published") {
    items.push({ text: "Archive", onSelect: onArchiveClicked })
  } else if (props.course.status === "archived") {
    items.push({ text: "Delete", onSelect: onDeleteClicked })
  } else if (props.course.status === "draft") {
    if (hasChanges) {
      items.push({ text: "Discard changes", onSelect: onDiscardChangesClicked })
    }
  }

  return (
    <OptionsButton
      button={{
        leftIcon: appIcons.dots,
        iconSx: {
          width: "20px",
          height: "20px",
        },
        sx: {
          padding: "5px",
        },
        size: "small",
      }}
      sx={{
        border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        borderRadius: "2px",
        boxSizing: "border-box",
      }}
      items={items}
    />
  )
}

const DiscardChangesMenu = (props: { course: CourseDto }) => {
  const { onDiscardChangesClicked, onDiscardDraftClicked } =
    useCourseMenuActions(props.course)
  const hasChanges = useAppSelector(selectShowDiscardChanges(props.course.id))

  const items = [
    { text: "Discard current changes", onSelect: onDiscardChangesClicked },
    { text: "Revert to published version", onSelect: onDiscardDraftClicked },
  ]

  return (
    hasChanges && (
      <OptionsButton
        button={{
          leftIcon: appIcons.reverseLeft,
          iconSx: {
            width: "20px",
            height: "20px",
            fill: "none",
            stroke: appTheme.palette.icon.black,
          },
          sx: {
            padding: "5px",
          },
          size: "small",
        }}
        sx={{
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          borderRadius: "2px",
          boxSizing: "border-box",
        }}
        items={items}
      />
    )
  )
}

const selectShowDiscardChanges = (courseId: string) =>
  createSelector(
    [selectActiveCourse, selectLatestPublishedCourse(courseId)],
    (draft, published) => {
      if (!draft || !published) {
        return false
      }

      return !areCourseDtosEqual(draft, published)
    },
  )

export function CourseEditorPopupHeader(props: { course: CourseDto }) {
  const { course } = props

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px 24px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: appTheme.palette.primary.contrast,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <CourseTitle course={course} />
        <CourseEditorStatus course={course} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <HeaderMenu course={course} />
        <DiscardChangesMenu course={course} />
        <PreviewButton course={course} />
        <PublishButton course={course} />
      </Box>
    </Box>
  )
}
