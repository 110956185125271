import { Syllabus } from "@features/syllabus/syllabusSelectors"
import { Stack, Typography, Chip } from "@mui/material"
import { domainToDisplayName } from "@utils/domainUtils"
import { startDateToCohortStyleDate } from "@utils/syllabusTags"

const SyllabusCardHeader = ({
  syllabus,
  isDefaultSyllabus,
}: {
  syllabus: Syllabus
  isDefaultSyllabus: boolean
}) => {
  return (
    <Stack direction={{ xs: "column", md: "row" }} p={3} gap={3}>
      <Stack
        order={{ md: 2 }}
        direction="row"
        gap={1}
        flex={1}
        justifyContent="flex-end"
        alignItems={{ md: "center" }}
      >
        <Chip
          label={domainToDisplayName(syllabus.domain ?? "")}
          size="small"
          sx={{
            width: "fit-content",
          }}
        />
        {syllabus.programStartDate && (
          <Chip
            label={startDateToCohortStyleDate(syllabus.programStartDate)}
            size="small"
            sx={{
              width: "fit-content",
            }}
          />
        )}
        {isDefaultSyllabus && (
          <Chip color="lilac25" label="Default syllabus" size="small" />
        )}
      </Stack>
      <Typography order={{ md: 1 }} variant="h6">
        {syllabus.name ?? ""}
      </Typography>
    </Stack>
  )
}

export default SyllabusCardHeader
