import { TextField, TextFieldProps, debounce } from "@mui/material"
import { useMemo, useEffect, useState } from "react"

export type EditorTextFieldProps = TextFieldProps & {
  debounce?: boolean
}

function EditorTextField(props: EditorTextFieldProps) {
  if (props.debounce) {
    return <DebouncedEditorTextField {...props} />
  }

  return <OverrideDefaultsTextField {...props} />
}

function DebouncedEditorTextField(props: EditorTextFieldProps) {
  const [value, setValue] = useState(props.value)
  const { onChange } = props

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const debouncedOnChange = useMemo(
    () =>
      onChange
        ? debounce(
            (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              onChange?.(e)
            },
            250,
          )
        : undefined,
    [onChange],
  )

  useEffect(() => {
    return () => {
      debouncedOnChange?.clear()
    }
  }, [debouncedOnChange])

  return (
    <OverrideDefaultsTextField
      {...props}
      onChange={(e) => {
        setValue(e.target.value)
        debouncedOnChange?.(e)
      }}
      value={value}
    />
  )
}

function OverrideDefaultsTextField(props: EditorTextFieldProps) {
  const { debounce, ...domProps } = props

  return (
    <TextField
      sx={{
        width: "100%",
      }}
      size="small"
      {...domProps}
    />
  )
}

export default EditorTextField
