import { SyllabusDto } from "@masterschool/course-builder-api"
import { SortOrder } from "./coursesSortHelpers"

export type SyllabusSortFunction = (
  syllabus: SyllabusDto[],
  sortOrder: SortOrder,
) => SyllabusDto[]

export type SyllabusComparator = (a: SyllabusDto, b: SyllabusDto) => -1 | 0 | 1

export const alphabeticSyllabusesSort: SyllabusSortFunction = (
  syllabuses,
  sortOrder,
) => {
  const sortOrderSign = sortOrder === "ascending" ? 1 : -1
  return syllabuses.sort((a, b) => sortOrderSign * alphabeticComparator(a, b))
}

export const chronologicalSyllabusesSort: SyllabusSortFunction = (
  syllabuses,
  sortOrder,
) => {
  const sortOrderSign = sortOrder === "ascending" ? 1 : -1
  return syllabuses.sort(
    (a, b) => sortOrderSign * chronologicalComparator(a, b),
  )
}

const alphabeticComparator: SyllabusComparator = (a, b) => {
  const nameA = (a.name ?? "").toLowerCase()
  const nameB = (b.name ?? "").toLowerCase()

  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

const chronologicalComparator: SyllabusComparator = (a, b) => {
  if (a.createdAt < b.createdAt) {
    return -1
  }
  if (a.createdAt > b.createdAt) {
    return 1
  }
  return 0
}
