import {
  ComponentsProps,
  ComponentsOverrides,
  ThemeOptions,
  ComponentsVariants,
} from "@mui/material"

const MuiIconButtonOverrides: {
  defaultProps?: ComponentsProps["MuiIconButton"]
  styleOverrides?: ComponentsOverrides<ThemeOptions>["MuiIconButton"]
  variants?: ComponentsVariants["MuiIconButton"]
} = {
  styleOverrides: {
    root: {
      borderRadius: "0px",
      padding: "6px",
      gap: "8px",
      minWidth: "unset",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
}

export { MuiIconButtonOverrides }
