import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  ThemeOptions,
  Theme,
} from "@mui/material/styles"

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    tiny: true
  }
  interface ButtonPropsColorOverrides {
    brightGreen: true
    sand50: true
  }
}

const MuiButtonOverrides: {
  defaultProps?: ComponentsProps["MuiButton"]
  styleOverrides?: ComponentsOverrides<ThemeOptions>["MuiButton"]
  variants?: ComponentsVariants<Theme>["MuiButton"]
} = {
  styleOverrides: {
    root: {
      borderRadius: "2px",
      minWidth: "unset",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  variants: [
    {
      props: { variant: "contained", color: "primary" },
      style: ({ theme }) => ({
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
      }),
    },
    {
      props: { variant: "outlined" },
      style: ({ theme }) => ({
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.other.outlineBorder,
        "&:hover": {
          borderColor: theme.palette.action.hover,
          backgroundColor: theme.palette.other.outlineBorder,
        },
      }),
    },
    {
      props: { variant: "outlined", disabled: true },
      style: ({ theme }) => ({
        "&.Mui-disabled": {
          borderColor: theme.palette.other.outlineBorder,
        },
      }),
    },
    {
      props: { variant: "text" },
      style: ({ theme }) => ({
        "&:hover": {
          backgroundColor: theme.palette.other.outlineBorder,
        },
      }),
    },
    {
      props: { size: "large" },
      style: ({ theme }) => ({
        height: "42px",
        padding: "9px 16px",
        ...theme.typography.body1_sb,
      }),
    },
    {
      props: { size: "medium" },
      style: ({ theme }) => ({
        height: "40px",
        padding: "8px 16px",
        ...theme.typography.body1_sb,
      }),
    },
    {
      props: { size: "small" },
      style: ({ theme }) => ({
        height: "32px",
        padding: "5px 12px",
        ...theme.typography.body2_sb,
      }),
    },
    {
      props: { size: "tiny" },
      style: ({ theme }) => ({
        height: "25px",
        padding: "3px 8px",
        ...theme.typography.body3_sb,
      }),
    },
  ],
}

export { MuiButtonOverrides }
