import React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Stack } from "@mui/material"

export function InstructorDetails({ domain }: { domain: string }) {
  const signatureUrl = domainToSignature(domain)
  const instructorName = domainToInstructorName(domain)

  if (!signatureUrl || !instructorName) {
    return null
  }

  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
        py: 0,
        px: "24px",
        border: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "& .MuiAccordionSummary-content": {
            flexGrow: 0,
            m: 0,
          },
          "& .Mui-expanded": {
            margin: "0 !important",
          },
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "400" }}>
          Instructor details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" gap="16px">
          <Typography variant="body1">{`${instructorName}, Course Instructor`}</Typography>
          <img
            alt="instructor-signature"
            src={signatureUrl}
            width="124px"
            height="48px"
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

function domainToSignature(domain: string) {
  switch (domain) {
    case "WEB":
      return "https://d1e3so4zvt5fy3.cloudfront.net/lms_app/alon_gal_signature.png"
    case "DATA_SCIENCE":
      return "https://d1e3so4zvt5fy3.cloudfront.net/lms_app/kostya_kilimnik_signature.png"
    case "CYBER":
      return "https://d1e3so4zvt5fy3.cloudfront.net/lms_app/nativ_levy_signature.png"
    default:
      return undefined
  }
}

function domainToInstructorName(domain: string) {
  switch (domain) {
    case "WEB":
      return "Alon Gal"
    case "DATA_SCIENCE":
      return "Kostya Kilimnik"
    case "CYBER":
      return "Nativ Levy"
    default:
      return undefined
  }
}
