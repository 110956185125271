import { useAppDispatch } from "@app/hooks"
import { ProjectClient } from "@clients/projectClient"
import GenericDialog from "@cmp/genericDialog"
import { showErrorSnackbar } from "@features/ui/uiSlice"
import { ProjectDto, UnitDto } from "@masterschool/course-builder-api"
import {
  Autocomplete,
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  SvgIcon,
  TextField,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useEffect, useState } from "react"
import { ManageProgramProjectDialog } from "./manageProgramProjectDialog"

export const AddProjectMenu = (props: {
  programId: string
  unitsInSyllabus: UnitDto[]
  disabled: boolean
  icon: "plus" | "chevronDown"
  buttonProps?: ButtonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const [showAddProjectDialog, setShowAddProjectDialog] = useState(false)
  const [showAddExistingDialog, setShowAddExistingDialog] = useState(false)
  const [existingProject, setExistingProject] = useState<
    Omit<ProjectDto, "id"> | undefined
  >(undefined)

  const onCreateNewProjectClicked = () => {
    setShowAddProjectDialog(true)
    handleCloseMenu()
  }

  const onAddExistingProjectClicked = () => {
    setShowAddExistingDialog(true)
    handleCloseMenu()
  }

  const buttonProps = props.buttonProps ?? {}

  return (
    <Box display="flex" flexShrink={0}>
      <Button
        disabled={props.disabled}
        variant={buttonProps.variant ?? "contained"}
        disableElevation
        onClick={handleClick}
        endIcon={
          <SvgIcon
            component={
              props.icon === "chevronDown"
                ? appIcons.chevronDown
                : appIcons.plus
            }
            inheritViewBox
            sx={{
              width: "24px",
              height: "24px",
              stroke: props.icon === "plus" ? "currentColor" : undefined,
            }}
          />
        }
        {...buttonProps}
      >
        Add project
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuItem onClick={onCreateNewProjectClicked} disableRipple>
          Add a new project
        </MenuItem>
        <MenuItem onClick={onAddExistingProjectClicked} disableRipple>
          Choose from existing projects
        </MenuItem>
      </Menu>
      {showAddProjectDialog && (
        <ManageProgramProjectDialog
          programId={props.programId}
          open={showAddProjectDialog}
          onClose={() => {
            setShowAddProjectDialog(false)
            setExistingProject(undefined)
          }}
          unitsInSyllabus={props.unitsInSyllabus}
          project={existingProject}
        />
      )}
      <SelectExistingProjectDialog
        open={showAddExistingDialog}
        onClose={() => setShowAddExistingDialog(false)}
        onProjectSelected={(project) => {
          setExistingProject(project)
          setShowAddExistingDialog(false)
          setShowAddProjectDialog(true)
        }}
      />
    </Box>
  )
}

const SelectExistingProjectDialog = (props: {
  open: boolean
  onClose: () => void
  onProjectSelected: (project: Omit<ProjectDto, "id">) => void
}) => {
  const { open, onClose } = props
  const [selectedProject, setSelectedProject] = useState<ProjectDto | null>(
    null,
  )
  const canSubmit = selectedProject !== null
  const onProjectSelected = () => {
    if (!selectedProject) return
    const { id, ...project } = selectedProject
    props.onProjectSelected(project)
  }
  const dispatch = useAppDispatch()
  const [projects, setProjects] = useState<ProjectDto[]>([])
  useEffect(() => {
    if (open) {
      ProjectClient.listProjects()
        .then((projects) => {
          setProjects(projects)
        })
        .catch(() => {
          dispatch(showErrorSnackbar("Failed to load projects"))
          setProjects([])
        })
    } else {
      setSelectedProject(null)
    }
  }, [open, dispatch])

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Add existing project"
      size="sm"
      content={
        <Autocomplete
          value={selectedProject}
          options={projects}
          onChange={(_, newSelection) => setSelectedProject(newSelection)}
          placeholder={"Project"}
          fullWidth
          autoComplete
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          renderOption={(props, project) => {
            return (
              <MenuItem {...props} key={project.id} value={project.id}>
                {project?.name} ({project?.domain})
              </MenuItem>
            )
          }}
          getOptionLabel={(project) => `${project.name} (${project.domain})`}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="medium"
              label="Project"
              InputLabelProps={{
                sx: { fontSize: "16px" },
              }}
            />
          )}
        />
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Continue",
          onClick: onProjectSelected,
          disabled: !canSubmit,
        },
      ]}
    />
  )
}
