import { useAppDispatch } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { archiveSyllabus } from "@features/syllabusEditor/syllabusEditorSlice"

function ArchiveSyllabusConfirmationPopup(props: {
  syllabusId: string
  onClose: () => void
}) {
  const { syllabusId, onClose } = props

  const dispatch = useAppDispatch()
  const onArchive = () => {
    dispatch(archiveSyllabus({ syllabusId })).then(() => {
      onClose()
    })
  }

  return (
    <GenericDialog
      open
      onClose={onClose}
      size="xs"
      title="Archive this syllabus?"
      subtitle={
        "The syllabus will no longer be visible to all users in the main" +
        "repository. You can restore it at any time."
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Archive",
          onClick: onArchive,
        },
      ]}
    />
  )
}

export default ArchiveSyllabusConfirmationPopup
