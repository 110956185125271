import { ProgramClassDto } from "@masterschool/course-builder-api"
import { Chip, FormControl } from "@mui/material"
import { MultipleOptionsSelector } from "@cmp/form-components/multipleOptionsSelector"

export const AssignedClassesSelector = (props: {
  selectedClassesIDs: string[]
  onSelectedClassesChange: (classesIDs: string[]) => void
  programClasses: ProgramClassDto[]
  label: string
  helperText?: string
}) => {
  const { selectedClassesIDs, onSelectedClassesChange, programClasses } = props
  const { label, helperText } = props

  return (
    <FormControl>
      <MultipleOptionsSelector
        options={programClasses}
        label={label}
        selectedOptions={selectedClassesIDs}
        onChange={onSelectedClassesChange}
        getOptionLabel={(programClass) => programClass.name}
        helperText={helperText}
        renderTag={(programClass, index, getTagProps) => (
          <Chip
            label={programClass.name}
            size="small"
            sx={{ width: "fit-content" }}
            {...getTagProps({ index })}
          />
        )}
      />
    </FormControl>
  )
}
