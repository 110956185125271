import {
  Box,
  Button,
  Card,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../theme/appTheme"
import { useAppSelector } from "@app/hooks"
import {
  selectLoggedInUser,
  selectUserLoginState,
} from "@features/login/loginSelectors"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

export function Login(props: { redirectUrl?: string | undefined }) {
  const redirectUrl = props.redirectUrl
  const theme = useTheme()
  const loginURL = import.meta.env.VITE_LOGIN_BE_URL
  const userState = useAppSelector(selectUserLoginState)
  const user = useAppSelector(selectLoggedInUser)
  const isUnauthorized = userState === "not-authorized"

  const navigate = useNavigate()
  useEffect(() => {
    if (userState === "logged-in") {
      navigate("/")
    }
  }, [userState, navigate])
  const [quoteIndex, setQuoteIndex] = useState(
    Math.floor(Math.random() * QUOTES.length),
  )
  const [quoteOpacity, setQuoteOpacity] = useState(1)
  useEffect(() => {
    const interval = setInterval(() => {
      setQuoteOpacity(0)
      setTimeout(() => {
        setQuoteIndex((quoteIndex) => (quoteIndex + 1) % QUOTES.length)
        setQuoteOpacity(1)
      }, 200)
    }, 8000)
    return () => clearInterval(interval)
  }, [])

  const redirectUrlParam = redirectUrl ? `&redirectUrl=${redirectUrl}` : ""
  const googleLoginUrl = `${loginURL}/api/google/login?appName=lms${redirectUrlParam}`

  if (userState === "pending") {
    return null
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="32px"
      flexDirection="column"
      sx={{
        paddingTop: "calc(100vh / 6)",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: appTheme.palette.background.default,
      }}
    >
      <Stack alignItems="flex-start">
        <SvgIcon
          component={appIcons.masterschoolWithText}
          sx={{ height: 30, width: "auto" }}
          inheritViewBox
        />
        <Typography ml="41px">BackOffice</Typography>
      </Stack>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        gap="40px"
        alignItems="center"
      >
        <Card
          component={Stack}
          spacing={3}
          p={6}
          width="400px"
          sx={{
            backgroundColor: "primary.contrast",
            border: "1px solid",
            borderColor: "other.outlineBorder",
            boxShadow: "none",
            borderRadius: "2px",
            [theme.breakpoints.down("sm")]: {
              px: 4,
            },
          }}
        >
          <Typography variant="h3" textAlign="center">
            Welcome Back!
          </Typography>
          <Typography variant="body2" textAlign="center">
            Please login with your Masterschool employee account to access the
            LMS.
          </Typography>
          <Button
            onClick={() => (window.location.href = googleLoginUrl)}
            variant="outlined"
            startIcon={
              <SvgIcon
                component={appIcons.googleIcon}
                sx={{ width: 20, height: 20 }}
              />
            }
            sx={{
              borderColor: "primary.main",
            }}
          >
            <Typography variant="body1">Login with Google</Typography>
          </Button>
          {isUnauthorized && (
            <Box
              display="flex"
              flexDirection="column"
              gap="4px"
              textAlign="center"
            >
              <Typography variant="body2_sb" color="red">
                You do not have access to this page.
              </Typography>
              <Typography variant="body3" color="red">
                Only Masterschool employees can access the LMS.
              </Typography>
              <Typography variant="body3" color="red">
                <b>{user?.email}</b> is not authorized.
              </Typography>
            </Box>
          )}
        </Card>
      </Box>
      <Box
        sx={{
          opacity: quoteOpacity,
          maxWidth: "500px",
          transition: "all 0.2s ease-in-out",
        }}
      >
        {QUOTES[quoteIndex]}
      </Box>
    </Box>
  )
}

const QUOTES = [
  <Typography variant="body3">
    <i>"The best LMS Masterschool has ever built."</i>
    <br /> - Shirin R. R. Obinov
  </Typography>,
  <Typography variant="body3">
    <i>
      "I have never seen my design come to life in such a polished and
      professional way"
    </i>
    <br /> - Ruthie K. Elbert
  </Typography>,
  <Typography variant="body3">
    <i>"This is the iPhone moment of content creators everywhere"</i>
    <br /> - Steve Placements
  </Typography>,
  <Typography variant="body3">
    <i>"Everyone who worked on this project should get a raise"</i>
    <br /> - Zur Tene
  </Typography>,
  <Typography variant="body3">
    <i>"I can quit now"</i>
    <br /> - Ivan Zhao, Co-founder and CEO of Notion
  </Typography>,
  <Typography variant="body3">
    <i>"Life is what happens while you're busy building Syllabuses"</i>
    <br /> - John Lennon
  </Typography>,
  <Typography variant="body3">
    <i>"Content writing is the new AI"</i>
    <br /> - Nvidia CEO, John Vidia
  </Typography>,
  <Typography variant="body3">
    <i>
      “I must not use Notion. Notion is the mind-killer. Notion is the
      little-death that brings total obliteration. I will face my Notion. I will
      permit it to pass over me and through me. And when it has gone past I will
      turn the inner eye to see its path. Where the Notion has gone there will
      be nothing. Only The LMS will remain.”
    </i>
    <br /> - Muad'Dibtamar
  </Typography>,
]
