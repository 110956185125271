import { CreateShiftRequest, ShiftDto } from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { ShiftCard } from "./shiftCard"
import appTheme from "../theme/appTheme"

const formatTime = (hour: number, minute: number) => {
  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`
}

const times = Array.from({ length: 24 * 4 }, (_, i) => {
  const hour = Math.floor(i / 4)
  const minute = (i % 4) * 15
  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`
}).filter((time) => time <= "20:00" && time >= "09:00")

const availableTimezones = [
  "EU/Berlin",
  "EU/London",
  "EU/Israel",
  "US/New_York",
  "US/California",
]

const stringDayToDayNumber = (day: string) => {
  switch (day) {
    case "Monday":
      return 1
    case "Tuesday":
      return 2
    case "Wednesday":
      return 3
    case "Thursday":
      return 4
    case "Friday":
      return 5
    case "Saturday":
      return 6
    case "Sunday":
      return 0
    default:
      throw new Error("Invalid day")
  }
}

const dayToString = (day: number) => {
  switch (day) {
    case 0:
      return "Sunday"
    case 1:
      return "Monday"
    case 2:
      return "Tuesday"
    case 3:
      return "Wednesday"
    case 4:
      return "Thursday"
    case 5:
      return "Friday"
    case 6:
      return "Saturday"
    default:
      return ""
  }
}

export const CreateOrAttachShift = (props: {
  availableShifts: ShiftDto[]
  onAttachShift: (shift: ShiftDto) => void
  onCreateShift: (createShiftRequest: CreateShiftRequest) => void
}) => {
  const { availableShifts, onAttachShift, onCreateShift } = props
  const [selectedShift, setSelectedShift] = useState<ShiftDto>()
  const [isOnAttachMode, setIsOnAttachMode] = useState(true)
  const [timezone, setSelectedTimeZone] = useState("EU/Berlin")
  const [name, setName] = useState("")
  const [days, setDays] = useState<number[]>([])
  const [startHour, setStartHour] = useState(9)
  const [startMinute, setStartMinute] = useState(0)
  const [endHour, setEndHour] = useState(18)
  const [endMinute, setEndMinute] = useState(0)

  const onAttachShiftProxy = (shift: ShiftDto) => {
    onAttachShift(shift)
    setSelectedShift(undefined)
  }

  const onSelectedShiftChange = (selectedShiftId: string) => {
    const shift = availableShifts.find((shift) => shift.id === selectedShiftId)
    if (shift) {
      setSelectedShift(shift)
    }
  }

  const insertNewShift = () => {
    const createShiftRequest = {
      startTime: formatTime(startHour, startMinute),
      endTime: formatTime(endHour, endMinute),
      timezone,
      days,
      name,
    }
    onCreateShift(createShiftRequest)
    clearInsertForm()
  }

  const clearInsertForm = () => {
    setName("")
    setDays([])
    setStartHour(8)
    setStartMinute(0)
    setEndHour(17)
    setEndMinute(0)
  }

  const setDaysProxy = (selectedDays: string[]) => {
    setDays(selectedDays.map((day) => stringDayToDayNumber(day)))
  }
  const attachShiftForm = (
    <Box display="flex" flexDirection="column" gap="16px" flex="1">
      <FormControl>
        <InputLabel id="shift-label">Select shift</InputLabel>
        <Select
          key={!!selectedShift ? selectedShift.id : ""}
          labelId="shift-label"
          id="shift"
          name="shift"
          value={!!selectedShift ? selectedShift.id : ""}
          label="Preferred Shift"
          onChange={(e) => onSelectedShiftChange(e.target.value as string)}
          required
        >
          {availableShifts.map((shift) => (
            <MenuItem key={shift.id} value={shift.id}>
              {shift.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box width="100%">
        {selectedShift && (
          <ShiftCard
            shift={selectedShift}
            onDeleteShift={(_) => {}}
            canDelete={false}
          />
        )}
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!selectedShift}
        onClick={() => selectedShift && onAttachShiftProxy(selectedShift)}
      >
        Attach Shift
      </Button>
    </Box>
  )

  const createCustomShiftForm = (
    <Box flex="1">
      <FormControl fullWidth>
        <TextField
          label="Shift name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="days-select-label">Days</InputLabel>
          <Select
            labelId="days-select-label"
            id="days-select"
            label="Days"
            multiple
            value={days.map((day) => dayToString(day))}
            onChange={(e) => setDaysProxy(e.target.value as string[])}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container spacing={4} margin="normal">
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="start-time-label">Start Time</InputLabel>
              <Select
                labelId="start-time-label"
                id="start-time-select"
                value={formatTime(startHour, startMinute)}
                label="Start Time"
                onChange={(e) => {
                  const [hour, minute] = e.target.value.split(":")
                  setStartHour(Number(hour))
                  setStartMinute(Number(minute))
                  const endTime = formatTime(endHour, endMinute)
                  const startTime = formatTime(Number(hour), Number(minute))
                  if (endTime <= startTime) {
                    if (Number(hour) < 19) {
                      setEndHour(Number(hour) + 1)
                      setEndMinute(0)
                    } else {
                      setEndHour(20)
                      setEndMinute(0)
                    }
                  }
                }}
              >
                {times
                  .filter((time) => time !== "20:00")
                  .map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="end-time-label">End Time</InputLabel>
              <Select
                labelId="end-time-label"
                id="end-time-select"
                value={formatTime(endHour, endMinute)}
                label="End Time"
                onChange={(e) => {
                  const [hour, minute] = e.target.value.split(":")
                  setEndHour(Number(hour))
                  setEndMinute(Number(minute))
                }}
              >
                {times
                  .filter((endTime) => {
                    return endTime > formatTime(startHour, startMinute)
                  })
                  .map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FormControl fullWidth margin="normal">
          <InputLabel id="timezone-select-label">Timezone</InputLabel>
          <Select
            labelId="timezone-select-label"
            id="timezone-select"
            value={timezone}
            label="Timezone"
            disabled={true}
            onChange={(e) => setSelectedTimeZone(e.target.value as string)}
          >
            {availableTimezones.map((timezone) => (
              <MenuItem key={timezone} value={timezone}>
                {timezone}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormControl>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={
          !name || days.length === 0 || !startHour || !endHour || !timezone
        }
        onClick={insertNewShift}
        sx={{ marginTop: "16px" }}
      >
        Create Custom Shift
      </Button>
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" flex="3">
      <Box
        bgcolor="white"
        padding="24px"
        display="flex"
        flexDirection="column"
        gap="16px"
        borderColor={appTheme.palette.eTypes.sand50}
        sx={{ borderWidth: "1px", borderStyle: "solid" }}
      >
        <Typography variant="h6" textAlign="left">
          Attach shifts
        </Typography>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio
              checked={isOnAttachMode}
              onChange={(e) => {
                setIsOnAttachMode(e.target.checked)
              }}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <Typography variant="body2">Choose from existing one</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Radio
              checked={!isOnAttachMode}
              onChange={(e) => {
                setIsOnAttachMode(!e.target.checked)
              }}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
            <Typography variant="body2">Create new</Typography>
          </Box>
        </Box>
        {isOnAttachMode ? attachShiftForm : createCustomShiftForm}
      </Box>
    </Box>
  )
}
