import { RootState } from "@app/store"
import { createSelector } from "@reduxjs/toolkit"

export const selectAppFeatureFlags = (state: RootState) =>
  state.appFeatureFlags.appFeatureFlags

export const selectAppFeaturesSearchText = (state: RootState) =>
  state.appFeatureFlags.searchText

export const selectAddAppFeatureFlagFormPresentation = (state: RootState) => {
  if (!state.appFeatureFlags.addAppFeatureFlagPopup) {
    return undefined
  }
  if (state.appFeatureFlags.appFeatureFlags.status !== "success") {
    return undefined
  }
  const isFlagExist =
    state.appFeatureFlags.appFeatureFlags.data?.findIndex(
      (flag) =>
        flag.name === state.appFeatureFlags.addAppFeatureFlagPopup?.form.name,
    ) !== -1
  const isNameValid =
    state.appFeatureFlags.addAppFeatureFlagPopup.form.name.length > 0

  return {
    isOpen: true,
    name: state.appFeatureFlags.addAppFeatureFlagPopup.form.name,
    enabled: state.appFeatureFlags.addAppFeatureFlagPopup.form.enabled,
    request: state.appFeatureFlags.addAppFeatureFlagPopup.requestStatus,
    loading:
      state.appFeatureFlags.addAppFeatureFlagPopup.requestStatus === "pending",
    disableButton: isFlagExist || !isNameValid,
  }
}

export const selectTurnOnOffAppFeatureFlagFormPresentation = (
  state: RootState,
) => {
  if (state.appFeatureFlags.appFeatureFlags.status !== "success") {
    return undefined
  }
  if (!state.appFeatureFlags.turnOnOffAppFeatureFlagPopup) {
    return undefined
  }
  const name = state.appFeatureFlags.turnOnOffAppFeatureFlagPopup.flagName
  const flag = state.appFeatureFlags.appFeatureFlags.data?.find(
    (flag) => flag.name === name,
  )
  if (!flag) {
    return undefined
  }
  return {
    isOpen: true,
    name: name,
    state: flag.enabled,
    request: state.appFeatureFlags.turnOnOffAppFeatureFlagPopup.requestStatus,
    loading:
      state.appFeatureFlags.turnOnOffAppFeatureFlagPopup.requestStatus ===
      "pending",
  }
}

export const selectIsFeatureEnabled = createSelector(
  [selectAppFeatureFlags, (_: RootState, name: string) => name],
  (featureFlags, name) =>
    featureFlags.status === "success" &&
    featureFlags.data.some((flag) => flag.name === name && flag.enabled),
)
