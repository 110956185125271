import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import GenericTable, { renderWithHighlightText } from "@cmp/genericTable"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { SearchComponent } from "@cmp/searchComponent"
import SyllabusStatusChip from "@cmp/syllabusStatusChip"
import {
  pickPublishedSyllabusPopupClosed,
  pickPublishedSyllabusPopupOpened,
} from "@features/program/programSlice"
import { selectProgramsSyllabusPage } from "@features/program/programSliceSelectors"
import {
  connectSyllabusToProgram,
  editProgramClassesSyllabus,
} from "@features/program/programSliceThunks"
import { FilterName, SearchAdvanceFilter } from "@features/search/searchSlice"
import {
  Syllabus,
  selectPublishedSyllabuses,
} from "@features/syllabus/syllabusSelectors"
import { fetchSyllabuses } from "@features/syllabus/syllabusesMenuSlice"
import { CircularProgress, Divider, Stack, Typography } from "@mui/material"
import { domainToDisplayName } from "@utils/domainUtils"
import { useEffect, useState } from "react"
import { useSyllabusActionConfirmationPopup } from "./syllabusActionConfirmationPopup"

const SelectSyllabusPopup = () => {
  const [selectedSyllabus, setSelectedSyllabus] = useState<
    Syllabus | undefined
  >(undefined)
  const { pickPublishedSyllabusPopup: model } = useAppSelector(
    selectProgramsSyllabusPage,
  )
  const confirmationPopup = useSyllabusActionConfirmationPopup()
  const connectItemType = model.classIds
    ? model.classIds.length > 0
      ? "class"
      : "classes"
    : "program"
  const dispatch = useAppDispatch()

  const onSyllabusSelected = (syllabus: Syllabus) => {
    if (model.withConfirmationPopup) {
      openConfirmationPopup(syllabus)
    } else {
      connectSyllabus(syllabus)
    }
    dispatch(pickPublishedSyllabusPopupClosed())
  }

  const openConfirmationPopup = (syllabus: Syllabus) => {
    confirmationPopup.open({
      action: {
        type: "connect",
        syllabusName: syllabus?.name ?? "",
        item: connectItemType,
      },
      onCancel: onCancelConfirmation,
      onConfirm: () => {
        connectSyllabus(syllabus)
      },
    })
  }

  const connectSyllabus = async (selectedSyllabus: Syllabus) => {
    if (!model.programId) return
    const syllabusId = selectedSyllabus.id
    const programId = model.programId
    confirmationPopup.setIsLoading(true)
    try {
      if (connectItemType === "class" || connectItemType === "classes") {
        await connectToClass(syllabusId, programId)
      } else {
        await connectToProgram(syllabusId, programId)
      }
    } finally {
      confirmationPopup.close()
      onClose()
    }
  }

  const connectToProgram = async (syllabusId: string, programId: string) => {
    return dispatch(
      connectSyllabusToProgram({
        programId,
        syllabusId,
      }),
    )
  }

  const connectToClass = async (syllabusId: string, programId: string) => {
    return dispatch(
      editProgramClassesSyllabus({
        programId,
        syllabusId,
        classIds: model.classIds ?? [],
      }),
    )
  }

  const onClose = () => {
    dispatch(pickPublishedSyllabusPopupClosed())
  }

  const onCancelConfirmation = () => {
    confirmationPopup.close()
    setSelectedSyllabus(undefined)
    dispatch(
      pickPublishedSyllabusPopupOpened({ programId: model.programId ?? "" }),
    )
  }

  return (
    <>
      <GenericDialog
        open={model.isOpen}
        title="Connect syllabus"
        size="lg"
        onClose={onClose}
        fullHeight
        disableContentPadding
        content={
          <Stack position="relative">
            {!model.withConfirmationPopup && selectedSyllabus && (
              <Stack
                position="absolute"
                width={1}
                height={1}
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                sx={{ opacity: 0.8, zIndex: 10 }}
              >
                <CircularProgress color="secondary" />
              </Stack>
            )}
            <SelectSyllabusTable onSelect={onSyllabusSelected} />
          </Stack>
        }
      />
    </>
  )
}

export const SelectSyllabusTable = ({
  onSelect,
}: {
  onSelect: (syllabus: Syllabus) => void
}) => {
  const dispatch = useAppDispatch()
  const publishedSyllabus = useAppSelector(selectPublishedSyllabuses)
  const [loading, setLoading] = useState(true)
  const noPublishedSyllabuses = !loading && publishedSyllabus.length === 0

  useEffect(() => {
    dispatch(fetchSyllabuses()).then(() => setLoading(false))
  }, [dispatch])

  return (
    <Stack overflow="hidden">
      <Divider />
      {noPublishedSyllabuses ? (
        <NoPublishedSyllabuses />
      ) : (
        <SyllabusesTable
          syllabuses={publishedSyllabus}
          onSelect={onSelect}
          isLoadingSyllabuses={loading}
        />
      )}
    </Stack>
  )
}

const NoPublishedSyllabuses = () => {
  return (
    <Typography m="auto" p={10}>
      No published syllabuses found
    </Typography>
  )
}

const SyllabusesTable = ({
  syllabuses,
  onSelect,
  isLoadingSyllabuses,
}: {
  syllabuses: Syllabus[]
  isLoadingSyllabuses: boolean
  onSelect: (syllabus: Syllabus) => void
}) => {
  const [searchText, setSearchText] = useState<string>("")
  const [filters, setFilters] = useState<SearchAdvanceFilter[]>([])
  const allDomains = [...new Set(syllabuses.msCompactMap((s) => s.domain))]
  const filteredDomains =
    filters.find((f) => f.filterName === FilterName.Domains)?.selectedValues ??
    []

  const syllabusesByDomain = filterSyllabusesByDomains(
    syllabuses,
    filteredDomains,
  )
  const syllabusesToDisplay = filterSyllabusesBySearchText(
    syllabusesByDomain,
    searchText,
  )

  return (
    <Stack
      px={3}
      pt={5}
      gap={4}
      height={1}
      overflow="hidden"
      alignItems="center"
    >
      <SearchComponent
        onSearchTextChange={setSearchText}
        searchText={searchText}
        inputFilters={[
          {
            filterName: FilterName.Domains,
            optionalValues: allDomains,
            selectedValues: filteredDomains,
          },
        ]}
        onInputFiltersChange={setFilters}
        searchPlaceholder="Search for a syllabus"
        filterTitle="Filter syllabuses"
        getOptionLabel={domainToDisplayName}
      />
      {isLoadingSyllabuses && (
        <LoadingComponent loadedComponents="published syllabuses" />
      )}
      {!isLoadingSyllabuses && (
        <>
          {syllabusesToDisplay.length === 0 && (
            <Typography p={10} color="text.secondary">
              No syllabuses match the search criteria
            </Typography>
          )}
          {syllabusesToDisplay.length > 0 && (
            <GenericTable
              highlightText={searchText}
              data={syllabusesToDisplay}
              onClick={onSelect}
              keys={["name", "domain", "status"]}
              renderHeader={{
                name: () => "Syllabus Name",
                programStartDate: () => "Cohort",
                programType: () => "Type",
              }}
              sx={{ overflow: "auto" }}
              render={{
                domain: (domain) =>
                  renderWithHighlightText(
                    (domain && domainToDisplayName(domain)) ?? "",
                    searchText,
                  ),
                status: (_, syllabus) => (
                  <SyllabusStatusChip
                    status={syllabus.status}
                    isActive={false}
                    showIndicator={false}
                  />
                ),
              }}
            />
          )}
        </>
      )}
    </Stack>
  )
}

const filterSyllabusesByDomains = (
  syllabuses: Syllabus[],
  domains: string[],
): Syllabus[] => {
  if (domains.length === 0) {
    return syllabuses
  }
  return syllabuses.filter((syllabus) =>
    domains.includes(syllabus.domain ?? ""),
  )
}

const filterSyllabusesBySearchText = (
  syllabuses: Syllabus[],
  searchText: string,
): Syllabus[] => {
  if (searchText === "") {
    return syllabuses
  }
  return syllabuses.filter((syllabus) => {
    const text = searchText.toLowerCase()
    const name = (syllabus.name ?? "").toLowerCase()
    const domain = domainToDisplayName(syllabus.domain ?? "").toLowerCase()
    return name.includes(text) || domain.includes(text)
  })
}

export default SelectSyllabusPopup
