import GenericTable from "@cmp/genericTable"
import SearchTextField from "@cmp/searchTextField"
import { ClassChipList } from "./classChipList"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useState } from "react"
import { Box, Link, SvgIcon, Typography } from "@mui/material"
import { ProgramClass, SlackChannelData } from "./slackChannelsPage"
import { useAppDispatch } from "@app/hooks"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import appIcons from "@utils/appIcons"
import {
  addClassesToChannelPopupOpened,
  removeClassesFromChannelPopupOpened,
} from "@features/slack/slackSlice"

export const ChannelsTable = (props: {
  programId: string
  channels: SlackChannelData[]
}) => {
  const [searchText, setSearchText] = useState("")
  const onSearchTextChange = (text: string) => {
    setSearchText(text)
  }
  const filteredChannels = props.channels.filter((channel) =>
    channel.name.includes(searchText),
  )
  const isEmptyData = filteredChannels.length === 0

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <SearchTextField
        searchText={searchText}
        onSearchTextChange={onSearchTextChange}
        placeholder={"Search by channel name"}
      />
      {isEmptyData && (
        <Typography variant="body1" textAlign="center">
          No channels found
        </Typography>
      )}
      <GenericTable
        data={filteredChannels}
        keys={["name", "classes", "id"]}
        renderHeader={{
          name: () => <Typography variant="body2_sb">Name</Typography>,
          id: () => undefined,
          classes: () => <Typography variant="body2_sb">Classes</Typography>,
        }}
        render={{
          name: (_, row, hover) => (
            <ChannelNameAndLink name={row.name} url={row.url} hover={hover} />
          ),
          classes: (programClasses) => (
            <ClassChipList classes={(programClasses as ProgramClass[]) ?? []} />
          ),
          id: (_, row) => (
            <SlackActionsMenu
              offeringGroupId={row.offeringGroupId}
              channelId={row.id}
              channelName={row.name}
              programId={props.programId}
            />
          ),
        }}
        sx={{
          overflow: "auto",
          maxHeight: "100%",
          borderRadius: "4px",
        }}
      />
    </Box>
  )
}

const ChannelNameAndLink = (props: {
  name: string
  url: string
  hover: boolean
}) => {
  const { name, url, hover } = props
  return (
    <Box
      display="flex"
      gap="24px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body1">{name}</Typography>
      <Box
        display="fleX"
        gap="8px"
        sx={{ opacity: hover ? 1 : 0, transition: "all 0.2s" }}
        onClick={(e) => {
          e.preventDefault()
          window.open(url, "_blank", "noopener")
        }}
      >
        <Link href={url} target="_blank" rel="noopener">
          Go to Channel
        </Link>
        <OpenInNewIcon sx={{ width: "20px", height: "20px" }} />
      </Box>
    </Box>
  )
}

const SlackActionsMenu = (props: {
  channelId: string
  offeringGroupId: string
  programId: string
  channelName: string
}) => {
  const { programId, channelId, channelName, offeringGroupId } = props
  const dispatch = useAppDispatch()

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon component={appIcons.dotsHorizontal} inheritViewBox />
          ),
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "add-class-to-channel",
            children: "Add classes to channel",
            onClick: () => {
              dispatch(
                addClassesToChannelPopupOpened({
                  programId: programId,
                  channelId: channelId,
                  channelName: channelName,
                  offeringGroupId: offeringGroupId,
                }),
              )
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "remove-class-from-channel",
            children: "Remove classes from channel",
            onClick: () => {
              dispatch(
                removeClassesFromChannelPopupOpened({
                  programId: programId,
                  channelId: channelId,
                  channelName: channelName,
                  offeringGroupId: offeringGroupId,
                }),
              )
            },
          },
        },
      ]}
    />
  )
}
