import GenericTable from "@cmp/genericTable"
import { MultipleStudentsOnboardPopup } from "@features/program/programSlice"
import { Box, Stack, Typography } from "@mui/material"
import appTheme from "../../../../theme/appTheme"

function MultipleStudentsRegistrationPreview(props: {
  model: MultipleStudentsOnboardPopup
}) {
  const { model } = props
  const studentsReadyForOnboarding = Object.keys(model.statuses).filter(
    (email) => model.statuses[email] === "ready",
  )

  const tableModel = model.students.map((student) => ({
    firstName: student.firstName,
    lastName: student.lastName,
    email: student.email,
    status: model.statuses[student.email],
  }))

  return (
    <Stack gap={3}>
      <Typography variant="body2">
        {studentsReadyForOnboarding.length} of {model.students.length} students
        can be enrolled.
      </Typography>
      <Box>
        <GenericTable
          sx={{
            maxHeight: "500px",
            overflow: "auto",
            borderBottom: `1px solid ${appTheme.palette.divider}`,
          }}
          data={tableModel}
          render={{
            status: (value) => <RegistrationStatusCell status={value} />,
          }}
          renderHeader={{
            email: () => "Email",
            status: () => "Status",
            firstName: () => "First name",
            lastName: () => "Last name",
          }}
        />
      </Box>
    </Stack>
  )
}

type RegistrationStatus = MultipleStudentsOnboardPopup["statuses"][string]

function RegistrationStatusCell(props: { status: RegistrationStatus }) {
  const text = () => {
    switch (props.status) {
      case "ready":
        return "Ready to add"
      case "registered":
        return "Already added"
      case "registeredElsewhere":
        return "Added elsewhere"
      case "invalid":
        return "Invalid"
    }
  }

  const color = () => {
    switch (props.status) {
      case "ready":
        return appTheme.palette.success.dark
      case "registered":
      case "registeredElsewhere":
      case "invalid":
        return appTheme.palette.error.dark
    }
  }

  return (
    <Typography
      sx={{
        color: color(),
      }}
    >
      {text()}
    </Typography>
  )
}

export default MultipleStudentsRegistrationPreview
