import { UniqueIdentifier } from "@dnd-kit/core"
import { SortableContext } from "@dnd-kit/sortable"
import SortableItem from "./sortableItem"

function Droppable(props: {
  id: string
  items: {
    identifier: UniqueIdentifier
    element: JSX.Element
  }[]
  placeholder: JSX.Element
  style?: (isDragging: boolean) => React.CSSProperties
  disabled?: boolean
}) {
  const { id, items, placeholder, style, disabled } = props
  const placeholderId = `placeholder-${window.crypto.randomUUID()}`
  return (
    <SortableContext id={id} items={items.map((item) => item.identifier)}>
      {items.map((item) => (
        <SortableItem
          key={item.identifier.toString()}
          item={item.element}
          id={item.identifier.toString()}
          style={style}
          disabled={disabled}
        />
      ))}
      {items.length === 0 ? (
        <SortableItem
          key={placeholderId}
          item={placeholder}
          id={placeholderId}
        />
      ) : null}
    </SortableContext>
  )
}

export default Droppable
