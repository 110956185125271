import { useAppDispatch } from "@app/hooks"
import {
  createNewChannelPopupOpened,
  fetchProgramSlackChannels,
} from "@features/slack/slackSlice"
import { selectProgramSlackChannels } from "@features/slack/slackSliceSelector"
import { Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import SlackTabDialogPresenter from "../../slack/slackDialogs/slackTabDialogPresenter"
import EmptyWidgetCard from "./emptyWidgetCard"
import WidgetCard from "./widgetCard"

const SlackChannelsWidget = ({ programId }: { programId: string }) => {
  const dispatch = useAppDispatch()
  const channels = useSelector(selectProgramSlackChannels(programId))

  useEffect(() => {
    dispatch(fetchProgramSlackChannels(programId))
  }, [programId, dispatch])

  if (channels.length === 0) {
    return (
      <>
        <SlackTabDialogPresenter />
        <EmptyWidgetCard
          message="There are no Slack channels to display."
          actionProps={{
            children: "Add channel",
            onClick: () => dispatch(createNewChannelPopupOpened({ programId })),
          }}
        />
      </>
    )
  }

  return (
    <WidgetCard
      title="Slack channels"
      navigateTo={`/program/${programId}?tab=slack`}
    >
      <Stack direction="row" gap={2}>
        <SvgIcon
          component={appIcons.slackIcon}
          inheritViewBox
          sx={{ width: 20, height: 20 }}
        />
        <Typography variant="body2">
          {channels.length === 1
            ? `${channels[0].name}`
            : `${channels.length} Channels`}
        </Typography>
      </Stack>
    </WidgetCard>
  )
}

export default SlackChannelsWidget
