import { useAppSelector } from "@app/hooks"
import { CourseDto } from "@masterschool/course-builder-api"
import { selectActiveCourse } from "@features/courseEditor/courseEditorSelectors"
import StatusChip, { StatusChipProps } from "./statusChip"
import { courseStatusTextAndColor } from "./courseStatus+Ui"
import {
  selectIsEditingPublishedCourse,
  selectLatestPublishedCourse,
} from "@features/coursesMenu/coursesSelectors"
import { areCourseDtosEqual } from "@utils/courseUtils"

function CourseEditorStatus(props: { course: CourseDto }) {
  const { course } = props
  const currentEditedCourseDto = useAppSelector(selectActiveCourse)
  const publishedCourse = useAppSelector(selectLatestPublishedCourse(course.id))
  const isEditingPublishedCourse = useAppSelector(
    selectIsEditingPublishedCourse(course.id),
  )

  const hasChanges =
    currentEditedCourseDto !== undefined &&
    publishedCourse !== undefined &&
    !areCourseDtosEqual(publishedCourse, currentEditedCourseDto)

  const statusTextAndColor = isEditingPublishedCourse
    ? {
        text: "Published",
        color: "success" as const,
      }
    : courseStatusTextAndColor(course.status)

  return (
    <CourseStatus
      text={statusTextAndColor.text}
      color={statusTextAndColor.color}
      showIndicator={hasChanges}
    />
  )
}

function CourseStatus(props: Omit<StatusChipProps, "isActive">) {
  return <StatusChip {...props} isActive={undefined} />
}

export default CourseEditorStatus
