import { Snackbar, SvgIcon } from "@mui/material"
import { useSelector } from "react-redux"
import { useAppDispatch } from "@app/hooks"
import { selectSnackbarPresentationState } from "@features/ui/uiSelector"
import { hideSnackbar } from "@features/ui/uiSlice"
import appIcons from "@utils/appIcons"
import { ElementType } from "react"

function SnackbarPresenter() {
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(hideSnackbar())
  }

  const presentationState = useSelector(selectSnackbarPresentationState)

  if (!presentationState) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: presentationState.anchorOrigin ?? "top",
        horizontal: "center",
      }}
      open={presentationState.visible}
      onClose={onClose}
      autoHideDuration={presentationState.duration}
      ContentProps={{
        sx: {
          bgcolor: presentationState.bgcolor,
        },
      }}
      message={
        <>
          <SvgIcon
            component={iconForSeverity(presentationState.severity)}
            sx={{
              width: 20,
              height: 20,
              mr: 1,
              color: "primary.contrast",
              stroke: "currentcolor",
              fill: "none",
              borderRadius: "50%",
              border:
                presentationState.severity === "success"
                  ? "1px solid white"
                  : "none",
            }}
          />
          {presentationState.message}
        </>
      }
    />
  )
}

function iconForSeverity(
  severity: "success" | "error" | "warning" | "info",
): ElementType<any> {
  switch (severity) {
    case "success":
      return appIcons.check
    case "info":
      return appIcons.infoCircle
    case "error":
      return appIcons.alertCircle
    case "warning":
      return appIcons.alertCircle
    default:
      return appIcons.infoCircle
  }
}

export default SnackbarPresenter
