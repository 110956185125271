import { useSearchParams } from "react-router-dom"

function useCloseElementEditor() {
  const [searchParams, setSearchParams] = useSearchParams()
  return () => {
    searchParams.delete("elementId")
    setSearchParams(searchParams, { replace: true })
  }
}

export default useCloseElementEditor
