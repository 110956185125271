import { useAppDispatch } from "@app/hooks"
import GenericDialog, { GenericDialogProps } from "@cmp/genericDialog"
import { deleteProgramClass } from "@features/program/programSliceThunks"
import { ProgramClassDto } from "@masterschool/course-builder-api"

function DeleteProgramClassDialog(props: {
  open: boolean
  programId: string
  programClass: ProgramClassDto
  onClose: () => void
}) {
  const { open, programId, programClass, onClose } = props
  const dispatch = useAppDispatch()
  const onDeleteRequest = async () => {
    dispatch(deleteProgramClass({ programId, classId: programClass.id })).then(
      () => onClose(),
    )
  }

  const dialogProps =
    programClass.students.length > 0
      ? getCannotDeleteClassProps({ onClose })
      : getDeleteClassProps({ programClass, onDeleteRequest, onClose })

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      size="xs"
      title={dialogProps.title}
      subtitle={dialogProps.subtitle}
      buttons={dialogProps.buttons}
    />
  )
}

function getDeleteClassProps(props: {
  programClass: ProgramClassDto
  onDeleteRequest: () => void
  onClose: () => void
}): {
  title: string
  subtitle: string
  buttons: GenericDialogProps["buttons"]
} {
  const { programClass, onDeleteRequest, onClose } = props
  return {
    title: "Remove class",
    subtitle: `Are you sure to want to remove ${programClass.name}?\nThis action is irreversible.`,
    buttons: [
      {
        type: "secondary",
        text: "Cancel",
        onClick: onClose,
      },
      {
        type: "danger",
        text: "Remove class",
        onClick: onDeleteRequest,
        loading: false,
      },
    ],
  }
}

function getCannotDeleteClassProps(props: { onClose: () => void }): {
  title: string
  subtitle: string
  buttons: GenericDialogProps["buttons"]
} {
  return {
    title: "Class cannot be deleted",
    subtitle:
      "This class cannot be deleted because it contains at least one student." +
      " Please remove all students to proceed with the deletion of the class.",
    buttons: [
      {
        type: "primary",
        text: "I understand",
        onClick: props.onClose,
      },
    ],
  }
}

export default DeleteProgramClassDialog
