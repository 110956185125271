import { useAppDispatch, useAppSelector } from "@app/hooks"
import { SearchComponent } from "@cmp/searchComponent"
import {
  programFilterUpdated,
  programSearchTextUpdated,
} from "@features/search/searchSlice"
import {
  SearchAdvanceFilter,
  selectSearchedPrograms,
} from "@features/search/searchSlice"
import { domainToDisplayName } from "@utils/domainUtils"

export const ProgramSearchComponent = () => {
  const dispatch = useAppDispatch()

  const programFilters = useAppSelector(selectSearchedPrograms)

  return (
    <SearchComponent
      onSearchTextChange={(text: string) =>
        dispatch(programSearchTextUpdated(text))
      }
      searchText={programFilters.text}
      inputFilters={programFilters.advanceFilters}
      onInputFiltersChange={(advanceFilter: SearchAdvanceFilter[]) => {
        dispatch(programFilterUpdated(advanceFilter))
      }}
      searchPlaceholder="Search for a program"
      filterTitle="Filter programs"
      getOptionLabel={(option) =>
        capitalizeFirstLetters(domainToDisplayName(option))
      }
    />
  )
}

const capitalizeFirstLetters = (str: string) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}
