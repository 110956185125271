import { Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

function BackButton(props: { onClick: () => void }) {
  return (
    <Button
      variant="text"
      size="small"
      onClick={props.onClick}
      startIcon={
        <SvgIcon
          component={appIcons.arrowLeft}
          inheritViewBox
          sx={{
            stroke: appTheme.palette.icon.black,
          }}
        />
      }
    >
      Back
    </Button>
  )
}

export default BackButton
