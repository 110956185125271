import { RootState } from "@app/store"
import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material"
import { createSelector } from "@reduxjs/toolkit"
import appTheme from "../../theme/appTheme"
import { SnackbarAnchorOrigin, SnackbarDuration } from "./uiSlice"
import {
  selectIsAccountManager,
  selectIsAdmin,
  selectIsDeveloper,
} from "@features/login/loginSelectors"
import { isProdEnv } from "@utils/envUtils"

export const selectPopup = (state: RootState) => state.ui.popup
const selectSnackbar = (state: RootState) => state.ui.snackbar

export type SnackbarPresentationState = {
  duration: number
  message: string
  severity: "success" | "error" | "warning" | "info"
  bgcolor: string
  anchorOrigin?: SnackbarAnchorOrigin
  sx: SxProps<Theme>
  visible: boolean
}

export const selectSnackbarPresentationState = createSelector(
  [selectSnackbar],
  (snackbar): SnackbarPresentationState | undefined => {
    if (snackbar === undefined) {
      return undefined
    }

    return {
      message: snackbar.message,
      bgcolor: snackbarTypeToBackgroundColor(snackbar.type),
      duration: snackbar.duration ?? SnackbarDuration.long,
      anchorOrigin: snackbar.anchorOrigin,
      sx: {
        padding: "0px",
      },
      severity: snackbar.type,
      visible: snackbar.visible,
    }
  },
)

function snackbarTypeToBackgroundColor(
  type: "success" | "error" | "warning" | "info",
): string {
  switch (type) {
    case "success":
      return appTheme.palette.eTypes.evergreen
    case "info":
      return appTheme.palette.other.snackbarBackground
    case "error":
      return appTheme.palette.error.main
    case "warning":
      return appTheme.palette.warning.main
  }
}

export const selectCanAccessAccountManagementTab = createSelector(
  [selectIsAdmin, selectIsAccountManager],
  (isAdmin, isAccountManager) => isAdmin || isAccountManager,
)

export const selectShouldShowProductionWarning = createSelector(
  [selectIsDeveloper],
  (isDeveloper) => {
    return isProdEnv() && isDeveloper
  },
)
