import {
  TabIdentifier,
  tabIdentifierToRouteDescriptor,
} from "@features/app-tabs/tabs.selector"
import { useLocation } from "react-router-dom"

export default function useSelectedTab(
  tabs: TabIdentifier[] | "unknown",
): TabIdentifier | undefined {
  const location = useLocation()
  if (tabs === "unknown") {
    return undefined
  }
  const routesDescriptor = tabs.map((tab) => {
    return {
      route: tabIdentifierToRouteDescriptor[tab],
      tab,
    }
  })

  return routesDescriptor.find((routeDescriptor) =>
    routeDescriptor.route.some((route) => {
      const firstPartPath = route.path.split("/")[0]
      return location.pathname.startsWith("/" + firstPartPath)
    }),
  )?.tab
}
