import { useAppDispatch } from "@app/hooks"
import { pickPublishedSyllabusPopupOpened } from "@features/program/programSlice"
import { Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../../theme/appTheme"
import EmptyStateContainer from "../components/emptyStateContainer"
import SyllabusPagePopupsPresenter from "./popups/popupsPresenter"
import ProgramSyllabusesView from "./programSyllabusesView"
import { useGetAllProgramSyllabusesData } from "./useGetAllProgramSyllabusesData"

function SyllabusPage(props: {
  syllabusId: string | undefined
  programId: string
  classId: string | undefined
}) {
  const allProgramSyllabuses = useGetAllProgramSyllabusesData({
    programId: props.programId,
    classId: props.classId,
  })

  return (
    <>
      <SyllabusPagePopupsPresenter />
      {allProgramSyllabuses.length === 0 ? (
        <NoSyllabusMessageView
          programId={props.programId}
          classId={props.classId}
        />
      ) : (
        <ProgramSyllabusesView
          programSyllabuses={allProgramSyllabuses}
          classId={props.classId}
          defaultSyllabusId={props.syllabusId}
          programId={props.programId}
        />
      )}
    </>
  )
}

function NoSyllabusMessageView({
  programId,
  classId,
}: {
  programId: string
  classId: string | undefined
}) {
  const dispatch = useAppDispatch()
  const showSelectSyllabusPopup = () => {
    dispatch(
      pickPublishedSyllabusPopupOpened({
        programId,
        classIds: classId ? [classId] : undefined,
        withConfirmationPopup: !classId,
      }),
    )
  }

  return (
    <EmptyStateContainer
      icon={appIcons.linkBroken01}
      title="No syllabus connected"
      subtitle={
        classId
          ? "Connect syllabus to this class"
          : "Connect and activate a syllabus to this program"
      }
      action={
        <Button
          onClick={showSelectSyllabusPopup}
          variant="contained"
          color="primary"
          size="medium"
          startIcon={
            <SvgIcon
              inheritViewBox
              component={appIcons.link01}
              sx={{
                fill: "none",
                stroke: appTheme.palette.eTypes.white,
              }}
            />
          }
        >
          Connect syllabus
        </Button>
      }
    />
  )
}

export default SyllabusPage
