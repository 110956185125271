import { useAppSelector } from "@app/hooks"
import {
  selectClassesForProgram,
  selectProgram,
} from "@features/program/programSliceSelectors"
import {
  Syllabus,
  selectSyllabuses,
} from "@features/syllabus/syllabusSelectors"
import { ProgramClassDto } from "@masterschool/course-builder-api"

export const useGetAllProgramSyllabusesData = ({
  programId,
  classId,
}: {
  programId: string
  classId?: string
}) => {
  const program = useAppSelector((state) => selectProgram(state, programId))
  const programClasses = useAppSelector(selectClassesForProgram(program?.id))
  const syllabuses = useAppSelector(selectSyllabuses)

  if (classId) {
    const currentClass = programClasses.find((c) => c.id === classId)
    const classSyllabus = syllabuses.find(
      (s) => s.id === currentClass?.syllabusId,
    )

    if (!classSyllabus || !currentClass) {
      return []
    }
    const isDefaultSyllabus = !currentClass.offeringSyllabusId
    return [buildSyllabusData(classSyllabus, isDefaultSyllabus, [currentClass])]
  }

  const programDefaultSyllabus =
    program?.syllabusId && syllabuses.find((s) => s.id === program.syllabusId)
  const defaultSyllabusData = programDefaultSyllabus
    ? buildSyllabusData(programDefaultSyllabus, true, [])
    : undefined
  const allSyllabusesData: ProgramSyllabusData[] = defaultSyllabusData
    ? [defaultSyllabusData]
    : []

  programClasses.forEach((c) => {
    const classSyllabus = syllabuses.find((s) => s.id === c.syllabusId)
    const classHasDefaultSyllabus =
      c.syllabusId === program?.syllabusId && !c.offeringSyllabusId
    if (classHasDefaultSyllabus) {
      defaultSyllabusData?.classes.push(c)
    } else if (classSyllabus) {
      let syllabusData = allSyllabusesData.find(
        (s) => s.id === classSyllabus.id && !s.isDefault,
      )
      if (!syllabusData) {
        syllabusData = buildSyllabusData(classSyllabus, false, [])
        allSyllabusesData.push(syllabusData)
      }
      syllabusData.classes.push(c)
    }
  })

  return allSyllabusesData
}

function buildSyllabusData(
  syllabus: Syllabus,
  isDefault: boolean,
  classes: ProgramClassDto[],
) {
  return {
    id: syllabus.id,
    syllabus,
    isDefault,
    classes,
  }
}

export type ProgramSyllabusData = ReturnType<typeof buildSyllabusData>
