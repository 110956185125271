import { Theme } from "@emotion/react"
import { Box, SvgIcon, SvgIconProps, SxProps, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { IconType } from "@utils/iconType"
import { ChangeEventHandler, useState } from "react"
import appTheme from "../theme/appTheme"
import OptionsButton2 from "./buttons/optionsButton2"
import { OptionsButtonProps2 } from "./buttons/optionsButton2"
import AppIcon from "@utils/appIcon"

function ItemDescriptor(props: {
  title: string
  subtitle?: string
  index?: number
  onClick?: () => void
  onTitleChange?: ChangeEventHandler<HTMLInputElement>
  icon?: IconType
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps2["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  const {
    title,
    subtitle,
    icon,
    index,
    onClick,
    sx,
    leftIcon,
    leftIconSx,
    optionsItems,
    extraIcons,
    extraText,
  } = props

  const [showOptionsButton, setShowOptionsButton] = useState(false)

  const optionsButton = optionsItems && (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          children: (
            <AppIcon
              icon={appIcons.dots}
              props={{
                sx: {
                  visibility: showOptionsButton ? "visible" : "hidden",
                },
              }}
            />
          ),
        },
      }}
      items={optionsItems}
    />
  )

  return (
    <Box
      onMouseEnter={() => setShowOptionsButton(true)}
      onMouseLeave={() => setShowOptionsButton(false)}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        gap: "8px",
        position: "relative",
      }}
    >
      {leftIcon && (
        <SvgIcon
          inheritViewBox
          component={leftIcon}
          sx={{
            fill: appTheme.palette.icon.black,
            width: "20px",
            height: "20px",
            ...leftIconSx,
          }}
        />
      )}
      <Box
        sx={{
          padding: "16px 24px",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          cursor: onClick === undefined ? "auto" : "pointer",
          alignItems: "center",
          ...sx,
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          {index !== undefined && (
            <Typography variant="body3" color="text.secondary">
              {isNaN(index) ? `??` : `${index + 1}.`}
            </Typography>
          )}
          {icon && (
            <SvgIcon
              component={icon}
              sx={{
                fill: "none",
                stroke: appTheme.palette.icon.black,
                width: "20px",
                height: "20px",
              }}
              inheritViewBox
            />
          )}
          <Box sx={{ width: "100%" }}>
            {
              <Typography
                variant="body2"
                color={title.length > 0 ? "text.primary" : "text.disabled"}
                sx={{
                  whiteSpace: "pre-wrap",
                  ":before": {
                    content: '""',
                    display: "inline-block",
                  },
                }}
              >
                {title.length > 0 ? title : "Unnamed"}
              </Typography>
            }
            {!!subtitle && (
              <Typography
                variant="body3"
                sx={{
                  color: "text.secondary",
                  ":before": {
                    content: '""',
                    display: "inline-block",
                  },
                }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          {extraIcons?.map((icon, index) => {
            const { id, ...iconProps } = icon
            return (
              <SvgIcon
                key={id}
                inheritViewBox
                {...iconProps}
                sx={{
                  fill: "none",
                  stroke: appTheme.palette.icon.black,
                  width: "20px",
                  height: "20px",
                  ...iconProps.sx,
                }}
              />
            )
          })}
        </Box>
        {extraText && (
          <Typography
            variant="body3"
            sx={{
              paddingRight: "4px",
              width: "fit-content",
              whiteSpace: "nowrap",
              paddingLeft: extraIcons ? "8px" : "0",
            }}
          >
            {extraText}
          </Typography>
        )}
        {showOptionsButton && (
          <div
            style={{
              marginLeft: "8px",
            }}
          >
            {optionsButton}
          </div>
        )}
      </Box>
    </Box>
  )
}

export default ItemDescriptor
