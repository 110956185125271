import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "@features/courseEditor/courseValidationsSelectors"
import EditorTextField, { EditorTextFieldProps } from "./editorTextField"

export type TitleTextFieldProps = EditorTextFieldProps & {
  elementId: string
}

function TitleTextField(props: TitleTextFieldProps) {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )
  const showError = errors?.includes("MISSING_TITLE") && !props.value
  const { elementId, ...domProps } = props

  return (
    <EditorTextField
      label="Name"
      error={showError}
      helperText={showError ? "This field is required" : ""}
      {...domProps}
    />
  )
}

export default TitleTextField
