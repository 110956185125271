import { Syllabus } from "@features/syllabus/syllabusSelectors"
import { Box, Chip, Drawer, Typography } from "@mui/material"
import { startDateToCohortStyleDate } from "@utils/syllabusTags"
import { useSearchParams } from "react-router-dom"
import UnitPreviewPanel from "../editor/syllabus/panel/unitPreviewPanel"
import UnitDescriptorView from "../editor/syllabus/units/unitDescriptorView"
import StaleCoursesSnackbarPresenter from "../editor/syllabus/validations/staleCoursesSnackbarPresenter"
import { domainToDisplayName } from "@utils/domainUtils"
import { useState } from "react"
import React from "react"

export function SyllabusView(props: {
  syllabus: Syllabus
  viewMode: "popup" | "inline"
  embeddedHeader?: JSX.Element
  hideStaleCourseSnackbar?: boolean
}) {
  const { syllabus, viewMode, embeddedHeader, hideStaleCourseSnackbar } = props
  const { selectedUnitId, setSelectedUnit, clearSelectedUnit } =
    useUnitSelection(viewMode)

  const selectedUnit = syllabus.units.find((unit) => unit.id === selectedUnitId)
  const syllabusTitlePrefix = selectedUnit
    ? `${selectedUnit.shortTitle || ""}${selectedUnit.unitNumber || ""}`
    : undefined
  const showUnitPreviewPanel = selectedUnit !== undefined

  const onUnitClicked = (unitId: string) => {
    setSelectedUnit(unitId)
  }

  const closeSelectedUnit = () => {
    if (!selectedUnitId) return
    clearSelectedUnit()
  }
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [drawerWidth, setDrawerWidth] = useState<number | undefined>(undefined)

  const finishRenderDrawer = (width: number | undefined) => {
    setDrawerWidth(width)
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        overflow: "hidden",
        display: "flex",
        position: "relative",
      }}
      ref={containerRef}
    >
      {!hideStaleCourseSnackbar && <StaleCoursesSnackbarPresenter />}
      <Box
        sx={{
          height: "100%",
          flex: 1,
          display: "flex",
          justifyContent: "center",
          marginRight:
            showUnitPreviewPanel && drawerWidth ? `${drawerWidth}px` : "0px",
          transition: "all 0.3s ease-in-out",
        }}
        onClick={closeSelectedUnit}
      >
        <Box
          sx={{
            overflow: "auto",
            height: "100%",
            width: "100%",
            maxWidth: "560px",
            padding: embeddedHeader ? "40px" : "48px",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {embeddedHeader && <Box>{embeddedHeader}</Box>}
          <Box marginTop="0px">
            <Typography variant="h4">{syllabus.name}</Typography>
          </Box>
          <Box display="flex" gap="8px">
            <Chip
              label={domainToDisplayName(syllabus.domain ?? "")}
              size="small"
              sx={{
                width: "fit-content",
              }}
            />
            {syllabus.programStartDate && (
              <Chip
                label={startDateToCohortStyleDate(syllabus.programStartDate)}
                size="small"
                sx={{
                  width: "fit-content",
                }}
              />
            )}
          </Box>
          <Box
            display="flex"
            sx={{
              width: "100%",
              gap: "16px",
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {syllabus.units.map((unit, i) => (
              <UnitDescriptorView
                key={unit.id}
                index={i}
                syllabusId={syllabus.id}
                unit={unit}
                editMode={false}
                onClick={() => onUnitClicked(unit.id)}
                isSelected={selectedUnitId === unit.id}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Drawer
        open={showUnitPreviewPanel}
        anchor="right"
        onClose={closeSelectedUnit}
        variant="persistent"
        ModalProps={{
          container: containerRef.current,
          style: { position: "absolute" },
        }}
        PaperProps={{
          style: {
            position: "absolute",
            boxShadow:
              "0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
          }}
        >
          {selectedUnit && (
            <UnitPreviewPanel
              syllabusTitlePrefix={syllabusTitlePrefix}
              unit={selectedUnit}
              domain={syllabus.domain}
              onClose={closeSelectedUnit}
              onFinishedRender={finishRenderDrawer}
            />
          )}
        </Box>
      </Drawer>
    </Box>
  )
}
const useUnitSelection = (mode: "popup" | "inline") => {
  const selectionBySearchParams = useUnitSelectionBySearchParams()
  const selectionByState = useUnitSelectionByState()
  switch (mode) {
    case "popup":
      return selectionByState
    case "inline":
      return selectionBySearchParams
  }
}

const useUnitSelectionByState = () => {
  const [selectedUnitId, setSelectedUnitId] = useState<string | undefined>(
    undefined,
  )

  return {
    selectedUnitId,
    setSelectedUnit: (unitId: string) => setSelectedUnitId(unitId),
    clearSelectedUnit: () => setSelectedUnitId(undefined),
  }
}
const useUnitSelectionBySearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return {
    selectedUnitId: searchParams.get("unitId"),
    setSelectedUnit: (unitId: string) => {
      searchParams.set("unitId", unitId)
      setSearchParams(searchParams, { replace: true })
    },
    clearSelectedUnit: () => {
      searchParams.delete("unitId")
      setSearchParams(searchParams, { replace: true })
    },
  }
}
