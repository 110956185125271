import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { Box, FormControlLabel, Switch } from "@mui/material"
import EditorTextField from "../../editor/components/editorTextField"
import { selectAddAppFeatureFlagFormPresentation } from "./appFeatureFlagsSelectors"
import {
  addAppFeatureFlagClosed,
  appFeatureFlagFormEdited,
  createAppFeatureFlagPopupConfirmed,
  fetchAppFeatureFlags,
} from "./appFeatureFlagsSlice"

function AddAppFeatureFlagPopup() {
  const dispatch = useAppDispatch()
  const addFeatureFlagForm = useAppSelector(
    selectAddAppFeatureFlagFormPresentation,
  )

  return (
    <GenericDialog
      title="Add new feature flag"
      onClose={() => dispatch(addAppFeatureFlagClosed())}
      open={addFeatureFlagForm !== undefined}
      size="xs"
      content={
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <EditorTextField
            label="Feature flag name"
            value={addFeatureFlagForm?.name ?? ""}
            onChange={(e) => {
              if (addFeatureFlagForm === undefined) {
                return
              }
              dispatch(
                appFeatureFlagFormEdited({
                  name: e.target.value,
                  enabled: addFeatureFlagForm.enabled,
                }),
              )
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={addFeatureFlagForm?.enabled === true}
                onChange={(e) => {
                  if (addFeatureFlagForm === undefined) {
                    return
                  }
                  dispatch(
                    appFeatureFlagFormEdited({
                      name: addFeatureFlagForm.name,
                      enabled: e.target.checked,
                    }),
                  )
                }}
              />
            }
            label={addFeatureFlagForm?.enabled ? "Enabled" : "Disabled"}
          />
        </Box>
      }
      buttons={[
        {
          type: "primary",
          text: "Add feature flag",
          onClick: () => {
            dispatch(createAppFeatureFlagPopupConfirmed()).then(() => {
              dispatch(fetchAppFeatureFlags())
            })
          },
          loading: addFeatureFlagForm?.request === "pending",
          disabled: addFeatureFlagForm?.disableButton === true,
        },
      ]}
    />
  )
}

export default AddAppFeatureFlagPopup
