import { Box, Link, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import SearchTextField from "@cmp/searchTextField"
import GenericTable from "@cmp/genericTable"
import { CreateProjectDialog, EditProjectDialog } from "./manageProjectDialog"
import { ProjectDto } from "@masterschool/course-builder-api"

import { useAppDispatch, useAppSelector } from "@app/hooks"
import { fetchProjects } from "@features/projects-tab/projectsTabSlice"
import { DeleteProjectDialog } from "./deleteProjectDialog"
import { AddProjectButton, ProjectActionButtons } from "./projectActionButtons"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { ErrorComponent } from "@cmp/loaders/errorComponent"

export function ProjectsTabView() {
  const projectsState = useAppSelector((state) => state.projectsTab)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  const [showNewProjectDialog, setShowNewProjectDialog] = useState(false)

  const isLoading = projectsState.loadingState === "loading"
  const isError = projectsState.loadingState === "failed"

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        paddingX: "24px",
        paddingBottom: "48px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1240px",
          marginX: "auto",
        }}
      >
        <CreateProjectDialog
          open={showNewProjectDialog}
          onClose={() => setShowNewProjectDialog(false)}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "80px",
            mb: "16px",
            gap: "16px",
          }}
        >
          <Box
            display="flex"
            sx={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h4">Projects</Typography>
            {isLoading && <LoadingComponent loadedComponents="projects" />}
            {isError && <ErrorComponent loadedComponents="projects" />}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <ProjectsTable
              projects={projectsState.projects}
              onAddProjectClicked={() => setShowNewProjectDialog(true)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const ProjectsTable = (props: {
  projects: ProjectDto[]
  onAddProjectClicked: () => void
}) => {
  const { projects, onAddProjectClicked } = props
  const [searchText, setSearchText] = useState("")
  const onSearchTextChange = (text: string) => {
    setSearchText(text)
  }

  const filteredProjects = projects
    .map((project) => ({
      ...project,
      links: undefined,
      actions: undefined,
      status: undefined,
    }))
    .filter((project) =>
      project.name.toLowerCase().includes(searchText.toLowerCase()),
    )
  const isEmptyData = filteredProjects.length === 0

  const [projectToEdit, setProjectToEdit] = useState<ProjectDto | null>(null)
  const [toDelete, setToDelete] = useState<ProjectDto | null>(null)

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {projectToEdit && (
        <EditProjectDialog
          project={projectToEdit}
          open={projectToEdit !== null}
          onClose={() => setProjectToEdit(null)}
        />
      )}
      {toDelete && (
        <DeleteProjectDialog
          project={toDelete}
          open={toDelete !== null}
          onClose={() => setToDelete(null)}
        />
      )}
      <Box display="flex" gap="16px" width="100%">
        <SearchTextField
          searchText={searchText}
          onSearchTextChange={onSearchTextChange}
          placeholder={"Search by project name"}
        />
        <AddProjectButton onClick={onAddProjectClicked} />
      </Box>
      {isEmptyData && (
        <Typography variant="body1" textAlign="center">
          No projects found
        </Typography>
      )}
      <GenericTable
        data={filteredProjects}
        keys={["name", "domain", "links", "actions"]}
        renderHeader={{
          name: () => <Typography variant="body1_sb">Project Name</Typography>,
          domain: () => <Typography variant="body1_sb">Domain</Typography>,
          links: () => <Typography variant="body1_sb">Links</Typography>,
          actions: () => <div></div>,
        }}
        render={{
          links: (_, row, hover) => (
            <Box display="flex" gap="8px" padding={0}>
              <Link href={row.detailsLink}>Details</Link>
              <Link href={row.guidelinesLink}>Guidelines</Link>
            </Box>
          ),
          actions: (_, row, hover) =>
            hover ? (
              <ProjectActionButtons
                onEdit={() => setProjectToEdit(row)}
                onDelete={() => setToDelete(row)}
              />
            ) : (
              <Box width="80px" height="32px" />
            ),
        }}
        sx={{
          overflow: "auto",
          maxHeight: "100%",
          borderRadius: "4px",
        }}
      />
    </Box>
  )
}
