import { useAppDispatch, useAppSelector } from "@app/hooks"
import { UsersClient } from "@clients/usersClient"
import GenericDialog from "@cmp/genericDialog"
import {
  selectClassesForProgram,
  selectProgram,
} from "@features/program/programSliceSelectors"
import {
  createNewChannelPopupClosed,
  createNewSlackChannel,
  fetchProgramSlackChannels,
} from "@features/slack/slackSlice"
import { ProgramClassDto, UserDto } from "@masterschool/course-builder-api"
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material"
import { useEffect, useState } from "react"
import useFetchProgramData from "../../../useFetchProgramData"
import { ClassChip } from "../classChipList"
import { MultipleOptionsSelector } from "@cmp/form-components/multipleOptionsSelector"

export const CreateNewChannelDialog = () => {
  const model = useAppSelector(
    (state) => state.slack.popups.createNewChannelPopup,
  )
  const dispatch = useAppDispatch()
  const program = useAppSelector((state) =>
    selectProgram(state, model?.programId),
  )

  const [channelName, setChannelName] = useState("")
  const isChannelNameValid = isValidSlackChannelName(channelName)
  const [description, setDescription] = useState("")
  const [selectedClasses, setSelectedClasses] = useState<string[]>([])
  const [selectedStaffGroups, setSelectedStaffGroups] = useState<string[]>([])
  const [staffMembers, setStaffMembers] = useState<UserDto[]>([])
  const [selectedStaffIDs, setSelectedStaffIDs] = useState<string[]>([])
  const [isPublic, setIsPublic] = useState(false)
  const classesInProgram = useAppSelector(selectClassesForProgram(program?.id))

  const isLoading = useAppSelector(
    (state) =>
      state.slack.cohortToClasses[program?.startDate ?? ""]?.status ===
      "pending",
  )

  useEffect(() => {
    UsersClient.getUsersWithRoles()
      .then((employees) => {
        return employees.map((e) => ({
          ...e,
          name: e.firstName + " " + e.lastName,
        }))
      })
      .then((employees) => {
        employees.sort((a, b) => a.name.localeCompare(b.name))
        return employees
      })
      .then((employees) => setStaffMembers(employees))
  }, [])

  useFetchProgramData(program?.id)

  const selectedStaffSlackIDs = selectedStaffIDs.msCompactMap(
    (id) => staffMembers.find((m) => m.id === id)?.slackId,
  )
  const handleCreateChannel = () => {
    if (!program) {
      return
    }
    dispatch(
      createNewSlackChannel({
        name: channelName,
        description,
        classIDs: selectedClasses,
        staffMembersSlackIDs: selectedStaffSlackIDs,
        everyoneCanPost: isPublic,
      }),
    ).then(() => dispatch(fetchProgramSlackChannels(program.id)))
  }

  const canSubmit =
    channelName.length > 0 &&
    isChannelNameValid &&
    description.length > 0 &&
    selectedStaffSlackIDs.length > 0

  const ENABLE_STAFF_GROUP_SELECTION = false
  return (
    <GenericDialog
      open={model !== undefined}
      onClose={() => dispatch(createNewChannelPopupClosed())}
      title="Add a Slack channel"
      size="sm"
      content={
        <Stack gap={2}>
          <TextField
            label="Channel name"
            size="small"
            required
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
            error={!isChannelNameValid}
            helperText={
              isChannelNameValid
                ? ""
                : `Channel names can't contain spaces, punctuation or non-English characters.`
            }
          />
          <TextField
            label="Description"
            size="small"
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <ClassesSelector
            classes={classesInProgram}
            selectedClasses={selectedClasses}
            onChange={(classes) => setSelectedClasses(classes)}
          />
          {ENABLE_STAFF_GROUP_SELECTION && (
            <StaffGroupSelector
              groups={[
                data_staff,
                web_staff,
                cyber_staff,
                compass_staff,
                chip_design_staff,
                mentor_training_staff,
              ]}
              selectedGroups={selectedStaffGroups}
              onChange={(groups) => setSelectedStaffGroups(groups)}
            />
          )}
          <StaffMemberSelector
            members={staffMembers}
            selectedMembers={selectedStaffIDs}
            onChange={(memberIDs) => setSelectedStaffIDs(memberIDs)}
          />
          <FormControl>
            <FormLabel focused={false}>Permissions</FormLabel>
            <RadioGroup
              value={isPublic ? "ALL" : "STAFF"}
              onChange={(e) => setIsPublic(e.target.value === "ALL")}
            >
              <FormControlLabel
                value="STAFF"
                control={<Radio />}
                label="Only authorized Masterschool employees can post."
              />
              <FormControlLabel
                value="ALL"
                control={<Radio />}
                label="Everyone (including students) can post."
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      }
      buttons={[
        {
          type: "primary",
          text: "Add Slack channel",
          disabled: !canSubmit,
          onClick: handleCreateChannel,
          loading: isLoading,
        },
      ]}
    />
  )
}

const ClassesSelector = (props: {
  classes: ProgramClassDto[]
  selectedClasses: string[]
  onChange: (classIDs: string[]) => void
}) => {
  const { classes, selectedClasses, onChange } = props
  return (
    <MultipleOptionsSelector
      options={classes}
      label="Choose a class"
      selectedOptions={selectedClasses}
      onChange={onChange}
      getOptionLabel={(programClass) => programClass.name}
      renderTag={(programClass, index, getTagProps) => (
        <ClassChip programClass={programClass} {...getTagProps({ index })} />
      )}
    />
  )
}

const StaffGroupSelector = (props: {
  groups: StaffGroup[]
  selectedGroups: string[]
  onChange: (groupIDs: string[]) => void
}) => {
  const { groups, selectedGroups, onChange } = props
  return (
    <MultipleOptionsSelector
      options={groups}
      label="Staff groups to invite"
      selectedOptions={selectedGroups}
      onChange={onChange}
      getOptionLabel={(option) => option.name}
      renderTag={(group, index, getTagProps) => (
        <Chip
          label={group.name}
          size="small"
          sx={{
            width: "fit-content",
          }}
          {...getTagProps({ index })}
        />
      )}
    />
  )
}

const StaffMemberSelector = (props: {
  members: UserDto[]
  selectedMembers: string[]
  onChange: (memberIDs: string[]) => void
}) => {
  const { members, selectedMembers, onChange } = props
  const memberName = (user: UserDto) => user.firstName + " " + user.lastName
  return (
    <MultipleOptionsSelector
      options={members}
      label="Choose a Masterschool employee"
      selectedOptions={selectedMembers}
      onChange={onChange}
      getOptionLabel={(option) =>
        memberName(option) + ` (${option.slackId ?? "No slack user found"})`
      }
      required
      renderTag={(member, index, getTagProps) => (
        <Chip
          label={memberName(member)}
          size="small"
          sx={{
            width: "fit-content",
            backgroundColor: colorForName(memberName(member)),
          }}
          {...getTagProps({ index })}
        />
      )}
    />
  )
}

const colorForName = (name: string) => {
  let sum = 0
  for (let i = 0; i < name.length; i++) {
    sum += name.charCodeAt(i) % 60
  }

  sum = (sum % 56) + 180
  return `rgb(${sum}, ${sum}, ${sum})`
}

function isValidSlackChannelName(name: string) {
  if (name.length === 0) return true
  // regex for slack channel names
  return /^[a-z0-9-_]{1,80}$/.test(name)
}

export type StaffGroup = {
  id: string
  name: string
}

const data_staff = {
  id: "foo",
  name: "Data Staff",
}

const web_staff = {
  id: "bar",
  name: "Web Staff",
}

const cyber_staff = {
  id: "baz",
  name: "Cyber Staff",
}

const compass_staff = {
  id: "qux",
  name: "Compass Staff",
}

const chip_design_staff = {
  id: "quux",
  name: "Chip Design Staff",
}

const mentor_training_staff = {
  id: "corge",
  name: "Mentor Training Staff",
}
