import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { ProgramClass, SlackChannelData } from "./slackChannelsPage"
import { CampusClient } from "@clients/campusClient"
import { store } from "@app/store"

export class SlackClient {
  static getProgramChannels(programId: string): Promise<SlackChannelData[]> {
    return CampusClient.get<SlackChannelData[]>(
      `/slack/channels?programId=${programId}`,
    ).catch(() => {
      store.dispatch(showErrorSnackbar("Failed to fetch channels from Campus"))
      return [] as SlackChannelData[]
    })
  }

  static getEmployeeSlackIds(
    userIDs: string[],
  ): Promise<{ [id: string]: string }> {
    let query = "?userId=" + userIDs[0]
    if (userIDs.length > 0) {
      query = userIDs.slice(1).reduce((acc, id) => acc + `&userId=${id}`, query)
    }
    return CampusClient.get<{ [id: string]: string }>(
      `/slack/users${query}`,
    ).catch(() => {
      store.dispatch(
        showErrorSnackbar("Failed to fetch employee slack IDs from Campus"),
      )
      return {}
    })
  }

  static getClassesInCohort(startDate: string): Promise<ProgramClass[]> {
    const date = new Date(startDate)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return CampusClient.get<OfferingDescriptor[]>(
      `/cohort/classes?day=${day}&month=${month}&year=${year}`,
    )
      .then((res) => {
        return res.map((offering) => {
          return {
            id: offering.offeringID,
            clientId: offering.offeringClientID,
            startDate: offering.startDate,
            domain: offering.syllabusName,
            syllabusId: offering.syllabusId,
            description: offering.description,
          } as ProgramClass
        })
      })
      .catch(() => {
        store.dispatch(showErrorSnackbar("Failed to fetch classes from Campus"))
        return []
      })
  }

  /**
   *
   * @param channel channel to create.
   * @note @param staffMembersSlackIDs should be the slack IDs of the staff members in the @students workspace.
   * @returns
   */
  static createChannel(channel: {
    name: string
    description: string
    classIDs: string[]
    staffMembersSlackIDs: string[]
    everyoneCanPost: boolean
  }): Promise<void> {
    const {
      name,
      description,
      classIDs,
      everyoneCanPost,
      staffMembersSlackIDs,
    } = channel
    const body = {
      offeringGroupDescription: description,
      offeringClientIds: classIDs,
      slackOfferingGroupRequest: {
        slackChannelName: name,
        everyoneCanPost,
        specificSlackUsersWhichCanPost: staffMembersSlackIDs,
        addMasterschoolStaffToChannel: false,
      },
    }
    return CampusClient.post<void>("/offering-group/v2", body)
      .then(() => {
        store.dispatch(showSuccessSnackbar("Channel created successfully!"))
      })
      .catch(() => {
        store.dispatch(showErrorSnackbar("Failed to create channel"))
        return
      })
  }

  static addClassesToChannel(request: {
    offeringGroupId: string
    classIds: string[]
  }): Promise<void> {
    const body = {
      offeringClientIds: request.classIds,
      offeringGroupId: request.offeringGroupId,
    }
    return CampusClient.put<void>("/offering-group/add-offerings/v2", body)
      .then(() => {
        store.dispatch(showSuccessSnackbar("Classes added to Slack channel"))
      })
      .catch(() => {
        store.dispatch(
          showErrorSnackbar("Failed to add classes to Slack channel"),
        )
        return
      })
  }

  static removeClassesFromChannel(request: {
    offeringGroupId: string
    classIds: string[]
  }): Promise<void> {
    const body = {
      offeringClientIds: request.classIds,
      offeringGroupId: request.offeringGroupId,
    }
    return CampusClient.put<void>("/offering-group/add-offerings/v2", body)
      .then(() => {
        store.dispatch(
          showSuccessSnackbar("Classes removed from Slack channel"),
        )
      })
      .catch(() => {
        store.dispatch(
          showErrorSnackbar("Failed to remove classes from Slack channel"),
        )
        return
      })
  }
}

type OfferingDescriptor = {
  offeringID: number
  offeringClientID: string
  startDate: string
  syllabusName: string
  syllabusId: string
  description: string
}
