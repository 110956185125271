import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { programStartSyllabusActionConfirmationPopupUpdate } from "@features/program/programSlice"
import { selectProgramsSyllabusPage } from "@features/program/programSliceSelectors"
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material"
import { useState } from "react"

const ProgramStartSyllabusActionConfirmationPopup = () => {
  const { programStartSyllabusActionConfirmationPopup: model } = useAppSelector(
    selectProgramsSyllabusPage,
  )
  const [checkedConfirm, setCheckedConfirm] = useState(false)
  const dispatch = useAppDispatch()

  if (!model.isOpen) {
    return null
  }

  const { action } = model

  const onClose = () => {
    setCheckedConfirm(false)
    dispatch(
      programStartSyllabusActionConfirmationPopupUpdate({
        decision: "cancel",
        isOpen: false,
      }),
    )
  }

  const onConfirm = () => {
    setCheckedConfirm(false)
    dispatch(
      programStartSyllabusActionConfirmationPopupUpdate({
        decision: "confirm",
        isOpen: false,
      }),
    )
  }

  return (
    <GenericDialog
      open={true}
      title={getTitle(action)}
      onClose={onClose}
      size="xs"
      content={
        <Stack gap={2}>
          <Stack>
            <Typography>
              The program has started and is in progress. The following areas
              may be affected:
            </Typography>
            <Typography component="ul">
              <li>Learned content</li>
              <li>Current student progress</li>
              <li>Sprint start dates</li>
              <li>
                Live sessions may be rescheduled into different sprints than
                initially planned
              </li>
            </Typography>
          </Stack>
          <FormControlLabel
            sx={{ mx: 0, ":hover": { bgcolor: "action.hover" } }}
            control={
              <Checkbox
                checked={checkedConfirm}
                onChange={(e) => setCheckedConfirm(e.target.checked)}
              />
            }
            label="I understand the potential impacts and wish to proceed."
          />
        </Stack>
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: getCtaText(action),
          onClick: onConfirm,
          disabled: !checkedConfirm,
        },
      ]}
    />
  )
}

const getTitle = (action: "replace" | "revert-to-default" = "replace") => {
  switch (action) {
    case "replace":
      return "Are you sure you want to replace this syllabus?"
    case "revert-to-default":
      return "Are you sure you want to revert to default syllabus?"
  }
}

const getCtaText = (action: "replace" | "revert-to-default" = "replace") => {
  switch (action) {
    case "replace":
      return "Replace Syllabus"
    case "revert-to-default":
      return "Revert"
  }
}

export default ProgramStartSyllabusActionConfirmationPopup
