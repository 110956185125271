import { useAppDispatch, useAppSelector } from "@app/hooks"
import { store } from "@app/store"
import { UsersClient } from "@clients/usersClient"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import GenericTable from "@cmp/genericTable"
import SearchTextField from "@cmp/searchTextField"
import {
  classTransferStudentPopupOpened,
  editStudentDetailsPopupOpened,
  editStudentMentorPopupOpened,
  offboardStudentPopupOpened,
  programTransferStudentPopupOpened,
  studentsSearchChanged,
  editStudentCareerSuccessAdvisorPopupOpened,
  openEditDatacampCredentialsPopup,
} from "@features/program/programSlice"
import {
  selectStudent,
  selectStudents,
} from "@features/program/programSliceSelectors"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Box, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useParams } from "react-router-dom"
import { SlackIdCell } from "../../../account-management/accountManagement"
import appTheme from "../../../theme/appTheme"
import EmptyStateContainer from "../components/emptyStateContainer"
import OnboardStudentsButton from "./onboardStudentsButton"

function StudentsPage(props: { program: ProgramDto }) {
  const { classId } = useParams()
  const searchText = useAppSelector(
    (state) => state.program.studentsPage.search,
  )
  const students = useAppSelector(selectStudents(props.program.id, classId))
  const totalStudents = students.length

  const tableModel = students
    .map((s) => ({
      name: s.firstName + " " + s.lastName,
      email: s.email,
      studentId: s.id,
      slackId: s.slackId,
      className: s.className,
      classId: s.classId,
      mentorName:
        s.mentor !== undefined
          ? s.mentor.firstName + " " + s.mentor.lastName
          : "",
      careerSuccessAdvisorName: s.careerSuccessAdvisor
        ? s.careerSuccessAdvisor.firstName +
          " " +
          s.careerSuccessAdvisor.lastName
        : "",
    }))
    .filter(
      (s) =>
        s.name.toLowerCase().includes(searchText.toLowerCase()) ||
        s.email.toLowerCase().includes(searchText.toLowerCase()),
    )

  return totalStudents === 0 ? (
    <Empty programId={props.program.id} />
  ) : (
    <NotEmpty
      searchText={searchText}
      tableModel={tableModel}
      totalStudents={totalStudents}
      programId={props.program.id}
    />
  )
}

function Empty(props: { programId: string }) {
  const { classId } = useParams()
  const { programId } = props

  return (
    <EmptyStateContainer
      icon={appIcons.users02}
      title="No students yet"
      subtitle="Add and manage students for this program"
      action={
        <OnboardStudentsButton
          classId={classId}
          programId={programId}
          buttonText="Onboard students"
          variant="contained"
          size="large"
          icon="chevron"
        />
      }
    />
  )
}

type StudentsTableModel = {
  name: string
  email: string
  studentId: string
  slackId: string | undefined
  className: string
  classId: string
  mentorName: string | undefined
  careerSuccessAdvisorName: string | undefined
}[]

function NotEmpty(props: {
  searchText: string
  tableModel: StudentsTableModel
  totalStudents: number
  programId: string
}) {
  const { classId } = useParams()
  const { searchText, tableModel, totalStudents, programId } = props
  const dispatch = useAppDispatch()
  const studentsNumberPresentationTitle =
    tableModel.length === totalStudents
      ? ` (${totalStudents})`
      : ` (${tableModel.length} / ${totalStudents})`

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">
          Students
          <span
            style={{
              color: appTheme.palette.text.disabled,
            }}
          >
            {studentsNumberPresentationTitle}
          </span>
        </Typography>
        <OnboardStudentsButton
          classId={classId}
          programId={programId}
          buttonText="Onboard students"
          variant="contained"
          size="small"
          icon="chevron"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          pb: "16px",
        }}
      >
        <SearchTextField
          searchText={searchText}
          onSearchTextChange={(text) => dispatch(studentsSearchChanged(text))}
        />
        <GenericTable
          data={tableModel}
          keys={[
            "name",
            "email",
            "className",
            "mentorName",
            "careerSuccessAdvisorName",
            "slackId",
            "studentId",
          ]}
          sx={{
            borderRadius: "4px",
            overflow: "hidden",
          }}
          renderHeader={{
            studentId: () => undefined,
            slackId: () => <Typography variant="body2_sb">Slack</Typography>,
            mentorName: () => (
              <Typography variant="body2_sb">Mentor</Typography>
            ),
            careerSuccessAdvisorName: () => (
              <Typography variant="body2_sb">CSA</Typography>
            ),
          }}
          render={{
            studentId: (studentId, { name, classId: studentCurrentClass }) => (
              <StudentActionsMenu
                studentName={name}
                studentId={studentId}
                programId={programId}
                studentCurrentClass={studentCurrentClass}
                classId={classId}
              />
            ),
            slackId: (slackId) => <SlackIdCell slackId={slackId} />,
            mentorName: (mentorName) => (
              <Typography
                variant="body2"
                textOverflow={"ellipsis"}
                maxWidth="200px"
                noWrap
              >
                {mentorName}
              </Typography>
            ),
            careerSuccessAdvisorName: (advisorName) => (
              <Typography
                variant="body2"
                textOverflow={"ellipsis"}
                maxWidth="200px"
                noWrap
              >
                {advisorName}
              </Typography>
            ),
            className: (className) => (
              <Typography
                variant="body2"
                textOverflow={"ellipsis"}
                maxWidth="200px"
                noWrap
              >
                {className}
              </Typography>
            ),
          }}
        />
        {tableModel.length === 0 && (
          <Typography textAlign="center">No students match search</Typography>
        )}
      </Box>
    </Box>
  )
}

const StudentActionsMenu = (props: {
  studentId: string
  studentName: string
  studentCurrentClass: string
  programId: string | undefined
  classId: string | undefined
}) => {
  const { studentId, programId, classId, studentName, studentCurrentClass } =
    props
  const dispatch = useAppDispatch()
  const student = useAppSelector(selectStudent(programId, classId, studentId))
  if (!student) {
    return
  }
  const generateLinkForStudent = async () => {
    if (!student.email) {
      return
    }
    await UsersClient.generateImpersonationLink(student.email)
      .then((link) => {
        navigator.clipboard.writeText(link)
        store.dispatch(
          showSuccessSnackbar("Link copied. Open the URL in incognito mode."),
        )
      })
      .catch(() => {
        store.dispatch(
          showErrorSnackbar(
            "Failed to copy impersonation link, try again later.",
          ),
        )
        return undefined
      })
  }
  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon
              component={appIcons.dotsHorizontal}
              inheritViewBox
              sx={{ color: "primary.main" }}
            />
          ),
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "editStudentDetails",
            children: "Edit student",
            onClick: () => {
              if (!studentId) {
                return
              }
              dispatch(
                editStudentDetailsPopupOpened({
                  studentId,
                  firstName: student.firstName,
                  lastName: student.lastName,
                  email: student.email,
                }),
              )
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "impersonate",
            children: "Copy impersonation link",
            onClick: generateLinkForStudent,
          },
        },
        {
          type: "menu-item",
          props: {
            key: "transfer program",
            children: "Transfer to a different program",
            onClick: () => {
              if (!studentId || !programId) {
                return
              }
              dispatch(
                programTransferStudentPopupOpened({
                  studentId,
                  programId,
                  studentName: studentName,
                }),
              )
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "transfer class",
            children: "Transfer to a different class",
            onClick: () => {
              if (!studentId || !programId) {
                return
              }
              dispatch(
                classTransferStudentPopupOpened({
                  studentId,
                  programId,
                  studentName: studentName,
                  currentClassId: studentCurrentClass,
                }),
              )
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "assign mentor to student",
            children: student.mentor ? "Change mentor" : "Assign mentor",
            onClick: () => {
              if (!studentId || !programId) {
                return
              }
              dispatch(
                editStudentMentorPopupOpened({
                  studentId,
                  studentName: studentName,
                  programId: programId,
                  mentorId: student.mentor?.id,
                }),
              )
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "assign CSA to student",
            children: student.careerSuccessAdvisor
              ? "Change CSA"
              : "Assign CSA",
            onClick: () => {
              if (!studentId || !programId) {
                return
              }
              dispatch(
                editStudentCareerSuccessAdvisorPopupOpened({
                  studentId,
                  studentName: studentName,
                  programId: programId,
                  initialCareerSuccessAdvisorClientId:
                    student.careerSuccessAdvisor?.userClientId,
                }),
              )
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "offboard",
            children: "Offboard student",
            onClick: () => {
              if (!programId) {
                return
              }
              dispatch(
                offboardStudentPopupOpened({
                  programId: programId,
                  studentId: studentId,
                  classId: classId,
                }),
              )
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "edit datacamp email",
            children: "Edit Datacamp email",
            onClick: () => {
              if (!studentId) {
                return
              }
              dispatch(
                openEditDatacampCredentialsPopup({
                  studentId,
                  studentName: `${student.firstName} ${student.lastName}`,
                }),
              )
            },
          },
        },
      ]}
    />
  )
}

export default StudentsPage
