import {
  CourseDto,
  CourseSyllabusElement,
  CourseSyllabusLiveEvent,
  Topic,
} from "@masterschool/course-builder-api"
import { Box, SvgIcon, SvgIconProps, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import {
  courseSyllabusDuration,
  durationFormatting,
} from "@utils/syllabus+duration"
import appTheme from "../theme/appTheme"
import CourseElementDescriptor from "./courseElementDescriptor"
import { CourseLiveEventDescriptor } from "./courseLiveEventDescriptior"
import {
  CompletionType,
  elementCompletionTypeValue,
} from "./elements/ElementCompletionType"

function CoursePreview({
  course,
  syllabusTitlePrefix,
}: {
  course: CourseDto
  syllabusTitlePrefix?: string
}) {
  return (
    <Box
      sx={{
        overflow: "auto",
        height: "100%",
        width: "100%",
        padding: "48px 0px",
        gap: "24px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CourseGeneralView
        course={course}
        syllabusTitlePrefix={syllabusTitlePrefix}
      />
      <CourseTopics course={course} />
    </Box>
  )
}

function CourseGeneralView(props: {
  course: CourseDto
  syllabusTitlePrefix?: string
}) {
  const { course, syllabusTitlePrefix } = props
  const title =
    syllabusTitlePrefix !== undefined
      ? `${syllabusTitlePrefix} · ${course.title}`
      : course.title

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="h4">{title}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "8px",
          gap: "8px",
        }}
      >
        <SvgIcon
          component={appIcons.clock}
          sx={{
            stroke: appTheme.palette.icon.black,
            fill: "none",
            width: "16px",
            height: "16px",
          }}
          inheritViewBox
        />
        <Typography variant="body2">
          {durationFormatting(courseSyllabusDuration(course.syllabus))}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          marginTop: "8px",
        }}
      >
        {course.description}
      </Typography>
    </Box>
  )
}

function CourseTopics(props: { course: CourseDto }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "40px",
      }}
    >
      {props.course.syllabus.topics.map((topic) => (
        <TopicView topic={topic} key={topic.id} />
      ))}
    </Box>
  )
}

function TopicView(props: { topic: Topic }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ElementsPreview topic={props.topic} />
      <LiveEventsPreview topic={props.topic} />
    </Box>
  )
}

function ElementsPreview(props: { topic: Topic }) {
  const firstElementBorderRadius = {
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
  }

  const lastElementBorderRadius = {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
  }

  const shouldRenderLiveEvents = props.topic.liveEvents.length > 0

  const elementExtraText = (completionType: CompletionType) => {
    switch (completionType) {
      case "mandatory":
        return undefined
      case "optional":
        return "Optional"
      case "advancedLearning":
        return "Advanced"
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="body2_sb">{props.topic.title}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {props.topic.elements.map(
          (element: CourseSyllabusElement, index: number) => (
            <CourseElementDescriptor
              key={element.item.id}
              element={element}
              topic={props.topic}
              editMode={false}
              extraText={elementExtraText(elementCompletionTypeValue(element))}
              sx={{
                cursor: "default",
                borderRadius: "0px",
                bgcolor: appTheme.palette.primary.contrast,
                ...(index === 0 ? firstElementBorderRadius : {}),
                ...(index === props.topic.elements.length - 1 &&
                !shouldRenderLiveEvents
                  ? lastElementBorderRadius
                  : {}),
                ...(index === props.topic.elements.length - 1
                  ? {}
                  : { borderBottom: "none" }),
              }}
            />
          ),
        )}
      </Box>
    </Box>
  )
}

function LiveEventsPreview(props: { topic: Topic }) {
  const lastElementBorderRadius = {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
  }
  const extraIcons: (SvgIconProps & { id: string })[] = [
    {
      id: "hidden",
      component: appIcons.eyeOff,
      sx: { stroke: appTheme.palette.icon.disabled },
    },
  ]

  if (props.topic.liveEvents.length === 0) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        borderLeft={`1px solid ${appTheme.palette.other.outlineBorder}`}
        borderRight={`1px solid ${appTheme.palette.other.outlineBorder}`}
        pl="24px"
        py="5px"
        bgcolor="eTypes.sand25"
      >
        <Typography variant="body2">Live sessions</Typography>
      </Box>

      {props.topic.liveEvents.map(
        (liveEvent: CourseSyllabusLiveEvent, index: number) => (
          <CourseLiveEventDescriptor
            key={liveEvent.id}
            liveEvent={liveEvent}
            topic={props.topic}
            editMode={false}
            sx={{
              cursor: "default",
              bgcolor: appTheme.palette.primary.contrast,
              borderRadius: "0px",
              ...(index === props.topic.liveEvents.length - 1
                ? lastElementBorderRadius
                : {}),
              ...(index === props.topic.liveEvents.length - 1
                ? {}
                : { borderBottom: "none" }),
            }}
            extraIcons={extraIcons}
          />
        ),
      )}
    </Box>
  )
}

export default CoursePreview
