import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  classTransferStudentPopupClosed,
  classTransferStudentUpdated,
} from "@features/program/programSlice"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import {
  changeStudentClass,
  fetchClasses,
} from "@features/program/programSliceThunks"
import { ChangeProgramClassRequestChangeTypeEnum } from "@masterschool/course-builder-api"
import { Alert, Stack, SvgIcon, TextField, Typography } from "@mui/material"
import { SingleItemSelection } from "./transferStudentCommon"
import { mapToChangeType } from "./transferStudentUtils"
import appIcons from "@utils/appIcons"

function ClassTransferStudentPopup() {
  const dispatch = useAppDispatch()
  const model = useAppSelector(
    (state) => state.program.studentsPage.classTransferStudentPopup,
  )

  const classes = useAppSelector(selectClassesForProgram(model?.programId))

  const onSubmit = () => {
    if (!model || !model.form.reason || !model.form.selectedClassId) {
      return
    }
    dispatch(changeStudentClass())
      .then(() => {
        dispatch(fetchClasses({ programId: model.programId }))
      })
      .finally(() => dispatch(classTransferStudentPopupClosed()))
  }

  const content = (
    <Stack gap={3}>
      {model?.loadingState === "rejected" && (
        <Alert
          severity="error"
          sx={{
            alignItems: "center",
            ".MuiAlert-icon": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Typography variant="body2">
            An error occurred while trying to transfer the student.
          </Typography>
        </Alert>
      )}

      <SingleItemSelection
        onChange={(selectedClassId: string) =>
          dispatch(
            classTransferStudentUpdated({
              key: "selectedClassId",
              value: selectedClassId,
            }),
          )
        }
        transformToValue={(value) => value}
        selectionLabel="Class"
        initialValue={model?.form.selectedClassId ?? ""}
        getIsOptionDisabled={(value) => value === model?.currentClassId}
        items={classes.map((c) => ({
          value: c.id,
          label:
            `${c.name}` +
            `${
              c.id === model?.currentClassId ? " (current student class)" : ""
            }`,
        }))}
      />
      <SingleItemSelection
        onChange={(reason: ChangeProgramClassRequestChangeTypeEnum) =>
          dispatch(
            classTransferStudentUpdated({ key: "reason", value: reason }),
          )
        }
        transformToValue={mapToChangeType}
        selectionLabel="Reason"
        initialValue={model?.form.reason}
        items={[
          {
            value: ChangeProgramClassRequestChangeTypeEnum.StudentAsked,
            label: "Student asked",
          },
          {
            value: ChangeProgramClassRequestChangeTypeEnum.StaffDecision,
            label: "Staff decision",
          },
          {
            value: ChangeProgramClassRequestChangeTypeEnum.Graduate,
            label: "Graduated",
          },
        ]}
      />
      <TextField
        defaultValue={model?.form.summary ?? ""}
        onChange={(event) => {
          dispatch(
            classTransferStudentUpdated({
              key: "summary",
              value: event.target.value,
            }),
          )
        }}
        variant="outlined"
        rows={3}
        multiline
        fullWidth
        label="Summary"
        InputLabelProps={{
          style: { fontSize: "17px" },
        }}
      />
      <Alert
        severity="info"
        icon={
          <SvgIcon
            component={appIcons.infoCircle}
            inheritViewBox
            sx={{ fill: "none", width: 20, height: 20 }}
          />
        }
      >
        This student will be taken out of all relevant previous Slack channels
        and added to all Slack channels associated with the new program.
      </Alert>
    </Stack>
  )

  return (
    <GenericDialog
      open={model !== undefined}
      size="sm"
      title={`Transfer ${model?.studentName} to a different class`}
      content={content}
      onClose={() => {
        dispatch(classTransferStudentPopupClosed())
      }}
      buttons={[
        {
          text: "Transfer student",
          type: "primary",
          onClick: onSubmit,
          disabled: !model?.form.selectedClassId || !model?.form.reason,
          loading: model?.loadingState === "loading",
        },
      ]}
    />
  )
}

export default ClassTransferStudentPopup
