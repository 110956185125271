import { AddClassesToChannelDialog } from "./addClassesToChannelDialog"
import { CreateNewChannelDialog } from "./createNewChannelDialog"
import { RemoveClassesFromChannelDialog } from "./removeClassesFromChannelDialog"

function SlackTabDialogPresenter() {
  return (
    <>
      <CreateNewChannelDialog />
      <AddClassesToChannelDialog />
      <RemoveClassesFromChannelDialog />
    </>
  )
}

export default SlackTabDialogPresenter
