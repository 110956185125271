import OverflowTooltip from "@cmp/overflowTooltip"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"

export function SingleItemSelection<T extends string>(props: {
  maxHeight?: number
  onChange: (value: T) => void
  transformToValue: (value: string) => T | undefined
  selectionLabel: string
  disabled?: boolean
  getIsOptionDisabled?: (value: T) => boolean
  initialValue: T | undefined
  items: { value: T; label: string }[]
}) {
  const { onChange, transformToValue, selectionLabel, initialValue, items } =
    props
  const { getIsOptionDisabled, disabled } = props
  return (
    <FormControl>
      <InputLabel
        sx={{
          fontSize: "17px",
        }}
      >
        {selectionLabel}
      </InputLabel>
      <Select
        label={selectionLabel}
        value={initialValue ?? ""}
        disabled={disabled}
        onChange={(e) => {
          const newType = transformToValue(e.target.value)
          if (newType) {
            onChange(newType)
          }
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: props.maxHeight ?? 250,
              maxWidth: 250,
            },
          },
        }}
      >
        {items.map(({ label, value }) => (
          <MenuItem
            value={value}
            key={value}
            disabled={getIsOptionDisabled ? getIsOptionDisabled(value) : false}
          >
            <OverflowTooltip title={label}>
              <Typography noWrap>{label}</Typography>
            </OverflowTooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
