import { useAppSelector } from "@app/hooks"
import { DeleteIcon } from "@cmp/buttons/deleteButton"
import {
  DatacampLesson,
  DatacampTasksGroup,
  Topic,
} from "@masterschool/course-builder-api"
import { Box, Typography } from "@mui/material"
import { useState } from "react"
import appTheme from "../../../../theme/appTheme"
import EditorTextField from "../../../components/editorTextField"
import UrlTextField from "../../../components/urlTextField"
import { useEditDatacampLesson } from "../../custom-hooks/useEditElementItem"
import DragTaskIcon from "./dragTaskIcon"
import { taskCardBGColor } from "./taskCardBGColor"

function DatacampTasksGroupEditor(props: {
  topic: Topic
  lesson: DatacampLesson
  groupId: string
  index: number
  highlight: boolean
  hide: boolean
}) {
  const { topic, lesson, groupId, index, highlight, hide } = props
  const group = lesson.tasks.find((group) => group.id === groupId)
  const draggedGroupId = useAppSelector(
    (state) => state.courseEditor.draggedTaskId,
  )
  const isDragging = draggedGroupId === groupId

  const editLesson = useEditDatacampLesson()
  const editGroup = useEditDatacampTasksGroup()
  const [isBeingRemoved, setIsBeingRemoved] = useState(false)

  const onDeleteClicked = () => {
    setIsBeingRemoved(true)
    setTimeout(() => {
      editLesson(
        lesson,
        "tasks",
        [...lesson.tasks.slice(0, index), ...lesson.tasks.slice(index + 1)],
        topic,
      )
    }, 200)
  }

  if (!group) {
    return undefined
  }

  return (
    <Box
      key={group.id}
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: isBeingRemoved || hide ? 0 : 1,
        transition: "all 0.2s ease-in-out",
        transform: highlight
          ? "scale(1.05)"
          : isDragging
          ? "scale(0.95)"
          : "scale(1)",
        padding: "16px 8px",
        gap: "8px",
        cursor: "default",
      }}
    >
      <DragTaskIcon />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          borderRadius: "8px",
          padding: "16px 8px",
          alignItems: "center",
          gap: "16px",
          bgcolor: taskCardBGColor,
        }}
      >
        <Typography variant="body3" color="text.secondary">
          {isNaN(index) ? `??` : `${index + 1}.`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <UrlTextField
            elementId={group.id}
            value={group.url}
            debounce
            onChange={(e) => {
              editGroup(lesson, index, "url", e.target.value, topic)
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <EditorTextField
              type="number"
              label="Number of tasks"
              value={group.exIds.length}
              debounce
              onChange={(e) => {
                const parsed = parseInt(e.target.value)
                const updatedValue = isNaN(parsed) ? 0 : Math.max(0, parsed)
                editGroup(
                  lesson,
                  index,
                  "exIds",
                  Array.from(Array(updatedValue).keys()).map((i) => i + 1),
                  topic,
                )
              }}
            />
          </Box>
        </Box>
        <DeleteIcon onClick={() => onDeleteClicked()} />
      </Box>
    </Box>
  )
}

function useEditDatacampTasksGroup() {
  const editLesson = useEditDatacampLesson()
  return <T extends keyof DatacampTasksGroup>(
    lesson: DatacampLesson,
    index: number,
    key: T,
    value: DatacampTasksGroup[T],
    topic: Topic,
  ) => {
    editLesson(
      lesson,
      "tasks",
      [
        ...lesson.tasks.slice(0, index),
        {
          ...lesson.tasks[index],
          [key]: value,
        },
        ...lesson.tasks.slice(index + 1),
      ],
      topic,
    )
  }
}

export default DatacampTasksGroupEditor
