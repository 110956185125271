import { ComponentsProps } from "@mui/material"
import { TypographyOptions } from "@mui/material/styles/createTypography"

type SemiBoldStyle = {
  fontWeight: "400"
}

type TypographyWithoutSemiBold = Omit<
  TypographyOptions,
  `${keyof TypographyOptions}_sb` | `${keyof TypographyOptions}_m`
>

const semiBold: SemiBoldStyle = {
  fontWeight: "400",
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h3i: React.CSSProperties
    body3: React.CSSProperties
    body1_sb: React.CSSProperties & SemiBoldStyle
    body2_sb: React.CSSProperties & SemiBoldStyle
    body3_sb: React.CSSProperties & SemiBoldStyle
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h3i: React.CSSProperties
    body3: React.CSSProperties
    body1_sb: React.CSSProperties & SemiBoldStyle
    body2_sb: React.CSSProperties & SemiBoldStyle
    body3_sb: React.CSSProperties & SemiBoldStyle
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    // [K in keyof BaseTypographyVariants]: true;
    h3i: true
    body3: true
    body1_sb: true
    body2_sb: true
    body3_sb: true
  }
}

const typographyBase: TypographyWithoutSemiBold = {
  allVariants: {
    // Default text color (unfortunately can't reference other text color here- same value as text.primary)
    fontFamily: "Founders Grotesk",
    fontWeight: "300",
  },
  h1: {
    fontWeight: "400",
    fontFamily: "Freight",
    fontSize: 96,
    lineHeight: "112px",
    letterSpacing: "-1.5px",
  },
  h2: {
    fontWeight: "400",
    fontFamily: "Freight",
    fontSize: 60,
    lineHeight: "72px",
    letterSpacing: "-0.5px",
  },
  h3: {
    fontSize: 44,
    letterSpacing: "0.44px",
  },
  h3i: {
    fontFamily: "Freight",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: 44,
  },
  h4: {
    fontSize: 38,
    letterSpacing: "0.38px",
  },
  h5: {
    fontSize: 32,
    letterSpacing: "0.32px",
  },
  h6: {
    fontWeight: "400",
    fontSize: 22,
    letterSpacing: "0.22px",
    lineHeight: "25.96px",
  },
  subtitle1: {
    fontWeight: "400",
    fontSize: 20,
    letterSpacing: "0.2px",
  },
  subtitle2: {
    fontWeight: "400",
    fontSize: 18,
    letterSpacing: "0.18px",
  },
  body1: {
    fontSize: 18,
    letterSpacing: "0.36px",
    lineHeight: 1.2,
  },
  body2: {
    fontSize: 17,
    letterSpacing: "0.34px",
    lineHeight: 1.2,
  },
  body3: {
    // We add all props here since it's not a default variant
    fontFamily: "Founders Grotesk",
    fontWeight: "300",
    fontSize: 15,
    letterSpacing: "0.30px",
    lineHeight: 1.2,
  },
  caption: {
    fontSize: 14,
    textTransform: "uppercase",
    letterSpacing: "0.28px",
  },
  overline: {
    fontWeight: "400",
    fontSize: 12,
    lineHeight: "32px",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  button: {
    fontWeight: "400",
    textTransform: "none",
  },
}

export const typography: TypographyOptions = {
  ...typographyBase,
  body1_sb: {
    fontFamily: "Founders Grotesk",
    ...typographyBase.body1,
    ...semiBold,
  },
  body2_sb: {
    fontFamily: "Founders Grotesk",
    ...typographyBase.body2,
    ...semiBold,
    letterSpacing: "0.4px",
  },
  body3_sb: {
    fontFamily: "Founders Grotesk",
    ...typographyBase.body3,
    ...semiBold,
    letterSpacing: "0.38px",
  },
}

export const MuiTypographyComponentOverride: {
  defaultProps?: ComponentsProps["MuiTypography"]
} = {
  defaultProps: {
    color: "text.primary",
    variantMapping: {
      // Map the new h3i variant to render a <h3> by default
      h3i: "h3",
      body3: "p",
      body1_sb: "p",
      body2_sb: "p",
      body3_sb: "p",
    },
  },
}
