import { ChangeProgramClassRequestChangeTypeEnum } from "@masterschool/course-builder-api"

export function mapToChangeType(value: string) {
  switch (value) {
    case ChangeProgramClassRequestChangeTypeEnum.StudentAsked:
      return ChangeProgramClassRequestChangeTypeEnum.StudentAsked
    case ChangeProgramClassRequestChangeTypeEnum.StaffDecision:
      return ChangeProgramClassRequestChangeTypeEnum.StaffDecision
    case ChangeProgramClassRequestChangeTypeEnum.Graduate:
      return ChangeProgramClassRequestChangeTypeEnum.Graduate
    default:
      return undefined
  }
}
