import { useAppDispatch } from "@app/hooks"
import { useEffect } from "react"
import { fetchClasses } from "@features/program/programSliceThunks"

function useFetchProgramData(programId: string | undefined) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!programId) {
      return
    }
    dispatch(fetchClasses({ programId }))
  }, [dispatch, programId])
}

export default useFetchProgramData
