import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { CampusClient } from "@clients/campusClient"
import { store } from "@app/store"

export type DeeplinkData = {
  id: string
  deepLinkId: string
  redirectUrl: string
  description: string | undefined
  type: "liveSession" | "other"
}

export class DeeplinksClient {
  static listDeeplinks(): Promise<DeeplinkData[]> {
    return CampusClient.get<DeeplinkData[]>(`/deep-link`)
  }

  static createDeeplink(deepLinkData: Omit<DeeplinkData, "id">): Promise<void> {
    return CampusClient.put<void>(`/deep-link/upsert`, deepLinkData)
      .then(() => {
        store.dispatch(showSuccessSnackbar("Deeplink created"))
      })
      .catch(() => {
        store.dispatch(showErrorSnackbar("Failed to create deeplink"))
      })
  }

  static editDeeplink(deepLinkData: DeeplinkData): Promise<void> {
    return CampusClient.put<void>(`/deep-link/edit`, {
      recordId: deepLinkData.id,
      ...deepLinkData,
    })
      .then(() => {
        store.dispatch(showSuccessSnackbar("Deeplink edited"))
      })
      .catch(() => {
        store.dispatch(showErrorSnackbar("Failed to edit deeplink"))
      })
  }
}
