import { useAppDispatch } from "@app/hooks"
import { ProgramStaffMember } from "@clients/programStaffClient"
import GenericDialog from "@cmp/genericDialog"
import { removeProgramStaffMember } from "@features/program/programSliceThunks"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"

function RemoveStaffMemberDialog(props: {
  open: boolean
  programId: string
  userClientId: string
  member: ProgramStaffMember | undefined
  onClose: () => void
}) {
  const { open, programId, userClientId, member, onClose } = props
  const dispatch = useAppDispatch()
  const onRemoveRequest = async () => {
    dispatch(removeProgramStaffMember({ programId, userClientId }))
      .then(() => {
        dispatch(showSuccessSnackbar("Staff member removed"))
        onClose()
      })
      .catch((e) => {
        dispatch(
          showErrorSnackbar(
            "Error removing staff member: " + e.message ?? "Unknown error",
          ),
        )
      })
  }
  const isMentor = member?.roles.includes("mentor")
  const subtitle = isMentor
    ? `${
        member?.name ?? "unknown"
      }'s students will be left without a mentor if they haven't been reassigned. This can't be undone.`
    : "They will be removed from all program Slack channels. This can't be undone."

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      size="sm"
      title={`Remove ${member?.name ?? "unknown"}?`}
      subtitle={subtitle}
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "danger",
          text: "Remove",
          onClick: onRemoveRequest,
          loading: false,
        },
      ]}
    />
  )
}

export default RemoveStaffMemberDialog
