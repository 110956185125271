import GenericDialog from "@cmp/genericDialog"
import { useState } from "react"

const ResourceLockedPopup = (props: {
  resourceType: "syllabus" | "course"
  lockedBy?: string
  open: boolean
  onClose: () => void
  onTakeControl: () => void
}) => {
  const { resourceType, lockedBy, open, onClose, onTakeControl } = props
  const [isTakingControl, setIsTakingControl] = useState(false)

  const onTakeControlClick = async () => {
    setIsTakingControl(true)
    await onTakeControl()
    setIsTakingControl(false)
  }

  return (
    <GenericDialog
      open={open}
      size="xs"
      title="Editing in progress"
      subtitle={`This ${resourceType} is currently being edited by ${
        lockedBy || "another user"
      }. Editing it may result in them losing their unsaved changes.`}
      onClose={onClose}
      buttons={[
        {
          text: "Edit anyway",
          type: "secondary",

          onClick: onTakeControlClick,
          loading: isTakingControl,
        },
        {
          text: "Go back",
          type: "primary",
          onClick: onClose,
        },
      ]}
    />
  )
}

export default ResourceLockedPopup
