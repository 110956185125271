import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "@features/courseEditor/courseValidationsSelectors"
import EditorTextField, { EditorTextFieldProps } from "./editorTextField"

export type UrlTextFieldProps = EditorTextFieldProps & {
  elementId: string
  missingUrl?: boolean
  invalidUrl?: boolean
}

function UrlTextField(props: UrlTextFieldProps) {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )
  const isMissingUrl =
    props.missingUrl ??
    (isUrlMissing(props.value) && missingUrlBackendValidations(errors))
  const isInvalidUrl =
    props.missingUrl ??
    (isUrlInvalid(props.value) && errors?.includes("INVALID_URL"))
  const isInvalidCodioUrl =
    isCodioUrlInvalid(props.value, props.label?.toString() ?? "URL") &&
    errors?.includes("INVALID_CODIO_TASK_URL")

  const errorMessage = isMissingUrl
    ? "This field is required"
    : isInvalidUrl || isInvalidCodioUrl
    ? "Invalid URL"
    : undefined

  const { elementId, missingUrl, invalidUrl, ...domProps } = props

  return (
    <EditorTextField
      label={props.label || "URL"}
      error={errorMessage !== undefined}
      helperText={errorMessage}
      {...domProps}
    />
  )
}

function isUrlMissing(value: unknown) {
  return value === undefined || value === null || value === ""
}

function isUrlInvalid(value: unknown) {
  if (typeof value !== "string") {
    return true
  }
  try {
    new URL(value)
    return false
  } catch {
    return true
  }
}

function isCodioUrlInvalid(value: unknown, label: string) {
  if (typeof value !== "string") {
    return true
  }

  try {
    new URL(value)
  } catch {
    return true
  }

  if (label.toLocaleLowerCase().includes("project")) {
    const urlElements = value.split("lti/")
    return urlElements.length !== 2
  }

  if (label.toLocaleLowerCase().includes("task")) {
    const urlWithoutProtocol = value.replace(/^https:\/\//, "")
    const taskUrlElements = urlWithoutProtocol.split("/")
    return taskUrlElements.length !== 3
  }
}

function missingUrlBackendValidations(errors: string[] | undefined) {
  return (
    errors?.includes("MISSING_URL") ||
    errors?.includes("MISSING_CODIO_TASK_URL")
  )
}

export default UrlTextField
