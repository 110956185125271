import { RootState } from "@app/store"
import { LoginState, Role, User } from "./loginSlice"
import { createSelector } from "@reduxjs/toolkit"

export const selectUserLoginState = (state: { login: LoginState }) => {
  const userState = selectUser(state)
  const canUserAccessLMS = isMSEmployee(state)
  if (typeof userState !== "string") {
    return canUserAccessLMS ? "logged-in" : "not-authorized"
  }
  return userState === "loading" || userState === "not-loaded"
    ? "pending"
    : "not-logged-in"
}

const selectUser = (state: { login: LoginState }) => {
  return state.login.user
}

export const selectLoggedInUser = (state: {
  login: LoginState
}): User | null => {
  const user = selectUser(state)
  return typeof user === "string" ? null : user
}

const hasRole = (state: { login: LoginState }, role: Role) => {
  const user = selectLoggedInUser(state)
  if (!user) return false
  return user.roles.includes(role)
}

const isMSEmployee = (state: { login: LoginState }) => {
  return hasRole(state, Role.MasterschoolEmployee)
}

const isProgramManager = (state: { login: LoginState }) => {
  return hasRole(state, Role.ProgramManager)
}

const isAdmin = (state: { login: LoginState }) => {
  return hasRole(state, Role.LmsAdmin)
}

export const selectIsProgramManager = (state: { login: LoginState }) => {
  return isProgramManager(state)
}

export const selectIsMSEmployee = (state: { login: LoginState }) => {
  return isMSEmployee(state)
}

export const selectIsAccountManager = (state: { login: LoginState }) => {
  return hasRole(state, Role.AccountManager)
}

export const selectIsAdmin = (state: { login: LoginState }) => {
  return isAdmin(state)
}

export const selectAccessToken = (state: RootState) => {
  return state.login.accessToken
}

export const selectIsSalesTeamLead = (state: RootState) => {
  return hasRole(state, Role.SalesTeamLead)
}

export const selectIsDeveloper = createSelector(
  [selectLoggedInUser],
  (user) => {
    if (!user) return false
    const DEV_EMAILS = [
      "shirin@masterschool.com",
      "hadar.nuni@masterschool.com",
      "michael.focshaner@masterschool.com",
      "or.eliyahu@masterschool.com",
      "amit.s@masterschool.com",
      "saleem@masterschool.com",
    ]

    return DEV_EMAILS.includes(user.email)
  },
)
