import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { SelectedStatusChip } from "@cmp/syllabusStatusChip"
import {
  createProgramClass,
  editProgramClass,
} from "@features/program/programSliceThunks"
import { selectSyllabus } from "@features/syllabus/syllabusSelectors"
import {
  ProgramClassDto,
  ProgramDto,
  SyllabusDtoStatusEnum,
} from "@masterschool/course-builder-api"
import {
  Alert,
  Box,
  Button,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import { formatDateAsYearAndLongMonth } from "@utils/date"
import { stringAsCamelCase } from "@utils/syllabusTags"
import { useEffect, useState } from "react"
import appTheme from "../../../theme/appTheme"
import ProgramClassSyllabusSelectionDialog from "./programClassSyllabusSelectionDialog"
import appIcons from "@utils/appIcons"

function ManageProgramClassDialog(props: {
  open: boolean
  onClose: () => void
  program: ProgramDto
  programClass: ProgramClassDto | undefined
}) {
  const { open, onClose, program, programClass } = props
  const [programClassName, setProgramClassName] = useState("")
  const className = programClass?.name ?? ""
  const [syllabusId, setSyllabusId] = useState(
    programClass?.syllabusId ?? program.syllabusId,
  )
  const classSyllabusId = programClass?.syllabusId
  const programSyllabusId = program.syllabusId
  const [isSelectSyllabusDialogOpen, setIsSelectSyllabusDialogOpen] =
    useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setProgramClassName(className)
  }, [className])

  useEffect(() => {
    setSyllabusId(classSyllabusId ?? programSyllabusId)
  }, [classSyllabusId, programSyllabusId])

  const popupHeader = programClass ? "Edit class" : "Create new class"
  const cta = programClass ? "Save changes" : "Create class"
  const onCTAClick = () => {
    if (programClass) {
      dispatch(
        editProgramClass({
          programId: program.id,
          classId: programClass.id,
          name: programClassName,
          syllabusId,
        }),
      ).then(() => onClose())
    } else {
      dispatch(
        createProgramClass({
          name: programClassName,
          programId: program.id,
          syllabusId,
        }),
      ).then(() => onClose())
    }
  }

  return (
    <>
      <GenericDialog
        open={open}
        onClose={onClose}
        title={popupHeader}
        content={
          <Stack gap={2}>
            <TextField
              label="Class Name"
              value={programClassName}
              onChange={(e) => setProgramClassName(e.target.value)}
            />
            <SyllabusDescriptor
              syllabusId={syllabusId}
              program={program}
              onSelectReplaceSyllabus={() => {
                setIsSelectSyllabusDialogOpen(true)
              }}
            />
            {syllabusId && (
              <SelectedSyllabusAlert
                syllabusId={syllabusId}
                program={program}
                resetToProgramSyllabus={() => {
                  setSyllabusId(program.syllabusId)
                }}
              />
            )}
          </Stack>
        }
        buttons={[
          {
            type: "secondary",
            text: "Cancel",
            onClick: onClose,
          },
          {
            type: "primary",
            text: cta,
            onClick: onCTAClick,
          },
        ]}
      />
      <ProgramClassSyllabusSelectionDialog
        open={isSelectSyllabusDialogOpen}
        selectedSyllabusId={syllabusId}
        onClose={() => {
          setIsSelectSyllabusDialogOpen(false)
        }}
        onClickSyllabus={(syllabusId) => {
          setSyllabusId(syllabusId)
          setIsSelectSyllabusDialogOpen(false)
        }}
      />
    </>
  )
}

function SyllabusDescriptor(props: {
  syllabusId: string | undefined
  program: ProgramDto
  onSelectReplaceSyllabus: () => void
}) {
  const { syllabusId, program, onSelectReplaceSyllabus } = props
  const syllabus = useAppSelector(selectSyllabus(syllabusId))
  const programDomain = program.domain
  const programCohort = formatDateAsYearAndLongMonth(
    new Date(program.startDate as string),
  )
  const programScope = stringAsCamelCase(program.type)
  const programDescriptor = `${programDomain}, ${programCohort}, ${programScope}`
  const cta = syllabusId ? "Replace Syllabus" : "Select Syllabus"
  let syllabusDescription = (
    <Typography variant="body2">
      Program does not have a connected syllabus
    </Typography>
  )
  if (syllabus) {
    syllabusDescription = (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle2">{syllabus.name}</Typography>
        <Typography variant="body2">{programDescriptor}</Typography>
        <Box sx={{ marginTop: "8px" }}>
          <SelectedStatusChip
            syllabusId={syllabus.id}
            status={SyllabusDtoStatusEnum.Published}
            isActive={false}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <Typography variant="body1">Connected Syllabus</Typography>
      <Box
        sx={{
          marginTop: "8px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          borderRadius: "4px",
          bgcolor: appTheme.palette.primary.contrast,
        }}
      >
        {syllabusDescription}
        <Button variant="outlined" onClick={onSelectReplaceSyllabus}>
          {cta}
        </Button>
      </Box>
    </Box>
  )
}

function SelectedSyllabusAlert(props: {
  syllabusId: string
  program: ProgramDto
  resetToProgramSyllabus: () => void
}) {
  const { syllabusId, program, resetToProgramSyllabus } = props
  if (!program.syllabusId) return null

  const alertType = syllabusId === program.syllabusId ? "info" : "warning"
  const alertContent =
    syllabusId === program.syllabusId
      ? "The selected syllabus is the default program syllabus"
      : "A unique syllabus has been selected, providing different materials than the standard program."
  const action =
    syllabusId === program.syllabusId ? null : (
      <Button
        color="inherit"
        size="small"
        sx={{ alignSelf: "center" }}
        onClick={resetToProgramSyllabus}
      >
        Reset to program syllabus
      </Button>
    )
  return (
    <Alert
      icon={
        <SvgIcon
          component={
            alertType === "info" ? appIcons.infoCircle : appIcons.alertTriangle
          }
          inheritViewBox
          sx={{ fill: "none", width: 20, height: 20 }}
        />
      }
      severity={alertType}
      action={action}
    >
      {alertContent}
    </Alert>
  )
}

export default ManageProgramClassDialog
