import { ComponentsProps } from "@mui/material"

const MuiTooltipOverrides: {
  defaultProps?: ComponentsProps["MuiTooltip"]
} = {
  defaultProps: {
    arrow: true,
    enterDelay: 500,
    leaveDelay: 200,
    placement: "top",
    componentsProps: {
      tooltip: {
        sx: { backgroundColor: "#4E2D41" },
      },
      arrow: {
        sx: { color: "#4E2D41" },
      },
    },
  },
}

export { MuiTooltipOverrides }
