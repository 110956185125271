import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  offboardStaffConfirmed,
  offboardStaffPopupClosed,
} from "@features/account-management/accountManagementSlice"

function OffboardStaffDialog() {
  const model = useAppSelector(
    (state) => state.accountManagement.offboardStaffPopup,
  )
  const dispatch = useAppDispatch()

  return (
    <GenericDialog
      open={model !== undefined}
      title="Offboard staff member"
      onClose={() => dispatch(offboardStaffPopupClosed())}
      subtitle={`Are you sure you want to offboard ${
        model?.user.firstName + " " + model?.user.lastName
      }? This action is irreversible.`}
      size="xs"
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: () => dispatch(offboardStaffPopupClosed()),
        },
        {
          loading: model?.requestStatus === "pending",
          text: "Offboard member",
          type: "danger",
          onClick: () => dispatch(offboardStaffConfirmed()),
        },
      ]}
    />
  )
}

export default OffboardStaffDialog
