import { useAppDispatch } from "@app/hooks"
import { LegacySyllabusClient } from "@clients/legacySyllabusClient"
import GenericDialog from "@cmp/genericDialog"
import { popupClosed } from "@features/ui/uiSlice"
import { Box, Button, Divider, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { JSONTree } from "react-json-tree"

export const CampusSyllabusJSONPreview = ({
  syllabusId,
  version,
}: {
  syllabusId: string
  version: number
}) => {
  const dispatch = useAppDispatch()
  const [syllabus, setSyllabus] = useState<any>(undefined)
  useEffect(() => {
    LegacySyllabusClient.getLegacySyllabus(syllabusId, version).then(
      (syllabus) => {
        setSyllabus(syllabus)
      },
    )
  }, [syllabusId, version])

  return (
    <GenericDialog
      open
      onClose={() => {
        dispatch(popupClosed())
      }}
      size="lg"
      fullHeight
      content={
        <Stack
          alignItems="flex-start"
          overflow="auto"
          divider={<Divider flexItem sx={{ my: 0.5 }} />}
        >
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                syllabus ? JSON.stringify(syllabus, null, 4) : "",
              )
            }}
            variant="outlined"
            size="small"
          >
            Copy JSON to clipboard
          </Button>
          <Box overflow="auto">
            <JSONTree
              data={syllabus ?? {}}
              theme={{ base00: "#000000" }}
              invertTheme
            />
          </Box>
        </Stack>
      }
    />
  )
}
