import { SyllabusDto } from "@masterschool/course-builder-api"

export const areSyllabusDtosEqual = (dto1: SyllabusDto, dto2: SyllabusDto) => {
  return (
    JSON.stringify(extractSyllabusFieldsToCompare(dto1)) ===
    JSON.stringify(extractSyllabusFieldsToCompare(dto2))
  )
}

const extractSyllabusFieldsToCompare = (syllabus: SyllabusDto) => {
  return {
    id: syllabus.id,
    name: syllabus.name,
    units: syllabus.units,
    externalName: syllabus.externalName,
    domain: syllabus.domain,
  }
}
