import { RootState } from "@app/store"
import { createSelector } from "@reduxjs/toolkit"

export const selectClassesInCohort = (startDate: string | undefined) =>
  createSelector(
    [(state: RootState) => state.slack.cohortToClasses],
    (classesState) => {
      if (!startDate) {
        return []
      }
      const classes = classesState[startDate]
      if (!classes) {
        return []
      }
      if (classes.status === "success") {
        return classes.data
      }
      return []
    },
  )

export const selectProgramSlackChannels = (programId: string | undefined) =>
  createSelector(
    [(state: RootState) => state.slack.programToChannels],
    (channelsState) => {
      if (!programId) {
        return []
      }
      const channels = channelsState[programId]
      if (!channels) {
        return []
      }
      if (channels.status === "success") {
        return channels.data
      }
      return []
    },
  )

export const selectSlackChannel = (
  programId: string | undefined,
  channelId: string | undefined,
) =>
  createSelector([selectProgramSlackChannels(programId)], (channels) => {
    if (!channelId) {
      return undefined
    }
    return channels.find((channel) => channel.id === channelId)
  })
