import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericTable from "@cmp/genericTable"
import { formatDate } from "@masterschool/foundations"
import { Box, Button, SvgIcon, Switch, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useEffect } from "react"
import appTheme from "../../theme/appTheme"
import AddAppFeatureFlagPopup from "./addFeatureFlagPopup"
import {
  selectAppFeatureFlags,
  selectAppFeaturesSearchText,
} from "./appFeatureFlagsSelectors"
import {
  addAppFeatureFlagPopupOpened,
  fetchAppFeatureFlags,
  setSearchText,
  turnOnOffAppFeatureFlagPopupOpened,
} from "./appFeatureFlagsSlice"
import TurnOnOffFeatureFlagPopup from "./turnOnOffFeatureFlagPopup"
import SearchTextField from "@cmp/searchTextField"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { ErrorComponent } from "@cmp/loaders/errorComponent"

function AppFeatureFlags() {
  const flags = useAppSelector(selectAppFeatureFlags)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAppFeatureFlags())
  }, [dispatch])

  const searchText = useAppSelector(selectAppFeaturesSearchText)
  const formatOptionalDate = (date: Date | undefined) =>
    date
      ? formatDate(date, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : ""

  const isLoading = flags.status === "pending" || flags.status === "idle"
  const isError = flags.status === "error"

  const data = flags.status === "success" ? flags.data : []
  const dataToShow = data.filter((flag) =>
    flag.name.toLowerCase().includes(searchText.toLowerCase()),
  )

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        paddingX: "24px",
        paddingBottom: "48px",
      }}
    >
      <AddAppFeatureFlagPopup />
      <TurnOnOffFeatureFlagPopup />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1240px",
          marginX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "80px",
            mb: "16px",
            gap: "16px",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">App feature flags</Typography>
            {isLoading && <LoadingComponent loadedComponents="feature flags" />}
            {isError && <ErrorComponent loadedComponents="feature flags" />}
          </Box>
          <Box display="flex" gap="16px" width="100%">
            <SearchTextField
              searchText={searchText}
              onSearchTextChange={(text) => dispatch(setSearchText(text))}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "fit-content",
                flexShrink: 0,
              }}
              size="medium"
              onClick={() => dispatch(addAppFeatureFlagPopupOpened())}
              startIcon={
                <SvgIcon
                  component={appIcons.plus}
                  sx={{
                    stroke: appTheme.palette.primary.contrastText,
                    fill: "inherit",
                    width: "20px",
                    height: "20px",
                  }}
                  inheritViewBox
                />
              }
            >
              Add new feature flag
            </Button>
          </Box>
          {dataToShow.length > 0 ? (
            <GenericTable
              data={dataToShow}
              renderHeader={{
                enabled: () => "Status",
              }}
              render={{
                createdAt: formatOptionalDate,
                updatedAt: formatOptionalDate,
                enabled: (enabled, flag) => (
                  <>
                    <Switch
                      checked={enabled}
                      onChange={() => {
                        dispatch(
                          turnOnOffAppFeatureFlagPopupOpened({
                            flagName: flag.name,
                          }),
                        )
                      }}
                    />
                  </>
                ),
              }}
            />
          ) : (
            <Typography variant="body1">No feature flags found</Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default AppFeatureFlags
