import { EditChannelClassesPopup } from "./slackSlice"

export function updatedCheckedClasses(
  classId: string,
  popupState: EditChannelClassesPopup,
) {
  const currentIndex = popupState.checkedClassesIds.indexOf(classId)
  if (currentIndex === -1) {
    popupState.checkedClassesIds.push(classId)
  } else {
    popupState.checkedClassesIds.splice(currentIndex, 1)
  }
}
