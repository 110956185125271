import { Box, MenuItem, MenuList, SxProps, Theme } from "@mui/material"

export function GeneralNavigationSideBar(props: {
  tabs: (string | JSX.Element)[]
  onClick?: (tab: string) => void
  sx?: SxProps<Theme>
  header?: JSX.Element
  listSx?: SxProps<Theme>
  isOpen?: boolean
  onOpenCloseClicked?: () => void
}) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...props.sx,
      }}
    >
      <Box display="flex" flexDirection="column" overflow="hidden">
        {props.header ? props.header : <></>}
        <MenuList
          sx={{
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            ...props.listSx,
          }}
        >
          {props.tabs.map((tab) =>
            typeof tab === "string" ? (
              <MenuItem
                key={tab}
                onClick={() => {
                  if (props.onClick) {
                    props.onClick(tab)
                  }
                }}
              >
                {tab}
              </MenuItem>
            ) : (
              tab
            ),
          )}
        </MenuList>
      </Box>
    </Box>
  )
}
