import AppObserver from "@app/middlewares/appObserver"
import { store } from "@app/store"
import { saveSyllabus } from "@features/syllabusEditor/syllabusEditorSlice"

const syllabusEditsObserver: AppObserver = {
  didUpdate: (previousState, currentState, action) => {
    switch (action.type) {
      case "syllabusEditorSlice/sprintMetadataEdited":
      case "syllabusEditorSlice/unitAccessConditionEdited":
      case "syllabusEditorSlice/unitSprintsEdited":
        store.dispatch(saveSyllabus())
        break
    }
  },
}

export default syllabusEditsObserver
