import { CampusClient } from "./campusClient"

export type DatacampCredentials = {
  platformName: "auth0"
  platformUsername: string
}

export class LearningPlatformCredentialsClient {
  static async getStudentDatacampCredentials(
    studentId: string,
  ): Promise<DatacampCredentials> {
    return CampusClient.get<DatacampCredentials>(
      `v1/campus/learning-platform/datacamp/${studentId}`,
    )
  }

  static async updateStudentDatacampCredentials(
    studentId: string,
    updatedCredentials: Omit<DatacampCredentials, "platformName">,
  ) {
    return CampusClient.patch<DatacampCredentials>(
      `v1/campus/learning-platform/datacamp/${studentId}`,
      updatedCredentials,
    )
  }
}
