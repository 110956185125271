import GenericTable from "@cmp/genericTable"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import appIcons from "@utils/appIcons"
import { Box, Chip, SvgIcon } from "@mui/material"
import { ProgramStaffMember } from "@clients/programStaffClient"
import { ProgramClassDto } from "@masterschool/course-builder-api"
import { selectStudents } from "@features/program/programSliceSelectors"
import { useAppSelector } from "@app/hooks"
import { Role } from "@features/login/loginSlice"
import roleDisplayNames from "../../../account-management/roleDisplayName"

export const StaffTable = (props: {
  programId: string
  staffMembers: ProgramStaffMember[]
  programClasses: ProgramClassDto[]
  onEditUserClicked: (userId: string) => void
  onRemoveUserClicked: (userId: string) => void
}) => {
  const { programId, staffMembers, onEditUserClicked, onRemoveUserClicked } =
    props
  const programStudents = useAppSelector(selectStudents(programId))

  const mappedData = staffMembers.map((staffMember) => ({
    ...staffMember,
    classes: staffMember.properties?.assignedClasses ?? [],
    students: programStudents.filter(
      (student) => student.mentor?.userClientId === staffMember.userClientId,
    ).length,
  }))
  mappedData.sort((a, b) => {
    return rolesSortValue(a.roles) - rolesSortValue(b.roles)
  })

  return (
    <GenericTable
      data={mappedData}
      keys={["name", "email", "classes", "students", "roles", "userClientId"]}
      renderHeader={{
        userClientId: () => undefined,
        students: () => "Assigned students",
      }}
      sx={{
        borderRadius: "4px",
        overflow: "hidden",
      }}
      render={{
        userClientId: (userClientId) => {
          return (
            <ActionsMenu
              userId={userClientId}
              onEditUserClicked={onEditUserClicked}
              onRemoveUserClicked={onRemoveUserClicked}
            />
          )
        },
        students: (students, row) =>
          row.roles.includes(Role.Mentor) ? <>{students}</> : null,
        classes: (classes: string[]) => {
          return (
            <Box display="flex" gap="8px" flexWrap={"wrap"}>
              {classes.map((classId) => {
                const classData = props.programClasses.find(
                  (c) => c.id === classId,
                )
                return (
                  <Chip
                    key={classId}
                    label={classData?.name ?? "Unknown class"}
                    size="small"
                    variant="outlined"
                    sx={{
                      width: "fit-content",
                      maxWidth: "180px",
                    }}
                  />
                )
              })}
            </Box>
          )
        },
        roles: (roles: string[]) => {
          const sortedRoles = roles.toSorted((a, b) => {
            return roleSortValue(a) - roleSortValue(b)
          })
          return (
            <Box display="flex" gap="8px" flexWrap={"wrap"}>
              {sortedRoles.map((role) => (
                <Chip
                  key={role}
                  label={roleDisplayNames(role)}
                  size="small"
                  variant="outlined"
                  sx={{
                    width: "fit-content",
                  }}
                />
              ))}
            </Box>
          )
        },
      }}
    />
  )
}

function ActionsMenu(props: {
  userId: string
  onEditUserClicked: (userId: string) => void
  onRemoveUserClicked: (userId: string) => void
}) {
  const { userId, onEditUserClicked, onRemoveUserClicked } = props

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon
              component={appIcons.dotsHorizontal}
              inheritViewBox
              sx={{ color: "primary.main" }}
            />
          ),
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "edit",
            children: "Edit roles and assignments",
            onClick: () => {
              onEditUserClicked(userId)
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "delete",
            children: "Remove staff member",
            onClick: () => {
              onRemoveUserClicked(userId)
            },
          },
        },
      ]}
    />
  )
}

function rolesSortValue(roles: string[]) {
  return Math.min(...roles.map((r) => roleSortValue(r)))
}

export function roleSortValue(role: string): number {
  switch (role) {
    case "program_owner":
    case "program_manager":
      return 0
    case "mentor":
      return 1
    case "instructor":
      return 2
    default:
      return 3
  }
}
