import { useAppSelector, useAppDispatch } from "@app/hooks"
import Droppable from "@cmp/droppable"
import {
  useSensors,
  useSensor,
  PointerSensor,
  DndContext,
  DragOverlay,
} from "@dnd-kit/core"
import { Box } from "@mui/material"
import { createPortal } from "react-dom"
import { useSearchParams } from "react-router-dom"
import {
  dragUnitStarted,
  dragUnitEnded,
  unitDraggedOver,
} from "@features/syllabusEditor/syllabusEditorSlice"
import UnitDescriptorView from "./unitDescriptorView"
import { Syllabus } from "@features/syllabus/syllabusSelectors"

function SyllabusEditorUnitsList(props: { syllabus: Syllabus }) {
  const { syllabus } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const draggedUnitId = useAppSelector(
    (state) => state.syllabusEditor.draggedUnitId,
  )
  const draggedUnit = syllabus.units.find((u) => u.id === draggedUnitId)

  const dispatch = useAppDispatch()
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )

  if (syllabus.units.length === 0) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexDirection: "column",
        width: "100%",
        pt: "16px",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <DndContext
        onDragStart={(start) => {
          dispatch(dragUnitStarted({ id: start.active.id.toString() }))
        }}
        onDragEnd={() => {
          dispatch(dragUnitEnded())
        }}
        onDragCancel={() => {
          dispatch(dragUnitEnded())
        }}
        onDragOver={({ active, over }) => {
          dispatch(
            unitDraggedOver({
              sourceIndex: active?.data.current?.sortable?.index,
              targetIndex: over?.data.current?.sortable?.index,
            }),
          )
        }}
        sensors={sensors}
      >
        <Droppable
          id={syllabus.id}
          items={syllabus.units.map((unit, index) => ({
            element: (
              <UnitDescriptorView
                syllabusId={syllabus.id}
                unit={unit}
                index={index}
                editMode={true}
                hide={draggedUnitId === unit.id}
                onClick={() => {
                  searchParams.set("unitId", unit.id)
                  searchParams.delete("unitTab")
                  setSearchParams(searchParams, {
                    replace: true,
                  })
                }}
                isSelected={searchParams.get("unitId") === unit.id}
              />
            ),
            identifier: unit.id,
          }))}
          placeholder={<></>}
          disabled={false}
        />
        {createPortal(
          <DragOverlay
            dropAnimation={{
              duration: 300,
              easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
            }}
            style={{
              zIndex: 9999999,
            }}
          >
            {draggedUnit ? (
              <UnitDescriptorView
                syllabusId={syllabus.id}
                unit={draggedUnit}
                index={NaN}
                editMode={false}
                isSelected={searchParams.get("unitId") === draggedUnit.id}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    </Box>
  )
}

export default SyllabusEditorUnitsList
