import { selectLoggedInUser } from "@features/login/loginSelectors"
import { fetchPrograms } from "@features/program/programSliceThunks"
import {
  Syllabus,
  selectEditableSyllabus,
} from "@features/syllabus/syllabusSelectors"
import {
  SaveSyllabusResponse,
  fetchSyllabus,
  publishSyllabus,
  releaseControlIfNeededOfSyllabus,
  saveSyllabus,
  takeControlOfSyllabus,
  unitAdded,
  unmounted,
} from "@features/syllabusEditor/syllabusEditorSlice"
import { Box, Button, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import useKeyDown from "@utils/hooks/useKeyDown"
import useWindowEvent from "@utils/hooks/useRefreshObserver"
import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import EditCourseFromSyllabusConfirmationPopup from "../../main/popups/editCourseFromSyllabusConfirmationPopup"
import { PublishSyllabusPopup } from "../../main/popups/publishSyllabusPopup"
import appTheme from "../../theme/appTheme"
import ResourceLockedPopup from "../popups/resourceLockedPopup"
import CourseEditorPopup from "./panel/popups/courseEditorPopup"
import UnitCourseSelectionPopup from "./panel/popups/unitCourseSelectionPopup"
import SyllabusEditorPanel from "./panel/syllabusEditorPanel"
import useActiveSyllabusUnit from "./panel/useActiveSyllabusUnit"
import { SyllabusEditorGeneralInfo } from "./syllabusEditorGeneralInfo"
import { SyllabusEditorHeader } from "./syllabusEditorHeader"
import SyllabusEditorUnitsList from "./units/syllabusEditorUnitsList"
import StaleCoursesPopupPresenter from "./validations/staleCoursesPopup/staleCoursesPopupPresenter"
import StaleCoursesSnackbarPresenter from "./validations/staleCoursesSnackbarPresenter"

export function SyllabusEditorContainer() {
  const syllabus = useAppSelector(selectEditableSyllabus)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(selectLoggedInUser)
  const saveInterval = useRef<NodeJS.Timeout | null>(null)
  const [lockingUserState, setLockingUserState] =
    useState<LockingUserState>("NO-LOCK")

  const { syllabusId } = useParams()

  useKeyDown("q", () => dispatch(saveSyllabus()), true)

  const clearCurrentSaveInterval = () => {
    if (saveInterval.current) {
      clearInterval(saveInterval.current)
      saveInterval.current = null
    }
  }

  const onSaveSyllabus = useCallback(
    (res: SaveSyllabusResponse) => {
      if (res.payload?.error === "LockedByAnotherUser") {
        setLockingUserState("OTHER-USER")
        clearCurrentSaveInterval()
        // Fetch syllabus to get the lock info
        syllabusId && dispatch(fetchSyllabus({ syllabusId }))
      }
    },
    [dispatch, syllabusId],
  )

  const startSaveIntervalIfNeeded = useCallback(() => {
    if (lockingUserState === "OTHER-USER") {
      return
    }
    clearCurrentSaveInterval()
    saveInterval.current = setInterval(() => {
      dispatch(saveSyllabus()).then(onSaveSyllabus)
    }, 10000)
  }, [dispatch, lockingUserState, onSaveSyllabus])

  useEffect(() => {
    const lock = syllabus?.lock
    if (!lock) {
      setLockingUserState("NO-LOCK")
      return
    }

    const lockedByThisUser = lock.lockedByUserId === user?.clientId
    setLockingUserState(lockedByThisUser ? "THIS-USER" : "OTHER-USER")
  }, [syllabus, user])

  useEffect(() => {
    if (!syllabusId) {
      return
    }
    dispatch(fetchSyllabus({ syllabusId }))
    return () => {
      dispatch(saveSyllabus()).then(() => {
        dispatch(releaseControlIfNeededOfSyllabus({ syllabusId }))
        dispatch(unmounted())
      })
    }
  }, [dispatch, syllabusId])

  useEffect(() => {
    if (!saveInterval.current) startSaveIntervalIfNeeded()

    return () => {
      clearCurrentSaveInterval()
    }
  }, [dispatch, saveInterval, startSaveIntervalIfNeeded])

  useWindowEvent("beforeunload", (event) => {
    dispatch(saveSyllabus())
    syllabusId && dispatch(releaseControlIfNeededOfSyllabus({ syllabusId }))
  })

  const onCloseLockedResourcePopup = () => {
    if (!syllabus) return
    navigate(`/syllabus/${syllabus.id}`)
  }

  const onTakeControlResource = async () => {
    if (!syllabusId) return
    await dispatch(takeControlOfSyllabus({ syllabusId }))
    startSaveIntervalIfNeeded()
  }

  if (!syllabus) return <div>Loading...</div>

  return (
    <SyllabusEditor
      syllabus={syllabus}
      showResourceLockedPopup={lockingUserState === "OTHER-USER"}
      onCloseLockedResourcePopup={onCloseLockedResourcePopup}
      onTakeControlResource={onTakeControlResource}
    />
  )
}

function SyllabusEditor(props: {
  syllabus: Syllabus
  showResourceLockedPopup: boolean
  onCloseLockedResourcePopup: () => void
  onTakeControlResource: () => void
}) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    syllabus,
    showResourceLockedPopup,
    onCloseLockedResourcePopup,
    onTakeControlResource,
  } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const unit = useActiveSyllabusUnit()
  const shouldTranslate = unit !== undefined

  const itemsContainerRef = useRef<HTMLElement>(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false)
  const showCourseSelectionPopup = useAppSelector(
    (state) => state.syllabusEditor.coursesSelectionPopup !== undefined,
  )
  const showEditCourseConfirmationPopup = useAppSelector(
    (state) => state.syllabusEditor.editCourseConfirmationPopup !== undefined,
  )

  useEffect(() => {
    if (shouldScrollToBottom) {
      itemsContainerRef.current?.scrollTo({
        top: itemsContainerRef.current.scrollHeight,
        behavior: "smooth",
      })
      setShouldScrollToBottom(false)
    }
  }, [shouldScrollToBottom])

  const [showPublishPopup, setShowPublishPopup] = useState<boolean>(false)

  const closeSelectedUnit = () => {
    if (!unit?.id) {
      return
    }

    searchParams.delete("unitId")
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onAddUnitClicked = () => {
    const newUnitId = window.crypto.randomUUID()
    dispatch(unitAdded({ id: newUnitId }))
    searchParams.delete("unitTab")
    searchParams.set("unitId", newUnitId)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  function onPublishApproved() {
    dispatch(publishSyllabus()).then(() => {
      setShowPublishPopup(false)
      navigate(`/syllabus/${syllabus.id}`)
    })
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <ResourceLockedPopup
        resourceType="syllabus"
        lockedBy={syllabus.lock?.lockedBy}
        open={showResourceLockedPopup}
        onClose={onCloseLockedResourcePopup}
        onTakeControl={onTakeControlResource}
      />
      <SyllabusEditorHeader
        onPublishChangesClicked={() => setShowPublishPopup(true)}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "space-between",
          bgcolor: appTheme.palette.eTypes.sand15,
        }}
      >
        <Box
          sx={{
            width: "60%",
            height: "100%",
            transform: shouldTranslate ? "translateX(0%)" : "translateX(33%)",
            transition: "all .35s ease-in-out",
            display: "flex",
            justifyContent: "center",
            padding: "48px 24px",
            overflow: "auto",
          }}
          onClick={closeSelectedUnit}
        >
          <Box
            sx={{
              maxWidth: "600px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "36px",
            }}
          >
            <SyllabusEditorGeneralInfo syllabus={syllabus} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                pb: "48px",
              }}
            >
              <Typography variant="body1_sb">Units</Typography>
              <SyllabusEditorUnitsList syllabus={syllabus} />
              <Button
                size="small"
                startIcon={
                  <SvgIcon
                    component={appIcons.plus}
                    inheritViewBox
                    sx={{
                      stroke: appTheme.palette.icon.black,
                    }}
                  />
                }
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation()
                  onAddUnitClicked()
                }}
              >
                Add unit
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            transform: shouldTranslate ? "translateX(0%)" : "translateX(100%)",
            transition: "all .35s ease-in-out",
          }}
        >
          {<SyllabusEditorPanel />}
        </Box>
      </Box>
      {showCourseSelectionPopup && <UnitCourseSelectionPopup />}
      {showEditCourseConfirmationPopup && (
        <EditCourseFromSyllabusConfirmationPopup />
      )}
      {showPublishPopup && (
        <PublishSyllabusPopup
          onPublishClicked={onPublishApproved}
          onClose={() => {
            setShowPublishPopup(false)
            dispatch(fetchPrograms())
          }}
        />
      )}
      <StaleCoursesSnackbarPresenter />
      <StaleCoursesPopupPresenter />
      <CourseEditorPopup />
    </Box>
  )
}

type LockingUserState = "THIS-USER" | "OTHER-USER" | "NO-LOCK"
