import { CourseDto } from "@masterschool/course-builder-api"

export const areCourseDtosEqual = (dto1: CourseDto, dto2: CourseDto) => {
  return (
    JSON.stringify(extractCourseDtoFieldsToCompare(dto1)) ===
    JSON.stringify(extractCourseDtoFieldsToCompare(dto2))
  )
}

const extractCourseDtoFieldsToCompare = (course: CourseDto) => {
  return {
    id: course.id,
    name: course.name,
    title: course.title,
    shortTitle: course.shortTitle,
    description: course.description,
    syllabus: course.syllabus,
    domains: course.domains,
    createdBy: course.createdBy,
  }
}
