import { store } from "@app/store"
import {
  Configuration,
  ClassApi,
  UserDto,
  ChangeProgramClassRequest,
} from "@masterschool/course-builder-api"
import { selectAccessToken } from "@features/login/loginSelectors"
import { CampusClient } from "./campusClient"

export class ProgramClassClient {
  static classClient = new ClassApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static async registerStudentToClass(
    classId: string,
    student: Omit<UserDto, "id" | "roles">,
  ) {
    return ProgramClassClient.classClient
      .programClassControllerRegisterStudent(
        student,
        classId,
        ProgramClassClient.makeHeaders(),
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response?.status === 409) {
          throw new Error(error.response.data.message)
        }
        throw error
      })
  }

  static async studentRegistrationStatus(studentEmail: string) {
    return ProgramClassClient.classClient
      .programClassControllerUserRegistrationStatus(
        studentEmail,
        ProgramClassClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static async unregisterStudentFromClass(studentId: string) {
    return ProgramClassClient.classClient
      .programClassControllerUnregisterStudent(
        studentId,
        ProgramClassClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static async createProgramClass(
    name: string,
    programId: string,
    syllabusId?: string,
  ) {
    return ProgramClassClient.classClient
      .programClassControllerCreateProgramClass(
        {
          name,
          programId,
          syllabusId,
        },
        ProgramClassClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static async editProgramClass(req: {
    classId: string
    name: string
    syllabusId: string | undefined
  }) {
    return ProgramClassClient.classClient
      .programClassControllerEditProgramClass(
        req,
        req.classId,
        ProgramClassClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static async changeStudentClass(request: ChangeProgramClassRequest) {
    return ProgramClassClient.classClient
      .programClassControllerChangeClass(
        request,
        ProgramClassClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static async deleteProgramClass(classId: string): Promise<Date> {
    return CampusClient.delete(`/school/${classId}`, {})
  }
}
