import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  CareerSuccessAdvisorClient,
  CareerSuccessAdvisorDto,
} from "@clients/careerSuccessAdvisorClient"
import GenericDialog from "@cmp/genericDialog"
import { editStudentCareerSuccessAdvisorPopupClosed } from "@features/program/programSlice"
import { fetchClasses } from "@features/program/programSliceThunks"
import { Autocomplete, MenuItem, TextField } from "@mui/material"
import { useEffect, useState } from "react"

function EditStudentCareerSuccessAdvisorPopup() {
  const [advisor, setAdvisor] = useState<CareerSuccessAdvisorDto | undefined>(
    undefined,
  )
  const [didLoad, setDidLoad] = useState(false)
  const [advisors, setAdvisors] = useState<CareerSuccessAdvisorDto[]>([])
  const dispatch = useAppDispatch()
  const model = useAppSelector(
    (state) => state.program.studentsPage.editStudentCareerSuccessAdvisorPopup,
  )

  useEffect(() => {
    const matchingAdvisor = advisors.find(
      (m) => m.userClientId === model?.initialCareerSuccessAdvisorClientId,
    )
    setAdvisor(matchingAdvisor)
  }, [model, advisors])

  useEffect(() => {
    if (!didLoad) {
      CareerSuccessAdvisorClient.listAdvisors().then((advisors) => {
        setAdvisors(
          advisors.sort((a, b) => a.firstName.localeCompare(b.firstName)),
        )
        setDidLoad(true)
      })
    }
  }, [didLoad])

  const onCTAClick = () => {
    if (!advisor || !model) return

    CareerSuccessAdvisorClient.assignStudentToAdvisor({
      studentUserClientId: model.studentId,
      advisorUserClientId: advisor.userClientId,
    })
      .then(() => {
        dispatch(fetchClasses({ programId: model.programId }))
      })
      .then(() => {
        dispatch(editStudentCareerSuccessAdvisorPopupClosed())
      })
  }

  if (!model) return null

  return (
    <GenericDialog
      open
      size="xs"
      onClose={() => {
        dispatch(editStudentCareerSuccessAdvisorPopupClosed())
      }}
      title={`Assign ${model.studentName} to a CSA`}
      content={
        <Autocomplete
          value={advisor || null}
          options={advisors}
          onChange={(_, newSelection) => {
            setAdvisor(newSelection ? newSelection : undefined)
          }}
          placeholder={"Select a Career Success Advisor"}
          autoComplete
          fullWidth
          isOptionEqualToValue={(option, value) =>
            option.userClientId === value?.userClientId
          }
          renderOption={(props, advisor) => {
            return (
              <MenuItem
                {...props}
                key={advisor.userClientId}
                value={advisor.userClientId}
              >
                {advisor.firstName} {advisor.lastName}
              </MenuItem>
            )
          }}
          getOptionLabel={(advisor) =>
            `${advisor.firstName} ${advisor.lastName}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="medium"
              label="Career Success Advisor"
              InputLabelProps={{
                sx: { fontSize: "16px" },
              }}
            />
          )}
        />
      }
      buttons={[
        {
          type: "primary",
          text: "Assign",
          disabled: !advisor,
          onClick: onCTAClick,
        },
      ]}
    />
  )
}

export default EditStudentCareerSuccessAdvisorPopup
