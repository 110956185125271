import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  ThemeOptions,
} from "@mui/material/styles"

const borderRadius = 8
const MuiAccordionDetailsOverrides: {
  defaultProps?: ComponentsProps["MuiAccordionDetails"]
  styleOverrides?: ComponentsOverrides<ThemeOptions>["MuiAccordionDetails"]
  variants?: ComponentsVariants["MuiAccordionDetails"]
} = {
  styleOverrides: {
    root: {
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      padding: 24,
    },
  },
}
const MuiAccordionSummaryOverrides: {
  defaultProps?: ComponentsProps["MuiAccordionSummary"]
  styleOverrides?: ComponentsOverrides<ThemeOptions>["MuiAccordionSummary"]
  variants?: ComponentsVariants["MuiAccordionSummary"]
} = {
  styleOverrides: {
    root: {
      padding: "0 24px",
    },
    content: {
      "&, &.Mui-expanded": {
        margin: "24px 0",
      },
    },
  },
}

const MuiAccordionOverrides: {
  defaultProps?: ComponentsProps["MuiAccordion"]
  styleOverrides?: ComponentsOverrides<ThemeOptions>["MuiAccordion"]
  variants?: ComponentsVariants["MuiAccordion"]
} = {
  defaultProps: {
    disableGutters: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      overflow: "hidden",
      boxShadow: theme.shadows?.[0],
      "&:before": {
        display: "none",
      },
      "&.MuiAccordion-rounded": {
        borderRadius,
      },
      border: `1px solid ${theme.palette?.other.outlineBorder}`,
    }),
  },
  variants: [
    {
      props: { square: true },
      style: {
        "& .MuiAccordionDetails-root": {
          borderRadius: 0,
        },
      },
    },
  ],
}

export {
  MuiAccordionOverrides,
  MuiAccordionDetailsOverrides,
  MuiAccordionSummaryOverrides,
}
