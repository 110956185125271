import { DateTimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import appIcons from "@utils/appIcons"
import { DateTimePickerProps } from "@mui/lab"
import { useState } from "react"
import dayjs, { Dayjs } from "dayjs"

const FormDateTimePicker = (
  props: DateTimePickerProps<Dayjs> & {
    value: string | null
    errorMessage?: string
  },
  size: "small" | "medium" | "large" = "medium",
) => {
  const { value, errorMessage, minDateTime, ...datePickerProps } = props
  const [error, setError] = useState(false)
  const onError = (error: boolean) => {
    setError(error)
    datePickerProps.onError?.(error)
  }
  const safeValue = value ? dayjs(value) : null
  const safeMinDateTime = minDateTime ? dayjs(minDateTime) : null
  let helperText = datePickerProps.helperText
  if (!helperText) {
    helperText = error ? errorMessage : ""
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        {...datePickerProps}
        value={safeValue}
        minDateTime={safeMinDateTime || undefined}
        format="DD-MM-YYYY HH:mm"
        timezone="system"
        onError={(error) => onError(!!error)}
        slotProps={{
          textField: {
            helperText,
            size: props.size,
          },
          inputAdornment: {
            sx: {
              paddingRight: "8px",
            },
          },
        }}
        sx={{ width: "100%" }}
        slots={{
          openPickerIcon: appIcons.calendar,
        }}
      />
    </LocalizationProvider>
  )
}

export default FormDateTimePicker
