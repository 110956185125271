import { Box, Button, IconButton, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../theme/appTheme"

export const ProjectActionButtons = (props: {
  onEdit: () => void
  onDelete: () => void
}) => {
  return (
    <Box
      display="flex"
      gap="8px"
      justifyContent="end"
      width="100%"
      minWidth="80px"
    >
      <IconButton size="small" onClick={props.onEdit}>
        <SvgIcon
          component={appIcons.edit03}
          inheritViewBox
          sx={{
            width: "20px",
            height: "20px",
            fill: "none",
            stroke: "black",
          }}
        />
      </IconButton>
      <IconButton size="small" onClick={props.onDelete}>
        <SvgIcon
          component={appIcons.trash03}
          inheritViewBox
          sx={{
            width: "20px",
            height: "20px",
            fill: "none",
            stroke: "black",
          }}
        />
      </IconButton>
    </Box>
  )
}

export const AddProjectButton = (props: { onClick: () => void }) => {
  return (
    <Button
      variant="contained"
      onClick={props.onClick}
      style={{
        width: "fit-content",
        flexShrink: 0,
      }}
      size="medium"
      startIcon={
        <SvgIcon
          component={appIcons.plus}
          sx={{
            stroke: appTheme.palette.primary.contrastText,
            fill: "inherit",
            width: "20px",
            height: "20px",
          }}
          inheritViewBox
        />
      }
    >
      Add project
    </Button>
  )
}
