import AppObserver from "@app/middlewares/appObserver"
import { store } from "@app/store"
import { selectAccessToken } from "../login/loginSelectors"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { LoginClient } from "@clients/loginClient"

export const accessTokenObserver: AppObserver = {
  didUpdate: (previousState, currentState, action) => {
    const prevAccessToken = selectAccessToken(previousState)
    const newAccessToken = selectAccessToken(currentState)
    const isInitialLoading = currentState.login.user === "not-loaded"

    if (prevAccessToken === newAccessToken && !isInitialLoading) {
      return
    }
    setAccessTokenInLocalStorage(newAccessToken)
    if (newAccessToken) {
      store.dispatch(fetchUserForAccessToken(newAccessToken))
    }
  },
}

const ACCESS_TOKEN_KEY = "accessToken"
export function getAccessTokenFromLocalStorage() {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export function setAccessTokenInLocalStorage(accessToken: string | null) {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  } else {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  }
}

export const fetchUserForAccessToken = createAppAsyncThunk(
  "login/fetchUserForAccessToken",
  async (accessToken: string) => {
    return LoginClient.getUser(accessToken).then((user) => {
      return { accessToken, ...user }
    })
  },
)
