import { Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import EmptyStateContainer from "../components/emptyStateContainer"
import appTheme from "../../../theme/appTheme"
import { useSearchParams } from "react-router-dom"
import { useEffect } from "react"

export const EmptyStaffView = (props: { onAddNewClassClick: () => void }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const action = searchParams.get("action")
  const onAddNewClassClick = props.onAddNewClassClick

  useEffect(() => {
    if (action === "add") {
      onAddNewClassClick()
      searchParams.delete("action")
      setSearchParams(searchParams, {
        replace: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  return (
    <EmptyStateContainer
      title="No staff members added"
      subtitle="Add staff members to this program"
      icon={appIcons.userPlus01}
      action={
        <Button
          variant="contained"
          onClick={props.onAddNewClassClick}
          startIcon={
            <SvgIcon
              component={appIcons.plus}
              sx={{
                stroke: appTheme.palette.primary.contrastText,
                fill: "inherit",
                width: "20px",
                height: "20px",
              }}
              inheritViewBox
            />
          }
        >
          Add staff member
        </Button>
      }
    />
  )
}
