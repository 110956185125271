import { useAppDispatch, useAppSelector } from "@app/hooks"
import { SearchComponent } from "@cmp/searchComponent"
import {
  selectSyllabusSearch,
  syllabusFilterUpdated,
  syllabusSearchTextUpdated,
} from "@features/search/searchSlice"
import { SearchAdvanceFilter } from "@features/search/searchSlice"
import { domainToDisplayName } from "@utils/domainUtils"

export const SyllabusSearchComponent = () => {
  const dispatch = useAppDispatch()

  const searchFilters = useAppSelector(selectSyllabusSearch)

  return (
    <SearchComponent
      onSearchTextChange={(text: string) =>
        dispatch(syllabusSearchTextUpdated(text))
      }
      searchText={searchFilters.text}
      inputFilters={searchFilters.advanceFilters}
      onInputFiltersChange={(advanceFilter: SearchAdvanceFilter[]) => {
        dispatch(syllabusFilterUpdated(advanceFilter))
      }}
      searchPlaceholder="Search for a syllabus"
      filterTitle="Filter syllabuses"
      getOptionLabel={domainToDisplayName}
    />
  )
}
