import { CourseDto } from "@masterschool/course-builder-api"
import { createSlice } from "@reduxjs/toolkit"
import { CourseClient } from "@clients/courseClient"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"

export interface CourseViewState {
  course: CourseDto | undefined
}

const initialState: CourseViewState = {
  course: undefined,
}

export const courseViewSlice = createSlice({
  name: "courseView",
  initialState,
  reducers: {
    unmounted: (state) => {
      state.course = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourse.pending, (state, action) => {
        state.course = undefined
      })
      .addCase(fetchCourse.fulfilled, (state, action) => {
        state.course = action.payload
      })
      .addCase(fetchCourse.rejected, (state, action) => {
        state.course = undefined
      })
  },
})

export const { unmounted } = courseViewSlice.actions

export const fetchCourse = createAppAsyncThunk(
  "courseView/fetchCourse",
  async (courseMeta: { courseId: string; version: number }) => {
    return CourseClient.getCourseByVersion(
      courseMeta.courseId,
      courseMeta.version,
    )
  },
)

export default courseViewSlice.reducer
