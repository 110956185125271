import { useAppDispatch, useAppSelector } from "@app/hooks"
import { LearningPlatformCredentialsClient } from "@clients/learningPlatformCredentialsClient"
import GenericDialog from "@cmp/genericDialog"
import {
  closeEditDatacampCredentialsPopup,
  editStudentCareerSuccessAdvisorPopupClosed,
} from "@features/program/programSlice"
import { Box, CircularProgress, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import EditorTextField from "../../../editor/components/editorTextField"

function EditStudentDatacampCredentialsPopup() {
  const [platformUsername, setPlatformUsername] = useState<string | undefined>(
    undefined,
  )
  const [didLoadInitialState, setDidLoadInitialState] = useState(false)
  const [isLoadingClickAction, setIsLoadingClickAction] = useState(false)
  const dispatch = useAppDispatch()
  const model = useAppSelector(
    (state) => state.program.studentsPage.editDatacampCredentialsPopup,
  )
  const isDisabled = !model || !platformUsername

  useEffect(() => {
    if (!model || didLoadInitialState) {
      return
    }

    LearningPlatformCredentialsClient.getStudentDatacampCredentials(
      model.studentId,
    ).then((datacampCredentials) => {
      setPlatformUsername(datacampCredentials.platformUsername)
      setDidLoadInitialState(true)
    })
  }, [model, didLoadInitialState])

  const onCTAClick = () => {
    if (isDisabled) {
      return
    }
    setIsLoadingClickAction(true)
    LearningPlatformCredentialsClient.updateStudentDatacampCredentials(
      model.studentId,
      {
        platformUsername: platformUsername,
      },
    ).then(() => {
      setIsLoadingClickAction(false)
      dispatch(editStudentCareerSuccessAdvisorPopupClosed())
    })
  }

  if (!model) return null

  return (
    <GenericDialog
      open
      size="xs"
      onClose={() => {
        setDidLoadInitialState(false)
        dispatch(closeEditDatacampCredentialsPopup())
      }}
      title={`Edit ${model.studentName} Datacamp email`}
      content={
        didLoadInitialState ? (
          <Stack spacing={2}>
            <EditorTextField
              key="email"
              debounce
              label="email"
              size="medium"
              value={platformUsername || ""}
              onChange={(e) => {
                setPlatformUsername(e.target.value)
              }}
              error={false}
              helperText={undefined}
            />
          </Stack>
        ) : (
          <Box alignContent="center" justifyContent="center" display="flex">
            <CircularProgress />
          </Box>
        )
      }
      buttons={[
        {
          type: "primary",
          text: "Assign",
          disabled: isDisabled,
          loading: isLoadingClickAction,
          onClick: onCTAClick,
        },
      ]}
    />
  )
}

export default EditStudentDatacampCredentialsPopup
