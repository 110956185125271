import { store } from "@app/store"
import { selectAccessToken } from "@features/login/loginSelectors"
import {
  Configuration,
  CreateSyllabusRequest,
  SyllabusApi,
  SyllabusDto,
} from "@masterschool/course-builder-api"
export class SyllabusClient {
  private static syllabusClient = new SyllabusApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static getSyllabus(
    id: string,
    programStartDate?: Date | string,
    version?: number,
  ) {
    return SyllabusClient.syllabusClient
      .syllabusControllerV1Get(
        id,
        programStartDate ? new Date(programStartDate) : undefined,
        version,
        SyllabusClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static listSyllabuses() {
    return SyllabusClient.syllabusClient
      .syllabusControllerV1List(SyllabusClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static createSyllabus(syllabus: CreateSyllabusRequest) {
    return SyllabusClient.syllabusClient
      .syllabusControllerV1Create(syllabus, SyllabusClient.makeHeaders())
      .then((response) => response.data)
  }

  static updateSyllabus(
    id: string,
    updateSyllabus: SyllabusDto,
  ): Promise<SyllabusDto> {
    return SyllabusClient.syllabusClient
      .syllabusControllerV1Update(
        updateSyllabus,
        id,
        SyllabusClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static publish(syllabusId: string): Promise<void> {
    return SyllabusClient.syllabusClient
      .syllabusControllerV1Publish(
        { syllabusId: syllabusId },
        SyllabusClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static archive(syllabusId: string): Promise<void> {
    return SyllabusClient.syllabusClient
      .syllabusControllerV1Archive(
        { syllabusId: syllabusId },
        SyllabusClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static createNewDraftVersion(syllabusId: string): Promise<SyllabusDto> {
    return SyllabusClient.syllabusClient
      .syllabusControllerV1CreateNewDraftVersion(
        { syllabusId: syllabusId },
        SyllabusClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static deleteSyllabus(id: string): Promise<boolean> {
    return SyllabusClient.syllabusClient
      .syllabusControllerV1Delete(id, SyllabusClient.makeHeaders())
      .then(() => true)
  }
}
