import { PayloadAction } from "@reduxjs/toolkit"
import { SyllabusEditorState } from "./syllabusEditorState"
import { SectionAccessCondition } from "@masterschool/course-builder-api"

export const unitAccessConditionEdited = (
  state: SyllabusEditorState,
  action: PayloadAction<UnitAccessConditionEditedPayload>,
) => {
  const { unitId, accessCondition } = action.payload
  const unit = state.syllabus?.units.find((u) => u.id === unitId)
  if (!unit) return
  unit.accessCondition = accessCondition
  return state
}

type UnitAccessConditionEditedPayload = {
  unitId: string
  accessCondition: SectionAccessCondition
}
