import { CourseDto } from "@masterschool/course-builder-api"

export function findTopicByElementId(
  course: CourseDto,
  elementId: string | undefined,
) {
  const elementTopic = course.syllabus.topics.find((t) =>
    t.elements.find((e) => e.item.id === elementId),
  )
  const liveEventTopic = course.syllabus.topics.find((t) =>
    t.liveEvents.find((le) => le.id === elementId),
  )
  return elementTopic || liveEventTopic
}
