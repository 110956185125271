import { store } from "@app/store"
import { selectAccessToken } from "@features/login/loginSelectors"
import {
  Configuration,
  ResourceLockingApi,
  TakeLockRequestResourceTypeEnum,
} from "@masterschool/course-builder-api"

export class ResourceLockingClient {
  static api = new ResourceLockingApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static takeControlOfSyllabus(resourceId: string) {
    return ResourceLockingClient.api
      .resourceLockingControllerTakeControl(
        {
          resourceId: resourceId,
          resourceType: TakeLockRequestResourceTypeEnum.Syllabus,
        },
        ResourceLockingClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static releaseControlOfResource(resourceId: string) {
    return ResourceLockingClient.api
      .resourceLockingControllerReleaseControl(
        resourceId,
        ResourceLockingClient.makeHeaders(),
      )
      .then((response) => response.data)
  }
}
