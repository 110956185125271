import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  pickPublishedSyllabusPopupOpened,
  suggestConnectClassesAfterDisconnectPopupUpdate,
} from "@features/program/programSlice"
import {
  selectClassesForProgram,
  selectProgramsSyllabusPage,
} from "@features/program/programSliceSelectors"
import { Stack, Typography } from "@mui/material"

const SuggestConnectClassesAfterDisconnectPopup = () => {
  const { suggestConnectClassesAfterDisconnectPopup: model } = useAppSelector(
    selectProgramsSyllabusPage,
  )
  const dispatch = useAppDispatch()
  const { classIds, isOpen, programId } = model
  const programClasses = useAppSelector(selectClassesForProgram(programId))
  if (!isOpen || !programId) return null

  const onClose = () => {
    dispatch(
      suggestConnectClassesAfterDisconnectPopupUpdate({
        isOpen: false,
        programId,
      }),
    )
  }

  const onChooseSyllabus = () => {
    dispatch(
      pickPublishedSyllabusPopupOpened({
        programId,
        classIds,
        withConfirmationPopup: false,
      }),
    )
    onClose()
  }

  return (
    <GenericDialog
      open
      onClose={onClose}
      size="xs"
      title="Syllabus disconnected. Make sure all classes are connected to a syllabus"
      content={
        <Stack>
          <Typography component="ul">
            {programClasses.map((c) => (
              <li key={c.id}>{c.name}</li>
            ))}
          </Typography>
        </Stack>
      }
      buttons={[
        {
          type: "secondary",
          text: "I’ll do it later",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Choose a syllabus",
          onClick: onChooseSyllabus,
        },
      ]}
    />
  )
}

export default SuggestConnectClassesAfterDisconnectPopup
