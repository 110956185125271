import { selectAccessToken } from "@features/login/loginSelectors"
import { store } from "@app/store"

export class LegacySyllabusClient {
  private static readonly basePath = `${
    import.meta.env.VITE_BASE_URL ?? ""
  }/api`

  private static makeHeaders() {
    return {
      Authorization: `Bearer ${selectAccessToken(store.getState())}`,
    }
  }

  static getLegacySyllabus(id: string, version: number): Promise<any> {
    const headers = LegacySyllabusClient.makeHeaders()
    const request: RequestInit = { method: "GET", headers }

    return fetch(
      LegacySyllabusClient.basePath +
        `/legacy-syllabus/${id}?version=${version}`,
      request,
    ).then((res) => res.json())
  }
}
