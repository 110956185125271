import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  SvgIcon,
  SxProps,
  Theme,
} from "@mui/material"
import { IconType } from "@utils/iconType"
import appTheme from "../../theme/appTheme"

export function CustomMenuItem(props: {
  text: string
  icon?: IconType
  thumbnail?: JSX.Element
  sxIcon?: SxProps<Theme>
  disabled?: boolean
  onClick: () => void
}) {
  const { text, icon, thumbnail, sxIcon, disabled, onClick } = props
  return (
    <MenuItem onClick={onClick} disabled={disabled} sx={{ gap: "8px" }}>
      {icon && (
        <ListItemIcon style={{ minWidth: "0px" }}>
          <SvgIcon
            component={icon}
            inheritViewBox
            sx={{
              stroke: appTheme.palette.icon.black,
              height: "20px",
              fill: "none",
              ...sxIcon,
            }}
          />
        </ListItemIcon>
      )}
      {thumbnail}
      <ListItemText primary={text} />
    </MenuItem>
  )
}
