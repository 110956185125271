import { RootState } from "@app/store"
import { CourseDto, CourseStatus } from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"
import { areCourseDtosEqual } from "@utils/courseUtils"
import { selectLastVersionSyllabuses } from "@features/syllabus/syllabusSelectors"

export const selectCourse = (courseId: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courses) {
      return undefined
    }

    return courses.find((course) => course.id === courseId)
  })

export const selectLatestCourseVersion = (courseId?: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !courses) {
      return undefined
    }

    return getLatestCourseVersion(courses, courseId)
  })

export const selectLatestPublishedCourse = (courseId?: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !courses) {
      return undefined
    }

    return findLatestCourseVersionForStatus(
      courses,
      courseId,
      CourseStatus.Published,
    )
  })

export const selectCourseVersion = (courseId: string, version: number) =>
  createSelector([selectCourses], (courses) => {
    if (!courses) {
      return undefined
    }

    return courses.find(
      (course) => course.id === courseId && course.version === version,
    )
  })

export const selectLatestDraftCourse = (courseId?: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !courses) {
      return undefined
    }

    return findLatestCourseVersionForStatus(
      courses,
      courseId,
      CourseStatus.Draft,
    )
  })

export const selectIsEditingPublishedCourse = (courseId: string) =>
  createSelector(
    [selectLatestPublishedCourse(courseId), selectLatestDraftCourse(courseId)],
    (published, draft) => {
      return published !== undefined && draft !== undefined
    },
  )

function findLatestCourseVersionForStatus(
  courses: CourseDto[],
  courseId: string,
  status: CourseStatus,
) {
  return getLatestCourseVersion(
    courses.filter((course) => course.status === status),
    courseId,
  )
}

function getLatestCourseVersion(
  courses: CourseDto[],
  courseId: string,
): CourseDto | undefined {
  const coursesById = courses.filter((course) => course.id === courseId)

  if (coursesById.length === 0) {
    return undefined
  }

  return coursesById.reduce((prev, curr) =>
    (curr.version ?? 1) > (prev.version ?? 1) ? curr : prev,
  )
}

export const selectCourses = (state: RootState): CourseDto[] | undefined => {
  if (
    state.coursesMenu.courses === "pending" ||
    state.coursesMenu.courses === "rejected"
  ) {
    return undefined
  }

  return state.coursesMenu.courses
}

export const selectCoursesMainPageTab = (state: RootState) =>
  state.coursesMenu.tab

export const selectPublishedCourses = createSelector(
  selectCourses,
  (courses) => {
    if (!courses) {
      return undefined
    }

    return courses.filter((course) => course.status === CourseStatus.Published)
  },
)

export const selectLatestVersionsPublishedCourses = createSelector(
  selectPublishedCourses,
  (courses) => {
    if (!courses) {
      return undefined
    }

    const latestCoursesByVersion = courses.reduce((acc, curr) => {
      const course = acc[curr.id]
      if (!course || course.version < curr.version) {
        acc[curr.id] = curr
      }
      return acc
    }, {} as { [key: string]: CourseDto })

    return Object.values(latestCoursesByVersion)
  },
)

export const selectHasChanges = (courseId?: string) =>
  createSelector(
    [selectLatestDraftCourse(courseId), selectLatestPublishedCourse(courseId)],
    (draft, published) => {
      if (!draft || !published) {
        return false
      }

      return !areCourseDtosEqual(draft, published)
    },
  )

export const selectCoursesFetchingStatus = (state: RootState) => {
  if (state.coursesMenu.courses === "pending") {
    return "loading"
  } else if (state.coursesMenu.courses === "rejected") {
    return "error"
  } else {
    return "idle"
  }
}

export const selectCoursesSortOption = (state: RootState) =>
  state.coursesMenu.sortOption

export const selectDependentSyllabuses = (courseId: string | undefined) =>
  createSelector([selectLastVersionSyllabuses], (syllabuses) => {
    return syllabuses
      .filter((syllabus) => {
        return syllabus.units.some((unit) =>
          unit.courseDescriptors.some((cd) => cd.courseId === courseId),
        )
      })
      .map((syllabus) => syllabus.id)
  })
