import { Chip, Typography } from "@mui/material"

export const LanguageChip = (props: { language: string }) => {
  const { language } = props
  const flagForLanguage = (language: string) => {
    switch (language.toLowerCase()) {
      case "english":
        return "🇬🇧"
      case "spanish":
        return "🇪🇸"
      case "french":
        return "🇫🇷"
      case "german":
        return "🇩🇪"
      case "italian":
        return "🇮🇹"
      case "russian":
        return "🇷🇺"
      case "ja":
        return "🇯🇵"
      case "herbrew":
        return "🇮🇱"
      default:
        return ""
    }
  }
  const label = (
    <Typography variant="body3">
      <span style={{ fontSize: "13px" }}>{flagForLanguage(language)}</span>
      {" " + language.charAt(0).toUpperCase() + language.slice(1)}
    </Typography>
  )

  return <Chip label={label} size="small" variant="outlined" />
}
