import { useAppDispatch, useAppSelector } from "@app/hooks"
import Droppable from "@cmp/droppable"
import { selectIsTopicOpen } from "@features/courseEditor/courseEditorSelectors"
import {
  elementAdded,
  liveEventAdded,
  topicEdited,
  topicOpenStateChanged,
} from "@features/courseEditor/courseEditorSlice"
import {
  selectIsTopicInvalid,
  selectPublishValidationsForItem,
} from "@features/courseEditor/courseValidationsSelectors"
import {
  Topic,
  TopicItemValidationErrorsEnum,
} from "@masterschool/course-builder-api"
import {
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { useSearchParams } from "react-router-dom"
import appTheme from "../../theme/appTheme"
import AddItemButton from "../components/addItemButton"
import EditorTextField from "../components/editorTextField"
import CourseSyllabusElementView from "./courseSyllabusElementView"
import { CourseSyllabusLiveEventView } from "./courseSyllabusLiveEventsView"
import Placeholder from "./placeholder"
import TopicHeader from "./topicHeader"
import { doesTopicContainMandatoryItem } from "./topicValidations"

function TopicView(props: { topic: Topic; availableTopics?: Topic[] }) {
  const { topic } = props
  const isOpen = useAppSelector(selectIsTopicOpen(topic.id))
  const dispatch = useAppDispatch()
  const elementsNumber = topic.elements.length ?? 0
  const liveEventsNumber = topic.liveEvents.length ?? 0
  const placeholder =
    elementsNumber + liveEventsNumber === 0 ? <Placeholder /> : <></>

  const errors = useAppSelector(selectPublishValidationsForItem(topic.id))
  const hasMandatoryItem = doesTopicContainMandatoryItem(topic)

  return (
    <Box
      id={topic.id}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        borderRadius: "8px",
        backgroundColor: "#F5F5F0",
        boxSizing: "border-box",
        position: "relative",
        bgcolor: appTheme.palette.primary.contrast,
      }}
    >
      <TopicHeader
        topic={topic}
        onClick={() =>
          dispatch(
            topicOpenStateChanged({
              topicId: topic.id,
            }),
          )
        }
        isTopicOpen={isOpen}
      />
      <Collapse in={isOpen} sx={{ width: "100%" }}>
        <Box width="100%" padding="16px">
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginBottom: "16px",
            }}
          >
            <EditorTextField
              label="Topic name"
              onChange={(e) => {
                dispatch(
                  topicEdited({
                    editStepId: window.crypto.randomUUID(),
                    topicId: topic.id,
                    key: "title",
                    value: e.target.value,
                  }),
                )
              }}
              value={topic.title}
              error={errors?.includes("MISSING_TITLE") && topic.title === ""}
              helperText={
                errors?.includes("MISSING_TITLE")
                  ? "This field is required"
                  : ""
              }
              multiline
              maxRows={2}
            />
            <Droppable
              id={`${topic.id}-elements`}
              items={topic.elements.map((e, index) => {
                return {
                  identifier: e.item.id,
                  element: (
                    <CourseSyllabusElementView
                      element={e}
                      topic={topic}
                      index={index}
                      availableTopics={props.availableTopics}
                    />
                  ),
                }
              })}
              placeholder={placeholder}
              style={(isDragging) => ({
                opacity: isDragging ? 0 : 1,
              })}
            />
            <Box>
              {topic.liveEvents.length ? (
                <Typography variant="body2_sb" pb="8px">
                  Live Sessions
                </Typography>
              ) : (
                <></>
              )}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Droppable
                  id={`${topic.id}-liveEvents`}
                  items={topic.liveEvents.map((le, index) => {
                    return {
                      identifier: le.id,
                      element: (
                        <CourseSyllabusLiveEventView
                          liveEvent={le}
                          topic={topic}
                          index={index}
                          availableTopics={props.availableTopics}
                        />
                      ),
                    }
                  })}
                  placeholder={<></>}
                  style={(isDragging) => ({
                    opacity: isDragging ? 0 : 1,
                  })}
                />
              </Box>
            </Box>

            {errors?.includes("MISSING_MANDATORY_ITEM") &&
              !hasMandatoryItem && (
                <FormHelperText
                  error
                  sx={{
                    alignSelf: "center",
                    textTransform: "none",
                  }}
                >
                  Add at least one mandatory item, quiz or survey.
                </FormHelperText>
              )}
          </Box>
          <AddItemFormButton topic={topic} />
        </Box>
      </Collapse>
    </Box>
  )
}

export default TopicView

const AddItemFormButton = (props: { topic: Topic }) => {
  const { topic } = props
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const isInvalidBecauseMissingElements =
    useAppSelector(
      selectIsTopicInvalid(topic, TopicItemValidationErrorsEnum.ELEMENTS),
    ) && topic.elements.length === 0

  return (
    <FormControl sx={{ display: "flex" }}>
      <AddItemButton
        onElementClick={(type) => {
          const element = SyllabusElementFactory.makeElement(type)
          dispatch(
            elementAdded({
              editStepId: window.crypto.randomUUID(),
              topicId: topic.id,
              element: element,
            }),
          )
          searchParams.set("elementId", element.item.id)
          setSearchParams(searchParams, {
            replace: true,
          })
        }}
        onLiveEventClick={() => {
          const liveEvent = SyllabusElementFactory.makeLiveEvent()
          dispatch(
            liveEventAdded({
              editStepId: window.crypto.randomUUID(),
              topicId: topic.id,
              liveEvent: liveEvent,
            }),
          )
          searchParams.set("elementId", liveEvent.id)
          setSearchParams(searchParams, {
            replace: true,
          })
        }}
        sx={{
          color: isInvalidBecauseMissingElements
            ? appTheme.palette.error.main
            : undefined,
        }}
        iconSx={{
          stroke: isInvalidBecauseMissingElements
            ? appTheme.palette.error.main
            : appTheme.palette.icon.black,
        }}
      />
      <FormHelperText
        error={isInvalidBecauseMissingElements}
        variant="standard"
        sx={{
          textTransform: "none",
          paddingTop: "4px",
          paddingLeft: "14px",
        }}
      >
        {isInvalidBecauseMissingElements
          ? "Topic must have at least one element"
          : ""}
      </FormHelperText>
    </FormControl>
  )
}
