import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import { UserDto } from "@masterschool/course-builder-api"
import { ProgramStaffMember } from "@clients/programStaffClient"
import { Role } from "@features/login/loginSlice"
import appIcons from "@utils/appIcons"
import appTheme from "../../../theme/appTheme"
import { useAppSelector } from "@app/hooks"
import roleDisplayNames from "../../../account-management/roleDisplayName"
import { selectCanAccessAccountManagementTab } from "@features/ui/uiSelector"

export const EmployeeSelector = (props: {
  employees: UserDto[]
  selectedEmployee: UserDto | undefined
  onEmployeeChange: (employee: UserDto | undefined) => void
  programCurrentStaff: ProgramStaffMember[]
  loading: boolean
}) => {
  const {
    employees,
    selectedEmployee,
    onEmployeeChange,
    programCurrentStaff,
    loading,
  } = props
  const currentStaffIds = programCurrentStaff.map((staff) => staff.userClientId)

  return (
    <FormControl>
      <Autocomplete
        value={selectedEmployee ?? null}
        options={employees}
        loading={loading}
        noOptionsText={<NoOptionsLabel />}
        onChange={(_, newSelection) =>
          onEmployeeChange(newSelection ?? undefined)
        }
        placeholder={"Search by name or email"}
        autoComplete
        fullWidth
        renderOption={(props, employee) =>
          renderEmployeeOption(props, employee, currentStaffIds)
        }
        getOptionLabel={(employee) =>
          `${employee.firstName} ${employee.lastName} (${employee.email})`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="medium"
            label="Search by name or email"
            InputLabelProps={{
              sx: { fontSize: "16px" },
            }}
          />
        )}
      />
    </FormControl>
  )
}

function renderEmployeeOption(
  props: React.HTMLAttributes<HTMLLIElement>,
  employee: UserDto,
  currentStaffIds: string[],
): JSX.Element {
  const isAlreadyInProgram = currentStaffIds.includes(employee.id)

  const EmployeeRoleChips = (props: { employee: UserDto }) => {
    const relevantRoles = props.employee.roles.filter((role) =>
      [Role.Instructor, Role.ProgramManager, Role.Mentor].includes(
        role as Role,
      ),
    )
    return (
      <Box display="flex" gap="8px" sx={{ maxWidth: "268px" }} flexWrap="wrap">
        {relevantRoles.map((role) => (
          <Chip
            label={roleDisplayNames(role)}
            size="small"
            variant="outlined"
            key={role}
            sx={{ width: "fit-content" }}
          />
        ))}
      </Box>
    )
  }
  return (
    <MenuItem
      {...props}
      key={employee.id}
      disabled={currentStaffIds.includes(employee.id)}
    >
      <Box
        display="flex"
        overflow="hidden"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="236px">
          <Typography
            maxWidth="236px"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {employee.firstName} {employee.lastName}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            maxWidth="236px"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {employee.email}
          </Typography>
        </Box>
        {!isAlreadyInProgram && <EmployeeRoleChips employee={employee} />}
        {isAlreadyInProgram && (
          <Box display="flex" gap="8px">
            <SvgIcon
              component={appIcons.check}
              inheritViewBox
              sx={{
                fill: "none",
                width: "20px",
                height: "20px",
                stroke: appTheme.palette.icon.black,
              }}
            />
            <Typography>Added to program</Typography>
          </Box>
        )}
      </Box>
    </MenuItem>
  )
}

const NoOptionsLabel = () => {
  const canAccessAccountManager = useAppSelector(
    selectCanAccessAccountManagementTab,
  )

  return (
    <Typography>
      No results. Try adding the employee in the{" "}
      {canAccessAccountManager ? (
        <a
          href="/account-management"
          style={{ color: appTheme.palette.text.primary }}
        >
          Account manager
        </a>
      ) : (
        "Account manager"
      )}{" "}
      tab.
    </Typography>
  )
}
