import { useAppSelector } from "@app/hooks"
import { RootState } from "@app/store"
import PendingDataPresenter from "@cmp/loaders/pendingDataPresenter"
import { ProgramDto } from "@masterschool/course-builder-api"
import PendingDataState from "@utils/pendingDataState"
import { useParams } from "react-router-dom"
import useFetchProgramData from "../../useFetchProgramData"
import { Box } from "@mui/material"
import ProgramHeader from "./programHeader"
import ProgramView from "../layout/programView"
import ProgramSidebar from "@cmp/sidebar/programSidebar"
import appTheme from "../../../theme/appTheme"
import { createSelector } from "@reduxjs/toolkit"

function ProgramViewContainer() {
  const { programId } = useParams()
  useFetchProgramData(programId)
  const program = useAppSelector(selectProgram(programId))

  return (
    <PendingDataPresenter
      state={program}
      component={ProgramViewPresenter}
      loadedComponents={`program ${programId}`}
    />
  )
}

function ProgramViewPresenter(props: { data: ProgramDto }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        bgcolor: appTheme.palette.background.paper,
      }}
    >
      <ProgramHeader program={props.data} />
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <ProgramSidebar />
        <ProgramView program={props.data} />
      </Box>
    </Box>
  )
}

const selectProgram = (programId?: string) =>
  createSelector(
    [
      (state: RootState) => state.program.programs,
      (state: RootState) => state.program.loadingState,
    ],
    (programs, loadingState): PendingDataState<ProgramDto> =>
      programState(programs, programId, loadingState),
  )

const programState = (
  programs: ProgramDto[],
  programId: string | undefined,
  loadingState: "idle" | "loading" | "failed",
): PendingDataState<ProgramDto> => {
  if (!programId) {
    return { status: "idle" }
  }
  const program = programs.find((p) => p.id === programId)
  if (program) {
    return { status: "success", data: program }
  }

  switch (loadingState) {
    case "idle":
      return {
        status: "idle",
      }
    case "loading":
      return { status: "pending" }
    case "failed":
      return { status: "error" }
    default:
      return { status: "idle" }
  }
}

export default ProgramViewContainer
