import { useEffect } from "react"

type KeyboardEventCallback = (event: KeyboardEvent) => void

function useKeyDown(
  key: string,
  callback: KeyboardEventCallback,
  ctrlKey = false,
): void {
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent): void {
      if (event.key === key && (!ctrlKey || event.ctrlKey || event.metaKey)) {
        callback(event)
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [key, callback, ctrlKey])
}

export default useKeyDown
