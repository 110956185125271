import { Box } from "@mui/material"
import { ReactNode } from "react"
import appTheme from "../../theme/appTheme"

export const FULL_SCREEN_COMPONENT_Z_INDEX = 10

const FullScreenComponent = (props: { children: ReactNode }) => {
  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      top={0}
      left={0}
      width="100%"
      height="100vh"
      gap="8px"
      sx={{
        backgroundColor: appTheme.palette.background.default,
        zIndex: FULL_SCREEN_COMPONENT_Z_INDEX,
      }}
    >
      {props.children}
    </Box>
  )
}

export default FullScreenComponent
