import { useAppDispatch, useAppSelector } from "@app/hooks"
import FullScreenLoadingComponent from "@cmp/loaders/fullScreenLoadingComponent"
import {
  selectEditableSyllabus,
  selectLastPublishedSyllabus,
  selectLastSyllabusVersion,
} from "@features/syllabus/syllabusSelectors"
import {
  createNewDraftVersion,
  fetchSyllabus,
  unmounted,
} from "@features/syllabusEditor/syllabusEditorSlice"
import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"
import { Box } from "@mui/material"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import appTheme from "../theme/appTheme"
import { SyllabusView } from "./syllabusView"
import { SyllabusViewHeader } from "./syllabusViewHeader"
import { useEffect } from "react"

export function SyllabusViewContainer() {
  const { syllabusId } = useParams()
  const [searchParams] = useSearchParams()
  const version = searchParams.get("version")
  const navigate = useNavigate()

  const syllabus = useAppSelector(selectEditableSyllabus)
  const lastPublishedSyllabus = useAppSelector(
    selectLastPublishedSyllabus(syllabusId ?? ""),
  )
  const versionToDisplay = version
    ? parseInt(version)
    : lastPublishedSyllabus?.version

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!syllabusId) return
    dispatch(fetchSyllabus({ syllabusId, version: versionToDisplay }))
    return () => {
      dispatch(unmounted())
    }
  }, [versionToDisplay, syllabusId, dispatch])

  const lastVersion = useAppSelector(selectLastSyllabusVersion(syllabusId))

  if (!syllabus) {
    return <FullScreenLoadingComponent loadedComponents="" />
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <SyllabusViewHeader
        syllabus={syllabus}
        onEditClicked={async () => {
          if (lastVersion?.status === SyllabusDtoStatusEnum.Published) {
            await dispatch(createNewDraftVersion(syllabus.id))
          }
          navigate(`/syllabus-edit/${syllabus.id}`)
        }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          overflow: "hidden",
          marginLeft: "auto",
          marginRight: "auto",
          bgcolor: appTheme.palette.eTypes.sand15,
        }}
      >
        <SyllabusView syllabus={syllabus} viewMode="inline" />
      </Box>
    </Box>
  )
}
