import { useAppDispatch } from "@app/hooks"
import { onboardStaffFormChanged } from "@features/account-management/accountManagementSlice"
import { Role } from "@features/login/loginSlice"
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"

export function OnboardStaffRoleSelection(props: { isAdmin: boolean }) {
  const { isAdmin } = props
  const dispatch = useAppDispatch()
  const [checkedState, setCheckedState] = useState({
    instructor: false,
    mentor: false,
    programOwner: false,
    accountManager: false,
    careerSuccessAdvisor: false,
    professionalMentor: false,
  })
  const handleChange = (event: {
    target: { name: string; checked: boolean }
  }) => {
    setCheckedState({
      ...checkedState,
      [event.target.name]: event.target.checked,
    })
  }

  useEffect(() => {
    const roles: Role[] = []
    if (checkedState.instructor) roles.push(Role.Instructor)
    if (checkedState.mentor) roles.push(Role.Mentor)
    if (checkedState.programOwner) roles.push(Role.ProgramManager)
    if (checkedState.accountManager) roles.push(Role.AccountManager)
    if (checkedState.careerSuccessAdvisor) roles.push(Role.CareerSuccessAdvisor)
    if (checkedState.professionalMentor) roles.push(Role.ProfessionalMentor)
    dispatch(
      onboardStaffFormChanged({
        key: "additionalRoles",
        value: roles,
      }),
    )
  }, [checkedState, dispatch])

  return (
    <FormGroup row>
      <TooltipedCheckbox
        tooltipText="Instructor will be registered in the campus as an instructor."
        checkboxIsChecked={checkedState.instructor}
        checkboxName="instructor"
        checkboxLabel="Instructor"
        onCheckboxChange={handleChange}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedState.mentor}
            onChange={handleChange}
            name="mentor"
          />
        }
        label="Mentor"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedState.careerSuccessAdvisor}
            onChange={handleChange}
            name="careerSuccessAdvisor"
          />
        }
        label="Career Success Advisor"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedState.professionalMentor}
            onChange={handleChange}
            name="professionalMentor"
          />
        }
        label="Professional Mentor"
      />
      <TooltipedCheckbox
        tooltipText="Program owner will have access to the programs dashboard in the LMS"
        checkboxIsChecked={checkedState.programOwner}
        checkboxName="programOwner"
        checkboxLabel="Program owner"
        onCheckboxChange={handleChange}
      />
      {isAdmin && (
        <TooltipedCheckbox
          tooltipText="Account Manager will have access to the account management section in the LMS"
          checkboxIsChecked={checkedState.accountManager}
          checkboxName="accountManager"
          checkboxLabel="Account manager"
          onCheckboxChange={handleChange}
        />
      )}
    </FormGroup>
  )
}

function TooltipedCheckbox(props: {
  tooltipText: string
  checkboxIsChecked: boolean
  checkboxName: string
  checkboxLabel: string
  onCheckboxChange: (
    onChange: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
}) {
  const {
    tooltipText,
    checkboxIsChecked,
    checkboxName,
    checkboxLabel,
    onCheckboxChange,
  } = props
  return (
    <Tooltip
      enterDelay={750}
      title={
        <Typography
          variant="body2"
          sx={{
            padding: "8px 16px",
            color: "white",
          }}
        >
          {tooltipText}
        </Typography>
      }
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxIsChecked}
            onChange={onCheckboxChange}
            name={checkboxName}
          />
        }
        label={checkboxLabel}
      />
    </Tooltip>
  )
}
