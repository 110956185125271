import { useAppDispatch, useAppSelector } from "@app/hooks"
import { CourseClient } from "@clients/courseClient"
import GenericDialog from "@cmp/genericDialog"
import { fetchCourses } from "@features/coursesMenu/coursesMenuSlice"
import {
  selectLatestCourseVersion,
  selectLatestPublishedCourse,
} from "@features/coursesMenu/coursesSelectors"
import {
  CourseDto,
  CourseMetadataDto,
  CourseStatus,
} from "@masterschool/course-builder-api"
import { CircularProgress, Divider, Stack } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import CoursePreview from "../../editor/coursePreview"
import { VersionsMenu } from "@cmp/version-management/versionsMenu"
import { RevertButton } from "@cmp/version-management/revertButton"
import { editCourseVersion } from "@features/syllabusEditor/syllabusEditorSlice"
import { selectVersionIdInEditedCourse } from "../../editor/syllabus/units/content-editor/unit.editor.selectors"

const CourseVersionHistoryPopup = (props: {
  courseId: string
  onClose: () => void
  context: "courseEditor" | "syllabusEditor"
}) => {
  const { courseId, onClose, context } = props

  const {
    versionsHistory,
    setSelectedVersion,
    selectedVersion,
    revertToSelectedVersion,
    shouldVerifyBeforeRevert,
  } = useCourseVersionHistoryManager(courseId, context)

  const versionInUsedByEditedSyllabus = useAppSelector((state) =>
    selectVersionIdInEditedCourse(state, courseId),
  )
  const showVersionInUse = context === "syllabusEditor"

  const courseLastVersion = useAppSelector(selectLatestCourseVersion(courseId))
  const activeVersion =
    context === "syllabusEditor"
      ? versionInUsedByEditedSyllabus
      : courseLastVersion?.version
  const showRevertButton = selectedVersion !== activeVersion

  return (
    <>
      <GenericDialog
        open
        onClose={onClose}
        title="Version history"
        size="lg"
        fullHeight
        disableContentPadding
        content={
          <Stack overflow="auto" height={1}>
            <Divider />
            <Stack direction="row" flex={1} overflow="hidden">
              <CourseVersionsMenu
                versionsHistory={versionsHistory ?? []}
                onVersionSelected={(versionNumber) =>
                  setSelectedVersion(versionNumber)
                }
                selectedCourseVersion={selectedVersion}
                courseId={courseId}
                versionInUseBySyllabus={
                  showVersionInUse ? versionInUsedByEditedSyllabus : undefined
                }
              />
              <SelectedVersionPanel
                courseId={courseId}
                selectedVersion={selectedVersion}
                showRevertButton={showRevertButton}
                shouldVerifyBeforeRevert={shouldVerifyBeforeRevert}
                onRevertClicked={revertToSelectedVersion}
              />
            </Stack>
          </Stack>
        }
      />
    </>
  )
}

function CourseVersionsMenu(props: {
  versionsHistory: CourseMetadataDto[] | undefined
  onVersionSelected: (versionNumber: number) => void
  selectedCourseVersion: number | undefined
  courseId: string
  versionInUseBySyllabus?: number | undefined
}) {
  const {
    versionsHistory,
    selectedCourseVersion,
    onVersionSelected,
    courseId,
    versionInUseBySyllabus,
  } = props
  const lastPublishedVersion = useAppSelector(
    selectLatestPublishedCourse(courseId),
  )
  return (
    <VersionsMenu
      versionsHistory={versionsHistory ?? []}
      onVersionSelected={(versionNumber) => onVersionSelected(versionNumber)}
      selectedVersion={selectedCourseVersion}
      lastPublishedVersion={lastPublishedVersion?.version}
      versionInUseBySyllabus={versionInUseBySyllabus}
    />
  )
}

function SelectedVersionPanel(props: {
  courseId: string
  selectedVersion: number | undefined
  showRevertButton: boolean
  shouldVerifyBeforeRevert: boolean
  onRevertClicked: () => Promise<void>
}) {
  const {
    courseId,
    selectedVersion,
    showRevertButton,
    shouldVerifyBeforeRevert,
    onRevertClicked,
  } = props
  const [displayedCourse, setDisplayedCourse] = useState<CourseDto | undefined>(
    undefined,
  )

  useEffect(() => {
    setDisplayedCourse(undefined)
    if (selectedVersion === undefined) return
    CourseClient.getCourseByVersion(courseId, selectedVersion).then(
      (course) => {
        setDisplayedCourse(course)
      },
    )
  }, [courseId, selectedVersion])

  const isLoading =
    selectedVersion === undefined || displayedCourse === undefined

  return (
    <Stack
      direction="row"
      flex={1}
      alignItems={isLoading ? "center" : "start"}
      justifyContent="center"
      overflow="auto"
    >
      {isLoading && <CircularProgress />}
      {!isLoading && displayedCourse && (
        <Stack px={6} alignItems="center" flex={1} pt={"45px"}>
          {showRevertButton && (
            <RevertButton
              doRevert={onRevertClicked}
              shouldVerify={shouldVerifyBeforeRevert}
            />
          )}
          <CoursePreview course={displayedCourse} />
        </Stack>
      )}
    </Stack>
  )
}

const useFetchCourseVersionsHistory = (
  courseId: string,
  context: "courseEditor" | "syllabusEditor",
) => {
  const [versionsHistory, setVersionsHistory] = useState<
    CourseMetadataDto[] | undefined
  >(undefined)

  const fetch = useCallback(() => {
    CourseClient.listCourseVersions(courseId).then((versions) => {
      const sorted = versions.sort((a, b) => b.version - a.version)
      const sortedWithoutDraft = sorted.filter(
        (version) => version.status !== CourseStatus.Draft,
      )
      const versionHistoryToDisplay =
        context === "syllabusEditor" ? sortedWithoutDraft : sorted
      setVersionsHistory(versionHistoryToDisplay)
    })
  }, [courseId, context])

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    versionsHistory,
    refetchVersionsHistory: fetch,
  }
}

const useChangeCourseVersionInSyllabus = (courseId: string) => {
  const dispatch = useAppDispatch()
  const revert = (targetCourseVersionId: number) => {
    dispatch(
      editCourseVersion({
        courseId,
        targetCourseVersionId,
      }),
    )
  }

  return {
    shouldVerifyBeforeRevert: false,
    revert,
  }
}

const useRevertCourseToVersion = (courseId: string) => {
  const dispatch = useAppDispatch()
  const courseLastVersion = useAppSelector(selectLatestCourseVersion(courseId))
  const isLastVersionPublished = courseLastVersion
    ? courseLastVersion.status === CourseStatus.Published
    : false

  const revert = async (targetVersion: number) => {
    return CourseClient.revertToVersion(courseId, targetVersion).then(() => {
      dispatch(fetchCourses())
    })
  }
  return {
    shouldVerifyBeforeRevert: !isLastVersionPublished,
    revert,
  }
}

const useCourseVersionHistoryManager = (
  courseId: string,
  context: "courseEditor" | "syllabusEditor",
) => {
  const [selectedVersion, setSelectedVersion] = useState<number | undefined>(
    undefined,
  )
  const { versionsHistory, refetchVersionsHistory } =
    useFetchCourseVersionsHistory(courseId, context)

  useEffect(() => {
    if (!versionsHistory) {
      setSelectedVersion(undefined)
    } else {
      setSelectedVersion(versionsHistory[0].version)
    }
  }, [versionsHistory])

  const courseRevertParams = useRevertCourseToVersion(courseId)
  const syllabusRevertParams = useChangeCourseVersionInSyllabus(courseId)

  const shouldVerifyBeforeRevert =
    context === "syllabusEditor"
      ? syllabusRevertParams.shouldVerifyBeforeRevert
      : courseRevertParams.shouldVerifyBeforeRevert

  const revertToSelectedVersion = async () => {
    if (selectedVersion === undefined) return
    if (context === "syllabusEditor") {
      await syllabusRevertParams.revert(selectedVersion)
    } else {
      await courseRevertParams.revert(selectedVersion)
      setSelectedVersion(undefined)
      refetchVersionsHistory()
    }
  }

  return {
    versionsHistory,
    setSelectedVersion,
    selectedVersion,
    revertToSelectedVersion,
    shouldVerifyBeforeRevert,
  }
}

export default CourseVersionHistoryPopup
