import { useAppSelector } from "@app/hooks"
import GenericTable from "@cmp/genericTable"
import { SelectedStatusChip } from "@cmp/syllabusStatusChip"
import {
  Syllabus,
  selectSyllabusesMainPageTab,
} from "@features/syllabus/syllabusSelectors"
import { SyllabusIdentifierEnumeration } from "@features/syllabus/syllabusesMenuSlice"
import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"
import { Chip, Typography } from "@mui/material"
import { domainToDisplayName } from "@utils/domainUtils"
import { startDateToCohortStyleDate } from "@utils/syllabusTags"
import { useNavigate } from "react-router-dom"
import SyllabusOptionsMenu from "./syllabusOptionsMenu"

function SyllabusesTable(props: { syllabuses: Syllabus[] }) {
  const { syllabuses } = props
  const navigate = useNavigate()

  const onSyllabusClicked = (
    id: string,
    isActive: boolean,
    status: SyllabusDtoStatusEnum,
  ) => {
    const destination =
      isActive || status !== SyllabusDtoStatusEnum.Draft
        ? `/syllabus/${id}`
        : `/syllabus-edit/${id}`
    navigate(destination)
  }

  const searchText = useAppSelector(
    (state) => state.search.syllabusFilters.text,
  )

  if (syllabuses.length === 0) {
    return <EmptySyllabusesDescriptorsContainer />
  }

  return (
    <GenericTable
      highlightText={searchText}
      data={syllabuses}
      onClick={(syllabus) => {
        onSyllabusClicked(syllabus.id, syllabus.isActive, syllabus.status)
      }}
      keys={[
        "name",
        "domain",
        "programStartDate",
        "programType",
        "status",
        "id",
      ]}
      renderHeader={{
        name: () => "Syllabus Name",
        programStartDate: () => "Cohort",
        programType: () => "Type",
        id: () => undefined,
      }}
      render={{
        domain: (domain) => domain && domainToDisplayName(domain),
        programStartDate: (startDate) =>
          startDate && (
            <Chip
              label={startDateToCohortStyleDate(startDate)}
              size="small"
              sx={{
                width: "fit-content",
              }}
            />
          ),
        status: (_, syllabus) => (
          <SelectedStatusChip
            syllabusId={syllabus.id}
            status={syllabus.status}
            isActive={syllabus.isActive}
          />
        ),
        programType: (programType) =>
          programType && (
            <Chip
              label={programType.replace("_", "-")}
              size="small"
              sx={{
                width: "fit-content",
                textTransform: "capitalize",
              }}
            />
          ),
        id: (_, syllabus) => <SyllabusOptionsMenu syllabus={syllabus} />,
      }}
    />
  )
}

function EmptySyllabusesDescriptorsContainer() {
  const isFiltersApplied = useAppSelector(
    (state) =>
      state.search.syllabusFilters.text !== "" ||
      state.search.syllabusFilters.advanceFilters.length > 0,
  )
  const tab = useAppSelector(selectSyllabusesMainPageTab)

  return (
    <Typography
      variant="body1"
      sx={{
        width: "100%",
        textAlign: "center",
        padding: "24px",
        color: "text.disabled",
      }}
    >
      {isFiltersApplied
        ? "We couldn't find any syllabuses that match your search"
        : placeholderText(tab)}
    </Typography>
  )
}

function placeholderText(tabIdentifier: SyllabusIdentifierEnumeration) {
  switch (tabIdentifier) {
    case SyllabusIdentifierEnumeration.All:
      return "There are no syllabuses yet"
    case SyllabusIdentifierEnumeration.Active:
      return "No syllabuses have been activated yet"
    case SyllabusIdentifierEnumeration.Draft:
      return "You don't have any drafts"
    case SyllabusIdentifierEnumeration.Archived:
      return "No syllabuses have been archived yet"
  }
}

export default SyllabusesTable
