import { useAppDispatch } from "@app/hooks"
import { Alert, AlertTitle, Box, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import appTheme from "../../../../theme/appTheme"
import { preprocessMultipleStudentsRegistration } from "@features/program/programSliceThunks"

function StudentsCSVFileDropzone(props: { classId: string }) {
  const [isDragging, setIsDragging] = useState(false)
  const [invalidFile, setInvalidFile] = useState(false)
  const dispatch = useAppDispatch()

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    setIsDragging(false)

    const files = e.dataTransfer?.files

    if (files && files.length > 0) {
      handleFile(files[0])
    }
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files

    if (files && files.length > 0) {
      handleFile(files[0])
    }
  }

  const fileReader = new FileReader()

  fileReader.onload = (event) => {
    const content = event.target?.result as string
    const parsedStudents = parseCSV(content)

    if (parsedStudents) {
      const uniqueStudents = parsedStudents?.filter(
        (student, index, self) =>
          index === self.findIndex((s) => s.email === student.email),
      )

      dispatch(
        preprocessMultipleStudentsRegistration({
          classId: props.classId,
          students: uniqueStudents,
        }),
      )
      setInvalidFile(false)
    } else {
      setInvalidFile(true)
    }
  }

  function handleFile(file: File) {
    if (file.name.toLowerCase().endsWith(".csv")) {
      fileReader.readAsText(file)
    } else {
      setInvalidFile(true)
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {invalidFile && (
        <Alert
          severity="error"
          icon={
            <SvgIcon
              component={appIcons.infoCircle}
              inheritViewBox
              sx={{ fill: "none", width: 20, height: 20, rotate: "180deg" }}
            />
          }
        >
          <AlertTitle>Formatting error</AlertTitle>
          The column names in the CSV file don't match the required format.
        </Alert>
      )}
      <Box
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "32px 24px",
          gap: "8px",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: isDragging
            ? appTheme.palette.grey[400]
            : appTheme.palette.grey[50],
          border: `1px dashed ${appTheme.palette.grey[300]}`,
          cursor: "pointer",
          transition: "all 0.3s ease",
          height: "136px",
        }}
        component="label"
      >
        <input type="file" onChange={handleFileChange} hidden />
        <SvgIcon
          inheritViewBox
          component={appIcons.uploadCloud01}
          sx={{
            fill: "none",
            stroke: appTheme.palette.icon.black,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">
            Drag & drop or click to upload a file
          </Typography>
          <Typography variant="body3" color="text.secondary">
            Download the template below.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

function parseCSV(
  csvContent: string,
): Array<{ email: string; firstName: string; lastName: string }> | undefined {
  const allowedCharactersRegex = /[^a-zA-Z0-9!#$%&'*+/=?^_`{|}~@.,\n\r -]/g
  const lines = csvContent
    .replace(allowedCharactersRegex, "")
    .split(/\r|\r?\n/g)

  // Validate the header
  const header = lines[0].split(",").map((value) => value.trim())
  const emailIndex = header.indexOf("Email")
  const firstNameIndex = header.indexOf("First Name")
  const lastNameIndex = header.indexOf("Last Name")

  if (emailIndex === -1 || firstNameIndex === -1 || lastNameIndex === -1) {
    // Invalid header
    return undefined
  }

  return lines.slice(1).msCompactMap((line) => {
    const values = line.split(",")

    const email = values[emailIndex]?.trim()
    const firstName = values[firstNameIndex]?.trim()
    const lastName = values[lastNameIndex]?.trim()

    // Ensure none of the values are empty
    if (!email || !firstName || !lastName) {
      return undefined
    }

    return {
      email,
      firstName,
      lastName,
    }
  })
}

export default StudentsCSVFileDropzone
