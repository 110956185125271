import { useAppDispatch, useAppSelector } from "@app/hooks"
import { MentorClient, MentorDto } from "@clients/mentorClient"
import GenericDialog from "@cmp/genericDialog"
import { editStudentMentorPopupClosed } from "@features/program/programSlice"
import { fetchClasses } from "@features/program/programSliceThunks"
import { Autocomplete, MenuItem, TextField } from "@mui/material"
import { useEffect, useState } from "react"

function EditStudentMentorPopup() {
  const [mentor, setMentor] = useState<MentorDto | undefined>(undefined)
  const [didLoad, setDidLoad] = useState(false)
  const [mentors, setMentors] = useState<MentorDto[]>([])
  const dispatch = useAppDispatch()
  const model = useAppSelector(
    (state) => state.program.studentsPage.editStudentMentorPopup,
  )

  useEffect(() => {
    const matchingMentor = mentors.find((m) => m.id === model?.mentorId)
    setMentor(matchingMentor)
  }, [model, mentors])

  useEffect(() => {
    if (!didLoad) {
      MentorClient.listMentors().then((mentors) => {
        setMentors(
          mentors.sort((a, b) => a.firstName.localeCompare(b.firstName)),
        )
        setDidLoad(true)
      })
    }
  }, [didLoad])

  const onCTAClick = () => {
    if (!mentor || !model) return

    MentorClient.assignStudentToMentor(model.studentId, mentor.userClientId)
      .then(() => {
        dispatch(fetchClasses({ programId: model.programId }))
      })
      .then(() => {
        dispatch(editStudentMentorPopupClosed())
      })
  }

  if (!model) return null

  return (
    <GenericDialog
      open
      size="xs"
      onClose={() => {
        dispatch(editStudentMentorPopupClosed())
      }}
      title={`Assign ${model.studentName} to a mentor`}
      content={
        <Autocomplete
          value={mentor || null}
          options={mentors}
          onChange={(_, newSelection) =>
            setMentor(newSelection ? newSelection : undefined)
          }
          placeholder={"Select a mentor"}
          autoComplete
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          renderOption={(props, mentor) => {
            return (
              <MenuItem {...props} key={mentor.id} value={mentor.id}>
                {mentor.firstName} {mentor.lastName}
              </MenuItem>
            )
          }}
          getOptionLabel={(mentor) => `${mentor.firstName} ${mentor.lastName}`}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="medium"
              label="Mentor"
              InputLabelProps={{
                sx: { fontSize: "16px" },
              }}
            />
          )}
        />
      }
      buttons={[
        {
          type: "primary",
          text: "Assign mentor",
          disabled: !mentor,
          onClick: onCTAClick,
        },
      ]}
    />
  )
}

export default EditStudentMentorPopup
