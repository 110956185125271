import { useAppDispatch, useAppSelector } from "@app/hooks"
import CustomTooltip from "@cmp/customTooltip"
import {
  pickPublishedSyllabusPopupOpened,
  programStartSyllabusActionConfirmationPopupUpdate,
  suggestConnectClassesAfterDisconnectPopupUpdate,
} from "@features/program/programSlice"
import {
  selectProgram,
  selectProgramsSyllabusPage,
} from "@features/program/programSliceSelectors"
import {
  disconnectSyllabusFromProgram,
  editProgramClassesSyllabus,
} from "@features/program/programSliceThunks"
import {
  Syllabus,
  selectSyllabusIdToName,
} from "@features/syllabus/syllabusSelectors"
import {
  PopupType,
  popupItemClicked,
  showSuccessSnackbar,
} from "@features/ui/uiSlice"
import { ProgramClassDto, ProgramDto } from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSyllabusActionConfirmationPopup } from "../popups/syllabusActionConfirmationPopup"

const SyllabusCardActions = ({
  syllabus,
  classId,
  programId,
  isDefaultSyllabus,
  connectedClasses,
}: {
  syllabus: Syllabus
  classId: string | undefined
  programId: string
  isDefaultSyllabus: boolean
  connectedClasses: ProgramClassDto[]
}) => {
  const dispatch = useAppDispatch()
  const onPreviewInCampusClicked = () => {
    dispatch(
      popupItemClicked({
        type: PopupType.CampusSyllabusPreview,
        syllabus,
        programId: syllabus.programId,
      }),
    )
  }

  if (!isDefaultSyllabus && connectedClasses.length === 0) {
    return null
  }

  return (
    <Stack direction="row" gap={1} p={3} justifyContent="flex-end">
      <SyllabusCardActionsMenu
        syllabus={syllabus}
        programId={programId}
        classId={classId}
        isDefaultSyllabus={isDefaultSyllabus}
        connectedClasses={connectedClasses}
      />
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={onPreviewInCampusClicked}
      >
        Preview in campus
      </Button>
      <Button
        LinkComponent={Link}
        {...{ to: `/syllabus/${syllabus.id}`, target: "_blank" }}
        variant="outlined"
        color="primary"
        size="small"
      >
        Open
      </Button>
    </Stack>
  )
}

const SyllabusCardActionsMenu = ({
  syllabus,
  classId,
  programId,
  isDefaultSyllabus,
  connectedClasses,
}: {
  syllabus: Syllabus
  classId: string | undefined
  programId: string
  isDefaultSyllabus: boolean
  connectedClasses: ProgramClassDto[]
}) => {
  const program = useAppSelector((state) => selectProgram(state, programId))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!program || (!isDefaultSyllabus && connectedClasses.length === 0)) {
    return null
  }

  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        sx={{ px: "5px" }}
      >
        <SvgIcon
          component={appIcons.dotsHorizontal}
          inheritViewBox
          sx={{
            colors: "inherit",
            stroke: "currentColor",
          }}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        {isDefaultSyllabus ? (
          <DefaultSyllabusMenuItems
            syllabus={syllabus}
            program={program}
            classId={classId}
            connectedClasses={connectedClasses}
            closeMenu={handleClose}
          />
        ) : (
          <ClassSyllabusMenuItems
            classes={connectedClasses}
            program={program}
            closeMenu={handleClose}
          />
        )}
      </Menu>
    </div>
  )
}

const DefaultSyllabusMenuItems = ({
  syllabus,
  program,
  classId,
  connectedClasses,
  closeMenu,
}: {
  syllabus: Syllabus
  program: ProgramDto
  classId: string | undefined
  connectedClasses: ProgramClassDto[]
  closeMenu: () => void
}) => {
  const dispatch = useAppDispatch()
  const confirmationPopup = useSyllabusActionConfirmationPopup()
  const isProgramStarted = new Date(program.startDate) < new Date()
  const classView = !!classId

  const openSelectSyllabusPopup = (classIds: string[] | undefined) => {
    dispatch(
      pickPublishedSyllabusPopupOpened({
        programId: program.id,
        classIds,
        withConfirmationPopup: !classView,
      }),
    )
    closeMenu()
  }

  const onDisconnectSyllabusClicked = () => {
    confirmationPopup.open({
      action: {
        type: "disconnect",
        syllabusName: syllabus.name ?? "",
        classesCount: connectedClasses.length,
      },
      onConfirm: onDisconnectSyllabusConfirmed,
      onCancel: confirmationPopup.close,
    })
  }

  const onDisconnectSyllabusConfirmed = () => {
    confirmationPopup.setIsLoading(true)
    dispatch(disconnectSyllabusFromProgram(program.id)).finally(() => {
      confirmationPopup.close()
      closeMenu()
      connectedClasses.length > 0 &&
        dispatch(
          suggestConnectClassesAfterDisconnectPopupUpdate({
            isOpen: true,
            programId: program.id,
            classIds: connectedClasses.map((c) => c.id),
          }),
        )
    })
  }

  const onReplaceSyllabusClicked = () =>
    openSelectSyllabusPopup(classView ? [classId] : undefined)

  return (
    <>
      <WithProgramStartedConfirmationMenuItem
        onClick={onReplaceSyllabusClicked}
        programStartDate={program.startDate}
        action="replace"
      >
        Replace Syllabus
      </WithProgramStartedConfirmationMenuItem>
      {!classView && (
        <CustomTooltip
          title={
            "This syllabus cannot be disconnected " +
            "because the program has already started"
          }
          placement="left"
          leaveDelay={0}
          disableTooltip={!isProgramStarted}
        >
          <Box>
            <MenuItem
              disabled={isProgramStarted}
              onClick={onDisconnectSyllabusClicked}
            >
              <Typography>Disconnect syllabus</Typography>
            </MenuItem>
          </Box>
        </CustomTooltip>
      )}
    </>
  )
}

const ClassSyllabusMenuItems = ({
  classes,
  program,
  closeMenu,
}: {
  classes: ProgramClassDto[]
  program: ProgramDto
  closeMenu: () => void
}) => {
  const confirmationPopup = useSyllabusActionConfirmationPopup()
  const dispatch = useAppDispatch()
  const hasDefaultSyllabus = !!program.syllabusId
  const syllabusIdToName = useAppSelector(selectSyllabusIdToName())

  const openSelectSyllabusPopup = () => {
    dispatch(
      pickPublishedSyllabusPopupOpened({
        programId: program.id,
        classIds: classes.map((c) => c.id),
        withConfirmationPopup: false,
      }),
    )
    closeMenu()
  }

  const onRevertSyllabus = () => {
    confirmationPopup.setIsLoading(true)
    dispatch(
      editProgramClassesSyllabus({
        programId: program.id,
        syllabusId: undefined,
        classIds: classes.map((c) => c.id),
      }),
    ).finally(() => {
      confirmationPopup.close()
      closeMenu()
      dispatch(showSuccessSnackbar("Syllabus reverted to default"))
    })
  }

  const openRevertSyllabusConfirmation = () => {
    confirmationPopup.open({
      action: {
        type: "revert",
        defaultSyllabusName: syllabusIdToName[program.syllabusId ?? ""] ?? "",
      },
      onCancel: confirmationPopup.close,
      onConfirm: onRevertSyllabus,
    })
  }

  return (
    <>
      <WithProgramStartedConfirmationMenuItem
        programStartDate={program.startDate}
        onClick={openSelectSyllabusPopup}
        action="replace"
      >
        Replace Syllabus
      </WithProgramStartedConfirmationMenuItem>
      {hasDefaultSyllabus && (
        <WithProgramStartedConfirmationMenuItem
          programStartDate={program.startDate}
          onClick={openRevertSyllabusConfirmation}
          action="revert-to-default"
        >
          <Typography>Revert to default syllabus</Typography>
        </WithProgramStartedConfirmationMenuItem>
      )}
    </>
  )
}

const WithProgramStartedConfirmationMenuItem = ({
  onClick,
  children,
  programStartDate,
  action,
}: {
  onClick: () => void
  children: React.ReactNode
  programStartDate: string
  action: "replace" | "revert-to-default"
}) => {
  const { programStartSyllabusActionConfirmationPopup: model } = useAppSelector(
    selectProgramsSyllabusPage,
  )
  const [isClicked, setIsClicked] = useState(false)
  const dispatch = useAppDispatch()
  const isProgramStarted = new Date(programStartDate) < new Date()

  useEffect(() => {
    if (model.isOpen || !isClicked) {
      return
    }
    setIsClicked(false)
    if (model.decision === "confirm") {
      onClick()
      dispatch(
        programStartSyllabusActionConfirmationPopupUpdate({
          decision: undefined,
        }),
      )
    }
  }, [model.isOpen, model.decision, onClick, dispatch, isClicked])

  const onItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setIsClicked(true)
    if (isProgramStarted) {
      dispatch(
        programStartSyllabusActionConfirmationPopupUpdate({
          action,
          isOpen: true,
        }),
      )
    } else {
      onClick()
    }
  }

  return <MenuItem onClick={onItemClick}>{children}</MenuItem>
}

export default SyllabusCardActions
