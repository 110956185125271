import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import TitleTextField from "../../components/titleTextField"
import UrlTextField from "../../components/urlTextField"
import { useEditSurvey } from "../custom-hooks/useEditElementItem"
import { useSelectedSurvey } from "../custom-hooks/useSelectedElementItem"
import { Topic } from "@masterschool/course-builder-api"

function SurveyEditor(props: { topic: Topic }) {
  const { topic } = props
  const survey = useSelectedSurvey()
  const editSurvey = useEditSurvey()

  if (!survey) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <TitleTextField
          elementId={survey.id}
          value={survey.title}
          debounce
          onChange={(e) => {
            editSurvey(survey, "title", e.target.value, topic)
          }}
        />
        <TimeEstimationTextField
          elementId={survey.id}
          value={survey.estimatedDuration}
          debounce
          onChange={(e) => {
            editSurvey(
              survey,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
        />
        <UrlTextField
          elementId={survey.id}
          value={survey.url}
          debounce
          onChange={(e) => {
            editSurvey(survey, "url", e.target.value, topic)
          }}
        />
      </Box>
    </Box>
  )
}

export default SurveyEditor
