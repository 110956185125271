import { SortItemsButton } from "@cmp/buttons/sortItemsButton"
import PlusIcon from "@mui/icons-material/Add"
import { TabList } from "@mui/lab"
import TabContext from "@mui/lab/TabContext"
import { Box, Button, Tab, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
  TabIdentifierEnumeration,
  createCourse,
  sortAlphabeticallyClicked,
  sortChronologicallyClicked,
  tabSelected,
} from "@features/coursesMenu/coursesMenuSlice"
import {
  selectCoursesFetchingStatus,
  selectCoursesSortOption,
} from "@features/coursesMenu/coursesSelectors"
import { selectMainPageCourses } from "@features/search/searchSlice"
import { CourseSearchComponent } from "./courseSearchComponent"
import { CoursesDescriptorsContainer } from "./coursesDescriptorsContainer"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { ErrorComponent } from "@cmp/loaders/errorComponent"

export function CoursesMainPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const loadingStatus = useAppSelector(selectCoursesFetchingStatus)

  const courses = useAppSelector(selectMainPageCourses)
  const sortOption = useAppSelector(selectCoursesSortOption)

  const tab = useAppSelector((state) => state.coursesMenu.tab)

  const highlightedCourseId = useAppSelector(
    (state) => state.coursesMenu.highlightedCourseId,
  )

  const onCreateCourseClicked = () => {
    return dispatch(createCourse()).then((result) => {
      if (!result.payload) return
      navigate(`/course-edit/${result.payload.id}`)
    })
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        paddingX: "24px",
        paddingBottom: "48px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1240px",
          marginX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "80px",
            mb: "16px",
          }}
        >
          <Box
            display="flex"
            sx={{
              justifyContent: "space-between",
              width: "100%",
              mb: "16px",
            }}
          >
            <Typography variant="h4">Courses</Typography>
            {loadingStatus === "loading" && (
              <LoadingComponent loadedComponents="courses" />
            )}
            {loadingStatus === "error" && (
              <ErrorComponent loadedComponents="courses" />
            )}
          </Box>
          <CourseSearchComponent />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "32px",
            }}
          >
            <TabContext value={tab}>
              <TabList
                onChange={(event, value) => {
                  dispatch(tabSelected(value))
                }}
              >
                <Tab
                  label={TabIdentifierEnumeration.All}
                  value={TabIdentifierEnumeration.All}
                />
                <Tab
                  label={TabIdentifierEnumeration.Published}
                  value={TabIdentifierEnumeration.Published}
                />
                <Tab label={"Drafts"} value={TabIdentifierEnumeration.Draft} />
                <Tab
                  label={TabIdentifierEnumeration.Favorites}
                  value={TabIdentifierEnumeration.Favorites}
                />
                <Tab
                  label={TabIdentifierEnumeration.Archived}
                  value={TabIdentifierEnumeration.Archived}
                />
              </TabList>
            </TabContext>
            <Box display="flex" gap="8px" alignItems="center">
              <SortItemsButton
                sortOption={sortOption}
                chronologicalClicked={(sortOrder) =>
                  dispatch(sortChronologicallyClicked(sortOrder))
                }
                alphabeticClicked={(sortOrder) =>
                  dispatch(sortAlphabeticallyClicked(sortOrder))
                }
              />
              <Button
                variant="contained"
                startIcon={<PlusIcon />}
                onClick={onCreateCourseClicked}
                size="small"
              >
                Add course
              </Button>
            </Box>
          </Box>
        </Box>
        <CoursesDescriptorsContainer
          courses={courses ?? []}
          highlightCourseId={highlightedCourseId}
        />
      </Box>
    </Box>
  )
}
