// Since react-redux fails to load if global objects as "Array" are manipulated, "@masterschool/foundations" must be
// imported before the store is created. This is because "@masterschool/foundations" manipulates global objects.
import "@masterschool/foundations"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import appTheme from "./theme/appTheme"
import { ThemeProvider } from "@emotion/react"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={appTheme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)
