import { ProgramClassDto } from "@masterschool/course-builder-api"
import GenericTable from "@cmp/genericTable"
import { useNavigate } from "react-router-dom"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import appIcons from "@utils/appIcons"
import { selectSyllabusIdToName } from "@features/syllabus/syllabusSelectors"
import { useAppSelector } from "@app/hooks"
import { SvgIcon } from "@mui/material"

export const ProgramClassesTable = (props: {
  programId: string
  classes: ProgramClassDto[]
  onEditClassClick: (programClass: ProgramClassDto) => void
  onDeleteClassClick: (programClass: ProgramClassDto) => void
}) => {
  const { programId, classes, onEditClassClick, onDeleteClassClick } = {
    ...props,
  }
  const syllabusIdToNames = useAppSelector(selectSyllabusIdToName())
  const navigate = useNavigate()
  const goToStudents = (id: string) => {
    navigate(`/program/${programId}/${id}?tab=students`)
  }

  return (
    <GenericTable
      data={getData(classes, syllabusIdToNames)}
      onClick={(row) => goToStudents(row.id)}
      keys={["name", "numberOfStudents", "syllabusName", "id"]}
      renderHeader={{
        id: () => undefined,
      }}
      sx={{
        borderRadius: "4px",
        overflow: "hidden",
      }}
      render={{
        id: (classId) => {
          return (
            <ActionsMenu
              classId={classId}
              classes={classes}
              onEditClassClick={onEditClassClick}
              onDeleteClassClick={onDeleteClassClick}
            />
          )
        },
      }}
    />
  )
}

function getData(
  classes: ProgramClassDto[],
  syllabusToNameMapping: Record<string, string>,
) {
  return [...classes]
    .sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    )
    .map((c) => {
      return {
        name: c.name,
        numberOfStudents: c.students.length,
        syllabusName: c.syllabusId ? syllabusToNameMapping[c.syllabusId] : "",
        id: c.id,
      }
    })
}

function ActionsMenu(props: {
  classId: string
  classes: ProgramClassDto[]
  onEditClassClick: (arg: ProgramClassDto) => void
  onDeleteClassClick: (arg: ProgramClassDto) => void
}) {
  const { classId, classes, onEditClassClick, onDeleteClassClick } = {
    ...props,
  }
  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon
              component={appIcons.dotsHorizontal}
              inheritViewBox
              sx={{ color: "primary.main" }}
            />
          ),
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "edit",
            children: "Edit class",
            onClick: () => {
              const programClass = classes.find((c) => c.id === classId)
              if (!programClass) {
                return
              }
              onEditClassClick(programClass)
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "delete",
            children: "Remove class",
            onClick: () => {
              const programClass = classes.find((c) => c.id === classId)
              if (!programClass) {
                return
              }
              onDeleteClassClick(programClass)
            },
          },
        },
      ]}
    />
  )
}
