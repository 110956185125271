import { Chip } from "@mui/material"

function ProgramTypeChip(props: { type: string }) {
  const text = (() => {
    switch (props.type) {
      case "part_time":
        return "Part Time"
      case "full_time":
        return "Full Time"
      default:
        return props.type
    }
  })()

  const outlined = (() => {
    switch (props.type) {
      case "part_time":
        return true
      case "full_time":
        return false
      default:
        return false
    }
  })()

  return (
    <Chip
      label={text}
      size="small"
      variant={outlined ? "outlined" : "filled"}
    />
  )
}

export default ProgramTypeChip
