import { Button } from "@mui/material"
import appIcons from "@utils/appIcons"
import EmptyStateContainer from "../components/emptyStateContainer"

export const EmptyClassesView = (props: { onAddNewClassClick: () => void }) => {
  return (
    <EmptyStateContainer
      title="No classes yet"
      subtitle="Add and manage classes for this program"
      icon={appIcons.bank}
      action={
        <Button variant="contained" onClick={props.onAddNewClassClick}>
          Create new class
        </Button>
      }
    />
  )
}
