import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  TextField,
} from "@mui/material"
import { useEffect, useState } from "react"

export function MultipleOptionsSelector<
  T extends { id: number | string } | string,
>(props: {
  label: string
  options: T[]
  selectedOptions: (T extends { id: number } ? number : string)[]
  onChange: (
    selectedOptions: (T extends { id: number } ? number : string)[],
  ) => void
  getOptionLabel: (option: T) => string
  renderTag: (
    option: T,
    index: number,
    getTagProps: AutocompleteRenderGetTagProps,
  ) => JSX.Element
  required?: boolean
  error?: boolean
  helperText?: string
  disabled?: boolean
  disableCloseOnSelect?: boolean
  getOptionDisabled?: (option: T) => boolean
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
  ) => JSX.Element
  noOptionsText?: string | React.ReactNode
}) {
  const { label, options, selectedOptions } = props
  const { onChange, getOptionLabel, renderTag, required, error } = props
  const { helperText, disabled, renderOption, getOptionDisabled } = props
  const { disableCloseOnSelect, noOptionsText } = props
  const [optionsDict, setOptionsDict] = useState<Record<string, T>>({})

  useEffect(() => {
    const dict = options.reduce((acc, option) => {
      acc[typeof option === "string" ? option : option.id] = option
      return acc
    }, {} as Record<string, T>)
    setOptionsDict(dict)
  }, [options])

  return (
    <Autocomplete
      value={selectedOptions}
      options={options.map((option) =>
        typeof option === "string" ? option : option.id,
      )}
      onChange={(_, newSelection) => {
        onChange(newSelection as typeof selectedOptions)
      }}
      placeholder={"none"}
      fullWidth
      multiple
      noOptionsText={noOptionsText}
      disableCloseOnSelect={disableCloseOnSelect}
      autoComplete
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          size="medium"
          required={required}
          error={error}
          helperText={helperText}
          InputLabelProps={{
            sx: { fontSize: "16px" },
          }}
        />
      )}
      getOptionLabel={(optionId) => {
        const option = options.find(
          (o) => (typeof o === "string" ? o : o.id) === optionId,
        )
        return option ? getOptionLabel(option) : "unknown"
      }}
      renderOption={
        renderOption
          ? (props, optionId) => renderOption(props, optionsDict[optionId])
          : undefined
      }
      getOptionDisabled={(option) =>
        getOptionDisabled ? getOptionDisabled(option as T) : false
      }
      renderTags={(value, getTagProps) =>
        value.map((optionId, index) => {
          const option = options.find(
            (o) => (typeof o === "string" ? o : o.id) === optionId,
          ) as T
          return renderTag(option, index, () => getTagProps({ index }))
        })
      }
    />
  )
}
