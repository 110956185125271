import {
  CodioLesson,
  CodioProject,
  CourseSyllabus,
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  DatacampLesson,
  MasterschoolLesson,
  NotionLesson,
  OtherLesson,
  RunestoneLesson,
  Survey,
  Test,
  Topic,
  TryHackmeLesson,
  TypeformLesson,
  UdacityLesson,
} from "@masterschool/course-builder-api"

export function durationFormatting(duration: number) {
  const hours = Math.floor(duration / 60)
  const minutes = Math.floor(duration % 60)
  return `${hours}h ${minutes}m`
}

export function courseSyllabusDuration(syllabus: CourseSyllabus) {
  return syllabus.topics.flatMap(topicDuration).msSum()
}

export function topicDuration(topic: Topic) {
  return topic.elements
    .filter((e) => !e.item.isExtraCurricular)
    .map(elementDuration)
    .msSum()
}

export function elementDuration(element: CourseSyllabusElement) {
  switch (element.type) {
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
      return masterschoolLessonDuration(element.item as MasterschoolLesson)
    case CourseSyllabusElementTypeEnum.DatacampLesson:
      return (element.item as DatacampLesson).estimatedDuration
    case CourseSyllabusElementTypeEnum.NotionLesson:
      return (element.item as NotionLesson).estimatedDuration
    case CourseSyllabusElementTypeEnum.OtherLesson:
    case CourseSyllabusElementTypeEnum.CourseraLesson:
      return (element.item as OtherLesson).estimatedDuration
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
      return (element.item as TryHackmeLesson).estimatedDuration
    case CourseSyllabusElementTypeEnum.UdacityLesson:
      return (element.item as UdacityLesson).estimatedDuration
    case CourseSyllabusElementTypeEnum.CodioLesson:
      return (element.item as CodioLesson).estimatedDuration
    case CourseSyllabusElementTypeEnum.Test:
      return (element.item as Test).estimatedDuration
    case CourseSyllabusElementTypeEnum.CodioProject:
      return (element.item as CodioProject).estimatedDuration
    case CourseSyllabusElementTypeEnum.Survey:
      return (element.item as Survey).estimatedDuration
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return (element.item as RunestoneLesson).estimatedDuration
    case CourseSyllabusElementTypeEnum.TypeformLesson:
      return (element.item as TypeformLesson).estimatedDuration
    case CourseSyllabusElementTypeEnum.EmptyProject:
    case CourseSyllabusElementTypeEnum.EmptyLesson:
      return 0
  }
}

export function masterschoolLessonDuration(lesson: MasterschoolLesson) {
  return Math.round(lesson.tasks.map((t) => t.estimatedDuration).msSum())
}
