import {
  ComponentsOverrides,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles"

const MuiAlertOverrides: {
  variants?: ComponentsVariants<Theme>["MuiAlert"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiAlert"]
} = {
  variants: [
    {
      props: { variant: "standard", severity: "warning" },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.warning.alert?.background,
        color: theme.palette.warning.alert?.content,
      }),
    },
    {
      props: { variant: "standard", severity: "info" },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.info.alert?.background,
        color: theme.palette.info.alert?.content,
      }),
    },
  ],
}

export { MuiAlertOverrides }
