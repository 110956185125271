import { ErrorComponent } from "./errorComponent"
import FullScreenComponent from "../layouts/fullScreenComponent"

const FullScreenErrorComponent = (props: { loadedComponents: string }) => {
  return (
    <FullScreenComponent>
      <ErrorComponent loadedComponents={props.loadedComponents} />
    </FullScreenComponent>
  )
}

export default FullScreenErrorComponent
