import ProgramStartSyllabusActionConfirmationPopup from "./programStartSyllabusActionConfirmationPopup"
import SelectSyllabusPopup from "./selectSyllabusPopup"
import SuggestConnectClassesAfterDisconnectPopup from "./suggestConnectClassesAfterDisconnectPopup"
import SyllabusActionConfirmationPopup from "./syllabusActionConfirmationPopup"

const SyllabusPagePopupsPresenter = () => {
  return (
    <>
      <SelectSyllabusPopup />
      <ProgramStartSyllabusActionConfirmationPopup />
      <SyllabusActionConfirmationPopup />
      <SuggestConnectClassesAfterDisconnectPopup />
    </>
  )
}

export default SyllabusPagePopupsPresenter
