import { ProgramDto } from "@masterschool/course-builder-api"
import { Box } from "@mui/material"
import appIcons from "@utils/appIcons"
import ProgramInfoCard from "./programInfoCard"
import { stringAsCamelCase } from "@utils/syllabusTags"
import { domainToDisplayName } from "@utils/domainUtils"
import WidgetCard from "./widgets/widgetCard"
import { useAppDispatch } from "@app/hooks"
import { editProgramPopupOpened } from "@features/program/programSlice"
import EditProgramPopup from "../../menu/editProgramPopup"

function ProgramInfo(props: { program: ProgramDto }) {
  const dispatch = useAppDispatch()

  const programLanguage = props.program.language.replace(/\b\w/g, (match) =>
    match.toUpperCase(),
  )
  return (
    <WidgetCard
      title="General information"
      onEdit={() =>
        dispatch(
          editProgramPopupOpened({
            programId: props.program.id,
          }),
        )
      }
    >
      <Box
        sx={{
          padding: "24px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ProgramInfoCard
          icon={appIcons.data}
          title="Domain"
          value={domainToDisplayName(props.program.domain)}
        />
        <ProgramInfoCard
          icon={appIcons.bank}
          title="Start date"
          value={new Date(props.program.startDate).toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
            timeZoneName: "short",
          })}
        />
        <ProgramInfoCard
          icon={appIcons.calendar}
          title="Type"
          value={stringAsCamelCase(props.program.type)}
        />
        <ProgramInfoCard
          icon={appIcons.globe}
          title="Language"
          value={programLanguage}
        />
      </Box>
      <EditProgramPopup />
    </WidgetCard>
  )
}

export default ProgramInfo
