import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import TitleTextField from "../components/titleTextField"
import UrlTextField from "../components/urlTextField"
import {
  Topic,
  CourseSyllabusLiveEventSessionTypeEnum as LiveSessionTypeEnum,
} from "@masterschool/course-builder-api"
import useSelectedLiveEvent from "./custom-hooks/useSelectedLiveEvent"
import { selectPublishValidationsForItem } from "../../features/courseEditor/courseValidationsSelectors"
import useEditLiveEvent from "./custom-hooks/useEditLiveEvent"
import { useAppSelector } from "@app/hooks"

export function LiveEventEditor(props: { topic: Topic }) {
  const { topic } = props
  const liveEvent = useSelectedLiveEvent()
  const errors = useAppSelector(selectPublishValidationsForItem(liveEvent?.id))
  const isInvalidMaterialsUrl =
    errors?.includes("MISSING_MATERIALS_URL") && liveEvent?.materialsUrl !== ""
  const isInvalidInstructionUrl =
    errors?.includes("INVALID_INSTRUCTION_URL") &&
    liveEvent?.instructionUrl !== ""
  const editLiveEvent = useEditLiveEvent()
  if (!liveEvent) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        bgcolor: "white",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "16px",
          }}
        >
          <TitleTextField
            elementId={liveEvent.id}
            label="Session name"
            value={liveEvent.title}
            debounce
            onChange={(e) => {
              editLiveEvent(liveEvent, "title", e.target.value, topic)
            }}
          />
        </Box>
        <LiveEventTypeSelection
          topic={topic}
          sessionType={liveEvent.sessionType}
        />
        <UrlTextField
          elementId={liveEvent.id}
          value={liveEvent.materialsUrl}
          debounce
          missingUrl={false}
          invalidUrl={isInvalidMaterialsUrl}
          label="Materials"
          onChange={(e) => {
            const newValue = e.target.value
            editLiveEvent(liveEvent, "materialsUrl", newValue, topic)
          }}
        />
        <TextField
          defaultValue={liveEvent.description}
          onChange={(event) => {
            editLiveEvent(liveEvent, "description", event.target.value, topic)
          }}
          rows={3}
          multiline
          fullWidth
          label="Description"
          size="small"
          InputProps={{ sx: { fontSize: "14px", padding: "16px" } }}
          InputLabelProps={{ sx: { fontSize: "14px" } }}
        />
        <UrlTextField
          elementId={liveEvent.id}
          value={liveEvent.instructionUrl}
          debounce
          missingUrl={false}
          invalidUrl={isInvalidInstructionUrl}
          label="Instructional Recommendations"
          onChange={(e) => {
            const newValue = e.target.value
            editLiveEvent(liveEvent, "instructionUrl", newValue, topic)
          }}
        />
      </Box>
    </Box>
  )
}

function LiveEventTypeSelection({
  topic,
  sessionType,
}: {
  topic: Topic
  sessionType: LiveSessionTypeEnum
}) {
  const editLiveEvent = useEditLiveEvent()
  const liveEvent = useSelectedLiveEvent()
  const allowedTypes = [
    LiveSessionTypeEnum.Lesson,
    LiveSessionTypeEnum.MorningOpening,
    LiveSessionTypeEnum.SocialActivity,
  ]

  const onChange = (newSessionType: string) => {
    if (liveEvent) {
      editLiveEvent(liveEvent, "sessionType", newSessionType, topic)
    }
  }

  const liveEventSessionTypeToText = (sessionType: LiveSessionTypeEnum) => {
    switch (sessionType) {
      case LiveSessionTypeEnum.Lesson:
        return "Lesson"
      case LiveSessionTypeEnum.MorningOpening:
        return "Morning Opening"
      case LiveSessionTypeEnum.SocialActivity:
        return "Social Activity"
    }
  }

  return (
    <FormControl
      sx={{
        width: "100%",
      }}
      size="small"
    >
      <InputLabel
        sx={{
          fontSize: "17px",
        }}
      >
        Session type
      </InputLabel>
      <Select
        label="Session type"
        value={sessionType}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      >
        {allowedTypes.map((type) => (
          <MenuItem value={type} key={type}>
            {liveEventSessionTypeToText(type)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
