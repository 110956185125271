import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  editRolesPopupAccepted,
  editRolesPopupClosed,
  roleCheckboxToggled,
} from "@features/account-management/accountManagementSlice"
import { selectIsAdmin } from "@features/login/loginSelectors"
import { Role } from "@features/login/loginSlice"
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import usePrevious from "@utils/hooks/usePrevious"
import appTheme from "../theme/appTheme"
import roleDisplayNames from "./roleDisplayName"

function EditRolesDialog() {
  const model = useAppSelector(
    (state) => state.accountManagement.editRolesPopup,
  )
  const previousModel = usePrevious(model, {
    saveOnlyDefined: true,
  })
  const dispatch = useAppDispatch()
  const roleOptions = [
    Role.MasterschoolEmployee,
    Role.Instructor,
    Role.Mentor,
    Role.ProgramManager,
    Role.SchoolMasterAdmin,
    Role.CareerSuccessAdvisor,
    Role.ProfessionalMentor,
    Role.SalesTeamLead,
  ]
  const isAdmin = useAppSelector(selectIsAdmin)
  if (isAdmin) {
    roleOptions.push(Role.AccountManager, Role.LmsAdmin)
  }
  const unselectedMentor =
    previousModel?.user.roles.includes(Role.Mentor) &&
    !model?.selectedRoles.includes(Role.Mentor)

  return (
    <GenericDialog
      open={model !== undefined}
      onClose={() => dispatch(editRolesPopupClosed(undefined))}
      size="sm"
      title={`Edit ${(model ?? previousModel)?.user.firstName} ${
        (model ?? previousModel)?.user.lastName
      }'s roles`}
      content={
        <FormGroup>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              maxWidth: "100%",
            }}
          >
            {roleOptions.map((role) => (
              <FormControlLabel
                checked={(model?.selectedRoles ?? []).includes(role)}
                key={role}
                control={<Checkbox />}
                label={roleDisplayNames(role)}
                onChange={() => {
                  dispatch(roleCheckboxToggled({ role }))
                }}
              />
            ))}
          </Box>
          {model?.requestStatus === "error" && (
            <Typography color={appTheme.palette.error.main}>
              Something went wrong, please refresh and try again
            </Typography>
          )}
          {unselectedMentor && <RemovingMentorRoleWarning />}
        </FormGroup>
      }
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: () => dispatch(editRolesPopupClosed(undefined)),
        },
        {
          text: "Save",
          type: "primary",
          onClick: () => dispatch(editRolesPopupAccepted()),
          loading: model?.requestStatus === "pending",
          disabled: areArraysEqual(
            model?.selectedRoles ?? [],
            model?.user.roles ?? [],
          ),
        },
      ]}
    />
  )
}

function areArraysEqual<T>(a: T[], b: T[]): boolean {
  return a.length === b.length && a.every((v, i) => v === b[i])
}

const RemovingMentorRoleWarning = () => {
  return (
    <Alert
      icon={
        <SvgIcon
          component={appIcons.alertTriangle}
          inheritViewBox
          sx={{ fill: "none", width: 20, height: 20 }}
        />
      }
      severity="warning"
    >
      Removing the mentor role will unassign any students from this staff
      member.
    </Alert>
  )
}

export default EditRolesDialog
