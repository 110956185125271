import { useEffect } from "react"

type EventCallback<T extends keyof WindowEventHandlersEventMap> = (
  event: WindowEventHandlersEventMap[T],
) => void

const useWindowEvent = <T extends keyof WindowEventHandlersEventMap>(
  event: T,
  callback: EventCallback<T>,
): void => {
  useEffect(() => {
    // Creating a different handleEvent function for each event is necessary for making sure that the removed event
    // listener is the same as the added one.
    const handleEvent = (event: WindowEventHandlersEventMap[T]) => {
      callback(event)
    }

    window.addEventListener(event, callback)

    return () => {
      window.removeEventListener(event, handleEvent)
    }
  }, [event, callback])
}

export default useWindowEvent
