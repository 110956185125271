import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ProjectDto } from "@masterschool/course-builder-api"
import { ProjectClient } from "@clients/projectClient"
import { showErrorSnackbar } from "@features/ui/uiSlice"

export type ProjectsTabState = {
  projects: ProjectDto[]
  loadingState: "idle" | "loading" | "failed"
}

export const getInitialState: () => ProjectsTabState = () => {
  return {
    projects: [],
    loadingState: "idle",
  }
}

const projectsTabSlice = createSlice({
  name: "projectsTab",
  initialState: getInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loadingState = "loading"
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.loadingState = "failed"
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.loadingState = "idle"
        state.projects = action.payload
      })
  },
})

export const fetchProjects = createAsyncThunk(
  "projectsTab/fetchProjects",
  async (_, thunkAPI) => {
    return ProjectClient.listProjects().catch((e) => {
      thunkAPI.dispatch(showErrorSnackbar("Failed to fetch projects"))
      throw e
    })
  },
)

export default projectsTabSlice.reducer
