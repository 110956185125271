import { CampusClient } from "./campusClient"

export type MenteeDto = {
  clientId: string
  email: string
  firstName: string
  lastName: string
}

export type MentorDto = {
  id: string
  userClientId: string
  firstName: string
  lastName: string
  email: string
  mentees: MenteeDto[]
}

export class MentorClient {
  static async listMentors(): Promise<MentorDto[]> {
    return CampusClient.get<MentorDto[]>(`mentor/list`)
  }

  static async assignStudentToMentor(
    studentId: string,
    mentorClientId: string,
  ) {
    return CampusClient.put<MentorDto>(`mentor/assign`, {
      userClientIds: [studentId],
      mentorClientId,
    })
  }

  static async assignStudentsToMentor(
    studentIDs: string[],
    mentorClientId: string,
  ) {
    return CampusClient.put<MentorDto>(`mentor/assign`, {
      userClientIds: studentIDs,
      mentorClientId,
    })
  }

  static async unassignStudentsFromMentor(
    studentIDs: string[],
    mentorClientId: string,
  ) {
    return CampusClient.delete<MentorDto>(`mentor/unassign`, {
      userClientIds: studentIDs,
      mentorClientId,
    })
  }
}
