import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles"

const MuiListItemTextOverrides: {
  defaultProps?: ComponentsProps["MuiListItemText"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiListItemText"]
  variants?: ComponentsVariants["MuiListItemText"]
} = {
  defaultProps: {
    primaryTypographyProps: { variant: "body2" },
  },
}

export { MuiListItemTextOverrides }
