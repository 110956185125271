import { Button, ButtonProps, Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"

const EmptyWidgetCard = (props: CardProps) => {
  const { message } = props
  return (
    <Stack
      bgcolor="primary.contrast"
      border="1px solid"
      borderColor="other.divider"
      borderRadius={2}
      gap={2}
      p={3}
      alignItems="center"
    >
      <Typography variant="body2" color="text.secondary">
        {message}
      </Typography>
      {props.actionProps ? (
        <Button
          variant="outlined"
          size="small"
          startIcon={
            <SvgIcon
              component={appIcons.plus}
              inheritViewBox
              sx={{ fill: "none", stroke: "currentcolor" }}
            />
          }
          {...props.actionProps}
        />
      ) : (
        props.actionElement
      )}
    </Stack>
  )
}

type CardProps = { message: string } & Action

type Action =
  | { actionProps: ButtonProps; actionElement?: never }
  | { actionProps?: never; actionElement: React.ReactNode }

export default EmptyWidgetCard
