import { ComponentsOverrides, Theme } from "@mui/material/styles"

const MuiDialogOverrides: {
  styleOverrides?: ComponentsOverrides<Theme>["MuiDialog"]
} = {
  styleOverrides: {
    paper: () => ({
      borderRadius: "2px",
    }),
  },
}

export { MuiDialogOverrides }
