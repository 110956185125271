import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { useEffect, useState } from "react"
import { FeatureFlag, FeatureFlagClient } from "@clients/featureFlagClient"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  editFeatureFlagsPopupAccepted,
  editFeatureFlagsPopupClosed,
} from "@features/account-management/accountManagementSlice"
import GenericDialog from "@cmp/genericDialog"

function EditUserFeatureFlagsDialog() {
  const model = useAppSelector(
    (state) => state.accountManagement.editFeatureFlagsPopup,
  )
  const isOpen = model !== undefined
  const userId = model?.userId

  const dispatch = useAppDispatch()
  const onClose = () => {
    dispatch(editFeatureFlagsPopupClosed())
  }
  const [availableFlags, setAvailableFlags] = useState<string[] | undefined>()
  const [editedUserFlags, setEditedUserFlags] = useState<FeatureFlag[]>([])

  useEffect(() => {
    if (!isOpen) return
    FeatureFlagClient.listFlags().then((flags) => {
      setAvailableFlags(flags)
    })
  }, [isOpen])

  useEffect(() => {
    setEditedUserFlags(model?.flags ?? [])
  }, [model])
  if (!isOpen || !userId) {
    return null
  }

  return (
    <GenericDialog
      open
      size="sm"
      onClose={onClose}
      title={`Select Feature Flags for user`}
      content={
        <FormGroup>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              maxWidth: "80%",
            }}
          >
            {availableFlags?.map((flagName) => {
              const flagValue = editedUserFlags.find(
                (f) => f.flag === flagName && f.enabled,
              )
              const sourceString =
                flagValue?.source && flagValue?.source !== "user"
                  ? ` (enabled for ${flagValue.source})`
                  : ""
              return (
                <FormControlLabel
                  checked={flagValue !== undefined && flagValue.enabled}
                  key={flagName}
                  control={<Checkbox />}
                  label={
                    mapCamelCaseStringToTitleCaseString(flagName) + sourceString
                  }
                  disabled={sourceString !== ""}
                  onChange={() => {
                    setEditedUserFlags((prev) => {
                      const newFlags = [...prev]
                      const flagIndex = newFlags.findIndex(
                        (f) => f.flag === flagName,
                      )
                      if (flagIndex === -1) {
                        newFlags.push({
                          flag: flagName,
                          enabled: true,
                          source: "user",
                        })
                      } else {
                        newFlags[flagIndex] = {
                          ...newFlags[flagIndex],
                          enabled: !newFlags[flagIndex].enabled,
                        }
                      }
                      return newFlags
                    })
                  }}
                />
              )
            })}
          </Box>
        </FormGroup>
      }
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: onClose,
        },
        {
          text: "Update Flags",
          type: "primary",
          onClick: () => {
            const flags = {}
            editedUserFlags.forEach((f) => {
              Object.assign(flags, { [f.flag]: f.enabled })
            })
            dispatch(editFeatureFlagsPopupAccepted({ userId, flags }))
          },
        },
      ]}
    />
  )
}

export default EditUserFeatureFlagsDialog

function mapCamelCaseStringToTitleCaseString(str: string): string {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
}
