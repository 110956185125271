import { Box, CircularProgress, Typography } from "@mui/material"

export const LoadingComponent = (props: { loadedComponents: string }) => {
  return (
    <Box display="flex" flexDirection="row" gap="16px" alignItems="center">
      <CircularProgress color={"secondary"} />
      <Typography variant="body1_sb">
        Loading {props.loadedComponents}...
      </Typography>
    </Box>
  )
}
