import { RootState } from "@app/store"
import { ConsultantDto } from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"

export const selectConsultants = (state: RootState): ConsultantDto[] => {
  if (state.salesManagement.consultants.status !== "success") {
    return []
  }

  return state.salesManagement.consultants.data
}

const selectShifts = (state: RootState) => state.salesManagement.shifts

export const consultantsWithShiftsSelector = createSelector(
  [selectConsultants, selectShifts],
  (consultants, shifts) => {
    if (shifts.status !== "success") {
      return consultants.map((consultant) => ({
        ...consultant,
        shifts: [],
        status: shifts.status,
      }))
    }

    return consultants.map((consultant) => ({
      ...consultant,
      shifts: shifts.data[consultant.id] ?? [],
      status: shifts.status,
    }))
  },
)
export const availableShiftsSelector = (state: RootState) => {
  const shifts = state.salesManagement.createdShifts
  if (shifts.status !== "success") {
    return []
  }

  return shifts.data
}

export const selectConsultantShifts = createSelector(
  [
    (state: RootState) => state.salesManagement.shifts,
    (_, consultantId: string | undefined) => consultantId,
  ],
  (shifts, consultantId) => {
    if (shifts.status !== "success" || !consultantId) {
      return []
    }
    return shifts.data[consultantId] ?? []
  },
)

export const selectConsultant = createSelector(
  [
    (state: RootState) => state.salesManagement.consultants,
    (_, consultantId: string | undefined) => consultantId,
  ],
  (consultants, consultantId) => {
    if (consultants.status !== "success" || !consultantId) {
      return undefined
    }
    return consultants.data.find((consultant) => consultant.id === consultantId)
  },
)
