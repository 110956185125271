import { createSlice } from "@reduxjs/toolkit"
import {
  fetchUserForAccessToken,
  getAccessTokenFromLocalStorage,
} from "./accessTokenObserver"

export type User = {
  email: string
  firstName: string
  lastName: string
  photoURL: string | undefined
  roles: Role[]
  clientId: string
}

export enum Role {
  ProgramManager = "program_manager",
  MasterschoolEmployee = "ms_employee",
  Instructor = "instructor",
  AccountManager = "account_manager",
  LmsAdmin = "lms_admin",
  SchoolMasterAdmin = "school_master_admin",
  CareerSuccessAdvisor = "career_success_advisor",
  Mentor = "mentor",
  ProfessionalMentor = "professional_mentor",
  SalesTeamLead = "sales_team_lead",
}
export const ALL_ROLES = Object.values(Role)

export type LoginState =
  | {
      accessToken: string
      user: "not-loaded" | "loading" | "error" | User
    }
  | {
      accessToken: null
      user: "not-logged-in"
    }

export const getInitialState: () => LoginState = () => {
  const accessToken = getAccessTokenFromLocalStorage()
  if (accessToken) {
    return {
      user: "not-loaded",
      accessToken: accessToken,
    }
  } else {
    return {
      user: "not-logged-in",
      accessToken: null,
    }
  }
}

const loginSlice = createSlice({
  name: "login",
  initialState: getInitialState,
  reducers: {
    appLoaded: (state) => {
      return state
    },
    loadedUser: (state, action) => {
      return action.payload
    },
    fetchedAccessToken: (state, action) => {
      state.accessToken = action.payload
      state.user = "not-loaded"
    },
    logoutUser: (state) => {
      return {
        accessToken: null,
        user: "not-logged-in",
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserForAccessToken.pending, (state) => {
        state.user = "loading"
      })
      .addCase(fetchUserForAccessToken.rejected, (state) => {
        state.user = "error"
      })
      .addCase(fetchUserForAccessToken.fulfilled, (state, action) => {
        state.user = action.payload
      })
  },
})

export const { appLoaded, fetchedAccessToken, logoutUser } = loginSlice.actions
export default loginSlice.reducer
