import { CampusClient } from "./campusClient"

export type AssignedStudentDto = {
  clientId: string
}

export type CareerSuccessAdvisorDto = {
  userClientId: string
  firstName: string
  lastName: string
}

export class CareerSuccessAdvisorClient {
  static async listAdvisors(): Promise<CareerSuccessAdvisorDto[]> {
    return CampusClient.get<CareerSuccessAdvisorDto[]>(
      `career-hub/career-success-advisor/list`,
    )
  }

  static async assignStudentToAdvisor(ids: {
    studentUserClientId: string
    advisorUserClientId: string
  }) {
    const { studentUserClientId, advisorUserClientId } = ids
    return CampusClient.put<CareerSuccessAdvisorDto>(
      `career-hub/career-success-advisor/assign`,
      {
        studentUserClientId,
        advisorUserClientId,
      },
    )
  }
}
