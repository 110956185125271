import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { TabIdentifierEnumeration } from "@features/coursesMenu/coursesMenuSlice"
import { selectLatestVersionsPublishedCourses } from "@features/coursesMenu/coursesSelectors"
import { selectCourseFilters } from "@features/search/searchSlice"
import { selectEditableSyllabus } from "@features/syllabus/syllabusSelectors"
import {
  courseAdded,
  editUnitCourses,
  editUnitCoursesPopupClosed,
} from "@features/syllabusEditor/syllabusEditorSlice"
import { UnitCourseDescriptor } from "@masterschool/course-builder-api"
import { Box, Button, Stack, SvgIcon, Typography } from "@mui/material"
import { createSelector } from "@reduxjs/toolkit"
import appIcons from "@utils/appIcons"
import { useRef } from "react"
import { CourseSearchComponent } from "../../../../main/courseSearchComponent"
import { filterCourses } from "../../../../main/main-page-helpers"
import appTheme from "../../../../theme/appTheme"
import useSelectedUnit from "../../useSelectedUnit"
import CourseSelectionButton from "./courseSelectionButton"
import UnitCoursesList, { UnitCoursesListRef } from "./unitCoursesList"

const UnitCourseSelectionPopup = () => {
  const dispatch = useAppDispatch()
  const availableCoursesDescriptors: UnitCourseDescriptor[] = useAppSelector(
    selectAvailableCourses,
  ).map((course) => ({
    courseId: course.id,
    version: course.version,
  }))
  const unit = useSelectedUnit()
  const selectedCourses =
    useAppSelector(
      (state) => state.syllabusEditor.coursesSelectionPopup?.selectedCourses,
    ) ?? []
  const listRef = useRef<UnitCoursesListRef>(null)
  const isEmptyUnit = unit?.courseDescriptors?.length === 0

  return (
    <GenericDialog
      onClose={() => dispatch(editUnitCoursesPopupClosed())}
      title={isEmptyUnit ? "Choose courses" : "Manage Courses"}
      open
      size="lg"
      disableContentPadding
      fullHeight
      content={
        <Stack
          direction="row"
          flex={1}
          height={1}
          borderTop="1px solid"
          borderColor="other.outlineBorder"
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
            }}
          >
            <Box
              sx={{
                padding: "24px",
              }}
            >
              <CourseSearchComponent />
            </Box>
            <Box
              sx={{
                overflowY: "auto",
                height: "100%",
              }}
            >
              {availableCoursesDescriptors.map((courseDescriptor, index) => (
                <CourseSelectionButton
                  key={courseDescriptor.courseId}
                  courseDescriptor={courseDescriptor}
                  onAddClick={() => {
                    dispatch(courseAdded(courseDescriptor))
                    listRef.current?.scrollToBottom()
                  }}
                  disabled={selectedCourses
                    .map((cd: UnitCourseDescriptor) => cd.courseId)
                    .includes(courseDescriptor.courseId)}
                  sx={{
                    borderBottom:
                      index === availableCoursesDescriptors.length - 1
                        ? `1px solid ${appTheme.palette.other.divider}`
                        : undefined,
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "280px",
              maxWidth: "280px",
              backgroundColor: appTheme.palette.background.default,
              borderLeft: `1px solid ${appTheme.palette.other.outlineBorder}`,
              gap: "16px",
              padding: "24px 0px 24px 0px",
              overflowY: "hidden",
              overflowX: "visible",
            }}
          >
            <Typography
              variant="body1_sb"
              sx={{
                padding: "0px 24px 0px 24px",
              }}
            >
              {`Selected Courses (${unit?.courseDescriptors?.length ?? 0})`}
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <UnitCoursesList ref={listRef} />
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                margin: "0px 24px",
              }}
              onClick={() => {
                dispatch(editUnitCourses())
              }}
              startIcon={isEmptyUnit ? <PlusIcon /> : undefined}
            >
              {isEmptyUnit ? "Add to unit" : "Save changes"}
            </Button>
          </Box>
        </Stack>
      }
    />
  )
}

const PlusIcon = () => (
  <SvgIcon
    component={appIcons.plus}
    inheritViewBox
    sx={{
      stroke: "white",
    }}
  />
)

const selectAvailableCourses = createSelector(
  [
    selectLatestVersionsPublishedCourses,
    selectEditableSyllabus,
    selectCourseFilters,
    (state) => state.syllabusEditor.coursesSelectionPopup,
  ],
  (publishedCourses, syllabus, filters, popup) => {
    if (!publishedCourses || !syllabus) {
      return []
    }

    const filteredCourse = filterCourses(
      publishedCourses,
      [],
      filters.text,
      filters.advanceFilters,
      TabIdentifierEnumeration.All,
      [],
    )

    const coursesInSyllabus = syllabus.units
      .filter((unit) => unit.id !== popup?.unitId)
      .map((unit) =>
        unit.courseDescriptors.map(
          (courseDescriptor) => courseDescriptor.courseId,
        ),
      )
      .flat()

    return filteredCourse.filter(
      (course) => !coursesInSyllabus.includes(course.id),
    )
  },
)

export default UnitCourseSelectionPopup
