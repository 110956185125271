import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Divider, Tab } from "@mui/material"
import SyllabusPanelHeader from "./syllabusPanelHeader"
import UnitGeneralInfoEditor from "../units/details-editor/unitGeneralInfoEditor"
import useActiveSyllabusUnit from "./useActiveSyllabusUnit"
import { useSearchParams } from "react-router-dom"
import UnitContentEditor from "../units/content-editor/unitContentEditor"
import appTheme from "../../../theme/appTheme"

function SyllabusEditorPanel() {
  const unit = useActiveSyllabusUnit()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("unitTab") || "details"

  if (!unit) {
    return null
  }
  const isMissingUnitNumber = !unit.unitNumber || isNaN(unit.unitNumber)
  const isUnitGeneralInfoInvalid =
    isMissingUnitNumber && unit.courseDescriptors.length > 0

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        bgcolor: appTheme.palette.primary.contrast,
        boxShadow:
          "0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <SyllabusPanelHeader />
      <Box
        sx={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          display: "flex",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TabContext value={tab}>
            <TabList
              onChange={(e, value) => {
                searchParams.set("unitTab", value)
                setSearchParams(searchParams, {
                  replace: true,
                })
              }}
              sx={{
                pl: "16px",
              }}
            >
              <Tab
                label="Details"
                value="details"
                sx={{
                  pb: "0px",
                }}
                style={{ color: isUnitGeneralInfoInvalid ? "red" : undefined }}
              />
              <Tab
                label="Content"
                value="content"
                sx={{
                  pb: "0px",
                }}
              />
            </TabList>
            <Divider />
            <TabPanel value="details">
              <UnitGeneralInfoEditor />
            </TabPanel>
            <TabPanel value="content">
              <UnitContentEditor unit={unit} modeProps={{ mode: "edit" }} />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  )
}

export default SyllabusEditorPanel
