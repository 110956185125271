import { useAppDispatch } from "@app/hooks"
import { useSelector } from "react-redux"
import { selectPopup } from "@features/ui/uiSelector"
import { popupClosed } from "@features/ui/uiSlice"
import popupFactory from "./popupFactory"

function PopupPresenter() {
  const dispatch = useAppDispatch()

  const popup = useSelector(selectPopup)
  if (!popup) {
    return null
  }

  return popupFactory(popup, () => {
    dispatch(popupClosed())
  })
}

export default PopupPresenter
