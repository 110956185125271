import { useSearchParams } from "react-router-dom"

/*
 * Removes the given search params from the URL.
 */
function useRemoveSearchParams(paramsToRemove: string[], replace = false) {
  const [, setSearchParams] = useSearchParams()

  return () => {
    setSearchParams(
      (params) => {
        paramsToRemove.forEach((param) => {
          params.delete(param)
        })
        return params
      },
      {
        replace,
      },
    )
  }
}

export default useRemoveSearchParams
