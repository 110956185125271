import { useAppDispatch } from "@app/hooks"
import {
  OnboardStaffPopup,
  onboardStaffFormChanged,
} from "@features/account-management/accountManagementSlice"
import { Role } from "@features/login/loginSlice"
import { FormGroup, TextField } from "@mui/material"
import { useEffect } from "react"
import CountrySelect from "../../static-model-files/countrySelect"

export function OnboardStaffDetailsForm(props: {
  model: OnboardStaffPopup | undefined
}) {
  const { model } = props
  const dispatch = useAppDispatch()
  const isInstructor = (model?.form.additionalRoles ?? []).includes(
    Role.Instructor,
  )
  useEffect(() => {
    if (!isInstructor) {
      dispatch(
        onboardStaffFormChanged({
          key: "country",
          value: "",
        }),
      )
      dispatch(
        onboardStaffFormChanged({
          key: "photoUrl",
          value: "",
        }),
      )
    }
  }, [isInstructor, dispatch])

  return (
    <>
      <GeneralUserFormFields model={model} />
      {isInstructor && <InstructorFormFields model={model} />}
    </>
  )
}

function GeneralUserFormFields(props: {
  model: OnboardStaffPopup | undefined
}) {
  const { model } = props
  const dispatch = useAppDispatch()
  return (
    <>
      <FormGroup
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "16px",
        }}
      >
        <TextField
          label="First name"
          variant="outlined"
          value={model?.form.firstName ?? ""}
          onChange={(e) => {
            dispatch(
              onboardStaffFormChanged({
                key: "firstName",
                value: e.target.value,
              }),
            )
          }}
        />
        <TextField
          label="Last name"
          variant="outlined"
          value={model?.form.lastName ?? ""}
          onChange={(e) => {
            dispatch(
              onboardStaffFormChanged({
                key: "lastName",
                value: e.target.value,
              }),
            )
          }}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          label="Email"
          variant="outlined"
          value={model?.form.email ?? ""}
          onChange={(e) => {
            dispatch(
              onboardStaffFormChanged({
                key: "email",
                value: e.target.value,
              }),
            )
          }}
        />
      </FormGroup>
    </>
  )
}

function InstructorFormFields(props: { model: OnboardStaffPopup | undefined }) {
  const { model } = props
  const dispatch = useAppDispatch()
  return (
    <FormGroup
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "16px",
      }}
    >
      <CountrySelect
        label="Country"
        value={model?.form.country ?? ""}
        onChange={(e) => {
          dispatch(
            onboardStaffFormChanged({
              key: "country",
              value: e.target.value,
            }),
          )
        }}
      />
      <TextField
        label="Photo URL"
        variant="outlined"
        value={model?.form.photoUrl ?? ""}
        onChange={(e) => {
          dispatch(
            onboardStaffFormChanged({
              key: "photoUrl",
              value: e.target.value,
            }),
          )
        }}
      />
    </FormGroup>
  )
}
