import { store } from "@app/store"
import { selectAccessToken } from "@features/login/loginSelectors"
import { fetchProgramProjects } from "@features/program/projects/programProjectsSlice"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import {
  Configuration,
  CreateProgramProjectRequest,
  CreateProgramRequest,
  ProgramApi,
  ProgramProjectDto,
  UpdateProgramRequest,
} from "@masterschool/course-builder-api"

export class ProgramClient {
  static programClient = new ProgramApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static listPrograms() {
    return ProgramClient.programClient
      .programControllerList(ProgramClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static setSyllabusForProgram(programId: string, syllabusId: string) {
    return ProgramClient.programClient
      .programControllerPutSyllabusForProgram({
        ...ProgramClient.makeHeaders(),
        data: {
          programId,
          syllabusId,
        },
      })
      .then((response) => response.data)
  }

  static deleteSyllabusFromProgram(programId: string) {
    return ProgramClient.programClient
      .programControllerDeleteSyllabusFromProgram(
        programId,
        ProgramClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static listClasses(programId: string) {
    return ProgramClient.programClient
      .programControllerListClassesForProgram(
        programId,
        ProgramClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static listProjects(programId: string) {
    return ProgramClient.programClient
      .programControllerListProjectsForProgram(
        programId,
        ProgramClient.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static addProjectToProgram(project: CreateProgramProjectRequest) {
    const req = ProgramClient.programClient
      .programControllerAddProjectToProgram(
        project,
        ProgramClient.makeHeaders(),
      )
      .then((response) => response.data)
    return ProgramClient.executeWithSnackbar(req, "create", project.programId)
  }

  static updateProject(project: ProgramProjectDto) {
    const req = ProgramClient.programClient
      .programControllerUpdateProjectOfProgram(
        project,
        ProgramClient.makeHeaders(),
      )
      .then((response) => response.data)
    return ProgramClient.executeWithSnackbar(req, "update", project.programId)
  }

  static deleteProject(projectId: string, programId: string) {
    const req = ProgramClient.programClient
      .programControllerDeleteProgramProjectById(
        projectId,
        ProgramClient.makeHeaders(),
      )
      .then((response) => response.data)
    return ProgramClient.executeWithSnackbar(req, "delete", programId)
  }

  private static executeWithSnackbar(
    req: Promise<any>,
    action: string,
    programId: string,
  ) {
    const dispatch = store.dispatch
    return req
      .then(() => {
        dispatch(showSuccessSnackbar(`Project ${action}d successfully`))
        dispatch(fetchProgramProjects(programId))
      })
      .catch(() => {
        dispatch(showErrorSnackbar(`Failed to ${action} project`))
      })
  }

  static async createProgram(request: CreateProgramRequest) {
    return ProgramClient.programClient
      .programControllerCreateProgram(request, ProgramClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static async updateProgram(programId: string, request: UpdateProgramRequest) {
    return ProgramClient.programClient
      .programControllerUpdate(request, programId, ProgramClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static async deleteProgram(programId: string) {
    return ProgramClient.programClient
      .programControllerDelete(programId, ProgramClient.makeHeaders())
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          throw new Error(err.response?.data?.message)
        }
        throw err
      })
  }
}
