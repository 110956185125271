import { useAppDispatch, useAppSelector } from "@app/hooks"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { fetchProgramSlackChannels } from "@features/slack/slackSlice"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import ManageProgramClassDialog from "../../classes/manageProgramClassDialog"
import EmptyWidgetCard from "./emptyWidgetCard"
import WidgetCard from "./widgetCard"

const ClassesWidget = ({ program }: { program: ProgramDto }) => {
  const { id: programId } = program
  const dispatch = useAppDispatch()
  const classes = useAppSelector(selectClassesForProgram(programId))
  const [isManageClassDialogOpen, setIsManageClassDialogOpen] = useState(false)
  const onAddNewClassClick = () => {
    setIsManageClassDialogOpen(true)
  }

  useEffect(() => {
    dispatch(fetchProgramSlackChannels(programId))
  }, [programId, dispatch])

  if (classes.length === 0) {
    return (
      <>
        <ManageProgramClassDialog
          open={isManageClassDialogOpen}
          onClose={() => {
            setIsManageClassDialogOpen(false)
          }}
          program={program}
          programClass={undefined}
        />
        <EmptyWidgetCard
          message="There are no classes to display."
          actionProps={{
            children: "Add class",
            onClick: onAddNewClassClick,
          }}
        />
      </>
    )
  }

  return (
    <WidgetCard
      title="Classes"
      navigateTo={`/program/${programId}?tab=classes`}
    >
      <Typography variant="body2">
        {classes.length === 1
          ? `${classes[0].name}`
          : `${classes.length} classes`}
      </Typography>
    </WidgetCard>
  )
}

export default ClassesWidget
