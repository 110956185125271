import { Box, Typography, LinearProgress } from "@mui/material"
import appTheme from "../../../../theme/appTheme"

function UploadingProgressBar(props: { progress: number }) {
  const { progress } = props

  return (
    <Box
      sx={{
        border: `1px solid ${appTheme.palette.grey[300]}`,
        padding: "24px",
        gap: "8px",
        display: "flex",
        flexDirection: "column",
        height: "136px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexDirection: "row",
        }}
      >
        <Typography
          variant="body3"
          sx={{ color: appTheme.palette.text.primary }}
        >
          Uploading...
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: appTheme.palette.text.secondary }}
        >
          {`${progress}%`}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          bgcolor: appTheme.palette.grey[200],
          height: "8px",
          width: "100%",
          borderRadius: "4px",
        }}
      />
    </Box>
  )
}

export default UploadingProgressBar
