import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"
import { useAppSelector } from "@app/hooks"
import { selectIsSyllabusPublishable } from "@features/syllabus/syllabusSelectors"
import StatusChip from "./statusChip"

export function SelectedStatusChip(props: {
  syllabusId: string
  status: SyllabusDtoStatusEnum
  isActive: boolean
}) {
  const { syllabusId, status, isActive } = props

  const showIndicator =
    useAppSelector(selectIsSyllabusPublishable(syllabusId)) &&
    status !== SyllabusDtoStatusEnum.Draft &&
    status !== SyllabusDtoStatusEnum.Archived

  return (
    <SyllabusStatusChip
      status={status}
      isActive={isActive}
      showIndicator={showIndicator}
    />
  )
}

export function SyllabusStatusChip(props: {
  status: SyllabusDtoStatusEnum
  isActive: boolean
  showIndicator?: boolean
}) {
  const { status, isActive, showIndicator } = props

  const statusTextAndColor = ((): {
    text: string
    color: "success" | "error" | undefined
  } => {
    if (isActive) {
      return {
        text: "Active",
        color: "success",
      }
    }
    switch (status) {
      case undefined:
        return {
          text: "Unknown",
          color: undefined,
        }
      case SyllabusDtoStatusEnum.Draft:
        return {
          text: "Drafts",
          color: undefined,
        }
      case SyllabusDtoStatusEnum.Archived:
        return {
          text: "Archived",
          color: "error",
        }
      case SyllabusDtoStatusEnum.Published:
        return {
          text: "Published",
          color: "success",
        }
      default:
        return {
          text: "Unknown",
          color: undefined,
        }
    }
  })()

  return (
    <StatusChip
      text={statusTextAndColor.text}
      color={statusTextAndColor.color}
      showIndicator={showIndicator === true}
      isActive={isActive}
    />
  )
}

export default SyllabusStatusChip
