import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import FormDateTimePicker from "@cmp/form-components/formDateTimePicker"
import { selectEditableSyllabus } from "@features/syllabus/syllabusSelectors"
import { UnitDto } from "@masterschool/course-builder-api"
import { editUnitAccessCondition } from "@features/syllabusEditor/syllabusEditorSlice"
import { useCallback, useEffect, useState } from "react"
import {
  UnlockStrategy,
  conditionFromFormFields,
  dateFromCondition,
  unlockStrategies,
  unlockStrategyDisplayName,
  unlockStrategyFromCondition,
} from "../access.condition.utils"

export const UnitAccessConditionDropdown = (props: { unit: UnitDto }) => {
  const { unit } = props
  const { id: unitId, accessCondition } = unit

  const unlockStrategy = unlockStrategyFromCondition(accessCondition)
  const [showDateSelector, setShowDateSelector] = useState(
    unlockStrategy === UnlockStrategy.OnCustomDate,
  )
  useEffect(() => {
    const unlockStrategy = unlockStrategyFromCondition(unit.accessCondition)
    setShowDateSelector(unlockStrategy === UnlockStrategy.OnCustomDate)
  }, [unit])

  const selectedStrategy = showDateSelector
    ? UnlockStrategy.OnCustomDate
    : unlockStrategy
  const customDate = dateFromCondition(accessCondition)

  const syllabus = useAppSelector(selectEditableSyllabus)
  const isFirstUnitInSyllabus = syllabus?.units[0].id === unit.id

  const dispatch = useAppDispatch()
  const onUnlockStrategyChange = useCallback(
    (strategy: UnlockStrategy) => {
      const accessCondition = conditionFromFormFields(strategy, customDate)
      if (accessCondition) {
        dispatch(editUnitAccessCondition({ unitId, accessCondition }))
      }
      setShowDateSelector(strategy === UnlockStrategy.OnCustomDate)
    },
    [unitId, customDate, dispatch],
  )

  const onCustomDateChange = useCallback(
    (date: string | null) => {
      const accessCondition = conditionFromFormFields(
        UnlockStrategy.OnCustomDate,
        date,
      )
      if (accessCondition) {
        dispatch(editUnitAccessCondition({ unitId, accessCondition }))
      }
    },
    [unitId, dispatch],
  )

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <FormControl>
        <InputLabel
          sx={{
            fontSize: "18px",
          }}
        >
          Unlock unit
        </InputLabel>
        <Select
          value={selectedStrategy}
          label="Unlock unit"
          size="small"
          onChange={(e) =>
            onUnlockStrategyChange(e.target.value as UnlockStrategy)
          }
        >
          {unlockStrategies.map((unlockStrategy) => (
            <MenuItem
              key={unlockStrategy}
              value={unlockStrategy}
              disabled={
                unlockStrategy === UnlockStrategy.OnPreviousSectionCompletion &&
                isFirstUnitInSyllabus
              }
            >
              <Typography>
                {unlockStrategyDisplayName(unlockStrategy, "unit")}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showDateSelector && (
        <FormDateTimePicker
          label={"Unlock date"}
          value={customDate ?? null}
          helperText={"Time is presented in your local timezone."}
          onChange={(v: any) => onCustomDateChange(String(v))}
          size="small"
        />
      )}
    </Box>
  )
}
