import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"
import { Box, SvgIcon, Typography } from "@mui/material"

export const ErrorComponent = (props: { loadedComponents: string }) => {
  return (
    <Box display="flex" flexDirection="row" gap="16px" alignItems="center">
      <SvgIcon
        component={SentimentVeryDissatisfiedIcon}
        sx={{
          fill: "red",
          width: "40px",
          height: "40px",
        }}
      />
      <Typography variant="body1_sb">
        Error loading {props.loadedComponents}.
      </Typography>
    </Box>
  )
}
