import { CourseSyllabusElementTypeEnum } from "@masterschool/course-builder-api"
import OptionsButton from "@cmp/buttons/optionsButton"
import appIcons from "@utils/appIcons"
import { SxProps } from "@mui/material"
import { isDevEnv, isLocalEnv } from "@utils/envUtils"

function AddItemButton(props: {
  onElementClick: (itemType: CourseSyllabusElementTypeEnum) => void
  onLiveEventClick: () => void
  sx?: SxProps
  iconSx?: SxProps
}) {
  const { onElementClick, onLiveEventClick, sx, iconSx } = props

  const showLiveSessions = isLocalEnv() || isDevEnv()

  const items = [
    {
      text: "Lesson",
      icon: appIcons.bookOpen01,
      onSelect: () => {
        onElementClick(CourseSyllabusElementTypeEnum.EmptyLesson)
      },
    },
    {
      text: "Quiz",
      icon: appIcons.file02,
      onSelect: () => {
        onElementClick(CourseSyllabusElementTypeEnum.Test)
      },
    },
    {
      text: "Project",
      icon: appIcons.codeSquare01,
      onSelect: () => {
        onElementClick(CourseSyllabusElementTypeEnum.EmptyProject)
      },
    },
    {
      text: "Survey",
      icon: appIcons.star01,
      onSelect: () => {
        onElementClick(CourseSyllabusElementTypeEnum.Survey)
      },
    },
  ]

  if (showLiveSessions) {
    items.push({
      text: "Live Session",
      icon: appIcons.playCircle,
      onSelect: () => {
        onLiveEventClick()
      },
    })
  }

  return (
    <OptionsButton
      button={{
        text: "Add item",
        leftIcon: appIcons.plusCircle,
        size: "small",
        iconSx: {
          fill: "none",
          ...iconSx,
        },
        sx: {
          border: "none",
          boxSizing: "border-box",
          ...sx,
        },
        variant: "text",
      }}
      items={items}
    />
  )
}

export default AddItemButton
