import { useAppSelector } from "@app/hooks"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import OnboardStudentsButton from "../../students/onboardStudentsButton"
import EmptyWidgetCard from "./emptyWidgetCard"
import WidgetCard from "./widgetCard"

function StudentsWidget({ program }: { program: ProgramDto }) {
  const programId = program.id
  const { classId } = useParams()
  const programClasses = useAppSelector(selectClassesForProgram(programId))
  const students = classId
    ? programClasses.find((c) => c.id === classId)?.students ?? []
    : programClasses.flatMap((c) => c.students)

  if (students.length === 0) {
    return (
      <EmptyWidgetCard
        message="There are no students to display."
        actionElement={
          <OnboardStudentsButton
            classId={classId}
            programId={programId}
            buttonText="Add students"
            size="small"
            variant="outlined"
            icon="plus"
          />
        }
      />
    )
  }

  return (
    <WidgetCard
      title="Students"
      navigateTo={`/program/${programId}?tab=students`}
    >
      <Typography variant="body2">{students.length} Students</Typography>
    </WidgetCard>
  )
}

export default StudentsWidget
