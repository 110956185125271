import { UnitDto } from "@masterschool/course-builder-api"
import { Box } from "@mui/material"
import UnitPreviewPanelHeader from "./unitPreviewPanelHeader"
import appTheme from "../../../theme/appTheme"
import UnitContentEditor from "../units/content-editor/unitContentEditor"
import { useEffect, useRef } from "react"

function UnitPreviewPanel(props: {
  syllabusTitlePrefix?: string
  unit: UnitDto
  domain: string | undefined
  onClose: () => void
  onFinishedRender: (width: number | undefined) => void
}) {
  const { unit, onClose, domain } = props
  const containerRef = useRef<HTMLElement>()
  useEffect(() => {
    props.onFinishedRender(containerRef.current?.offsetWidth)
  })
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        bgcolor: appTheme.palette.primary.contrast,
      }}
      ref={containerRef}
    >
      <UnitPreviewPanelHeader onClose={onClose} />
      <Box
        sx={{
          padding: "16px",
          overflowY: "auto",
        }}
      >
        <UnitContentEditor unit={unit} modeProps={{ mode: "view", domain }} />
      </Box>
    </Box>
  )
}

export default UnitPreviewPanel
