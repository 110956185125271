import { Box } from "@mui/material"
import appIcons from "@utils/appIcons"
import OptionsButton from "./optionsButton"
import {
  SortOption,
  SortOrder,
  SortType,
} from "../../main/sort/coursesSortHelpers"

export function SortItemsButton(props: {
  sortOption: SortOption
  chronologicalClicked: (sortOrder: SortOrder) => void
  alphabeticClicked: (sortOrder: SortOrder) => void
}) {
  const { chronologicalClicked, alphabeticClicked, sortOption } = props

  const handleOrderChanges = (
    newSortType: SortType,
    newSortOrder: SortOrder,
  ) => {
    if (sortOption.type === newSortType && sortOption.order === newSortOrder) {
      return
    }
    switch (newSortType) {
      case SortType.Chronological:
        chronologicalClicked(newSortOrder)

        break
      case SortType.Alphabetic:
        alphabeticClicked(newSortOrder)

        break
    }
  }

  const items = [
    {
      text: "Newest first",
      onSelect: () => {
        handleOrderChanges(SortType.Chronological, SortOrder.Descending)
      },
    },
    {
      text: "Oldest first",
      onSelect: () => {
        handleOrderChanges(SortType.Chronological, SortOrder.Ascending)
      },
    },
    {
      text: "A → Z",
      onSelect: () => {
        handleOrderChanges(SortType.Alphabetic, SortOrder.Descending)
      },
    },
    {
      text: "Z → A",
      onSelect: () => {
        handleOrderChanges(SortType.Alphabetic, SortOrder.Ascending)
      },
    },
  ]
  return (
    <Box width="fit-content">
      <OptionsButton
        button={{
          text: SortOptionToTitle(sortOption),
          rightIcon: appIcons.chevronDown,
          sx: {
            width: "140px",
            border: "none",
          },
          variant: "text",
          size: "small",
        }}
        items={items}
      />
    </Box>
  )
}

const SortOptionToTitle = (sortOption: SortOption) => {
  switch (sortOption.type) {
    case SortType.Chronological:
      return sortOption.order === SortOrder.Descending
        ? "Newest first"
        : "Oldest first"
    case SortType.Alphabetic:
      return sortOption.order === SortOrder.Descending ? "A → Z" : "Z → A"
  }
}
