import { NavigateOptions, useSearchParams } from "react-router-dom"

function useSpecificSearchParam(paramKey: string) {
  const [searchParams, setSearchParams] = useSearchParams()
  const param = searchParams.get(paramKey)

  const setParam = (
    newParam: string | undefined,
    navigateOpts?: NavigateOptions,
  ) => {
    if (newParam === undefined) {
      searchParams.delete(paramKey)
    } else {
      searchParams.set(paramKey, newParam)
    }
    setSearchParams(searchParams, navigateOpts)
  }

  return [param, setParam] as const
}

export default useSpecificSearchParam
