import React, { useRef, useState } from "react"
import { Box } from "@mui/material"
import CustomTooltip from "./customTooltip"

interface OverflowTooltipProps {
  children: React.ReactNode
  title?: string | JSX.Element
  sx?: React.CSSProperties
  disabled?: boolean
}

const OverflowTooltip: React.FC<OverflowTooltipProps> = ({
  children,
  title,
  sx,
  disabled,
}) => {
  const [disableTitleTooltip, setDisableTitleTooltip] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [content, setContent] = useState<string | JSX.Element>(title ?? "")
  const wrapperRef = useRef<HTMLElement>()

  const isChildOverflow = (element: HTMLElement) => {
    if (!element) {
      return true
    }

    return element.clientWidth - element.scrollWidth >= 0
  }

  const getChildTarget = (wrapper: HTMLElement, target: HTMLElement) => {
    let childTarget = target as HTMLElement
    wrapper.childNodes.forEach((child) => {
      if (child.contains(target)) {
        childTarget = child as HTMLElement
      }
    })

    return childTarget
  }

  const onTitleTooltipOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const wrapper = event.currentTarget as HTMLElement
    const target = event.target as HTMLElement
    const childTarget = getChildTarget(wrapper, target)
    const disableTooltip = isChildOverflow(childTarget as HTMLElement)

    setAnchorEl(disableTitleTooltip ? null : childTarget)

    if (!title && childTarget && !disableTooltip) {
      setContent(childTarget.innerText)
    }

    setDisableTitleTooltip(disableTooltip)
  }

  return (
    <CustomTooltip
      title={content}
      disableTooltip={disabled || disableTitleTooltip}
      placement="top"
      leaveDelay={0}
      offset={{ horizontal: 0, vertical: 0 }}
      fontColor="white"
      PopperProps={{
        anchorEl,
      }}
      sx={{ marginTop: "-8px" }}
    >
      <Box
        sx={{
          ...sx,
          width: "100%",
          "> *": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        }}
        ref={wrapperRef}
        onMouseOver={onTitleTooltipOpen}
      >
        {children}
      </Box>
    </CustomTooltip>
  )
}

export default OverflowTooltip
