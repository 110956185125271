import { useAppDispatch } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { popupClosed } from "@features/ui/uiSlice"
import { CourseDto } from "@masterschool/course-builder-api"
import CoursePreview from "../../editor/coursePreview"

function CoursePreviewPopup(props: { course: CourseDto }) {
  const dispatch = useAppDispatch()

  return (
    <GenericDialog
      open
      onClose={() => {
        dispatch(popupClosed())
      }}
      size="lg"
      fullHeight
      content={<CoursePreview course={props.course} />}
    />
  )
}

export default CoursePreviewPopup
