import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  ThemeOptions,
} from "@mui/material/styles"

const MuiDividerOverrides: {
  defaultProps?: ComponentsProps["MuiDivider"]
  styleOverrides?: ComponentsOverrides<ThemeOptions>["MuiDivider"]
  variants?: ComponentsVariants["MuiDivider"]
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette?.other.outlineBorder,
      borderWidth: 1,
      borderTop: "none",
      borderLeft: "none",
    }),
    wrapper: {
      padding: "0 16px",
    },
  },
}

export { MuiDividerOverrides }
