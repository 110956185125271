import { stringAsCamelCase } from "./syllabusTags"

export function domainToDisplayName(domain: string): string {
  switch (domain) {
    case "WEB":
      return "Software"
    case "DATA_SCIENCE":
      return "Data Analysis"
    default:
      return stringAsCamelCase(domain)
  }
}
