import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { selectActiveCourse } from "@features/courseEditor/courseEditorSelectors"
import {
  publishConfirmationDialogClosed,
  publishCourse,
} from "@features/courseEditor/courseEditorSlice"
import {
  selectDependentSyllabuses,
  selectLatestPublishedCourse,
} from "@features/coursesMenu/coursesSelectors"
import {
  Syllabus,
  selectLastVersionSyllabuses,
} from "@features/syllabus/syllabusSelectors"
import { Alert, Link, Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import appTheme from "../../theme/appTheme"

function CoursePublishConfirmationPopup() {
  const viewProps = useViewProps()

  if (!viewProps) {
    return null
  }

  return <View {...viewProps} />
}

type ViewModel = {
  title: string
  subtitle: string
  warningText?: string
  syllabuses: Syllabus[]
  syllabusesTextColor: string
  isPublishing: boolean
}

type ViewProps = {
  model: ViewModel
  onClose: () => void
  onPublish: () => void
  onSyllabusClicked: (syllabus: Syllabus) => void
}

function useViewProps(): ViewProps | undefined {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { syllabusId, unitId } = useParams()
  const [searchParams] = useSearchParams()
  const duplicateOriginalCourseId = searchParams.get(
    "duplicateOriginalCourseId",
  )
  const course = useAppSelector(selectActiveCourse)
  const dependentSyllabusesIDs = useAppSelector(
    selectDependentSyllabuses(course?.id),
  )
  const syllabusesMetadata = useAppSelector(selectLastVersionSyllabuses)
  const syllabuses = syllabusesMetadata.filter((syllabus) =>
    dependentSyllabusesIDs.includes(syllabus.id),
  )

  const editableCourse = useAppSelector(selectActiveCourse)
  const publishedCourse = useAppSelector(
    selectLatestPublishedCourse(editableCourse?.id),
  )
  const didChangeStructure =
    JSON.stringify(editableCourse?.syllabus.topics.map((t) => t.id)) !==
    JSON.stringify(publishedCourse?.syllabus.topics.map((t) => t.id))

  const onSyllabusClicked = (syllabus: Syllabus) => {
    navigate(`/syllabus/${syllabus.id}`)
  }
  const [isPublishing, setIsPublishing] = useState(false)

  const subtitle = (() => {
    if (dependentSyllabusesIDs.length === 0) {
      return "The course will be visible to all users in the main repository. It does not appear in any syllabuses."
    }
    if (!didChangeStructure) {
      return "The course changes will be visible to all users in the main repository and to students in the following syllabuses: "
    }
    return "The course changes will be visible to all users in the main repository. "
  })()

  const warningText = (() => {
    if (dependentSyllabusesIDs.length === 0) {
      return undefined
    }
    if (!didChangeStructure) {
      return undefined
    }
    return "The following syllabuses will need to be updated in order to see these changes:"
  })()

  const syllabusesTextColor = didChangeStructure
    ? appTheme.palette.warning.alert?.content ?? "text.primary"
    : "text.primary"

  const model: ViewModel = {
    title: "Publish changes?",
    subtitle: subtitle,
    syllabuses: syllabuses,
    syllabusesTextColor: syllabusesTextColor,
    warningText: warningText,
    isPublishing: isPublishing,
  }

  if (!course) {
    return undefined
  }

  const onPublish = async () => {
    setIsPublishing(true)

    const context =
      syllabusId && unitId
        ? {
            syllabusId,
            unitId,
            duplicateOriginalCourseId: duplicateOriginalCourseId ?? undefined,
          }
        : undefined

    dispatch(publishCourse(context)).then(() => {
      setIsPublishing(false)
      if (context) {
        navigate({
          pathname: `/syllabus-edit/${context.syllabusId}`,
          search: createSearchParams({
            unitId: context.unitId,
            unitTab: "content",
          }).toString(),
        })
      } else {
        navigate(`/course/${course.id}?version=${course.version}`)
      }
    })
  }

  return {
    model: model,
    onClose: () => dispatch(publishConfirmationDialogClosed()),
    onPublish: onPublish,
    onSyllabusClicked: onSyllabusClicked,
  }
}

function View(props: ViewProps) {
  return (
    <GenericDialog
      open
      title={props.model.title}
      subtitle={props.model.subtitle}
      onClose={props.onClose}
      size="xs"
      content={
        <Stack gap={2}>
          {props.model.warningText && (
            <ManualSyllabusUpdateWarning text={props.model.warningText}>
              {props.model.syllabuses.length > 0 && (
                <Stack pt={1} pl={1}>
                  {props.model.syllabuses.map((syllabus) => (
                    <SyllabusButton
                      key={syllabus.id}
                      syllabus={syllabus}
                      color={props.model.syllabusesTextColor}
                      onSyllabusClicked={() =>
                        props.onSyllabusClicked(syllabus)
                      }
                    />
                  ))}
                </Stack>
              )}
            </ManualSyllabusUpdateWarning>
          )}
        </Stack>
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: props.onClose,
        },
        {
          type: "primary",
          text: "Publish",
          onClick: props.onPublish,
          loading: props.model.isPublishing,
        },
      ]}
    />
  )
}

function SyllabusButton(props: {
  syllabus: Syllabus
  color?: string
  onSyllabusClicked: (syllabus: Syllabus) => void
}) {
  const { syllabus, color, onSyllabusClicked } = props

  return (
    <Typography color={color}>
      <span
        style={{
          textDecoration: "none",
        }}
      >
        &#x2022;&nbsp;
      </span>
      <Link
        href={`/syllabus/${syllabus.id}`}
        variant="body2"
        onClick={() => onSyllabusClicked(syllabus)}
        sx={{
          textDecoration: "none",
          color: color,
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {syllabus.name || "Unnamed syllabus"}
      </Link>
    </Typography>
  )
}

function ManualSyllabusUpdateWarning(props: {
  text: string
  children: React.ReactNode
}) {
  return (
    <Alert
      severity="warning"
      icon={
        <SvgIcon
          component={appIcons.alertTriangle}
          inheritViewBox
          sx={{ fill: "none", width: 20, height: 20 }}
        />
      }
    >
      {props.text}
      {props.children}
    </Alert>
  )
}

export default CoursePublishConfirmationPopup
