import { FilterName, SearchAdvanceFilter } from "@features/search/searchSlice"
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"

export function MainPageFilterContainer(props: {
  inputFilters: SearchAdvanceFilter[]
  title: string
  onApplyClicked: (filters: SearchAdvanceFilter[]) => void
  onClose: () => void
  getOptionLabel?: (option: string) => string
}) {
  const { inputFilters, title, onApplyClicked, onClose, getOptionLabel } = props

  const [filters, setFilters] = useState<SearchAdvanceFilter[]>(inputFilters)

  const onApplyClick = () => {
    onApplyClicked(filters)
    onClose()
  }

  function onSingleFilterChange(filterName: string, newValue: string[]) {
    const newFilters = filters.map((filter) => {
      if (filter.filterName === filterName) {
        return { ...filter, selectedValues: newValue }
      }
      return filter
    })
    setFilters(newFilters)
  }

  const onResetClicked = () => {
    const clearedFilters = filters.map((filter) => {
      return { ...filter, selectedValues: [] }
    })
    setFilters(clearedFilters)
    onApplyClicked(clearedFilters)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        padding: "32px 24px 24px 24px",
        width: "520px",
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="h6">{title}</Typography>
        {inputFilters.map((filter) => {
          return (
            <Autocomplete
              key={filter.filterName}
              sx={{ marginTop: "24px" }}
              value={
                filters.find((f) => f.filterName === filter.filterName)
                  ?.selectedValues
              }
              options={filter.optionalValues}
              onChange={(event, newValue) => {
                onSingleFilterChange(filter.filterName, newValue)
              }}
              placeholder={filter.filterName}
              getOptionLabel={getOptionLabel}
              fullWidth
              multiple
              includeInputInList
              autoComplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={filterNameLabelText(filter.filterName)}
                  size="small"
                  InputLabelProps={{
                    sx: { fontSize: "16px" },
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={getOptionLabel ? getOptionLabel(option) : option}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          )
        })}
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        gap="8px"
        marginTop="24px"
      >
        <Button
          onClick={onResetClicked}
          variant="outlined"
          size="large"
          style={{
            width: "100%",
          }}
        >
          Reset
        </Button>
        <Button
          onClick={onApplyClick}
          variant="contained"
          size="large"
          style={{
            width: "100%",
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  )
}

export function filterNameLabelText(filterName: FilterName) {
  switch (filterName) {
    case FilterName.Domains:
      return "Domain"
    case FilterName.Syllabuses:
      return "Syllabus"
    case FilterName.Cohort:
      return "Cohort"
    default:
      return filterName
  }
}
