import { store } from "@app/store"
import { selectAccessToken } from "@features/login/loginSelectors"
import {
  Configuration,
  CreateConsultantDto,
  ConsultantApi,
  ConsultantDto,
} from "@masterschool/course-builder-api"
export class ConsultantClient {
  private static consultantClient = new ConsultantApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static getConsultants(): Promise<ConsultantDto[]> {
    return ConsultantClient.consultantClient
      .consultantControllerGet(this.makeHeaders())
      .then((response) => response.data)
  }

  static create(consultantDto: CreateConsultantDto): Promise<ConsultantDto> {
    return ConsultantClient.consultantClient
      .consultantControllerCreate(consultantDto, this.makeHeaders())
      .then((response) => response.data)
  }

  static updateConsultantAvailability(
    consultantId: string,
    isAvailable: boolean,
  ): Promise<ConsultantDto> {
    return ConsultantClient.consultantClient
      .consultantControllerUpdateConsultantAvailability(
        { consultantId, isAvailable },
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }

  static updateConsultantAcceptedQtfs(
    consultantId: string,
    acceptedQtfs: number[],
  ): Promise<ConsultantDto> {
    return ConsultantClient.consultantClient
      .consultantControllerUpdateConsultantAcceptedQtfs(
        { consultantId, acceptedQtfs },
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }
}
