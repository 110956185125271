import { useAppDispatch, useAppSelector } from "@app/hooks"
import FormDateTimePicker from "@cmp/form-components/formDateTimePicker"
import GenericDialog from "@cmp/genericDialog"
import { sprintMetadataEdited } from "@features/syllabusEditor/syllabusEditorSlice"
import {
  AccessConditionType,
  DateCondition,
  SprintDto,
  UnitDto,
} from "@masterschool/course-builder-api"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import appTheme from "../../../../theme/appTheme"
import EditorTextField from "../../../components/editorTextField"
import useActiveSyllabusUnit from "../../panel/useActiveSyllabusUnit"
import {
  UnlockStrategy,
  conditionFromFormFields,
  dateFromCondition,
  unlockStrategies,
  unlockStrategyDisplayName,
  unlockStrategyFromCondition,
} from "../access.condition.utils"
import {
  SprintType,
  sprintTypeDisplayName,
  sprintTypeFromSprint,
} from "./unit.sprint.utils"
import { selectIsFirstUnitInEditableSyllabus } from "@features/syllabus/syllabusSelectors"

export const SprintSettingsDialog = (props: {
  sprint: SprintDto
  open: boolean
  onClose: () => void
}) => {
  const { open, onClose, sprint } = props
  const { id } = sprint
  const [title, setTitle] = useState(sprint.title)
  const [sprintType, setSprintType] = useState<SprintType>(
    sprintTypeFromSprint(sprint),
  )
  const [unlockStrategy, setUnlockStrategy] = useState<UnlockStrategy>(
    unlockStrategyFromCondition(sprint.accessCondition),
  )
  const [customDate, setCustomDate] = useState<string | undefined | null>(
    dateFromCondition(sprint.accessCondition),
  )
  const disableSave =
    unlockStrategy === UnlockStrategy.OnCustomDate && !customDate

  const dispatch = useAppDispatch()

  const onSaveClicked = () => {
    const metadata = sprintMetadata(
      title,
      sprintType,
      unlockStrategy,
      customDate,
    )
    dispatch(sprintMetadataEdited({ id, metadata }))
    onClose()
  }

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Sprint settings"
      size="sm"
      content={
        <Stack gap={3} paddingTop={"30px"}>
          <EditorTextField
            label="Sprint name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <SprintTypeSelector
            sprintType={sprintType}
            onChange={setSprintType}
          />
          <SprintAccessConditionDropdown
            unlockStrategy={unlockStrategy}
            onUnlockStrategyChange={setUnlockStrategy}
            customDate={customDate}
            onCustomDateChange={setCustomDate}
            sprintId={id}
          />
        </Stack>
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Save",
          onClick: onSaveClicked,
          disabled: disableSave,
        },
      ]}
    />
  )
}

function sprintMetadata(
  title: string,
  type: SprintType,
  unlockStrategy: UnlockStrategy,
  customDate?: string | undefined | null,
): Partial<Omit<SprintDto, "id" | "lastTopicId">> {
  return {
    title,
    isPracticeWeek: type === "practice",
    isVacationWeek: type === "vacation",
    accessCondition:
      conditionFromFormFields(unlockStrategy, customDate) ?? undefined,
  }
}

const SprintTypeSelector = (props: {
  sprintType: SprintType
  onChange: (type: SprintType) => void
}) => {
  const { sprintType, onChange } = props
  const sprintTypeOptions: SprintType[] = ["learning", "vacation", "practice"]
  return (
    <FormControl>
      <InputLabel sx={{ fontSize: "17px" }}>Type</InputLabel>
      <Select
        value={sprintType}
        label="Type"
        onChange={(e) => onChange(e.target.value as SprintType)}
      >
        {sprintTypeOptions.map((type) => (
          <MenuItem value={type} key={type}>
            <Box display="flex" gap="8px" alignItems="center">
              {type !== "learning" && (
                <SvgIcon
                  component={
                    type === "practice"
                      ? appIcons.rugbyBall
                      : appIcons.vacationTree
                  }
                  sx={{
                    fill: "none",
                    width: "16px",
                    height: "16px",
                  }}
                  inheritViewBox
                />
              )}
              <Typography>{sprintTypeDisplayName(type)}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const SprintAccessConditionDropdown = (props: {
  unlockStrategy: UnlockStrategy
  customDate?: string | null
  onUnlockStrategyChange: (strategy: UnlockStrategy) => void
  onCustomDateChange: (date: string | null) => void
  sprintId: string
}) => {
  const { unlockStrategy, customDate, sprintId } = props
  const { onUnlockStrategyChange, onCustomDateChange } = props

  const showDateSelector = unlockStrategy === UnlockStrategy.OnCustomDate
  const unit = useActiveSyllabusUnit()
  const isFirstUnit = useAppSelector(
    selectIsFirstUnitInEditableSyllabus(unit?.id),
  )
  const isFirstSprintInFirstUnit =
    unit?.sprints[0].id === sprintId && isFirstUnit
  const unitStartDate = unit ? unitCustomStartDate(unit) : undefined
  const customDateIsBeforeUnitStartDate =
    customDate &&
    unitStartDate &&
    new Date(customDate) < new Date(unitStartDate)

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <FormControl>
        <InputLabel
          sx={{
            fontSize: "18px",
          }}
        >
          Unlock
        </InputLabel>
        <Select
          value={unlockStrategy}
          label="Unlock"
          onChange={(e) =>
            onUnlockStrategyChange(e.target.value as UnlockStrategy)
          }
        >
          {unlockStrategies.map((unlockStrategy) => (
            <MenuItem
              key={unlockStrategy}
              value={unlockStrategy}
              disabled={
                unlockStrategy === UnlockStrategy.OnPreviousSectionCompletion &&
                isFirstSprintInFirstUnit
              }
            >
              <Typography>
                {unlockStrategyDisplayName(unlockStrategy, "sprint")}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showDateSelector && (
        <Box>
          <FormDateTimePicker
            label={"Unlock date"}
            value={customDate ?? null}
            helperText={"Time is presented in your local timezone."}
            onChange={(v: any) => onCustomDateChange(String(v))}
          />
          {customDateIsBeforeUnitStartDate && <ProblematicDateWarning />}
        </Box>
      )}
    </Box>
  )
}

function unitCustomStartDate(unit: UnitDto): string | undefined {
  const customStartDate =
    unit.accessCondition?.type === AccessConditionType.Date
      ? (unit.accessCondition as DateCondition).startDate
      : undefined
  return customStartDate
}

const ProblematicDateWarning = () => {
  return (
    <Box
      sx={{
        backgroundColor: appTheme.palette.warning.alert?.background,
        padding: "12px 16px",
      }}
      display="flex"
      gap="12px"
      marginTop="16px"
      alignItems="center"
    >
      <SvgIcon
        component={appIcons.alertTriangle}
        sx={{
          fill: "none",
          color: "warning.main",
          width: "22px",
          height: "22px",
        }}
      />
      <Typography
        color={appTheme.palette.warning.alert?.content}
        variant="body3"
      >
        The chosen date precedes the opening date of the unit.
      </Typography>
    </Box>
  )
}
