import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
} from "@mui/material"
import appTheme from "../theme/appTheme"
import { ReactNode, useState } from "react"

function GenericTable<T extends Object>(props: {
  /** The data to be displayed in the table. */
  data: T[]
  /** The keys of the data to be displayed.
   * If not provided, the keys will be extracted from the first
   * object in the data (only numbers, string and booleans).
   * The keys will be displayed in the order they are provided. */
  keys?: (keyof T)[]
  /** A function that will be called when a row is clicked. */
  onClick?: (row: T) => void
  /** A dictionary that maps the keys to a function that will be
   * called to render the header of the column.
   * If not provided, the keys will be displayed as the header. */
  renderHeader?: {
    [K in keyof T]?: (value: K) => ReactNode | undefined
  }
  /** A dictionary that maps the keys to a function that will be
   * called to render the value of the column.
   * If not provided, the value will be displayed as is. */
  render?: {
    [K in keyof T]?: (
      value: T[K],
      row: T,
      hover: boolean,
      highlightText?: string,
    ) => ReactNode | undefined
  }
  /** The style of the table container. */
  sx?: TableContainerProps["sx"]
  /** The text to highlight in the table. */
  highlightText?: string
}) {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null)
  if (props.data.length === 0) {
    return null
  }

  const columns = props.keys ?? extractStringNumberBooleanKeys(props.data[0])

  return (
    <TableContainer
      sx={{
        width: "100%",
        maxHeight: "100%",
        overflow: "auto",
        bgcolor: appTheme.palette.primary.contrast,
        border: `1px solid ${appTheme.palette.divider}`,
        borderRadius: "4px",
        ...props.sx,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns
              .map((column) => ({
                column,
                renderFunction: props.renderHeader?.[column],
              }))
              .map(({ column, renderFunction }) => (
                <TableCell
                  variant="head"
                  key={String(column)}
                  sx={{ bgcolor: appTheme.palette.primary.contrast }}
                >
                  {renderFunction
                    ? renderFunction(column)
                    : labelForKey(String(column))}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => (
            <TableRow
              key={index}
              onMouseEnter={() => setHoveredRow(index)}
              onMouseLeave={() => setHoveredRow(null)}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
                "&:hover": {
                  bgcolor: appTheme.palette.action.hover,
                },
              }}
              onClick={() => {
                if (props.onClick) {
                  props.onClick(row)
                }
              }}
            >
              {columns

                .map((column) => ({
                  column,
                  renderFunction: props.render?.[column],
                }))
                .map(({ column, renderFunction }) => (
                  <TableCell key={String(column)}>
                    {renderFunction
                      ? renderFunction(
                          row[column],
                          row,
                          index === hoveredRow,
                          props.highlightText,
                        )
                      : renderWithHighlightText(
                          row[column] as string | number | boolean,
                          props.highlightText,
                        )}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function extractStringNumberBooleanKeys<T extends Record<string, any>>(
  obj: T,
): (keyof T)[] {
  return Object.keys(obj).filter((key) => {
    const value = obj[key as keyof T]
    return (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    )
  }) as (keyof T)[]
}

function labelForKey(key: string) {
  const labelWithSpaces = key.replace(/([A-Z])/g, " $1").trim()
  return capitalizeFirstLetter(labelWithSpaces)
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function renderWithHighlightText(
  value: string | number | boolean,
  highlight: string | undefined,
) {
  if (!highlight) {
    return value
  }
  const stringValue = getStringValue(value)
  const parts = stringValue.split(new RegExp(`(${highlight})`, "gi"))
  return (
    <span>
      {parts.map((part, index) => (
        <span key={index}>
          {part.toLowerCase() === highlight.toLowerCase() ? (
            <mark>{part}</mark>
          ) : (
            part
          )}
        </span>
      ))}
    </span>
  )
}

function getStringValue(value: string | number | boolean) {
  if (typeof value === "string") {
    return value
  }
  if (typeof value === "number") {
    return value.toString()
  }
  if (typeof value === "boolean") {
    return String(value)
  }
  return ""
}

export default GenericTable
