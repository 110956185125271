import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import {
  AppFeatureFlag,
  AppFeatureFlagClient,
} from "@clients/appFeatureFlagsClient"
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import PendingDataState from "@utils/pendingDataState"

export interface AppFeatureFlagsState {
  appFeatureFlags: PendingDataState<AppFeatureFlag[]>
  searchText: string
  addAppFeatureFlagPopup: AddFeatureFlagPopupState | undefined
  turnOnOffAppFeatureFlagPopup: AppFeatureFlagTurnOnOffPopupState | undefined
}

export interface AddFeatureFlagPopupState {
  requestStatus: "idle" | "pending" | "success" | "error"
  form: AppFeatureFlag
}

export interface AppFeatureFlagTurnOnOffPopupState {
  requestStatus: "idle" | "pending" | "success" | "error"
  flagName: string
}

const initialState: AppFeatureFlagsState = {
  appFeatureFlags: { status: "idle" },
  searchText: "",
  addAppFeatureFlagPopup: undefined,
  turnOnOffAppFeatureFlagPopup: undefined,
}

export const appFeatureFlagsSlice = createSlice({
  name: "appFeatureFlags",
  initialState,
  reducers: {
    addAppFeatureFlagPopupOpened: (state) => {
      state.addAppFeatureFlagPopup = {
        requestStatus: "idle",
        form: {
          name: "",
          enabled: false,
        },
      }
    },
    appFeatureFlagFormEdited: (
      state,
      action: PayloadAction<{ name: string; enabled: boolean }>,
    ) => {
      if (!state.addAppFeatureFlagPopup) {
        return
      }
      state.addAppFeatureFlagPopup.form = action.payload
    },
    addAppFeatureFlagClosed: (state) => {
      state.addAppFeatureFlagPopup = undefined
    },
    turnOnOffAppFeatureFlagPopupOpened: (
      state,
      action: PayloadAction<{
        flagName: string
      }>,
    ) => {
      if (state.appFeatureFlags.status !== "success") {
        return
      }
      const isFlagExist = state.appFeatureFlags.data?.find(
        (flag) => flag.name === action.payload.flagName,
      )
      if (!isFlagExist) {
        return
      }
      state.turnOnOffAppFeatureFlagPopup = {
        requestStatus: "idle",
        flagName: action.payload.flagName,
      }
    },
    turnOnOffAppFeatureFlagPopupClosed: (state) => {
      state.turnOnOffAppFeatureFlagPopup = undefined
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppFeatureFlags.pending, (state) => {
      state.appFeatureFlags = { status: "pending" }
    })
    builder.addCase(
      fetchAppFeatureFlags.fulfilled,
      (state, action: PayloadAction<AppFeatureFlag[]>) => {
        state.appFeatureFlags = {
          status: "success",
          data: action.payload.sort((a, b) => a.name.localeCompare(b.name)),
        }
      },
    )
    builder.addCase(fetchAppFeatureFlags.rejected, (state) => {
      state.appFeatureFlags = { status: "error" }
    })
    builder.addCase(createAppFeatureFlagPopupConfirmed.pending, (state) => {
      if (!state.addAppFeatureFlagPopup) {
        return
      }
      state.addAppFeatureFlagPopup.requestStatus = "pending"
    })
    builder.addCase(createAppFeatureFlagPopupConfirmed.fulfilled, (state) => {
      state.addAppFeatureFlagPopup = undefined
    })
    builder.addCase(turnOnOffAppFeatureFlag.pending, (state) => {
      if (!state.turnOnOffAppFeatureFlagPopup) {
        return
      }
      state.turnOnOffAppFeatureFlagPopup.requestStatus = "pending"
    })
    builder.addCase(turnOnOffAppFeatureFlag.fulfilled, (state) => {
      state.turnOnOffAppFeatureFlagPopup = undefined
    })
    builder.addCase(turnOnOffAppFeatureFlag.rejected, (state) => {
      state.turnOnOffAppFeatureFlagPopup = undefined
    })
  },
})

export const fetchAppFeatureFlags = createAsyncThunk(
  "appFeatureFlags/fetchAppFeatureFlags",
  async () => {
    return AppFeatureFlagClient.listFlags()
  },
)

export const createAppFeatureFlagPopupConfirmed = createAppAsyncThunk(
  "appFeatureFlags/createAppFeatureFlagPopupConfirmed",
  async (_, thunkAPI) => {
    const popup = thunkAPI.getState().appFeatureFlags.addAppFeatureFlagPopup
    if (!popup) {
      return
    }
    return AppFeatureFlagClient.createOrUpdate(popup.form)
  },
)

export const turnOnOffAppFeatureFlag = createAppAsyncThunk(
  "appFeatureFlags/turnOnOffAppFeatureFlag",
  async (_, thunkAPI) => {
    const flags = thunkAPI.getState().appFeatureFlags.appFeatureFlags
    const flaName =
      thunkAPI.getState().appFeatureFlags.turnOnOffAppFeatureFlagPopup?.flagName
    if (flags.status !== "success" || !flaName) {
      return
    }
    const flag = flags.data?.find((f) => f.name === flaName)
    if (!flag) {
      return
    }
    return AppFeatureFlagClient.createOrUpdate({
      ...flag,
      enabled: !flag.enabled,
    })
  },
)

export default appFeatureFlagsSlice.reducer

export const {
  addAppFeatureFlagPopupOpened,
  appFeatureFlagFormEdited,
  addAppFeatureFlagClosed,
  turnOnOffAppFeatureFlagPopupOpened,
  turnOnOffAppFeatureFlagPopupClosed,
  setSearchText,
} = appFeatureFlagsSlice.actions
