import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { offboardStudentPopupClosed } from "@features/program/programSlice"
import { selectStudent } from "@features/program/programSliceSelectors"
import { unregisterStudent } from "@features/program/programSliceThunks"

function OffboardStudentPopup() {
  const dispatch = useAppDispatch()
  const model = useAppSelector(
    (state) => state.program.studentsPage.offboardStudentPopup,
  )
  const student = useAppSelector(
    selectStudent(model?.programId, model?.classId, model?.studentId),
  )

  return (
    <GenericDialog
      open={model !== undefined}
      size="xs"
      title={`Delete ${student?.firstName + " " + student?.lastName}`}
      subtitle={`This can't be undone.`}
      onClose={() => dispatch(offboardStudentPopupClosed())}
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: () => dispatch(offboardStudentPopupClosed()),
          disabled: model?.state === "pending",
        },
        {
          text: "Offboard student",
          type: "primary",
          loading: model?.state === "pending",
          onClick: () => {
            if (!model) {
              return
            }
            dispatch(
              unregisterStudent({
                programId: model.programId,
                classId: model.classId,
                studentId: model.studentId,
              }),
            )
          },
        },
      ]}
    />
  )
}

export default OffboardStudentPopup
