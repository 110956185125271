import { useAppSelector } from "@app/hooks"
import { ProgramManagementCalendar } from "@clients/calendarHubClient"
import { selectShowCalendarFeature } from "@features/account-management/accountManagementSelectors"
import { selectPrograms } from "@features/program/programSliceSelectors"
import MsCalendar from "@masterschool/ui-calendar"
import { Stack } from "@mui/material"
import { useMemo, useState } from "react"
import { buildMsCalendarGroupings } from "./calendarMappers"
import { useEventActions, useVideoConferenceClient } from "./useEventActions"
import { useGetCalendars } from "./useGetCalendar"
import { selectLoggedInUser } from "@features/login/loginSelectors"

const d1 = new Date("2023-08-10T10:00:00.000Z")
const d2 = new Date("2027-08-20T10:00:00.000Z")

type DateRange = {
  start: Date
  end: Date
}

const CalendarContainer = () => {
  const isCalendarFeatureEnabled = useAppSelector(selectShowCalendarFeature)

  const calendars = useGetCalendars()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dateRange] = useState<DateRange>({ start: d1, end: d2 })
  if (!isCalendarFeatureEnabled) {
    return null
  }
  return <CalendarWithEvent calendars={calendars} dateRange={dateRange} />
}

const CalendarWithEvent = ({
  calendars,
  dateRange,
}: {
  calendars: ProgramManagementCalendar
  dateRange: DateRange
}) => {
  const allCalendarIds = useMemo(
    () =>
      [...calendars.schoolCalendars, ...calendars.staffCalendars].map(
        (c) => c.id,
      ),
    [calendars],
  )

  const { msEvents, onChange, onDelete } = useEventActions(
    allCalendarIds,
    dateRange,
  )
  const { create: createVc } = useVideoConferenceClient()
  const programs = useAppSelector(selectPrograms)
  const user = useAppSelector(selectLoggedInUser)
  const userId = user?.clientId

  return (
    <Stack width={1} height={1} px={3} pt={8}>
      <Stack flex={1} overflow="auto">
        <MsCalendar
          events={msEvents}
          calendars={buildMsCalendarGroupings(calendars, programs)}
          onEventChange={onChange}
          supportCreateEventTypes={["live-session", "regular"]}
          userId={userId ?? ""}
          vcCreateAction={createVc}
          onDeleteEvent={onDelete}
          showDraftEvents
        />
      </Stack>
    </Stack>
  )
}

export default CalendarContainer
