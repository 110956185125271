import { useAppSelector } from "@app/hooks"
import {
  CourseSyllabusElementTypeEnum,
  Topic,
} from "@masterschool/course-builder-api"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { selectPublishValidations } from "@features/courseEditor/courseValidationsSelectors"
import useEditElement from "../custom-hooks/useEditElement"
import useSelectedElement from "../custom-hooks/useSelectedElement"
import ElementTypeSelection from "../elementTypeSelection"

function EditLessonType(props: { topic: Topic }) {
  const { topic } = props
  const element = useSelectedElement()
  const editElement = useEditElement()
  const validations = useAppSelector(selectPublishValidations)
  const isInvalid =
    (element && validations?.[element.item.id]?.includes("MISSING_PROVIDER")) ??
    false

  const allowedTypes = [
    CourseSyllabusElementTypeEnum.DatacampLesson,
    CourseSyllabusElementTypeEnum.MasterschoolLesson,
    CourseSyllabusElementTypeEnum.NotionLesson,
    CourseSyllabusElementTypeEnum.OtherLesson,
    CourseSyllabusElementTypeEnum.CourseraLesson,
    CourseSyllabusElementTypeEnum.TryHackmeLesson,
    CourseSyllabusElementTypeEnum.UdacityLesson,
    CourseSyllabusElementTypeEnum.CodioLesson,
    CourseSyllabusElementTypeEnum.RunestoneLesson,
  ]

  if (!element) {
    return undefined
  }

  return (
    <ElementTypeSelection
      type={element.type}
      isInvalid={isInvalid}
      allowedTypes={allowedTypes}
      category="lesson"
      onChange={(type) => {
        editElement(
          {
            type: type,
            item: {
              ...SyllabusElementFactory.makeElement(type).item,
              id: element.item.id,
              title: element.item.title,
              isHidden: element.item.isHidden,
              isExtraCurricular: element.item.isExtraCurricular,
            },
          },
          topic,
        )
      }}
    />
  )
}

export default EditLessonType
