import { useAppDispatch } from "@app/hooks"
import { Syllabus } from "@features/syllabus/syllabusSelectors"
import {
  domainChanged,
  syllabusNameUpdated,
} from "@features/syllabusEditor/syllabusEditorSlice"
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import appTheme from "../../theme/appTheme"
import { domainToDisplayName } from "@utils/domainUtils"
import { ProgramDomain } from "@masterschool/course-builder-api"

export function SyllabusEditorGeneralInfo(props: { syllabus: Syllabus }) {
  const { syllabus } = props
  const dispatch = useAppDispatch()

  const handleDomainChange = (event: SelectChangeEvent) => {
    dispatch(domainChanged(event.target.value))
  }

  const domains = Object.values(ProgramDomain)

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        maxWidth: "600px",
      }}
    >
      <Box>
        <TextField
          label="Syllabus name"
          value={syllabus.name}
          placeholder="Syllabus name"
          multiline
          fullWidth
          InputLabelProps={{ style: { fontSize: 16 } }}
          onChange={(event) =>
            dispatch(
              syllabusNameUpdated({
                name: event.target.value,
              }),
            )
          }
        />
      </Box>
      <Box>
        <FormControl fullWidth>
          <InputLabel sx={{ fontSize: 17 }}>Program</InputLabel>
          <Select
            value={
              domains.includes((syllabus.domain as ProgramDomain) ?? "")
                ? syllabus.domain
                : ""
            }
            label="Program"
            onChange={handleDomainChange}
            disabled={syllabus.isActive}
            sx={{
              bgcolor: appTheme.palette.primary.contrast,
            }}
          >
            {domains.map((programDomain) => (
              <MenuItem key={programDomain} value={programDomain}>
                {domainToDisplayName(programDomain)}
              </MenuItem>
            ))}
          </Select>
          {syllabus.isActive && (
            <FormHelperText>
              Syllabus is active. Program cannot be changed.
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </Box>
  )
}
