import { RootState } from "@app/store"
import { ProgramDto } from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"
import { formatDateAsYearAndLongMonth } from "@utils/date"
import { domainToDisplayName } from "@utils/domainUtils"

export const selectPrograms = (state: RootState): ProgramDto[] => {
  return state.program.programs
}

export const selectProgramsSyllabusPage = (state: RootState) =>
  state.program.syllabusesPage

export const selectProgram = createSelector(
  [
    (state: RootState) => {
      return state.program.programs
    },
    (_, programId: string | undefined) => programId,
  ],
  (programs, programId: string | undefined) => {
    if (!programId) {
      return undefined
    }
    return programs.find((p) => p.id === programId)
  },
)

export const selectClass = (classId?: string) =>
  createSelector(
    (state: RootState) => {
      return state.program.programIdToClasses
    },
    (programIdToClasses) => {
      if (!classId) {
        return undefined
      }
      return Object.values(programIdToClasses)
        .flat()
        .find((c) => c.id === classId)
    },
  )

export const selectClassesForProgram = (programId: string | undefined) =>
  createSelector(
    (state: RootState) => {
      return state.program.programIdToClasses
    },
    (programIdToClasses) => {
      if (!programId || !programIdToClasses[programId]) {
        return []
      }
      return programIdToClasses[programId] ?? []
    },
  )

export const selectFilteredStudents = (programId: string, classId?: string) =>
  createSelector(
    [
      selectClassesForProgram(programId),
      (state) => state.program.studentsPage.search,
    ],
    (classes, search) => {
      return classes
        .filter((c) => !classId || c.id === classId)
        .flatMap((c) => {
          return c.students.map((s) => ({ ...s, classId: c.id }))
        })
        .filter(
          (s) =>
            s.classId.includes(search) ||
            (s.firstName + " " + s.lastName)
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            s.email.toLowerCase().includes(search.toLowerCase()),
        )
    },
  )

export const selectStudents = (programId?: string, classId?: string) =>
  createSelector([selectClassesForProgram(programId)], (classes) =>
    classes
      .filter((c) => !classId || c.id === classId)
      .flatMap((c) =>
        c.students.map((s) => ({ ...s, className: c.name, classId: c.id })),
      ),
  )

export const selectStudent = (
  programId?: string,
  classId?: string,
  studentId?: string,
) =>
  createSelector([selectStudents(programId, classId)], (students) =>
    students.find((s) => s.id === studentId),
  )

export const filterProgramBySearchText = (
  programs: ProgramDto[],
  searchText: string,
) => {
  if (searchText === "") {
    return programs
  }

  const filteredByName = programs.filter((program) =>
    domainToDisplayName(program.domain)
      .toLowerCase()
      .includes(searchText.toLowerCase()),
  )

  const filteredByDate = programs.filter((program) =>
    formatDateAsYearAndLongMonth(program.startDate)
      .toLowerCase()
      .includes(searchText.toLowerCase()),
  )

  const filteredByLanguage = programs.filter((program) =>
    program.language.toLowerCase().includes(searchText.toLowerCase()),
  )

  return [
    ...new Set([...filteredByName, ...filteredByDate, ...filteredByLanguage]),
  ]
}

export const selectProgramByStudentId = (studentId: string) =>
  createSelector(
    (state: RootState) => {
      return Object.keys(state.program.programIdToClasses).filter((programId) =>
        state.program.programIdToClasses[programId].some((cl) =>
          cl.students.find((s) => s.id === studentId),
        ),
      )
    },
    (programs) => programs,
  )
