import { useAppDispatch } from "@app/hooks"
import DragIcon from "@cmp/dragIcon"
import { setDraggingTasksEnabled } from "@features/courseEditor/courseEditorSlice"

const DragTaskIcon = () => {
  const dispatch = useAppDispatch()

  return (
    <DragIcon
      onMouseEnter={() => {
        dispatch(setDraggingTasksEnabled(true))
      }}
      onMouseLeave={() => {
        dispatch(setDraggingTasksEnabled(false))
      }}
    />
  )
}

export default DragTaskIcon
