import { Box, IconButton, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../../theme/appTheme"
import { useSearchParams } from "react-router-dom"
import OptionsButton from "@cmp/buttons/optionsButton"
import { useAppDispatch } from "@app/hooks"
import {
  resetUnitSprintsToDefault,
  syllabusUnitRemoved,
} from "@features/syllabusEditor/syllabusEditorSlice"

function SyllabusPanelHeader() {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const unitId = searchParams.get("unitId")

  const closePanel = () => {
    searchParams.delete("unitId")
    searchParams.delete("unitTab")
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "16px 24px",
        alignItems: "center",
        width: "100%",
        gap: "24px",
        borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
      }}
    >
      <IconButton onClick={closePanel} sx={{ padding: 0 }}>
        <SvgIcon
          component={appIcons.xClose}
          inheritViewBox
          sx={{
            stroke: appTheme.palette.icon.black,
            cursor: "pointer",
          }}
        />
      </IconButton>
      <Typography
        variant="body1_sb"
        sx={{
          display: "flex",
          width: "100%",
          margin: "auto",
        }}
      >
        Unit Information
      </Typography>
      <OptionsButton
        button={{
          leftIcon: appIcons.dots,
          size: "small",
          sx: { padding: "4px" },
        }}
        items={[
          {
            text: "Remove unit",
            icon: appIcons.trash03,
            onSelect: () => {
              if (!unitId) {
                return
              }
              dispatch(
                syllabusUnitRemoved({
                  unitId: unitId,
                }),
              )
            },
          },
          {
            text: "Reset sprints",
            icon: appIcons.refreshCcw,
            onSelect: () => {
              if (unitId) dispatch(resetUnitSprintsToDefault({ unitId }))
            },
          },
        ]}
      />
    </Box>
  )
}

export default SyllabusPanelHeader
