import accountManagementReducer from "@features/account-management/accountManagementSlice"
import courseEditorReducer from "@features/courseEditor/courseEditorSlice"
import courseViewReducer from "@features/courseView/courseViewSlice"
import coursesMenuReducer, {
  TabIdentifierEnumeration,
} from "@features/coursesMenu/coursesMenuSlice"
import loginReducer from "@features/login/loginSlice"
import programReducer from "@features/program/programSlice"
import searchReducer from "@features/search/searchSlice"
import syllabusesMenuReducer from "@features/syllabus/syllabusesMenuSlice"
import syllabusEditorReducer from "@features/syllabusEditor/syllabusEditorSlice"
import uiReducer from "@features/ui/uiSlice"
import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import { SortOrder, SortType } from "../main/sort/coursesSortHelpers"
import observersMiddleware from "./middlewares/observersMiddleware"
import projectsTabReducer from "@features/projects-tab/projectsTabSlice"
import programProjectsReducer from "@features/program/projects/programProjectsSlice"
import slackReducer from "@features/slack/slackSlice"
import appFeatureFlagReducer from "@features/app-feature-flags/appFeatureFlagsSlice"
import salesManagementReducer from "@features/sales-management/salesManagement.slice"

export const store = configureStore({
  reducer: {
    courseEditor: courseEditorReducer,
    coursesMenu: coursesMenuReducer,
    courseView: courseViewReducer,
    search: searchReducer,
    syllabusesMenu: syllabusesMenuReducer,
    syllabusEditor: syllabusEditorReducer,
    ui: uiReducer,
    program: programReducer,
    login: loginReducer,
    accountManagement: accountManagementReducer,
    projectsTab: projectsTabReducer,
    programProjects: programProjectsReducer,
    slack: slackReducer,
    appFeatureFlags: appFeatureFlagReducer,
    salesManagement: salesManagementReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(observersMiddleware),
  preloadedState: {
    coursesMenu: {
      favoriteCourses: JSON.parse(
        localStorage.getItem("favoriteCourses") ?? "[]",
      ),
      courses: "pending",
      sortOption: {
        type: SortType.Alphabetic,
        order: SortOrder.Descending,
      },
      tab: TabIdentifierEnumeration.Published,
      highlightedCourseId: undefined,
    },
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
