import { InputAdornment, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import EditorTextField from "../editor/components/editorTextField"
import appTheme from "../theme/appTheme"

function SearchTextField(props: {
  searchText: string
  onSearchTextChange: (text: string) => void
  placeholder?: string
}) {
  const { searchText, onSearchTextChange, placeholder } = props

  return (
    <EditorTextField
      debounce
      placeholder={placeholder ?? "Search"}
      value={searchText}
      onChange={(e) => onSearchTextChange(e.target.value)}
      size="small"
      fullWidth
      InputProps={{
        sx: {
          borderRadius: "8px",
          fontSize: 14,
          height: "40px",
          lineHeight: "22px",
          bgcolor: appTheme.palette.primary.contrast,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon
              component={appIcons.searchSm}
              inheritViewBox
              sx={{
                stroke: appTheme.palette.icon.black,
                fill: "none",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default SearchTextField
