import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  IconButton,
  Stack,
  SvgIcon,
  SxProps,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import React from "react"

export type GenericDialogProps = {
  /**
   * Dialog title - displayed in the header
   */
  title?: string

  /**
   * Dialog subtitle - displayed below the title
   */
  subtitle?: string

  /**
   * Dialog icon - displayed in the header above the title
   */
  icon?: React.ReactNode

  /**
   * Dialog content - displayed in the body
   */
  content?: React.ReactNode

  /**
   * Whether to disable the content padding x-axis
   */
  disableContentGutters?: boolean

  /**
   * Whether to disable the content padding y-axis and x-axis
   */
  disableContentPadding?: boolean

  /**
   * Callback function to close the dialog
   */
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void

  /**
   * Whether to display a floating close button in the top right corner
   */
  floatingCloseButton?: boolean

  /**
   * Whether the dialog is open
   */
  open: boolean

  /**
   * Dialog buttons:
   *  - type: button type (primary, secondary, danger)
   *  - text: button text
   *  - onClick: button click handler
   *  - loading: whether the button is in loading state
   *  - disabled: whether the button is disabled
   */
  buttons?: {
    type: "primary" | "secondary" | "danger"
    text: string
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    loading?: boolean
    disabled?: boolean
    icon?: React.ReactNode
    align?: "start" | "end"
  }[]

  /**
   * Dialog size (width)s
   */
  size?: "xs" | "sm" | "md" | "lg" | "xl"

  /**
   * Whether to make the dialog full height
   */
  fullHeight?: boolean
}

function GenericDialog({
  title,
  subtitle,
  icon,
  content,
  disableContentGutters,
  disableContentPadding,
  onClose,
  floatingCloseButton,
  buttons = [],
  open,
  size = "md",
  fullHeight = false,
}: GenericDialogProps) {
  const withActions = buttons.length > 0
  const hasDefaultContent = title || subtitle || icon

  let contentContainerSXProps: SxProps = {
    pt: hasDefaultContent ? 2 : 0,
    px: disableContentPadding || disableContentGutters ? 0 : 3,
    pb: disableContentPadding || withActions ? 0 : 3,
  }

  if (fullHeight) {
    contentContainerSXProps = {
      ...contentContainerSXProps,
      height: 1,
      overflow: "auto",
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={size}
      fullWidth
      PaperProps={{
        sx: {
          height: fullHeight ? "100%" : "auto",
        },
      }}
    >
      <Stack flex={1} maxHeight={1} position="relative">
        <Stack
          pt={2}
          pl={3}
          pr={2}
          alignItems="flex-end"
          position={floatingCloseButton ? "absolute" : "relative"}
          right={0}
        >
          <IconButton onClick={onClose} size="small">
            <SvgIcon
              component={appIcons.xClose}
              inheritViewBox
              sx={{
                width: 20,
                height: 20,
                color: "icon.black",
                stroke: "currentColor",
                fill: "none",
              }}
            />
          </IconButton>
        </Stack>
        {hasDefaultContent && (
          <Stack px={3} gap={1}>
            {icon}
            <Stack gap={0.5}>
              {title && <Typography variant="h6">{title}</Typography>}
              {subtitle && (
                <Typography variant="body1" whiteSpace="pre-line">
                  {subtitle}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
        {content && <Stack sx={contentContainerSXProps}>{content}</Stack>}
      </Stack>
      {withActions && (
        <Stack
          direction="row"
          px={3}
          pb={3}
          pt={4}
          gap={2}
          justifyContent="flex-end"
        >
          {buttons.map((button, index) => {
            const isLast = index === buttons.length - 1
            let marginRight = button.align === "start" ? "auto" : 0
            if (!isLast && buttons[index + 1].align === "start") {
              marginRight = 0
            }
            return (
              <LoadingButton
                key={index}
                variant={button.type === "secondary" ? "text" : "contained"}
                color={button.type === "danger" ? "error" : "primary"}
                onClick={button.onClick}
                disabled={button.loading || button.disabled}
                loading={button.loading}
                startIcon={button.icon}
                sx={{
                  marginRight,
                }}
              >
                {button.text}
              </LoadingButton>
            )
          })}
        </Stack>
      )}
    </Dialog>
  )
}

export default GenericDialog
