import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import TitleTextField from "../../components/titleTextField"
import UrlTextField from "../../components/urlTextField"
import { useEditTryHackmeLesson } from "../custom-hooks/useEditElementItem"
import { useSelectedTryHackmeLesson } from "../custom-hooks/useSelectedElementItem"
import EditLessonType from "./EditLessonType"
import { Topic } from "@masterschool/course-builder-api"

function TryHackmeLessonEditor(props: { topic: Topic }) {
  const { topic } = props
  const lesson = useSelectedTryHackmeLesson()
  const editLesson = useEditTryHackmeLesson()

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <TitleTextField
          elementId={lesson.id}
          debounce
          onChange={(e) => {
            const newValue = e.target.value
            editLesson(lesson, "title", newValue, topic)
          }}
          value={lesson.title}
        />
        <EditLessonType topic={topic} />
      </Box>
      <TimeEstimationTextField
        elementId={lesson.id}
        value={lesson.estimatedDuration}
        debounce
        onChange={(e) => {
          editLesson(
            lesson,
            "estimatedDuration",
            parseInt(e.target.value),
            topic,
          )
        }}
      />
      <UrlTextField
        elementId={lesson.id}
        value={lesson.url}
        debounce
        onChange={(e) => {
          editLesson(lesson, "url", e.target.value, topic)
        }}
      />
    </Box>
  )
}

export default TryHackmeLessonEditor
