import { useAppSelector } from "@app/hooks"
import {
  SyllabusDto,
  UnitDto,
  CourseDto,
} from "@masterschool/course-builder-api"

function useComputeStaleCourseContainers(staleCourse: {
  syllabusId: string
  unitId: string
  courseId: string
}):
  | {
      syllabus: SyllabusDto
      unit: UnitDto
      course: CourseDto
    }
  | undefined {
  const { syllabusId, unitId, courseId } = staleCourse
  const syllabus = useAppSelector((state) =>
    state.syllabusesMenu.syllabuses.find((s) => s.id === syllabusId),
  )

  const unit = syllabus?.units.find((u) => u.id === unitId)

  const cd = unit?.courseDescriptors.find((cd) => cd.courseId === courseId)

  const course = useAppSelector((state) =>
    cd ? state.syllabusesMenu.courses[cd.courseId]?.[cd.version] : undefined,
  )

  if (!syllabus || !unit || !course) {
    return undefined
  }

  return {
    syllabus,
    unit,
    course,
  }
}

export default useComputeStaleCourseContainers
