import { useAppDispatch, useAppSelector } from "@app/hooks"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Box, Typography } from "@mui/material"
import EmptyWidgetCard from "./emptyWidgetCard"
import WidgetCard from "./widgetCard"
import { useNavigate } from "react-router-dom"
import { roleSortValue } from "../../staff/staffTable"
import roleDisplayName from "../../../../account-management/roleDisplayName"
import { useEffect } from "react"
import { fetchProgramStaff } from "@features/program/programSliceThunks"

function StaffWidget({ program }: { program: ProgramDto }) {
  const programId = program.id
  const programStaffMembers = useAppSelector(
    (state) => state.program.staffPage.staff,
  )
  const navigate = useNavigate()
  const onAddStaffClick = () => {
    navigate(`/program/${programId}?tab=staff&action=add`)
  }

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchProgramStaff(programId))
  }, [programId, dispatch])

  if (programStaffMembers.length === 0) {
    return (
      <EmptyWidgetCard
        message="There are no staff members to display."
        actionProps={{
          children: "Add staff",
          onClick: onAddStaffClick,
        }}
      />
    )
  }
  const setOfRoles = new Set<string>()
  programStaffMembers.forEach((staffMember) => {
    staffMember.roles.forEach((role) => setOfRoles.add(role))
  })
  const roles = Array.from(setOfRoles).toSorted((a, b) => {
    return roleSortValue(a) - roleSortValue(b)
  })

  return (
    <WidgetCard title="Staff" navigateTo={`/program/${programId}?tab=staff`}>
      <Box display="flex" flexDirection="column" gap="4px">
        {roles.map((role) => {
          const membersWithRole = programStaffMembers
            .filter((staffMember) => staffMember.roles.includes(role))
            .map((staffMember) => staffMember.name)
          return (
            <Box display="flex" gap="8px" key={role}>
              <Typography variant="body2_sb" width="128px" flexShrink={0}>
                {roleDisplayName(role)}
                {membersWithRole.length > 1 ? "s:" : ":"}
              </Typography>
              <Typography variant="body2">
                {membersWithRole.join(", ")}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </WidgetCard>
  )
}

export default StaffWidget
