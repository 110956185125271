import { Theme } from "@emotion/react"
import { ComponentsProps, ComponentsOverrides } from "@mui/material"

export const MuiInputLabelOverrides: {
  defaultProps?: ComponentsProps["MuiInputLabel"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiInputLabel"]
} = {
  defaultProps: {
    sx: {
      fontSize: "12px",
    },
  },
}
