import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { showErrorSnackbar } from "@features/ui/uiSlice"
import {
  ProgramClass,
  SlackChannelData,
} from "../../programs/program/slack/slackChannelsPage"
import { SlackClient } from "../../programs/program/slack/slackClient"
import PendingDataState from "@utils/pendingDataState"
import { updatedCheckedClasses } from "./slackSliceHelper"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"

export type LoadingState = "idle" | "loading" | "failed"

export type CreateNewChannelPopup = {
  programId: string
  loadingState: LoadingState
}

export type EditChannelClassesPopup = {
  offeringGroupId: string
  channelId: string
  channelName: string
  programId: string
  loadingState: LoadingState
  checkedClassesIds: string[]
}

export type SlackState = {
  cohortToClasses: {
    [startDate: string]: PendingDataState<ProgramClass[]>
  }
  programToChannels: {
    [programId: string]: PendingDataState<SlackChannelData[]>
  }
  popups: {
    createNewChannelPopup: CreateNewChannelPopup | undefined
    addClassesToChannelPopup: EditChannelClassesPopup | undefined
    removeClassesFromChannelPopup: EditChannelClassesPopup | undefined
  }
}

export const getInitialState: () => SlackState = () => {
  return {
    cohortToClasses: {},
    programToChannels: {},
    popups: {
      createNewChannelPopup: undefined,
      addClassesToChannelPopup: undefined,
      removeClassesFromChannelPopup: undefined,
    },
  }
}

const slackSlice = createSlice({
  name: "slackSlice",
  initialState: getInitialState,
  reducers: {
    createNewChannelPopupOpened: (
      state,
      action: PayloadAction<Omit<CreateNewChannelPopup, "loadingState">>,
    ) => {
      state.popups.createNewChannelPopup = {
        ...action.payload,
        loadingState: "idle",
      }
    },
    createNewChannelPopupClosed: (state) => {
      state.popups.createNewChannelPopup = undefined
    },
    addClassesToChannelPopupOpened: (
      state,
      action: PayloadAction<
        Omit<EditChannelClassesPopup, "loadingState" | "checkedClassesIds">
      >,
    ) => {
      state.popups.addClassesToChannelPopup = {
        ...action.payload,
        loadingState: "idle",
        checkedClassesIds: [],
      }
    },
    addClassesToChannelPopupClosed: (state) => {
      state.popups.addClassesToChannelPopup = undefined
    },
    addClassesToChannelUpdated: (
      state,
      action: PayloadAction<{ classId: string }>,
    ) => {
      if (!state.popups.addClassesToChannelPopup) {
        return
      }
      updatedCheckedClasses(
        action.payload.classId,
        state.popups.addClassesToChannelPopup,
      )
    },
    removeClassesFromChannelPopupOpened: (
      state,
      action: PayloadAction<
        Omit<EditChannelClassesPopup, "loadingState" | "checkedClassesIds">
      >,
    ) => {
      state.popups.removeClassesFromChannelPopup = {
        ...action.payload,
        loadingState: "idle",
        checkedClassesIds: [],
      }
    },
    removeClassesFromChannelPopupClosed: (state) => {
      state.popups.removeClassesFromChannelPopup = undefined
    },
    removeClassesFromChannelUpdated: (
      state,
      action: PayloadAction<{ classId: string }>,
    ) => {
      if (!state.popups.removeClassesFromChannelPopup) {
        return
      }
      updatedCheckedClasses(
        action.payload.classId,
        state.popups.removeClassesFromChannelPopup,
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgramSlackChannels.pending, (state, action) => {
        state.programToChannels[action.meta.arg] = {
          status: "pending",
        }
      })
      .addCase(fetchProgramSlackChannels.rejected, (state, action) => {
        state.programToChannels[action.meta.arg] = {
          status: "error",
        }
      })
      .addCase(fetchProgramSlackChannels.fulfilled, (state, action) => {
        state.programToChannels[action.meta.arg] = {
          status: "success",
          data: action.payload,
        }
      })
      .addCase(fetchClassesInCohort.pending, (state, action) => {
        state.cohortToClasses[action.meta.arg] = { status: "pending" }
      })
      .addCase(fetchClassesInCohort.rejected, (state, action) => {
        state.cohortToClasses[action.meta.arg] = { status: "error" }
      })
      .addCase(fetchClassesInCohort.fulfilled, (state, action) => {
        state.cohortToClasses[action.meta.arg] = {
          status: "success",
          data: action.payload,
        }
      })
      .addCase(createNewSlackChannel.pending, (state) => {
        if (state.popups.createNewChannelPopup === undefined) {
          return
        }
        state.popups.createNewChannelPopup.loadingState = "loading"
      })
      .addCase(createNewSlackChannel.rejected, (state) => {
        if (state.popups.createNewChannelPopup === undefined) {
          return
        }
        state.popups.createNewChannelPopup.loadingState = "failed"
      })
      .addCase(createNewSlackChannel.fulfilled, (state) => {
        state.popups.createNewChannelPopup = undefined
      })
      .addCase(addClassesToChannel.pending, (state) => {
        if (state.popups.addClassesToChannelPopup === undefined) {
          return
        }
        state.popups.addClassesToChannelPopup.loadingState = "loading"
      })
      .addCase(addClassesToChannel.rejected, (state) => {
        if (state.popups.addClassesToChannelPopup === undefined) {
          return
        }
        state.popups.addClassesToChannelPopup.loadingState = "failed"
      })
      .addCase(addClassesToChannel.fulfilled, (state) => {
        state.popups.addClassesToChannelPopup = undefined
      })
      .addCase(removeClassesFromChannel.pending, (state) => {
        if (state.popups.removeClassesFromChannelPopup === undefined) {
          return
        }
        state.popups.removeClassesFromChannelPopup.loadingState = "loading"
      })
      .addCase(removeClassesFromChannel.rejected, (state) => {
        if (state.popups.removeClassesFromChannelPopup === undefined) {
          return
        }
        state.popups.removeClassesFromChannelPopup.loadingState = "failed"
      })
      .addCase(removeClassesFromChannel.fulfilled, (state) => {
        state.popups.removeClassesFromChannelPopup = undefined
      })
  },
})

export const fetchProgramSlackChannels = createAsyncThunk(
  "slackSlice/fetchProgramSlackChannels",
  async (programId: string, thunkAPI) => {
    return SlackClient.getProgramChannels(programId).catch((e) => {
      thunkAPI.dispatch(showErrorSnackbar("Failed to fetch slack channels"))
      throw e
    })
  },
)

export const fetchClassesInCohort = createAsyncThunk(
  "slackSlice/fetchClassesInCohort",
  async (startDate: string, thunkAPI) => {
    return SlackClient.getClassesInCohort(startDate).catch((e) => {
      thunkAPI.dispatch(showErrorSnackbar("Failed to fetch classes in cohort"))
      throw e
    })
  },
)

export const createNewSlackChannel = createAsyncThunk(
  "slackSlice/createNewSlackChannel",
  async (
    requestParams: {
      name: string
      description: string
      classIDs: string[]
      staffMembersSlackIDs: string[]
      everyoneCanPost: boolean
    },
    thunkAPI,
  ) => {
    return SlackClient.createChannel(requestParams).catch((e) => {
      thunkAPI.dispatch(showErrorSnackbar("Failed to create new slack channel"))
      throw e
    })
  },
)

export const addClassesToChannel = createAppAsyncThunk(
  "slackSlice/addClassesToChannel",
  async (_, thunkAPI) => {
    const popupState = thunkAPI.getState().slack.popups.addClassesToChannelPopup
    if (!popupState) {
      return
    }
    const offeringGroupId = popupState.offeringGroupId
    const classIds = popupState.checkedClassesIds
    return SlackClient.addClassesToChannel({ offeringGroupId, classIds }).catch(
      (e) => {
        thunkAPI.dispatch(showErrorSnackbar("Failed to add classes to channel"))
        throw e
      },
    )
  },
)

export const removeClassesFromChannel = createAppAsyncThunk(
  "slackSlice/removeClassesFromChannel",
  async (_, thunkAPI) => {
    const popupState =
      thunkAPI.getState().slack.popups.removeClassesFromChannelPopup
    if (!popupState) {
      return
    }
    const offeringGroupId = popupState.offeringGroupId
    const classIds = popupState.checkedClassesIds
    return SlackClient.removeClassesFromChannel({
      offeringGroupId,
      classIds,
    }).catch((e) => {
      thunkAPI.dispatch(
        showErrorSnackbar("Failed to remove classes from channel"),
      )
      throw e
    })
  },
)

export const {
  createNewChannelPopupOpened,
  createNewChannelPopupClosed,
  addClassesToChannelPopupOpened,
  addClassesToChannelPopupClosed,
  addClassesToChannelUpdated,
  removeClassesFromChannelPopupOpened,
  removeClassesFromChannelPopupClosed,
  removeClassesFromChannelUpdated,
} = slackSlice.actions

export default slackSlice.reducer
