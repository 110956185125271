import { useAppSelector } from "@app/hooks"
import { useSearchParams } from "react-router-dom"
import { selectActiveCourse } from "@features/courseEditor/courseEditorSelectors"
import { CourseSyllabusElementTypeEnum } from "@masterschool/course-builder-api"

function useSelectedElement(type?: CourseSyllabusElementTypeEnum) {
  const [searchParams] = useSearchParams()
  const elementId = searchParams.get("elementId")
  const activeCourse = useAppSelector(selectActiveCourse)

  if (!elementId || !activeCourse) {
    return undefined
  }

  const topic = activeCourse.syllabus.topics.find((t) =>
    t.elements.some((element) => element.item.id === elementId),
  )

  const selectedElement = topic?.elements.find(
    (element) => element.item.id === elementId,
  )

  if (!topic || !selectedElement || (type && selectedElement.type !== type)) {
    return undefined
  }

  return selectedElement
}

export default useSelectedElement
