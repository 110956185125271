import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material"

export const MuiTextFieldOverrides: {
  defaultProps?: ComponentsProps["MuiTextField"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiTextField"]
} = {
  styleOverrides: {
    root: ({ theme }) => {
      const body2Size = theme.typography.body2.fontSize
      const shrinkedLabelFontSize = calcShrinkedFontSize(
        theme.typography.body3.fontSize,
      )
      return {
        "& .MuiOutlinedInput-root": {
          fontSize: body2Size,
          backgroundColor: "#fff",
          // Or added this, not sure why. "Maybe for search fields?" he said.
          ":before": {
            content: '""',
            display: "inline-block",
          },
          "& fieldset": {
            // this is the size of the hidden label, which makes the border disappear where there's the label
            fontSize: shrinkedLabelFontSize,
            borderColor: theme.palette.other.outlineBorder,
          },
          "&:hover fieldset": {
            borderColor: theme.palette.action.active,
          },
          "&.Mui-focused fieldset": {
            borderColor: theme.palette.action.active,
          },
          "&.Mui-focused.Mui-error fieldset": {
            borderColor: theme.palette.error.main,
          },
        },
        "& .MuiInputLabel-root": {
          // this is shown as the placeholder text
          fontSize: body2Size,
        },
        "& .MuiInputLabel-shrink": {
          fontSize: shrinkedLabelFontSize,
          // We change the translation from default since we change the font size from the deafult and we want
          // the label to be vertically positioned correctly.
          transform: `translate(14px, -12px) scale(${SHRINKED_LABEL_SCALE})`,
        },
      }
    },
  },
}

// MUI's TextField scales the label font size down when the input is focused or filled. This is the default value.
const SHRINKED_LABEL_SCALE = 0.75

function calcShrinkedFontSize(fontSize: string | number | undefined) {
  if (!fontSize) return "22.667px"
  if (typeof fontSize === "number") {
    fontSize = `${fontSize}px`
  }
  return `calc(${fontSize} / ${SHRINKED_LABEL_SCALE})`
}
