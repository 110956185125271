import { CustomMenuItem } from "@cmp/menu/customMenuItem"
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Menu,
  SvgIcon,
  SxProps,
  Theme,
} from "@mui/material"
import { IconType } from "@utils/iconType"
import { useState, useRef } from "react"

export type OptionsButtonProps = {
  button: {
    sx?: SxProps<Theme>
    iconSx?: SxProps<Theme>
    leftIcon?: IconType
    rightIcon?: IconType
    text?: string
    variant?: ButtonProps["variant"]
    size?: ButtonProps["size"]
  }
  items: {
    text: string
    onSelect: () => void
    icon?: IconType
    thumbnail?: JSX.Element
    sxIcon?: SxProps<Theme>
    disabled?: boolean
  }[]
  sx?: SxProps<Theme>
}

function OptionsButton(props: OptionsButtonProps) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  return (
    <Box
      sx={{
        ...props.sx,
      }}
    >
      <div ref={anchorRef}>
        <OpenListButton
          {...props.button}
          onClick={() => {
            setOpen(true)
          }}
        />
      </div>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          sx: {
            width:
              props.button.text === undefined
                ? "fit-content"
                : anchorRef.current && anchorRef.current.offsetWidth,
          },
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {props.items.map((item) => (
          <CustomMenuItem
            key={item.text}
            text={item.text}
            icon={item.icon}
            thumbnail={item.thumbnail}
            sxIcon={item.sxIcon}
            disabled={item.disabled}
            onClick={() => {
              setOpen(false)
              item.onSelect()
            }}
          />
        ))}
      </Menu>
    </Box>
  )
}

function OpenListButton(props: {
  onClick: () => void
  sx?: SxProps<Theme>
  iconSx?: SxProps<Theme>
  leftIcon?: IconType
  rightIcon?: IconType
  text?: string
  variant?: ButtonProps["variant"]
  size?: ButtonProps["size"]
}) {
  const { onClick, sx, iconSx, leftIcon, rightIcon, text, variant, size } =
    props

  if (!leftIcon && !text) {
    return null
  }

  if (leftIcon && !text) {
    return (
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
        sx={{
          ...sx,
        }}
      >
        {leftIcon && (
          <SvgIcon component={leftIcon} sx={iconSx} inheritViewBox />
        )}
        {text && <span>{text}</span>}

        {rightIcon && (
          <SvgIcon component={rightIcon} sx={iconSx} inheritViewBox />
        )}
      </IconButton>
    )
  } else {
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
        startIcon={
          leftIcon && (
            <SvgIcon component={leftIcon} sx={iconSx} inheritViewBox />
          )
        }
        endIcon={
          rightIcon && (
            <SvgIcon component={rightIcon} sx={iconSx} inheritViewBox />
          )
        }
        sx={{
          ...sx,
        }}
        variant={variant ?? "outlined"}
        size={size ?? "medium"}
      >
        {text}
      </Button>
    )
  }
}

export default OptionsButton
