import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  onboardStaffFormAccepted,
  onboardStaffPopupClosed,
} from "@features/account-management/accountManagementSlice"
import { selectIsAdmin } from "@features/login/loginSelectors"
import { Alert, Stack, SvgIcon, Typography } from "@mui/material"
import isValidEmail from "@utils/isValidEmail"
import appTheme from "../../theme/appTheme"
import { OnboardStaffDetailsForm } from "./onboardStaffDetailsForm"
import { OnboardStaffRoleSelection } from "./onboardStaffRoleSelection"
import appIcons from "@utils/appIcons"

function OnboardStaffDialog() {
  const model = useAppSelector(
    (state) => state.accountManagement.onboardStaffPopup,
  )
  const isAdmin = useAppSelector(selectIsAdmin)
  const dispatch = useAppDispatch()

  return (
    <GenericDialog
      open={model !== undefined}
      onClose={() => dispatch(onboardStaffPopupClosed(undefined))}
      title="Onboard new staff"
      size="sm"
      content={
        <Stack gap={2}>
          <OnboardStaffRoleSelection isAdmin={isAdmin} />
          <OnboardStaffDetailsForm model={model} />
          <Alert
            icon={
              <SvgIcon
                component={appIcons.infoCircle}
                inheritViewBox
                sx={{ fill: "none", width: 20, height: 20 }}
              />
            }
            severity="info"
          >
            An email invitation to Slack will be automatically sent
          </Alert>
          {model?.requestStatus === "error" && (
            <Typography color={appTheme.palette.error.main}>
              Something went wrong. Please try again later.
            </Typography>
          )}
        </Stack>
      }
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: () => dispatch(onboardStaffPopupClosed(undefined)),
        },
        {
          text: "Onboard staff",
          type: "primary",
          loading: model?.requestStatus === "pending",
          onClick: () => dispatch(onboardStaffFormAccepted()),
          disabled:
            !model?.form.email ||
            !model?.form.firstName ||
            !model?.form.lastName ||
            !isValidEmail(model?.form.email),
        },
      ]}
    />
  )
}

export default OnboardStaffDialog
