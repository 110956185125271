import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import {
  addClassesToChannel,
  addClassesToChannelPopupClosed,
  addClassesToChannelUpdated,
  fetchProgramSlackChannels,
} from "@features/slack/slackSlice"
import { selectSlackChannel } from "@features/slack/slackSliceSelector"
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import { ProgramClass } from "../slackChannelsPage"
import { ClassesToCheck } from "./dialogSharedComponents"

export const AddClassesToChannelDialog = () => {
  const model = useAppSelector(
    (state) => state.slack.popups.addClassesToChannelPopup,
  )
  const dispatch = useAppDispatch()
  const programClasses = useAppSelector(
    selectClassesForProgram(model?.programId),
  )

  const slackChannel = useAppSelector(
    selectSlackChannel(model?.programId, model?.channelId),
  )
  const programClassesNotInChannel = programClasses.filter(
    (programClass) =>
      slackChannel?.classes.find((c) => c.clientId === programClass.id) ===
      undefined,
  )

  const isDisabled = !model || model.checkedClassesIds.length === 0

  const onSubmit = () => {
    if (model === undefined || model.checkedClassesIds.length === 0) {
      return
    }
    dispatch(addClassesToChannel())
      .then(() => dispatch(fetchProgramSlackChannels(model.programId)))
      .finally(() => dispatch(addClassesToChannelPopupClosed()))
  }

  return (
    <GenericDialog
      open={model !== undefined}
      onClose={() => dispatch(addClassesToChannelPopupClosed())}
      size="sm"
      title={`Add classes to ${model?.channelName}`}
      content={
        <Stack direction="row" width="100%" pt="24px" gap={3}>
          <ClassesInChannel classes={slackChannel?.classes ?? []} />
          <Divider orientation="vertical" flexItem />
          <ClassesToCheck
            classesToCheck={programClassesNotInChannel}
            checkedClassesId={model?.checkedClassesIds ?? []}
            handleCheck={(classId: string) =>
              dispatch(addClassesToChannelUpdated({ classId }))
            }
            title="Add new classes"
          />
        </Stack>
      }
      buttons={[
        {
          type: "primary",
          text: !isDisabled
            ? model.checkedClassesIds.length === 1
              ? "Add 1 class"
              : `Add ${model.checkedClassesIds.length} classes`
            : "Add classes",
          disabled: isDisabled,
          onClick: onSubmit,
          loading: model?.loadingState === "loading",
        },
      ]}
    />
  )
}

function ClassesInChannel({ classes }: { classes: ProgramClass[] }) {
  return (
    <Stack direction="column" flex={1}>
      <Typography
        variant="body2"
        fontWeight="400"
      >{`Added classes (${classes.length})`}</Typography>
      <List>
        {classes.map((classItem) => (
          <ListItem key={classItem.clientId}>
            <ListItemText primary={`• ${classItem.description}`} />
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}
