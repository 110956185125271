import BackButton from "@cmp/buttons/backButton"
import { SelectedStatusChip } from "@cmp/syllabusStatusChip"
import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { duplicate } from "@features/syllabus/syllabusesMenuSlice"
import appTheme from "../theme/appTheme"
import OptionsButton from "@cmp/buttons/optionsButton"
import appIcons from "@utils/appIcons"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { PopupType, popupItemClicked } from "@features/ui/uiSlice"
import {
  Syllabus,
  selectLastPublishedSyllabus,
} from "@features/syllabus/syllabusSelectors"
import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"

export function SyllabusViewHeader(props: {
  syllabus: Syllabus
  onEditClicked: () => void
}) {
  const { syllabus, onEditClicked } = props

  const { name, status, isActive, id: syllabusId } = syllabus

  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px 16px",
        width: "100%",
        borderBottom: `1px solid ${appTheme.palette.divider}`,
        paddingRight: "60px",
        bgcolor: appTheme.palette.primary.contrast,
      }}
    >
      <Box width="33%">
        <BackButton
          onClick={() => {
            navigate(`/syllabuses`)
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "34%",
          justifyContent: "center",
          alignItems: "center",
          gap: "18px",
        }}
      >
        <Box>
          <Typography>{name?.length ? name : "Unnamed syllabus"}</Typography>
          <span
            style={{
              color: appTheme.palette.text.disabled,
            }}
          >
            {" "}
          </span>
        </Box>
        <SelectedStatusChip
          syllabusId={syllabus.id}
          status={status}
          isActive={isActive}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "33%",
          justifyContent: "end",
          gap: "8px",
        }}
      >
        <OptionsMenu syllabusId={syllabusId} />
        <Button onClick={onEditClicked} size="small" variant="outlined">
          Edit
        </Button>
      </Box>
    </Box>
  )
}

const OptionsMenu = (props: { syllabusId: string }) => {
  const { syllabusId } = props
  const dispatch = useAppDispatch()
  const syllabus = useAppSelector(selectLastPublishedSyllabus(syllabusId))

  const onDuplicateClicked = () => {
    dispatch(duplicate(syllabusId))
  }

  const onPreviewInCampusClicked = () => {
    if (!syllabus) return
    dispatch(
      popupItemClicked({
        type: PopupType.CampusSyllabusPreview,
        syllabus,
        programId: syllabus.programId,
      }),
    )
  }

  const onDeleteClicked = () => {
    dispatch(
      popupItemClicked({
        type: PopupType.SyllabusDeleteConfirmation,
        syllabusId,
      }),
    )
  }

  const items = [
    {
      text: "Preview in campus",
      onSelect: onPreviewInCampusClicked,
    },
    {
      text: "Duplicate",
      onSelect: onDuplicateClicked,
    },
    {
      text: "Show Campus JSON",
      onSelect: () => {
        if (syllabus === undefined) return
        dispatch(
          popupItemClicked({
            type: PopupType.CampusSyllabusJSONPreview,
            syllabusId: syllabus.id,
            version: syllabus.version,
          }),
        )
      },
    },
    {
      text: "Version History",
      onSelect: () => {
        if (syllabus === undefined) return
        dispatch(
          popupItemClicked({
            type: PopupType.SyllabusVersionsHistory,
            syllabusId: syllabus.id,
          }),
        )
      },
    },
  ]

  if (syllabus?.status === SyllabusDtoStatusEnum.Archived) {
    items.push({
      text: "Delete",
      onSelect: onDeleteClicked,
    })
  } else if (syllabus?.status === SyllabusDtoStatusEnum.Draft) {
    items.push({
      text: "Archive",
      onSelect: () => {
        dispatch(
          popupItemClicked({
            syllabusId: syllabus.id,
            type: PopupType.ArchiveSyllabusConfirmation,
          }),
        )
      },
    })
  }

  return (
    <OptionsButton
      button={{
        sx: {
          padding: "3px",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        },
        leftIcon: appIcons.dots,
      }}
      items={items}
    />
  )
}
