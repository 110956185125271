import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles"

const MuiListItemOverrides: {
  defaultProps?: ComponentsProps["MuiListItem"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiListItem"]
  variants?: ComponentsVariants["MuiListItem"]
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    }),
  },
}

export { MuiListItemOverrides }
