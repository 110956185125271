import {
  CourseDto,
  CourseItemValidation,
  CourseSyllabusElement,
  CourseSyllabusLiveEvent,
  Topic,
} from "@masterschool/course-builder-api"
import { CourseEditorState } from "./courseEditorSlice"
import { CourseEditingStep } from "./courseEditorUtils"
import { arrayMove } from "@dnd-kit/sortable"

export function pushNewStep(state: CourseEditorState, step: CourseEditingStep) {
  state.steps.splice(
    state.currentStep + 1,
    state.steps.length - state.currentStep - 1,
    step,
  )
  state.currentStep = state.steps.length - 1
}

export function topicEntryAdded(
  state: CourseEditorState,
  action: {
    editStepId: string
    topicId: string
    element?: CourseSyllabusElement
    liveEvent?: CourseSyllabusLiveEvent
  },
) {
  const course = state.steps[state.currentStep].value
  const topic = course.syllabus.topics.find((t) => t.id === action.topicId)

  if (!topic) {
    return
  }

  const updatedTopic = {
    ...topic,
    elements: action.element
      ? topic.elements.concat(action.element)
      : topic.elements,
    liveEvents: action.liveEvent
      ? topic.liveEvents.concat(action.liveEvent)
      : topic.liveEvents,
  }

  const updatedCourse: CourseDto = {
    ...course,
    syllabus: {
      ...course.syllabus,
      topics: course.syllabus.topics.map((t) =>
        t.id === action.topicId ? updatedTopic : t,
      ),
    },
  }
  const entryCaption = action.element
    ? `Element ${
        action.element.item.title ?? action.element.item.id
      } added to ${action.topicId}`
    : `Live event ${action.liveEvent?.title ?? action.liveEvent?.id} added to ${
        action.topicId
      }`

  pushNewStep(state, {
    stepId: action.editStepId,
    value: updatedCourse,
    caption: entryCaption,
  })
}

export function topicEntryEdited(
  state: CourseEditorState,
  action: {
    editStepId: string
    topicId: string
    element?: CourseSyllabusElement
    liveEvent?: CourseSyllabusLiveEvent
  },
) {
  const course = state.steps[state.currentStep].value
  const topic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, action.topicId),
  )

  if (!topic) {
    return
  }
  const updatedElements = () => {
    const element = action.element
    if (element) {
      return topic.elements.map((e) =>
        e.item.id === element.item.id ? element : e,
      )
    } else {
      return topic.elements
    }
  }
  const updatedLiveEvents = () => {
    const liveEvent = action.liveEvent
    if (liveEvent) {
      return topic.liveEvents.map((le) =>
        le.id === liveEvent.id ? liveEvent : le,
      )
    } else {
      return topic.liveEvents
    }
  }

  const updatedTopic = {
    ...topic,
    elements: updatedElements(),
    liveEvents: updatedLiveEvents(),
  }

  const updatedCourse: CourseDto = {
    ...course,
    syllabus: {
      ...course.syllabus,
      topics: course.syllabus.topics.map((t) =>
        areTopicIdsEqual(t.id, topic.id) ? updatedTopic : t,
      ),
    },
  }
  pushNewStep(state, {
    stepId: action.editStepId,
    value: updatedCourse,
    caption: `Element edited`,
  })
}

export function moveTopic(
  course: CourseDto,
  sourceIndex: number,
  targetIndex: number,
): CourseDto {
  if (
    sourceIndex < 0 ||
    sourceIndex >= course.syllabus.topics.length ||
    targetIndex < 0 ||
    targetIndex >= course.syllabus.topics.length
  ) {
    return course
  }

  return {
    ...course,
    syllabus: {
      ...course.syllabus,
      topics: arrayMove(course.syllabus.topics, sourceIndex, targetIndex),
    },
  }
}

export function moveElement(
  course: CourseDto,
  topicIdWithSuffix: string,
  sourceIndex: number,
  targetIndex: number,
): CourseDto {
  const topicId = removeItemSuffix(topicIdWithSuffix)
  const topic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, topicId),
  )

  if (!topic) {
    return course
  }

  if (
    (topicIdWithSuffix.endsWith("elements") &&
      !isIndicesInBounds(sourceIndex, targetIndex, topic.elements)) ||
    (topicIdWithSuffix.endsWith("liveEvents") &&
      !isIndicesInBounds(sourceIndex, targetIndex, topic.liveEvents))
  ) {
    return course
  }

  const updatedTopic = topicIdWithSuffix.endsWith("elements")
    ? {
        ...topic,
        elements: arrayMove(topic.elements, sourceIndex, targetIndex),
      }
    : topicIdWithSuffix.endsWith("liveEvents")
    ? {
        ...topic,
        liveEvents: arrayMove(topic.liveEvents, sourceIndex, targetIndex),
      }
    : topic

  return {
    ...course,
    syllabus: {
      ...course.syllabus,
      topics: course.syllabus.topics.map((t) =>
        areTopicIdsEqual(t.id, topicId) ? updatedTopic : t,
      ),
    },
  }
}

export function moveElementToTopic(
  course: CourseDto,
  sourceTopicIdWithSuffix: string,
  targetTopicIdWithSuffix: string,
  sourceIndex: number,
  targetIndex: number,
): CourseDto {
  if (
    !areContainersWithSameType(sourceTopicIdWithSuffix, targetTopicIdWithSuffix)
  ) {
    return moveLiveEventToEmptyTopic(
      course,
      sourceTopicIdWithSuffix,
      targetTopicIdWithSuffix,
      sourceIndex,
    )
  }
  const sourceTopicId = removeItemSuffix(sourceTopicIdWithSuffix)
  const targetTopicId = removeItemSuffix(targetTopicIdWithSuffix)
  const element = course.syllabus.topics.find(
    (t) => t.id + "-elements" === sourceTopicIdWithSuffix,
  )?.elements[sourceIndex]
  const liveEvent = course.syllabus.topics.find(
    (t) => t.id + "-liveEvents" === sourceTopicIdWithSuffix,
  )?.liveEvents[sourceIndex]

  if (!element && !liveEvent) {
    return course
  }

  const sourceTopic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, sourceTopicId),
  )
  const targetTopic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, targetTopicId),
  )

  if (!sourceTopic || !targetTopic) {
    return course
  }

  const updatedSourceTopic = element
    ? {
        ...sourceTopic,
        elements: sourceTopic.elements.filter(
          (e) => e.item.id !== element.item.id,
        ),
      }
    : liveEvent
    ? {
        ...sourceTopic,
        liveEvents: sourceTopic.liveEvents.filter(
          (le) => le.id !== liveEvent.id,
        ),
      }
    : sourceTopic

  if (
    (targetTopicId.endsWith("elements") &&
      targetIndex >= targetTopic.elements.length) ||
    (targetTopicId.endsWith("liveEvents") &&
      targetIndex >= targetTopic.liveEvents.length)
  ) {
    return course
  }

  const updatedTargetTopic = element
    ? {
        ...targetTopic,
        elements: addElementToArray(targetTopic.elements, element, targetIndex),
      }
    : liveEvent
    ? {
        ...targetTopic,
        liveEvents: addElementToArray(
          targetTopic.liveEvents,
          liveEvent,
          targetIndex,
        ),
      }
    : targetTopic

  return {
    ...course,
    syllabus: {
      ...course.syllabus,
      topics: course.syllabus.topics.map((t) => {
        if (areTopicIdsEqual(t.id, sourceTopicId)) {
          return updatedSourceTopic
        } else if (areTopicIdsEqual(t.id, targetTopicId)) {
          return updatedTargetTopic
        } else {
          return t
        }
      }),
    },
  }
}

export function flattenValidations(validations: CourseItemValidation): {
  [key: string]: string[]
} {
  const result: { [key: string]: string[] } = {}

  if (validations.errors.length > 0) {
    result[validations.id] = validations.errors
  }

  validations.topicsValidations.forEach((topicValidation) => {
    if (topicValidation.errors.length > 0) {
      result[topicValidation.id] = topicValidation.errors
    }

    topicValidation.syllabusElementsValidations.forEach(
      (syllabusElementValidation) => {
        if (syllabusElementValidation.errors.length > 0) {
          result[syllabusElementValidation.id] =
            syllabusElementValidation.errors
        }

        syllabusElementValidation.tasksValidations.forEach((taskValidation) => {
          if (taskValidation.errors.length > 0) {
            result[taskValidation.id] = taskValidation.errors
          }
        })
      },
    )
    topicValidation.syllabusLiveEventsValidations.forEach(
      (syllabusLiveEventValidation) => {
        if (syllabusLiveEventValidation.errors.length > 0) {
          result[syllabusLiveEventValidation.id] =
            syllabusLiveEventValidation.errors
        }
      },
    )
  })

  return result
}

function moveLiveEventToEmptyTopic(
  course: CourseDto,
  sourceTopicIdWithSuffix: string,
  targetTopicIdWithSuffix: string,
  sourceIndex: number,
) {
  const sourceTopicId = removeItemSuffix(sourceTopicIdWithSuffix)
  const targetTopicId = removeItemSuffix(targetTopicIdWithSuffix)

  const liveEvent = course.syllabus.topics.find(
    (t) => t.id + "-liveEvents" === sourceTopicIdWithSuffix,
  )?.liveEvents[sourceIndex]
  const element = course.syllabus.topics.find(
    (t) => t.id + "-elements" === sourceTopicIdWithSuffix,
  )?.elements[sourceIndex]

  if (!liveEvent && !element) {
    return course
  }

  const sourceTopic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, sourceTopicId),
  )
  const targetTopic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, targetTopicId),
  )

  if (!sourceTopic || !targetTopic) {
    return course
  }

  if (liveEvent && targetTopic.liveEvents.length === 0) {
    const updatedSourceTopic = {
      ...sourceTopic,
      liveEvents: sourceTopic.liveEvents.filter((le) => le.id !== liveEvent.id),
    }
    const updatedTargetTopic = {
      ...targetTopic,
      liveEvents: [liveEvent],
    }

    return updateCourseWithTopic(
      course,
      updatedSourceTopic,
      updatedTargetTopic,
      sourceTopicId,
      targetTopicId,
    )
  }

  if (element && targetTopic.elements.length === 0) {
    const updatedSourceTopic = {
      ...sourceTopic,
      elements: sourceTopic.elements.filter(
        (e) => e.item.id !== element.item.id,
      ),
    }
    const updatedTargetTopic = {
      ...targetTopic,
      elements: [element],
    }

    return updateCourseWithTopic(
      course,
      updatedSourceTopic,
      updatedTargetTopic,
      sourceTopicId,
      targetTopicId,
    )
  }

  return course
}

function updateCourseWithTopic(
  course: CourseDto,
  updatedSourceTopic: Topic,
  updatedTargetTopic: Topic,
  sourceTopicId: string,
  targetTopicId: string,
): CourseDto {
  return {
    ...course,
    syllabus: {
      ...course.syllabus,
      topics: course.syllabus.topics.map((t) => {
        if (areTopicIdsEqual(t.id, sourceTopicId)) {
          return updatedSourceTopic
        } else if (areTopicIdsEqual(t.id, targetTopicId)) {
          return updatedTargetTopic
        } else {
          return t
        }
      }),
    },
  }
}

function removeItemSuffix(id: string): string {
  if (id.endsWith("-elements")) {
    return id.replace("-elements", "")
  }
  if (id.endsWith("-liveEvents")) {
    return id.replace("-liveEvents", "")
  }
  return id
}

function isIndicesInBounds(
  sourceIndex: number,
  targetIndex: number,
  entriesArray: CourseSyllabusElement[] | CourseSyllabusLiveEvent[],
) {
  return (
    sourceIndex >= 0 &&
    sourceIndex < entriesArray.length &&
    targetIndex >= 0 &&
    targetIndex < entriesArray.length
  )
}

export function areContainersWithSameType(id1: string, id2: string) {
  return (
    (id1.endsWith("-elements") && id2.endsWith("-elements")) ||
    (id1.endsWith("-liveEvents") && id2.endsWith("-liveEvents"))
  )
}

function addElementToArray<T>(array: T[], entry: T, index: number): T[] {
  const copyArray = [...array]
  copyArray.splice(index, 0, entry)
  return copyArray
}

function areTopicIdsEqual(id1: string | number, id2: string | number) {
  // some legacy uses number as id
  // eslint-disable-next-line eqeqeq
  return id1 == id2
}

export function moveElementToEndOfTopic(
  course: CourseDto,
  sourceTopicId: string,
  elementId: string,
  targetTopicId: string,
): CourseDto {
  const sourceTopic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, sourceTopicId),
  )
  const targetTopic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, targetTopicId),
  )
  const element = sourceTopic?.elements.find((e) => e.item.id === elementId)

  if (!sourceTopic || !targetTopic || !element) {
    return course
  }

  const updatedSourceTopic = {
    ...sourceTopic,
    elements: sourceTopic.elements.filter((e) => e.item.id !== elementId),
  }
  const updatedTargetTopic = {
    ...targetTopic,
    elements: targetTopic.elements.concat(element),
  }

  return updateCourseWithTopic(
    course,
    updatedSourceTopic,
    updatedTargetTopic,
    sourceTopicId,
    targetTopicId,
  )
}

export function moveLiveEventToEndOfTopic(
  course: CourseDto,
  sourceTopicId: string,
  liveEventId: string,
  targetTopicId: string,
): CourseDto {
  const sourceTopic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, sourceTopicId),
  )
  const targetTopic = course.syllabus.topics.find((t) =>
    areTopicIdsEqual(t.id, targetTopicId),
  )
  const liveEvent = sourceTopic?.liveEvents.find((le) => le.id === liveEventId)

  if (!sourceTopic || !targetTopic || !liveEvent) {
    return course
  }

  const updatedSourceTopic = {
    ...sourceTopic,
    liveEvents: sourceTopic.liveEvents.filter((le) => le.id !== liveEventId),
  }
  const updatedTargetTopic = {
    ...targetTopic,
    liveEvents: targetTopic.liveEvents.concat(liveEvent),
  }

  return updateCourseWithTopic(
    course,
    updatedSourceTopic,
    updatedTargetTopic,
    sourceTopicId,
    targetTopicId,
  )
}
