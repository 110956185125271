import {
  Container,
  Stack,
  SvgIcon,
  SvgIconProps,
  Typography,
} from "@mui/material"
import React from "react"

const EmptyStateContainer = ({
  title,
  subtitle,
  icon,
  action,
  preventOverrideIconColor = false,
}: {
  icon: React.ElementType
  title: string
  subtitle: string
  action: React.ReactNode
  preventOverrideIconColor?: boolean
}) => {
  let iconSxProps: SvgIconProps["sx"] = {
    width: 24,
    height: 24,
  }
  if (!preventOverrideIconColor) {
    iconSxProps = {
      ...iconSxProps,
      color: "icon.default",
      fill: "none",
      stroke: "currentColor",
    }
  }

  return (
    <Container maxWidth="xl">
      <Stack gap={3} py={10} px={5} alignItems="center">
        <Stack
          bgcolor="eTypes.sand50"
          borderRadius={50}
          p={2}
          justifyContent="center"
        >
          <SvgIcon component={icon} inheritViewBox sx={iconSxProps} />
        </Stack>
        <Stack gap={0.5}>
          <Typography variant="body1_sb" align="center">
            {title}
          </Typography>
          <Typography variant="body1" align="center">
            {subtitle}
          </Typography>
        </Stack>
        {action}
      </Stack>
    </Container>
  )
}

export default EmptyStateContainer
