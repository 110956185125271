import { Button, Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { Link } from "react-router-dom"

const WidgetCard = ({
  title,
  children,
  onEdit,
  editLabel,
  editIcon,
  navigateTo,
}: {
  title: string
  children: React.ReactNode
  onEdit?: () => void
  editLabel?: string
  editIcon?: React.ElementType
  navigateTo?: string
}) => {
  const showEditButton = onEdit || navigateTo
  const buttonIcon = (
    <SvgIcon
      component={editIcon ?? appIcons.edit03}
      inheritViewBox
      sx={{
        width: 20,
        height: 20,
        fill: "none",
        color: "icon.black",
        stroke: "currentColor",
      }}
    />
  )

  return (
    <Stack
      bgcolor="primary.contrast"
      border="1px solid"
      borderColor="other.divider"
      borderRadius={2}
    >
      <Stack
        direction="row"
        borderBottom="1px solid"
        borderColor="other.divider"
        pl={3}
        pr={2}
        py={1}
        gap={1}
        alignItems="center"
      >
        <Typography variant="body1_sb" flex={1}>
          {title}
        </Typography>
        {showEditButton && (
          <Button
            size="small"
            {...{
              ...(navigateTo && { LinkComponent: Link, to: navigateTo }),
              ...(onEdit && { onClick: onEdit }),
            }}
            startIcon={editLabel ? buttonIcon : undefined}
            sx={{ p: "6px" }}
          >
            {!editLabel && buttonIcon}
            {editLabel}
          </Button>
        )}
      </Stack>
      <Stack p={3}>{children}</Stack>
    </Stack>
  )
}

export default WidgetCard
