import { Box, SvgIcon, Typography } from "@mui/material"
import { IconType } from "@utils/iconType"

function ProgramInfoCard(props: {
  icon?: IconType
  title: string
  value: string
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
        gap: "16px",
      }}
    >
      {props.icon ? (
        <SvgIcon
          inheritViewBox
          component={props.icon}
          sx={{
            fill: "none",
          }}
        />
      ) : null}
      <Box>
        <Typography variant="body2">{props.title}</Typography>
        <Typography variant="body2_sb">{props.value}</Typography>
      </Box>
    </Box>
  )
}

export default ProgramInfoCard
