import { useAppDispatch } from "@app/hooks"
import { ConsultantClient } from "@clients/consultantClient"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { consultantsWithShiftsSelector } from "@features/sales-management/salesManagement.selectors"
import {
  updateConsultantAvailability,
  updateConsultants,
} from "@features/sales-management/salesManagement.slice"
import { CreateConsultantDto } from "@masterschool/course-builder-api"
import PlusIcon from "@mui/icons-material/Add"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  Typography,
} from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import { useSelector } from "react-redux"
import EmptyStateContainer from "../programs/program/components/emptyStateContainer"
import appTheme from "../theme/appTheme"
import { ConsultantCell } from "./consultantCell"
import ContactForm from "./consultantForm"
import { ShiftCell } from "./shiftCell"
import { useNavigate } from "react-router-dom"

const EmptyState = (props: { onAddConsultantClick: () => void }) => {
  return (
    <EmptyStateContainer
      title="No consultants yet"
      subtitle="Add and manage consultants"
      icon={appIcons.bank}
      action={
        <Button variant="contained" onClick={props.onAddConsultantClick}>
          Add consultant
        </Button>
      }
    />
  )
}

export const SalesManagement = () => {
  const navigate = useNavigate()
  const user = useSelector(selectLoggedInUser)
  const userName = user?.firstName + " " + user?.lastName
  const consultantsWithShifts = useSelector(consultantsWithShiftsSelector).sort(
    (a, b) => a.name.localeCompare(b.name),
  )

  const [openConsultantInsertForm, setOpenConsultantInsertForm] =
    useState(false)
  const dispatch = useAppDispatch()
  const onCreateConsultant = (createConsultantModel: CreateConsultantDto) => {
    ConsultantClient.create(createConsultantModel).then(() => {
      setOpenConsultantInsertForm(false)
      dispatch(updateConsultants())
    })
  }
  const handleAvailableChange = (params: {
    consultantId: string
    newIsAvailable: boolean
  }) => {
    dispatch(
      updateConsultantAvailability({
        consultantId: params.consultantId,
        isAvailable: params.newIsAvailable,
      }),
    )
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
      height="100%"
      padding="30px"
      width="100%"
      gap="24px"
    >
      {consultantsWithShifts.length === 0 ? (
        <EmptyState
          onAddConsultantClick={() => setOpenConsultantInsertForm(true)}
        />
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            padding="30px"
            paddingTop="84px"
            width="100%"
            gap="24px"
          >
            <Box display="flex" width="100%" justifyContent="space-between">
              <Typography variant="h4">
                {userName}'s Sales team ({consultantsWithShifts.length})
              </Typography>
              <Button
                onClick={() => setOpenConsultantInsertForm(true)}
                variant="contained"
                sx={{ alignSelf: "end" }}
                startIcon={<PlusIcon />}
              >
                <Typography variant="body2" color="white">
                  {" "}
                  Add consultant
                </Typography>
              </Button>
            </Box>

            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Consultants</TableCell>
                    <TableCell align="left">Shifts</TableCell>
                    <TableCell align="left">Availability</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {consultantsWithShifts.map((consultantWithShift, index) => (
                    <TableRow
                      key={consultantWithShift.id}
                      sx={{
                        textDecoration: "none",
                        "&:last-child td, &:last-child th": { border: 0 },
                        bgcolor: consultantWithShift.isAvailable
                          ? appTheme.palette.background.paper
                          : "#FEECEB",
                      }}
                      hover={consultantWithShift.isAvailable}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/sales-management/shifts/${consultantWithShift.id}`,
                        )
                      }
                    >
                      <TableCell component="th" scope="row">
                        <ConsultantCell
                          consultant={consultantWithShift}
                          index={index}
                          key={consultantWithShift.id}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {ShiftCell(consultantWithShift.shifts)}
                      </TableCell>
                      <TableCell align="left" sx={{ zIndex: "100" }}>
                        <Switch
                          checked={consultantWithShift.isAvailable}
                          onChange={(event) => {
                            event.stopPropagation()
                            handleAvailableChange({
                              consultantId: consultantWithShift.id,
                              newIsAvailable: !consultantWithShift.isAvailable,
                            })
                          }}
                          onClick={(event) => event.stopPropagation()}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      <Dialog
        open={openConsultantInsertForm}
        onClose={() => setOpenConsultantInsertForm(false)}
        maxWidth="md"
      >
        <Box height="24px" />
        <DialogTitle>Add Consultant</DialogTitle>
        <Box height="2px" />
        <DialogContent>
          <ContactForm
            onCreate={onCreateConsultant}
            onClose={() => setOpenConsultantInsertForm(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}
