import GenericTable from "@cmp/genericTable"
import { ProgramProjectDto, UnitDto } from "@masterschool/course-builder-api"
import { Box, Link, Typography } from "@mui/material"
import { useState } from "react"
import { ManageProgramProjectDialog } from "./manageProgramProjectDialog"
import { DeleteProjectDialog } from "../../../projects/deleteProjectDialog"
import SearchTextField from "@cmp/searchTextField"
import { ProjectActionButtons } from "../../../projects/projectActionButtons"
import { AddProjectMenu } from "./addProjectMenu"

export const ProgramProjectsTable = (props: {
  projects: ProgramProjectDto[]
  programId: string
  unitsInSyllabus: UnitDto[]
  onAddProjectClicked: () => void
}) => {
  const { projects } = props
  const [searchText, setSearchText] = useState("")
  const onSearchTextChange = (text: string) => {
    setSearchText(text)
  }

  const filteredProjects = projects
    .map((project) => ({
      ...project,
      links: undefined,
      actions: undefined,
      status: undefined,
    }))
    .filter((project) =>
      project.name.toLowerCase().includes(searchText.toLowerCase()),
    )
  const isEmptyData = filteredProjects.length === 0

  const [projectToEdit, setProjectToEdit] = useState<ProgramProjectDto | null>(
    null,
  )
  const [toDelete, setToDelete] = useState<ProgramProjectDto | null>(null)

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {projectToEdit && (
        <ManageProgramProjectDialog
          project={projectToEdit}
          programId={props.programId}
          unitsInSyllabus={props.unitsInSyllabus}
          open={projectToEdit !== null}
          onClose={() => setProjectToEdit(null)}
        />
      )}
      {toDelete && (
        <DeleteProjectDialog
          project={toDelete}
          open={toDelete !== null}
          onClose={() => setToDelete(null)}
        />
      )}
      <Box display="flex" gap="16px" width="100%">
        <SearchTextField
          searchText={searchText}
          onSearchTextChange={onSearchTextChange}
          placeholder={"Search by project name"}
        />
        <AddProjectMenu
          programId={props.programId}
          unitsInSyllabus={props.unitsInSyllabus}
          disabled={false}
          icon="plus"
        />
      </Box>
      {isEmptyData && (
        <Typography variant="body1" textAlign="center">
          No projects found
        </Typography>
      )}
      <GenericTable
        data={filteredProjects}
        keys={[
          "name",
          "unitId",
          "startDate",
          "dueDate",
          "finalDueDate",
          "passingGrade",
          "links",
          "actions",
        ]}
        renderHeader={{
          name: () => <Typography variant="body1_sb">Project Name</Typography>,
          unitId: () => <Typography variant="body1_sb">Unit</Typography>,
          startDate: () => (
            <Typography variant="body1_sb">Start Date</Typography>
          ),
          dueDate: () => <Typography variant="body1_sb">Due Date</Typography>,
          finalDueDate: () => (
            <Typography variant="body1_sb">Final Due Date</Typography>
          ),
          passingGrade: () => (
            <Typography variant="body1_sb">Passing Grade</Typography>
          ),
          links: () => <Typography variant="body1_sb">Links</Typography>,
          actions: () => <div></div>,
        }}
        render={{
          links: (_, row, hover) => (
            <Box display="flex" flexDirection="column" padding={0}>
              <Link href={row.detailsLink} target="_blank">
                Details
              </Link>
              <Link href={row.guidelinesLink} target="_blank">
                Guidelines
              </Link>
            </Box>
          ),
          actions: (_, row, hover) =>
            hover ? (
              <ProjectActionButtons
                onEdit={() => setProjectToEdit(row)}
                onDelete={() => setToDelete(row)}
              />
            ) : (
              <Box width="80px" height="32px" />
            ),
          dueDate: renderDate,
          finalDueDate: renderDate,
          startDate: renderDate,
          unitId: (value) => {
            const unit = props.unitsInSyllabus.find((u) => u.id === value)
            return (
              <Typography>{unit?.unitNumber ?? "Not in Syllabus"}</Typography>
            )
          },
        }}
        sx={{
          overflow: "auto",
          maxHeight: "100%",
          borderRadius: "4px",
        }}
      />
    </Box>
  )
}

const renderDate = (value: string) => {
  const dateString = new Date(value).toLocaleString("en-GB", {
    year: "2-digit",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  })
  const dateParts = dateString.split(" ")
  const timezone = dateParts.pop()
  const stringWithoutTimezone = dateParts.join(" ")
  return (
    <Typography>
      {stringWithoutTimezone}
      <br />
      {timezone}
    </Typography>
  )
}
