import { CampusClient } from "./campusClient"

export type VideoConferenceDto = {
  id: string
  url: string
  meetingId: string
  provider: "zoom"
  createdAt: Date
}

export class VideoConferenceClient {
  static async create(): Promise<VideoConferenceDto> {
    return CampusClient.post<VideoConferenceDto>(`video-conference/create`, {})
  }

  static async get(id: string): Promise<VideoConferenceDto> {
    return CampusClient.get<VideoConferenceDto>(`video-conference/${id}`)
  }
}
