import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { editStudentDetailsPopupClosed } from "@features/program/programSlice"
import EditUserDetailsPopupForm from "../../../popups/popups/editUserDetailsPopupForm"

function EditStudentDetailsPopup() {
  const { isOpen, studentId, studentDetailsBeforeEdit } =
    useGetStudentDetailsPopupDetails()
  const onClosePopup = useCloseEditStudentDetailsPopup()

  if (!isOpen || !studentId || !studentDetailsBeforeEdit) {
    return null
  }

  return (
    <GenericDialog
      open
      size="sm"
      onClose={onClosePopup}
      title="Edit student"
      content={
        <EditUserDetailsPopupForm
          userId={studentId}
          userDetailsBeforeEdit={studentDetailsBeforeEdit}
          isEmployee={false}
          onClose={onClosePopup}
        />
      }
      buttons={[]}
    />
  )
}

function useGetStudentDetailsPopupDetails() {
  const model = useAppSelector(
    (state) => state.program.studentsPage.editStudentDetailsPopup,
  )
  return {
    isOpen: model !== undefined,
    studentId: model?.studentId,
    studentDetailsBeforeEdit: model?.form,
  }
}

function useCloseEditStudentDetailsPopup() {
  const dispatch = useAppDispatch()
  return () => {
    dispatch(editStudentDetailsPopupClosed())
  }
}

export default EditStudentDetailsPopup
