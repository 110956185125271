import { ProjectClient } from "@clients/projectClient"
import GenericDialog from "@cmp/genericDialog"
import { ProjectDto } from "@masterschool/course-builder-api"
import {
  Autocomplete,
  Chip,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  TextFieldProps,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import appTheme from "../theme/appTheme"

export const CreateProjectDialog = (props: {
  open: boolean
  onClose: () => void
}) => {
  return (
    <ManageProjectDialog
      project={null}
      open={props.open}
      onClose={props.onClose}
    />
  )
}

export const EditProjectDialog = (props: {
  project: ProjectDto
  open: boolean
  onClose: () => void
}) => {
  return (
    <ManageProjectDialog
      project={props.project}
      open={props.open}
      onClose={props.onClose}
    />
  )
}

const ManageProjectDialog = (props: {
  project: ProjectDto | null
  open: boolean
  onClose: () => void
}) => {
  const { project, open, onClose } = props
  const dialogMode = project ? "Edit" : "Create"

  const [projectName, setProjectName] = useState(project?.name ?? "")
  const [domain, setDomain] = useState<string | null>(project?.domain ?? null)
  const [detailsLink, setDetailsLink] = useState(project?.detailsLink ?? "")
  const [guidelinesLink, setGuidelinesLink] = useState(
    project?.guidelinesLink ?? "",
  )

  const isValidDetailsLink = detailsLink.length > 0 && isValidURL(detailsLink)
  const isValidGuidelinesLink =
    guidelinesLink.length > 0 && isValidURL(guidelinesLink)

  const canSubmit = projectName && isValidDetailsLink && isValidGuidelinesLink

  const onSubmitClicked = async () => {
    setIsLoading(true)
    const fieldsFromDialog = {
      name: projectName,
      domain: domain ?? undefined,
      detailsLink,
      guidelinesLink,
    }
    if (project) {
      await ProjectClient.updateProject({ id: project.id, ...fieldsFromDialog })
    } else {
      await ProjectClient.createProject(fieldsFromDialog)
    }
    setIsLoading(false)
    onClose()
  }
  const [isLoading, setIsLoading] = useState(false)

  return (
    <GenericDialog
      open={open}
      size="sm"
      onClose={onClose}
      title={`${dialogMode === "Create" ? "Create New" : "Edit"} Project`}
      content={
        <Stack gap={3}>
          <TextField
            label="Project Name"
            size="small"
            required
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
          <UrlFormField
            label="Details Link"
            size="small"
            required
            value={detailsLink}
            onChange={(e) => setDetailsLink(e.target.value)}
          />
          <UrlFormField
            label="Guidelines Link"
            size="small"
            required
            value={guidelinesLink}
            onChange={(e) => setGuidelinesLink(e.target.value)}
          />
          <Autocomplete
            value={domain}
            options={["Data", "Cyber", "Software", "Orientation"]}
            onChange={(_, newSelection) => setDomain(newSelection)}
            placeholder={"Domain"}
            fullWidth
            autoComplete
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="medium"
                label="Domain"
                InputLabelProps={{
                  sx: { fontSize: "16px" },
                }}
              />
            )}
            renderTags={(value) => <Chip label={value}></Chip>}
          />
        </Stack>
      }
      buttons={[
        {
          type: "primary",
          text: `${dialogMode} Project`,
          disabled: !canSubmit,
          loading: isLoading,
          onClick: onSubmitClicked,
        },
      ]}
    />
  )
}

export const UrlFormField = (
  props: TextFieldProps & {
    value: string
  },
) => {
  const { value, ...textFieldProps } = props
  return (
    <TextField
      type="url"
      fullWidth
      value={value}
      error={value.length > 0 && !isValidURL(value)}
      helperText={helperTextForURL(value)}
      InputProps={urlInputAdornment}
      {...textFieldProps}
    />
  )
}

function helperTextForURL(str: string) {
  if (str.length === 0) return ""
  return isValidURL(str) ? "" : "Must be a valid URL"
}

export function isValidURL(str: string | null) {
  if (!str) return false
  try {
    new URL(str)
    return true
  } catch {
    return false
  }
}

const urlInputAdornment: TextFieldProps["InputProps"] = {
  endAdornment: (
    <InputAdornment position="start">
      <SvgIcon
        component={appIcons.link01}
        inheritViewBox
        sx={{
          stroke: appTheme.palette.icon.default,
          fill: "none",
          width: "20px",
          height: "20px",
        }}
      />
    </InputAdornment>
  ),
}
