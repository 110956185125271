import { Box, Typography } from "@mui/material"
import appTheme from "../../../theme/appTheme"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { selectSyllabus } from "@features/syllabus/syllabusSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import { useEffect } from "react"
import { showInfoSnackbar } from "@features/ui/uiSlice"
import { fetchProgramProjects } from "@features/program/projects/programProjectsSlice"
import FullScreenLoadingComponent from "@cmp/loaders/fullScreenLoadingComponent"
import FullScreenErrorComponent from "@cmp/loaders/fullScreenErrorComponent"
import { ProgramProjectsTable } from "./programProjectsTable"
import { NoProjectsView } from "./noProjectsView"

export const ProgramProjectsView = (props: { program: ProgramDto }) => {
  const programId = props.program.id
  const syllabus = useAppSelector(selectSyllabus(props.program.syllabusId))
  const projectsState = useAppSelector((state) => state.programProjects)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchProgramProjects(programId))
  }, [dispatch, programId])

  const isLoading = projectsState.loadingState === "loading"
  const isError = projectsState.loadingState === "failed"
  const projects = projectsState.projects
  const hasProjects = projects.length > 0
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      {isLoading && <FullScreenLoadingComponent loadedComponents="projects" />}
      {isError && <FullScreenErrorComponent loadedComponents="projects" />}
      {!hasProjects && (
        <NoProjectsView programId={props.program.id} syllabus={syllabus} />
      )}
      {hasProjects && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            alignSelf="start"
            sx={{ paddingBottom: "16px" }}
          >
            Projects{" "}
            <span style={{ color: appTheme.palette.text.disabled }}>
              ({projects.length})
            </span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <ProgramProjectsTable
              projects={projectsState.projects}
              programId={programId}
              unitsInSyllabus={syllabus?.units ?? []}
              onAddProjectClicked={() => {
                dispatch(
                  showInfoSnackbar("Not implemented yet, check back later!"),
                )
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
