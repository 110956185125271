import appIcons from "@utils/appIcons"
import appTheme from "../../../../theme/appTheme"
import OptionsButton from "@cmp/buttons/optionsButton"
import { SprintDto } from "@masterschool/course-builder-api"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { splitSprint } from "@features/syllabusEditor/syllabusEditorSlice"
import useActiveSyllabusUnit from "../../panel/useActiveSyllabusUnit"
import { selectTopicsInSprint } from "./unit.editor.selectors"
import { SprintSettingsDialog } from "./sprintSettingsDialog"

export const SprintActionsMenu = (props: { sprint: SprintDto }) => {
  const { sprint } = props
  const dispatch = useAppDispatch()
  const [showSettingsDialog, setShowSettingsDialog] = useState(false)
  const canSplitSprint = useCanSplitSprint(sprint.id)
  const unitId = useActiveSyllabusUnit()?.id

  const onSplitSprintClicked = () => {
    if (!unitId) return
    dispatch(splitSprint({ sprintId: sprint.id, unitId }))
  }

  const menuActions = [
    {
      text: "Sprint settings",
      onSelect: () => setShowSettingsDialog(true),
      icon: appIcons.settings01,
    },
    {
      text: "Split sprint",
      onSelect: onSplitSprintClicked,
      disabled: !canSplitSprint,
      icon: appIcons.splitSprint,
    },
  ]
  return (
    <div>
      {showSettingsDialog && (
        <SprintSettingsDialog
          sprint={sprint}
          open={showSettingsDialog}
          onClose={() => setShowSettingsDialog(false)}
        />
      )}
      <OptionsButton
        button={{
          sx: {
            padding: "6px",
            borderRadius: "50%",
          },
          leftIcon: appIcons.edit02,
          iconSx: {
            fill: "none",
            width: "20px",
            height: "20px",
            stroke: appTheme.palette.icon.black,
          },
        }}
        items={menuActions}
      />
    </div>
  )
}

const useCanSplitSprint = (sprintId: string) => {
  const unit = useActiveSyllabusUnit()

  const sprintIndex = unit?.sprints.findIndex((s) => s.id === sprintId) ?? -1
  const topicsInCurrentSprint = useAppSelector(
    selectTopicsInSprint(unit?.id ?? "", sprintIndex),
  )

  return topicsInCurrentSprint.length > 1
}
