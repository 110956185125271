import FullScreenComponent from "../layouts/fullScreenComponent"
import { LoadingComponent } from "./loadingComponent"

const FullScreenLoadingComponent = (props: { loadedComponents: string }) => {
  return (
    <FullScreenComponent>
      <LoadingComponent loadedComponents={props.loadedComponents} />
    </FullScreenComponent>
  )
}

export default FullScreenLoadingComponent
