import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { syllabusActionConfirmationPopupUpdate } from "@features/program/programSlice"
import { selectProgramsSyllabusPage } from "@features/program/programSliceSelectors"
import { useState, useEffect } from "react"

const SyllabusActionConfirmationPopup = () => {
  const { syllabusActionConfirmationPopup: model } = useAppSelector(
    selectProgramsSyllabusPage,
  )
  const dispatch = useAppDispatch()
  const { action, isLoading, isOpen } = model
  if (!isOpen) {
    return null
  }
  const title = getTitle(action)
  const subtitle = getSubtitle(action)
  const ctaText = getActionText(action)

  const onCancel = () => {
    dispatch(
      syllabusActionConfirmationPopupUpdate({
        isOpen: false,
        decision: "cancel",
      }),
    )
  }

  const onClick = () => {
    dispatch(
      syllabusActionConfirmationPopupUpdate({
        isOpen: false,
        decision: "confirm",
      }),
    )
  }

  return (
    <GenericDialog
      open
      title={title}
      subtitle={subtitle}
      size="xs"
      onClose={onCancel}
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onCancel,
        },
        {
          type: "primary",
          text: ctaText,
          onClick,
          loading: isLoading,
        },
      ]}
    />
  )
}

const getTitle = (action: Action) => {
  switch (action.type) {
    case "connect":
      return `Connect syllabus ${action.syllabusName}`
    case "disconnect":
      return `Disconnect syllabus ${action.syllabusName}`
    case "revert":
      return `Revert to default syllabus?`
  }
}

const getSubtitle = (action: Action) => {
  switch (action.type) {
    case "connect":
      return `The syllabus will be linked to ${
        action.item === "program"
          ? "this program"
          : action.item === "class"
          ? "this class"
          : `${action.item} classes`
      } and it will be visible to all users in the main repository`
    case "disconnect":
      return action.classesCount > 0
        ? `This syllabus is currently connected to ${action.classesCount} classes. ` +
            `Once disconnected, you will need to manually connect these classes a new syllabus.`
        : `The syllabus will be disconnected from this program and will not be ` +
            `visible to all users in the main repository.`
    case "revert":
      return (
        `This class syllabus will revert to the program's ` +
        `default syllabus ${action.defaultSyllabusName}.`
      )
  }
}

const getActionText = (action: Action) => {
  switch (action.type) {
    case "connect":
      return "Connect"
    case "disconnect":
      return "Disconnect"
    case "revert":
      return "Revert"
  }
}

export const useSyllabusActionConfirmationPopup = () => {
  const { syllabusActionConfirmationPopup: model } = useAppSelector(
    selectProgramsSyllabusPage,
  )
  const [onConfirmAction, setOnConfirmAction] = useState<
    (() => void) | undefined
  >(undefined)
  const [onCancelAction, setOnCancelAction] = useState<
    (() => void) | undefined
  >(undefined)
  const [decision, setDecision] = useState<"confirm" | "cancel" | undefined>(
    undefined,
  )
  const [pendingConfirmation, setPendingConfirmation] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (model.isOpen || !model.decision || !pendingConfirmation) {
      return
    }

    if (model.decision === "confirm") {
      setPendingConfirmation(false)
      setDecision("confirm")
      onConfirmAction && onConfirmAction()
    }
    if (model.decision === "cancel") {
      setPendingConfirmation(false)
      setDecision("cancel")
      onCancelAction && onCancelAction()
    }
  }, [
    model.decision,
    model.isOpen,
    pendingConfirmation,
    onConfirmAction,
    onCancelAction,
  ])

  const open = ({
    action,
    onConfirm,
    onCancel,
  }: {
    action: Action
    onConfirm: () => void
    onCancel: () => void
  }) => {
    setPendingConfirmation(true)
    setOnConfirmAction(() => onConfirm)
    setOnCancelAction(() => onCancel)
    dispatch(
      syllabusActionConfirmationPopupUpdate({
        isOpen: true,
        action,
      }),
    )
  }

  const close = () => {
    setPendingConfirmation(false)
    dispatch(
      syllabusActionConfirmationPopupUpdate({
        isOpen: false,
        decision: undefined,
        isLoading: false,
      }),
    )
  }

  return {
    open,
    close,
    decision,
    setIsLoading: (isLoading: boolean) => {
      dispatch(syllabusActionConfirmationPopupUpdate({ isLoading }))
    },
  }
}

type Action =
  | {
      type: "connect"
      syllabusName: string
      item: "program" | "class" | "classes"
    }
  | {
      type: "disconnect"
      syllabusName: string
      classesCount: number
    }
  | {
      type: "revert"
      defaultSyllabusName: string
    }

export default SyllabusActionConfirmationPopup
