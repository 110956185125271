import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  ProgramDetailsFormModel,
  editProgramPopupClosed,
} from "@features/program/programSlice"
import ProgramDetailsForm from "../program/components/programDetailsForm"
import { selectProgram } from "@features/program/programSliceSelectors"
import { useEffect, useState } from "react"
import { ProgramDto } from "@masterschool/course-builder-api"
import { editProgramProperties } from "@features/program/programSliceThunks"
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material"
import BulletPoint from "@cmp/bulletPoint"
import { LoadingButton } from "@mui/lab"

type EditedProgramProperties = Partial<ProgramDetailsFormModel>

function EditProgramPopup() {
  const model = useAppSelector((state) => state.program.editProgramPopup)
  if (!model || !model.programId) {
    return null
  }
  return <EditProgramDialogWithConfirmation programId={model.programId} />
}

function EditProgramDialogWithConfirmation(props: { programId: string }) {
  const dispatch = useAppDispatch()

  const { programId } = props
  const [editedProperties, setEditedProperties] =
    useState<EditedProgramProperties>()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const shouldConfirmChanges = useShouldConfirmChanges(programId)
  const { isEditing, editProgram } = useEditProgram()

  const editProgramTitle = "Edit program"
  const confirmChangesTitle = editedProperties
    ? `Change program  ${buildDescriptionOfChangedProperties(
        editedProperties,
      )}?`
    : ""
  const editProgramContent = (
    <FillProgramDetailsDialogContent
      programId={programId}
      onSubmit={(editedProperties) => {
        if (shouldConfirmChanges) {
          setEditedProperties(editedProperties)
          setShowConfirmationDialog(true)
        } else {
          editProgram(programId, editedProperties)
        }
      }}
      isSubmitting={isEditing}
    />
  )

  const confirmChangesContent = (
    <ConfirmationDialogContent
      editedProperties={editedProperties}
      onSubmit={(editedProperties) => {
        editProgram(programId, editedProperties)
      }}
      isSubmitting={isEditing}
    />
  )

  const title = showConfirmationDialog ? confirmChangesTitle : editProgramTitle
  const content = showConfirmationDialog
    ? confirmChangesContent
    : editProgramContent

  return (
    <GenericDialog
      open={true}
      size="xs"
      onClose={() => dispatch(editProgramPopupClosed())}
      title={title}
      content={content}
    />
  )
}

function FillProgramDetailsDialogContent(props: {
  programId: string
  onSubmit: (properties: EditedProgramProperties) => void
  isSubmitting: boolean
}) {
  const { programId, onSubmit, isSubmitting } = props

  const programFormDetails = useProgramDetailsManager(programId || "")

  if (!programFormDetails?.data) {
    return null
  }
  return (
    <Box>
      <ProgramDetailsForm
        {...programFormDetails.data}
        onChange={(payload) => programFormDetails.onChange(payload)}
      />
      <Stack direction="row" pt={4} pb={0} gap={2} justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          onClick={async () => {
            const changes = programFormDetails.getChanges()
            if (!changes) return
            onSubmit(changes)
          }}
          disabled={!programFormDetails.getChanges()}
          loading={isSubmitting}
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  )
}

const buildDescriptionOfChangedProperties = (
  editedProperties: EditedProgramProperties,
) => {
  const names = Object.keys(editedProperties).map((key) =>
    propertyDisplayName(key as ProgramDetailsKeys),
  )
  if (names.length === 1) {
    return names[0]
  } else {
    const namesWithoutLast = names.slice(0, names.length - 1)
    const lastName = names[names.length - 1]

    const namesJoinedByComaWithoutLast = namesWithoutLast.join(", ")

    const multiplePropertiesString =
      namesJoinedByComaWithoutLast + " and " + lastName

    return multiplePropertiesString
  }
}

const ConfirmationDialogContent = (props: {
  editedProperties: Partial<ProgramDetailsFormModel> | undefined
  onSubmit: (properties: EditedProgramProperties) => void
  isSubmitting: boolean
}) => {
  const { editedProperties, onSubmit, isSubmitting } = props
  const hadChangedStartDate = !!editedProperties?.startDate
  const [confirmedChangeStartDate, setConfirmChangeStartDate] = useState(false)

  const dispatch = useAppDispatch()
  if (!editedProperties) {
    return null
  }
  return (
    <Box>
      <ConfirmationDialogBody
        shouldConfirmChangeStartDate={hadChangedStartDate}
        setConfirmChangeStartDate={setConfirmChangeStartDate}
        confirmedChangeStartDate={confirmedChangeStartDate}
      />
      <Stack direction="row" pt={4} pb={0} gap={2} justifyContent="flex-end">
        <LoadingButton
          variant="text"
          onClick={async () => {
            dispatch(editProgramPopupClosed())
          }}
          loading={isSubmitting}
        >
          Never mind
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={async () => {
            if (hadChangedStartDate && !confirmedChangeStartDate) {
              return
            }
            onSubmit(editedProperties)
          }}
          disabled={hadChangedStartDate && !confirmedChangeStartDate}
          loading={isSubmitting}
        >
          Change
        </LoadingButton>
      </Stack>
    </Box>
  )
}

const ConfirmationDialogBody = (props: {
  shouldConfirmChangeStartDate: boolean
  setConfirmChangeStartDate: (value: boolean) => void
  confirmedChangeStartDate: boolean
}) => {
  const {
    shouldConfirmChangeStartDate,
    setConfirmChangeStartDate,
    confirmedChangeStartDate,
  } = props
  return (
    <Stack gap={2}>
      <Typography>
        This will be visible to all students in the program.
      </Typography>
      {shouldConfirmChangeStartDate && (
        <AlertChangeOnStartDate
          confirmed={confirmedChangeStartDate}
          setConfirmed={setConfirmChangeStartDate}
        />
      )}
    </Stack>
  )
}

const AlertChangeOnStartDate = (props: {
  confirmed: boolean
  setConfirmed: (value: boolean) => void
}) => {
  const { confirmed, setConfirmed } = props
  return (
    <Stack gap={2}>
      <Stack>
        <Typography>Change of program start date may affect:</Typography>
        <Stack sx={{ pl: 1 }}>
          <BulletPoint>Sprint start dates</BulletPoint>
          <BulletPoint>
            Live sessions may be rescheduled into different prints than
            initially planned
          </BulletPoint>
        </Stack>
      </Stack>

      <FormControlLabel
        control={
          <Checkbox
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
            sx={{ ml: 1 }}
          />
        }
        label="I understand the potential impact."
      />
    </Stack>
  )
}

const useShouldConfirmChanges = (programId: string) => {
  const program = useAppSelector((state) => selectProgram(state, programId))
  const programStartDate = program?.startDate
    ? new Date(program.startDate)
    : undefined
  const hasProgramStarted = programStartDate && programStartDate < new Date()
  return hasProgramStarted
}

const useEditProgram = () => {
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useAppDispatch()

  const editProgram = async (
    programId: string,
    editedProperties: Partial<ProgramDetailsFormModel> | undefined,
  ) => {
    if (!editedProperties) return
    setIsEditing(true)
    await dispatch(editProgramProperties({ programId, editedProperties })).then(
      (res) => {
        setIsEditing(false)
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(editProgramPopupClosed())
        }
      },
    )
  }

  return { isEditing, editProgram }
}

const useProgramDetailsManager = (programId: string) => {
  const initialProgramState = useAppSelector((state) =>
    selectProgram(state, programId),
  )
  const { programFormDetails, setProgramFormDetails } =
    useProgramDetailsState(initialProgramState)

  const onChangeProgramDetails = (payload: EditedProgramProperties) => {
    if (programFormDetails) {
      const editedProgram = { ...programFormDetails, ...payload }
      setProgramFormDetails(editedProgram)
    }
  }
  const getChanges = () => {
    if (!programFormDetails || !initialProgramState) {
      return undefined
    }
    const changedFields = getChangedFields(
      programFormDetails,
      initialProgramState,
    )
    if (Object.keys(changedFields).length === 0) {
      return undefined
    }
    return changedFields
  }
  return {
    data: programFormDetails,
    onChange: onChangeProgramDetails,
    getChanges,
  }
}

const useProgramDetailsState = (
  initialProgramState: ProgramDto | undefined,
) => {
  const [programFormDetails, setProgramFormDetails] = useState<
    ProgramDetailsFormModel | undefined
  >(undefined)

  useEffect(() => {
    if (initialProgramState) {
      setProgramFormDetails({
        domain: initialProgramState.domain,
        type: initialProgramState.type,
        startDate: initialProgramState.startDate,
        durationInMonths: initialProgramState.durationInMonths,
        language: initialProgramState.language,
      })
    }
  }, [initialProgramState])

  return { programFormDetails, setProgramFormDetails }
}

const getChangedFields = (
  form: ProgramDetailsFormModel,
  initialProgram: ProgramDto,
) => {
  const changedFields = {} as Partial<ProgramDetailsFormModel>

  Object.keys(form).forEach((k) => {
    const key = k as keyof ProgramDetailsFormModel
    pushPropertyIfChanged(changedFields, key, form, initialProgram)
  })

  return changedFields
}

function pushPropertyIfChanged<K extends keyof ProgramDetailsFormModel>(
  changedFields: Partial<ProgramDetailsFormModel>,
  key: K,
  form: ProgramDetailsFormModel,
  initialProgram: ProgramDto,
) {
  if (form[key] !== initialProgram[key]) {
    changedFields[key] = form[key]
  }
}

export type ProgramDetailsKeys = keyof ProgramDetailsFormModel
const propertyDisplayName = (key: ProgramDetailsKeys): string => {
  switch (key) {
    case "startDate":
      return "start date"
    case "durationInMonths":
      return "duration"
    case "domain":
      return "domain"
    case "type":
      return "type"
    case "language":
      return "language"
  }
}

export default EditProgramPopup
