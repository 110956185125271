import { useAppDispatch } from "@app/hooks"
import {
  CourseSyllabusLiveEvent,
  Topic,
} from "@masterschool/course-builder-api"
import { liveEventEdited } from "../../../features/courseEditor/courseEditorSlice"

function useEditLiveEvent<K extends keyof CourseSyllabusLiveEvent>() {
  const dispatch = useAppDispatch()
  return (
    LiveEvent: CourseSyllabusLiveEvent,
    key: K,
    value: CourseSyllabusLiveEvent[K],
    topic: Topic,
  ) => {
    dispatch(
      liveEventEdited({
        editStepId: window.crypto.randomUUID(),
        topicId: topic.id,
        liveEvent: { ...LiveEvent, [key]: value },
      }),
    )
  }
}

export default useEditLiveEvent
