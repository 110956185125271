import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  MenuItemProps,
} from "@mui/material"
import { MouseEventHandler, useRef, useState } from "react"
import appTheme from "../../theme/appTheme"

export type ButtonModel =
  | {
      type: "button"
      props: ButtonProps
    }
  | {
      type: "icon"
      props: IconButtonProps
    }

export type ItemModel =
  | {
      type: "menu-item"
      props: MenuItemProps
    }
  | {
      type: "sub-menu"
      props: {
        key: string
        itemProps: MenuItemProps
        items: ItemModel[]
      }
    }

export type OptionsButtonProps2 = {
  buttonModel: ButtonModel
  items: ItemModel[]
}

function OptionsButton2(props: OptionsButtonProps2) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={anchorRef}>
        <OpenListButton
          model={props.buttonModel}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setOpen(true)
          }}
        />
      </div>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setOpen(false)
        }}
      >
        {props.items.map((item) => {
          switch (item.type) {
            case "menu-item":
              return <MenuItem {...item.props} key={item.props.key} />
            case "sub-menu":
              return <Submenu {...item.props} key={item.props.key} />
            default:
              return null
          }
        })}
      </Menu>
    </>
  )
}

function OpenListButton(props: {
  model: ButtonModel
  onClick: MouseEventHandler
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
}) {
  if (props.model.type === "button") {
    return (
      <Button
        {...props.model.props}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      />
    )
  } else {
    return (
      <IconButton
        {...props.model.props}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      />
    )
  }
}

function Submenu(props: { itemProps: MenuItemProps; items: ItemModel[] }) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={anchorRef}>
      <MenuItem
        {...props.itemProps}
        onMouseEnter={() => setOpen(true)}
        sx={{
          bgcolor: open ? appTheme.palette.eTypes.sand25 : "transparent",
        }}
      />
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
        onClick={(e) => {
          setOpen(false)
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          onMouseLeave: (e) => {
            const point = {
              x: e.clientX,
              y: e.clientY,
            }
            const rect = anchorRef.current?.getBoundingClientRect()

            if (!rect || doesRectContainPoint(rect, point)) {
              return
            }

            setOpen(false)
          },
        }}
      >
        {props.items.map((item) => {
          switch (item.type) {
            case "menu-item":
              return <MenuItem {...item.props} />
            case "sub-menu":
              return <Submenu {...item.props} />
            default:
              return null
          }
        })}
      </Menu>
    </div>
  )
}

function doesRectContainPoint(rect: DOMRect, point: { x: number; y: number }) {
  return (
    point.x >= rect.left &&
    point.x <= rect.right &&
    point.y >= rect.top &&
    point.y <= rect.bottom
  )
}

export default OptionsButton2
