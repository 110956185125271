import {
  CourseDto,
  CourseStatus,
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  CourseSyllabusLiveEvent,
  CourseraLesson,
  DatacampLesson,
  MasterschoolLesson,
  OtherLesson,
  Topic,
} from "@masterschool/course-builder-api"
import { isTopicValid } from "../../editor/topic/topicValidations"
import { areCourseDtosEqual } from "@utils/courseUtils"

export enum PublishValidationStatus {
  NOT_A_COURSE = "NOT_A_COURSE",
  MISSING_NAME = "MISSING_NAME",
  MISSING_TITLE = "MISSING_TITLE",
  MISSING_SHORT_TITLE = "MISSING_SHORT_TITLE",
  MISSING_DOMAIN = "MISSING_DOMAIN",
  INVALID_TOPICS = "MISSING_TOPICS",
  NO_CHANGE_FROM_PUBLISHED_VERSION = "NO_CHANGE_FROM_PUBLISHED_VERSION",

  PUBLISHABLE = "PUBLISHABLE",
}

export function courseValidationForPublish(
  course: CourseDto | undefined,
  lastCourseVersion: CourseDto | undefined,
): PublishValidationStatus {
  if (!course) return PublishValidationStatus.NOT_A_COURSE

  if (!course.name) return PublishValidationStatus.MISSING_NAME

  if (!course.title) return PublishValidationStatus.MISSING_TITLE

  if (!course.domains || course.domains.length === 0)
    return PublishValidationStatus.MISSING_DOMAIN

  const isFirstDraftVersion =
    course.version === 1 && course.status === CourseStatus.Draft

  if (isFirstDraftVersion) return PublishValidationStatus.PUBLISHABLE

  const areCoursesEqual = lastCourseVersion
    ? areCourseDtosEqual(course, lastCourseVersion)
    : false

  return areCoursesEqual
    ? PublishValidationStatus.NO_CHANGE_FROM_PUBLISHED_VERSION
    : PublishValidationStatus.PUBLISHABLE
}

export function topicValidation(course: CourseDto) {
  const topics = course.syllabus?.topics
  if (!topics || topics.length === 0) {
    return false
  }

  if (topics.some((topic) => !isTopicValid(topic))) {
    return false
  }

  return true
}

export function isElementInvalid(
  element: CourseSyllabusElement,
  publishValidations: {
    [key: string]: string[]
  },
) {
  if (publishValidations[element.item.id]?.length > 0) {
    return true
  }

  switch (element.type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
    case CourseSyllabusElementTypeEnum.OtherLesson:
    case CourseSyllabusElementTypeEnum.CourseraLesson:
      return (
        element.item as
          | DatacampLesson
          | MasterschoolLesson
          | OtherLesson
          | CourseraLesson
      ).tasks.some((task) => {
        return publishValidations[task.id]?.length > 0
      })
    default:
      return false
  }
}

export function isLiveEventInvalid(
  liveEvent: CourseSyllabusLiveEvent,
  publishValidations: {
    [key: string]: string[]
  },
) {
  return publishValidations[liveEvent.id]?.length > 0
}

export function isTopicInValid(
  topic: Topic,
  publishValidations: { [key: string]: string[] } | undefined,
) {
  if (!publishValidations) {
    return false
  }
  if (publishValidations[topic.id]?.length > 0) {
    return true
  }

  const areElementsInvalid = topic.elements.some((element) => {
    return isElementInvalid(element, publishValidations)
  })
  const areLiveEventsInvalid = topic.liveEvents.some((liveEvent) => {
    return isLiveEventInvalid(liveEvent, publishValidations)
  })
  return areElementsInvalid || areLiveEventsInvalid
}
