import { ErrorComponent } from "@cmp/loaders/errorComponent"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { Box, BoxProps } from "@mui/material"

function PendingResourceComponent<T>(props: {
  resource: T | "pending" | "rejected"
  render: (resource: T) => React.ReactNode
}) {
  const { resource, render } = props

  const boxProps: BoxProps["sx"] = {
    width: "100%",
    height: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }

  switch (resource) {
    case "pending":
      return (
        <Box sx={boxProps}>
          <LoadingComponent loadedComponents="" />
        </Box>
      )
    case "rejected":
      return (
        <Box sx={boxProps}>
          <ErrorComponent loadedComponents="" />
        </Box>
      )
  }

  return render(resource)
}

export default PendingResourceComponent
