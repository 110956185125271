import { Stack, Typography } from "@mui/material"

const BulletPoint = (props: { children: React.ReactNode }) => {
  return (
    <Stack direction="row" gap={1}>
      <Typography>
        <span
          style={{
            textDecoration: "none",
          }}
        >
          &#x2022;
        </span>
      </Typography>

      <Typography>{props.children}</Typography>
    </Stack>
  )
}

export default BulletPoint
