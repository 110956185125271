import { useAppDispatch } from "@app/hooks"
import { ItemModel } from "@cmp/buttons/optionsButton2"
import ItemDescriptor from "@cmp/itemDescriptor"
import { Theme } from "@emotion/react"
import { liveEventElementMovedToTopic } from "@features/courseEditor/courseEditorSlice"
import {
  CourseSyllabusLiveEvent,
  Topic,
} from "@masterschool/course-builder-api"
import {
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  SxProps,
} from "@mui/material"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import { IconType } from "@utils/iconType"
import appTheme from "../theme/appTheme"
import useSelectElement from "./custom-hooks/useSelectElement"
import useOnDelete from "./elements/custom-hooks/useOnDelete"

export function CourseLiveEventDescriptor(props: {
  liveEvent: CourseSyllabusLiveEvent
  topic: Topic
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
  availableTopics?: Topic[]
}) {
  const dispatch = useAppDispatch()
  const removeElement = useOnDelete()
  const selectElement = useSelectElement()

  const removeElementItem: ItemModel = {
    type: "menu-item",
    props: {
      key: "remove",
      children: (
        <>
          <ListItemIcon>
            <AppIcon icon={appIcons.trash03} />
          </ListItemIcon>
          <ListItemText primary="Remove" />
        </>
      ),
      onClick: () => {
        removeElement({
          topicId: props.topic.id,
          elementId: props.liveEvent.id,
        })
      },
    },
  }

  const moveElementItem: ItemModel | undefined =
    props.availableTopics && props.availableTopics.length > 0
      ? {
          type: "sub-menu",
          props: {
            key: "move",
            itemProps: {
              children: (
                <>
                  <ListItemIcon>
                    <AppIcon icon={appIcons.arrowRight} />
                  </ListItemIcon>
                  <ListItemText primary="Move to..." />
                </>
              ),
            },
            items: (props.availableTopics ?? []).map((topic) => {
              return {
                type: "menu-item",
                props: {
                  key: topic.id,
                  children: topic.title || (
                    <span
                      style={{
                        color: appTheme.palette.text.secondary,
                      }}
                    >
                      Untitled topic
                    </span>
                  ),
                  onClick: () => {
                    dispatch(
                      liveEventElementMovedToTopic({
                        editStepId: window.crypto.randomUUID(),
                        liveEventId: props.liveEvent.id,
                        sourceTopicId: props.topic.id,
                        targetTopicId: topic.id,
                      }),
                    )
                    selectElement(props.liveEvent.id)
                  },
                },
              }
            }),
          },
        }
      : undefined

  const optionsItems: ItemModel[] | undefined = props.editMode
    ? [removeElementItem, moveElementItem].msCompact()
    : undefined

  return (
    <ItemDescriptor
      optionsItems={optionsItems}
      {...props}
      title={props.liveEvent.title}
      subtitle={"Live Session"}
      icon={appIcons.playCircle}
    />
  )
}
