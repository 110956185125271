import { Badge, Chip } from "@mui/material"

export type StatusChipProps = {
  text: string
  color: "success" | "error" | undefined
  showIndicator: boolean
  isActive: boolean | undefined
}

function StatusChip({ text, color, showIndicator, isActive }: StatusChipProps) {
  const chip = (
    <Chip
      label={text}
      size="small"
      variant={isActive ? "filled" : "outlined"}
      color={color}
      sx={{
        width: "fit-content",
        color: isActive ? "white" : undefined,
      }}
    />
  )

  return showIndicator ? (
    <Badge
      variant="dot"
      color="info"
      sx={{
        "& .MuiBadge-badge": {
          right: 3,
          top: 3,
          border: "1px solid white",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        },
      }}
    >
      {chip}
    </Badge>
  ) : (
    chip
  )
}

export default StatusChip
