import { useAppDispatch, useAppSelector } from "@app/hooks"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { StaffTable } from "./staffTable"
import { Box, Button, Typography } from "@mui/material"
import appTheme from "../../../theme/appTheme"
import { useEffect } from "react"
import { EmptyStaffView } from "./emptyStaffView"

import {
  AddStaffMemberDialog,
  EditStaffMemberDialog,
} from "./manageProgramStaffDialog"
import { fetchProgramStaff } from "@features/program/programSliceThunks"
import {
  addStaffMemberPopupClosed,
  addStaffMemberPopupOpened,
  editStaffMemberPopupClosed,
  editStaffMemberPopupOpened,
  removeStaffFromProgramPopupClosed,
  removeStaffFromProgramPopupOpened,
} from "@features/program/programSlice"
import RemoveStaffMemberDialog from "./removeStaffMemberDialog"

export const ProgramStaffView = (props: { programId: string }) => {
  const { programId } = props

  const programStaffMembers = useAppSelector(
    (state) => state.program.staffPage.staff,
  )
  const classes = useAppSelector(selectClassesForProgram(programId))

  const showAddMemberPopup = useAppSelector(
    (state) => state.program.staffPage.openAddStaffMemberPopup,
  )
  const manageMemberPopup = useAppSelector(
    (state) => state.program.staffPage.editStaffMemberPopup,
  )
  const removeMemberPopup = useAppSelector(
    (state) => state.program.staffPage.removeStaffFromProgram,
  )
  const dispatch = useAppDispatch()
  const onAddNewStaffMemberClick = () => {
    dispatch(addStaffMemberPopupOpened())
  }
  const onEditStaffMemberClick = (userClientId: string) => {
    const managedMember = programStaffMembers.find(
      (m) => m.userClientId === userClientId,
    )
    if (!managedMember) return
    dispatch(editStaffMemberPopupOpened(managedMember))
  }

  const onRemoveUserClicked = (userClientId: string) => {
    dispatch(removeStaffFromProgramPopupOpened({ userClientId, programId }))
  }

  useEffect(() => {
    dispatch(fetchProgramStaff(programId))
  }, [programId, dispatch])

  return (
    <>
      <AddStaffMemberDialog
        open={showAddMemberPopup}
        onClose={() => dispatch(addStaffMemberPopupClosed())}
        programId={programId}
        programCurrentStaff={programStaffMembers}
      />
      {manageMemberPopup && (
        <EditStaffMemberDialog
          open={manageMemberPopup !== undefined}
          onClose={() => dispatch(editStaffMemberPopupClosed())}
          programId={programId}
          staffMember={manageMemberPopup}
        />
      )}
      {removeMemberPopup && (
        <RemoveStaffMemberDialog
          open={removeMemberPopup !== undefined}
          onClose={() => dispatch(removeStaffFromProgramPopupClosed())}
          programId={programId}
          userClientId={removeMemberPopup.userClientId}
          member={programStaffMembers.find(
            (m) => m.userClientId === removeMemberPopup.userClientId,
          )}
        />
      )}
      {programStaffMembers.length === 0 ? (
        <EmptyStaffView onAddNewClassClick={onAddNewStaffMemberClick} />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              Staff members{" "}
              <span
                style={{
                  color: appTheme.palette.text.disabled,
                }}
              >
                ({programStaffMembers.length})
              </span>
            </Typography>
            <Button
              variant="contained"
              size="small"
              onClick={onAddNewStaffMemberClick}
            >
              Add staff member
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              pb: "16px",
            }}
          >
            <StaffTable
              programId={programId}
              staffMembers={programStaffMembers}
              programClasses={classes}
              onEditUserClicked={onEditStaffMemberClick}
              onRemoveUserClicked={onRemoveUserClicked}
            />
          </Box>
        </Box>
      )}
    </>
  )
}
