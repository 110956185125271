import { useAppDispatch } from "@app/hooks"
import { Box, IconButton, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { elementRemoved } from "@features/courseEditor/courseEditorSlice"
import appTheme from "../../theme/appTheme"
import useSelectedElement from "./custom-hooks/useSelectedElement"
import useCloseElementEditor from "./custom-hooks/useCloseElementEditor"
import elementTypeToTitle from "@utils/syllabus+title"
import OptionsButton from "@cmp/buttons/optionsButton"
import useEditElement from "./custom-hooks/useEditElement"
import { Topic } from "@masterschool/course-builder-api"

function ElementEditorHeader(props: { topic: Topic }) {
  const { topic } = props
  const selectedElement = useSelectedElement()
  const dispatch = useAppDispatch()
  const closeEditor = useCloseElementEditor()
  const editElement = useEditElement()

  if (!selectedElement) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 24px",
        borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
        bgcolor: appTheme.palette.primary.contrast,
      }}
    >
      <Box display="flex" gap="24px">
        <IconButton onClick={closeEditor} sx={{ padding: 0 }}>
          <SvgIcon
            component={appIcons.xClose}
            inheritViewBox
            sx={{
              stroke: appTheme.palette.icon.black,
              width: "20px",
              height: "20px",
            }}
          />
        </IconButton>
        <Typography variant="h6">
          {selectedElement ? elementTypeToTitle(selectedElement.type) : ""}{" "}
          information
        </Typography>
      </Box>
      <OptionsButton
        button={{
          sx: { padding: "2px" },
          leftIcon: appIcons.dots,
        }}
        items={[
          {
            text: selectedElement.item.isHidden
              ? `Show ${elementTypeToTitle(selectedElement.type).toLowerCase()}`
              : `Hide ${elementTypeToTitle(
                  selectedElement.type,
                ).toLowerCase()}`,
            onSelect: () => {
              editElement(
                {
                  type: selectedElement.type,
                  item: {
                    ...selectedElement.item,
                    isHidden: !selectedElement.item.isHidden,
                  },
                },
                topic,
              )
            },
            icon: selectedElement.item.isHidden
              ? appIcons.eye
              : appIcons.eyeOff,
          },
          {
            text: `Delete ${elementTypeToTitle(
              selectedElement.type,
            ).toLowerCase()}`,
            onSelect: () => {
              dispatch(
                elementRemoved({
                  editStepId: window.crypto.randomUUID(),
                  topicId: topic.id,
                  elementId: selectedElement.item.id,
                }),
              )
              closeEditor()
            },
            icon: appIcons.trash03,
          },
        ]}
      />
    </Box>
  )
}

export default ElementEditorHeader
