import { Theme } from "@mui/material"
import { ComponentsProps, ComponentsOverrides } from "@mui/material"

export const MuiSelectOverrides: {
  defaultProps?: ComponentsProps["MuiSelect"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiSelect"]
} = {
  defaultProps: {
    sx: {
      fontSize: "17px",
    },
  },
}
