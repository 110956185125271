import {
  CalendarHubClient,
  ProgramManagementCalendar,
} from "@clients/calendarHubClient"
import { useEffect, useState } from "react"

export const useGetCalendars = () => {
  const [calendars, setCalendars] = useState<ProgramManagementCalendar>({
    schoolCalendars: [],
    staffCalendars: [],
    studentCalendars: [],
  })
  useEffect(() => {
    CalendarHubClient.getProgramCalendars().then((res) => {
      setCalendars(res)
    })
  }, [])
  return calendars
}
