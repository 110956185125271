import { useAppSelector } from "@app/hooks"
import {
  selectClass,
  selectFilteredStudents,
} from "@features/program/programSliceSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Box, Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useParams } from "react-router-dom"
import appTheme from "../../../theme/appTheme"
import {
  programDisplayName,
  getProgramNoSyllabusWarning,
  getProgramHasUnassignedStudentsWarning,
} from "../ui/program+ui"
import ProgramInfo from "./programInfo"
import ClassesWidget from "./widgets/classesWidget"
import ProjectsWidget from "./widgets/projectsWidget"
import SlackChannelsWidget from "./widgets/slackWidget"
import StudentsWidget from "./widgets/studentsWidget"
import SyllabusWidget from "./widgets/syllabusWidget"
import SyllabusPagePopupsPresenter from "../syllabus/popups/popupsPresenter"
import ProgramIssueWarning from "../ui/programIssueWarning"
import StaffWidget from "./widgets/staffWidget"
import HomepageUrlWidget from "./widgets/homepageURL/homepageUrlWidget"

function ProgramMain(props: { program: ProgramDto }) {
  const { classId } = useParams()
  const programClass = useAppSelector(selectClass(classId))
  const programStudents = useAppSelector(
    selectFilteredStudents(props.program.id),
  )

  const widgets = [
    <StudentsWidget {...props} />,
    <StaffWidget {...props} />,
    <SyllabusWidget {...props} />,
    <SlackChannelsWidget programId={props.program.id} />,
    classId ? null : <ClassesWidget program={props.program} />,
    <ProjectsWidget program={props.program} />,
  ].msCompactMap((widget) => widget)

  const noSyllabusWarning = getProgramNoSyllabusWarning(props.program)
  const unassignedStudentsWarning = getProgramHasUnassignedStudentsWarning(
    props.program,
    programStudents,
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
        width: "100%",
        maxWidth: "1240px",
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <SyllabusPagePopupsPresenter />
        {noSyllabusWarning && (
          <ProgramIssueWarning
            alertText={noSyllabusWarning}
            programId={props.program.id}
            targetTab="syllabus"
          />
        )}
        {unassignedStudentsWarning && (
          <ProgramIssueWarning
            alertText={unassignedStudentsWarning}
            programId={props.program.id}
            targetTab="students"
          />
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <SvgIcon
            inheritViewBox
            component={classId ? appIcons.home05 : appIcons.building06}
            sx={{
              stroke: appTheme.palette.icon.black,
              fill: "none",
              width: "32px",
              height: "32px",
            }}
          />
          <Typography variant="h5" sx={{}}>
            {programClass
              ? programClass.name
              : programDisplayName(props.program)}
          </Typography>
        </Stack>
        <Stack overflow="auto" gap={2} paddingBottom={2}>
          <ProgramInfo program={props.program} />
          <Box display="flex" gap="24px" width="100%">
            <Box
              display="grid"
              gridTemplateColumns={"1fr"}
              gap={2}
              width="100%"
            >
              {widgets.map((widget, index) => (
                <Stack key={index} sx={{ overflowX: "hidden" }}>
                  {widget}
                </Stack>
              ))}
            </Box>
            <Box width="60%">
              <HomepageUrlWidget {...props} />
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ProgramMain
