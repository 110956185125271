import { useAppDispatch, useAppSelector } from "@app/hooks"
import { selectSyllabus } from "@features/syllabus/syllabusSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Stack, Typography } from "@mui/material"
import { AddProjectMenu } from "../../projects/addProjectMenu"
import EmptyWidgetCard from "./emptyWidgetCard"
import WidgetCard from "./widgetCard"
import { useEffect } from "react"
import { fetchProgramProjects } from "@features/program/projects/programProjectsSlice"

function ProjectsWidget({ program }: { program: ProgramDto }) {
  const dispatch = useAppDispatch()
  const programId = program.id
  const syllabusId = program.syllabusId
  const projectsState = useAppSelector((state) => state.programProjects)
  const syllabus = useAppSelector(selectSyllabus(syllabusId))
  const disabled = syllabus === undefined
  const projects = projectsState.projects
  const hasNoProjects = projects.length === 0

  useEffect(() => {
    dispatch(fetchProgramProjects(programId))
  }, [dispatch, programId])

  if (hasNoProjects) {
    return (
      <EmptyWidgetCard
        message="There are no projects to display."
        actionElement={
          <Stack alignItems="center" gap={1}>
            <AddProjectMenu
              programId={programId}
              disabled={disabled}
              unitsInSyllabus={syllabus?.units ?? []}
              icon="chevronDown"
              buttonProps={{
                size: "small",
                variant: "outlined",
              }}
            />
            {disabled && (
              <Typography
                variant="body3"
                color="text.disabled"
                textAlign="center"
              >
                Projects can only be added after a syllabus has been connected
                to a program
              </Typography>
            )}
          </Stack>
        }
      />
    )
  }

  return (
    <WidgetCard
      title="Projects"
      navigateTo={`/program/${programId}?tab=projects`}
    >
      <Typography variant="body2">
        {projects.length === 1
          ? `${projects[0].name}`
          : `${projects.length} Projects`}
      </Typography>
    </WidgetCard>
  )
}

export default ProjectsWidget
