import { ProgramClassDto } from "@masterschool/course-builder-api"
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"

export function ClassesToCheck({
  classesToCheck,
  checkedClassesId,
  handleCheck,
  title,
}: {
  classesToCheck: ProgramClassDto[]
  checkedClassesId: string[]
  handleCheck: (classId: string) => void
  title: string | undefined
}) {
  return (
    <Stack flex={1}>
      {title && (
        <Typography variant="body2" fontWeight="400">
          {title}
        </Typography>
      )}
      <List style={{ maxHeight: "390px", overflow: "auto" }}>
        {classesToCheck.map((classItem) => (
          <ListItem
            key={classItem.id}
            dense
            onClick={() => handleCheck(classItem.id)}
            sx={{ padding: "0px" }}
          >
            <Checkbox
              edge="start"
              checked={checkedClassesId.indexOf(classItem.id) !== -1}
              disableRipple
              inputProps={{
                "aria-labelledby": `checkbox-list-label-${classItem.id}`,
              }}
            />
            <ListItemText
              id={`checkbox-list-label-${classItem.id}`}
              primary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ display: "inline" }}
                  >
                    {`Class ${classItem.name}`}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                    style={{
                      display: "inline",
                      marginLeft: "4px",
                    }}
                  >
                    {`(${classItem.students.length} active students)`}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}
