import { useAppDispatch } from "@app/hooks"
import { Box, IconButton, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { elementRemoved } from "../../features/courseEditor/courseEditorSlice"
import appTheme from "../../theme/appTheme"

import OptionsButton from "@cmp/buttons/optionsButton"
import { Topic } from "@masterschool/course-builder-api"
import useSelectedLiveEvent from "./custom-hooks/useSelectedLiveEvent"
import useCloseElementEditor from "../elements/custom-hooks/useCloseElementEditor"
import useEditLiveEvent from "./custom-hooks/useEditLiveEvent"

function LiveEventEditorHeader(props: { topic: Topic }) {
  const { topic } = props
  const selectedLiveEvent = useSelectedLiveEvent()
  const dispatch = useAppDispatch()
  const closeEditor = useCloseElementEditor()
  const editLiveEvent = useEditLiveEvent()

  if (!selectedLiveEvent) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 24px",
        bgcolor: appTheme.palette.background.default,
        borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
      }}
    >
      <Box display="flex" gap="24px">
        <IconButton onClick={closeEditor} sx={{ padding: 0 }}>
          <SvgIcon
            component={appIcons.xClose}
            inheritViewBox
            sx={{
              stroke: appTheme.palette.icon.black,
              width: "20px",
              height: "20px",
            }}
          />
        </IconButton>
        <Typography variant="h6">Live session information</Typography>
      </Box>
      <OptionsButton
        button={{
          sx: { padding: "2px" },
          leftIcon: appIcons.dots,
        }}
        items={[
          {
            text: selectedLiveEvent.isHidden
              ? `Show live session`
              : `Hide live session`,
            onSelect: () => {
              editLiveEvent(
                selectedLiveEvent,
                "isHidden",
                !selectedLiveEvent.isHidden,
                topic,
              )
            },
            icon: selectedLiveEvent.isHidden ? appIcons.eye : appIcons.eyeOff,
          },
          {
            text: `Delete live session`,
            onSelect: () => {
              dispatch(
                elementRemoved({
                  editStepId: window.crypto.randomUUID(),
                  topicId: topic.id,
                  elementId: selectedLiveEvent.id,
                }),
              )
              closeEditor()
            },
            icon: appIcons.trash03,
          },
        ]}
      />
    </Box>
  )
}

export default LiveEventEditorHeader
