import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { RootState } from "@app/store"
import { ProgramClassClient } from "@clients/programClassClient"
import { ProgramClient } from "@clients/programClient"
import {
  CreateProgramClassRequest,
  ProgramClassDto,
  UserDto,
} from "@masterschool/course-builder-api"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { selectProgramByStudentId } from "./programSliceSelectors"
import {
  ProgramStaffClient,
  StaffMemberProperties,
} from "@clients/programStaffClient"
import { MentorClient } from "@clients/mentorClient"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { ProgramDetailsFormModel } from "./programSlice"

export const unregisterStudent = createAppAsyncThunk(
  "program/unregisterStudent",
  async (requestParams: {
    programId: string
    classId: string
    studentId: string
  }) => {
    return ProgramClassClient.unregisterStudentFromClass(
      requestParams.studentId,
    )
  },
)

export const fetchPrograms = createAsyncThunk(
  "program/fetchPrograms",
  async () => {
    return ProgramClient.listPrograms()
  },
)

export const fetchClasses = createAppAsyncThunk(
  "program/fetchClasses",
  async (requestParams: { programId: string }) => {
    return ProgramClient.listClasses(requestParams.programId)
  },
)

export const registerStudent = createAppAsyncThunk(
  "program/registerStudent",
  async (requestParams: {
    classId: string
    student: Omit<UserDto, "id" | "roles">
  }) => {
    return ProgramClassClient.registerStudentToClass(
      requestParams.classId,
      requestParams.student,
    )
  },
)

export const preprocessMultipleStudentsRegistration = createAppAsyncThunk(
  "program/preprocessMultipleStudentsRegistration",
  async (
    requestParams: {
      classId: string
      students: Omit<UserDto, "id" | "roles">[]
    },
    thunkAPI,
  ) => {
    requestParams.students.forEach((student) => {
      thunkAPI.dispatch(
        preprocessSingleStudentRegistration({
          classId: requestParams.classId,
          student,
        }),
      )
    })
  },
)

export const preprocessSingleStudentRegistration = createAppAsyncThunk(
  "program/preprocessSingleStudentRegistration",
  async (requestParams: {
    classId: string
    student: Omit<UserDto, "id" | "roles">
  }) => {
    return ProgramClassClient.studentRegistrationStatus(
      requestParams.student.email,
    )
  },
)

export const registerMultipleStudents = createAppAsyncThunk(
  "program/registerMultipleStudents",
  async (requestParams: { classId: string }, thunkAPI) => {
    const state =
      thunkAPI.getState().program.studentsPage.multipleStudentsOnboardPopup
    if (!state) {
      return
    }

    const students = state.students.filter(
      (student) => state.statuses[student.email] === "ready",
    )
    students.forEach((student) => {
      thunkAPI.dispatch(
        registerStudent({
          classId: requestParams.classId,
          student,
        }),
      )
    })
  },
)

export const createProgramClass = createAppAsyncThunk(
  "program/createProgramClass",
  async (requestParams: CreateProgramClassRequest, thunkAPI) => {
    return ProgramClassClient.createProgramClass(
      requestParams.name,
      requestParams.programId,
      requestParams.syllabusId,
    ).then(() => {
      thunkAPI.dispatch(fetchClasses({ programId: requestParams.programId }))
    })
  },
)

export const editProgramClass = createAppAsyncThunk(
  "program/editProgramClass",
  async (
    requestParams: {
      programId: string
      classId: string
      name: string
      syllabusId: string | undefined
    },
    thunkAPI,
  ) => {
    const { programId, classId, syllabusId } = requestParams
    const state = thunkAPI.getState()
    const targetSyllabusId = getTargetSyllabusIdForClassEdit(
      state,
      programId,
      syllabusId,
    )
    return ProgramClassClient.editProgramClass({
      classId,
      name: requestParams.name,
      syllabusId: targetSyllabusId,
    }).then(() => {
      thunkAPI.dispatch(fetchClasses({ programId: requestParams.programId }))
    })
  },
)

export const editProgramClassesSyllabus = createAppAsyncThunk(
  "program/editProgramClassesSyllabus",
  async (
    requestParams: {
      programId: string
      classIds: string[]
      syllabusId: string | undefined
    },
    thunkAPI,
  ) => {
    const { programId, classIds, syllabusId } = requestParams
    const state = thunkAPI.getState()
    const targetSyllabusId = getTargetSyllabusIdForClassEdit(
      state,
      programId,
      syllabusId,
    )
    const classes = state.program.programIdToClasses[programId].filter((c) =>
      classIds.includes(c.id),
    )

    return makeMultipleEditProgramClassesRequests(
      classes,
      targetSyllabusId,
    ).then(() =>
      Promise.allSettled([
        thunkAPI.dispatch(fetchPrograms()),
        thunkAPI.dispatch(fetchClasses({ programId: requestParams.programId })),
      ]),
    )
  },
)

export const changeStudentProgram = createAppAsyncThunk(
  "program/changeProgram",
  async (_, thunkAPI) => {
    const popupState =
      thunkAPI.getState().program.studentsPage.programTransferStudentPopup

    if (!popupState) {
      return
    }
    const studentId = popupState.studentId
    const {
      selectedClassId: classId,
      reason: changeType,
      summary,
    } = popupState.form
    if (!classId || !changeType) {
      return
    }
    await ProgramClassClient.changeStudentClass({
      studentId,
      classId,
      changeType,
      summary,
    })
  },
)

export const changeStudentClass = createAppAsyncThunk(
  "program/changeClass",
  async (_, thunkAPI) => {
    const popupState =
      thunkAPI.getState().program.studentsPage.classTransferStudentPopup

    if (!popupState) {
      return
    }
    const studentId = popupState.studentId
    const {
      selectedClassId: classId,
      reason: changeType,
      summary,
    } = popupState.form
    if (!classId || !changeType) {
      return
    }
    await ProgramClassClient.changeStudentClass({
      studentId,
      classId,
      changeType,
      summary,
    })
  },
)

export const deleteProgramClass = createAppAsyncThunk(
  "program/deleteProgramClass",
  async (
    requestParams: {
      programId: string
      classId: string
    },
    thunkAPI,
  ) => {
    return ProgramClassClient.deleteProgramClass(requestParams.classId).then(
      () => {
        thunkAPI.dispatch(fetchClasses({ programId: requestParams.programId }))
      },
    )
  },
)

export const createProgram = createAppAsyncThunk(
  "program/createProgram",
  async (_, thunkAPI) => {
    const requestParams = thunkAPI.getState().program.createProgramPopup?.form

    if (
      !requestParams ||
      !requestParams.domain ||
      !requestParams.language ||
      !requestParams.startDate ||
      !requestParams.durationInMonths ||
      !requestParams.type
    ) {
      return
    }

    return ProgramClient.createProgram({
      domain: requestParams.domain,
      language: requestParams.language,
      startDate: requestParams.startDate,
      durationInMonths: requestParams.durationInMonths,
      type: requestParams.type,
    }).then(() => {
      thunkAPI.dispatch(fetchPrograms())
    })
  },
)

export const editProgramProperties = createAppAsyncThunk(
  "program/editProgramProperties",
  async (
    requestParams: {
      programId: string
      editedProperties: Partial<ProgramDetailsFormModel>
    },
    thunkAPI,
  ) => {
    const { programId, editedProperties } = requestParams
    if (Object.keys(editedProperties).length === 0) {
      return
    }
    return ProgramClient.updateProgram(programId, editedProperties).then(() => {
      thunkAPI.dispatch(fetchPrograms())
    })
  },
)

export const fetchStudentByFetchingPrograms = createAppAsyncThunk(
  "program/fetchStudent",
  async (requestParams: { studentId: string }, thunkAPI) => {
    const programIds = selectProgramByStudentId(requestParams.studentId)(
      thunkAPI.getState(),
    )
    programIds.forEach((programId) => {
      thunkAPI.dispatch(fetchClasses({ programId }))
    })
  },
)

export const deleteProgramConfirmed = createAppAsyncThunk(
  "program/deleteProgram",
  async (_, thunkAPI) => {
    const programId = thunkAPI.getState().program.deleteProgramPopup?.programId
    if (!programId) {
      return
    }
    return ProgramClient.deleteProgram(programId).then(() => {
      thunkAPI.dispatch(fetchPrograms())
    })
  },
)

export const fetchProgramStaff = createAppAsyncThunk(
  "program/fetchProgramStaff",
  async (programId: string) => {
    return ProgramStaffClient.listProgramStaff(programId)
  },
)

export const addProgramStaffMemberPopupAccepted = createAppAsyncThunk(
  "program/addProgramStaffMember",
  async (
    requestParams: {
      programId: string
      userClientId: string
      roles: string[]
      studentsToAssign: string[]
      properties: StaffMemberProperties | undefined
    },
    thunkAPI,
  ) => {
    return ProgramStaffClient.addProgramStaffMember(
      requestParams.programId,
      requestParams.userClientId,
      requestParams.roles,
      requestParams.properties,
    )
      .then(() => {
        thunkAPI.dispatch(
          showSuccessSnackbar("Staff member added successfully"),
        )
        if (requestParams.studentsToAssign.length > 0) {
          return MentorClient.assignStudentsToMentor(
            requestParams.studentsToAssign,
            requestParams.userClientId,
          ).catch((e) => {
            thunkAPI.dispatch(
              showErrorSnackbar(
                `Failed to assign students to mentor: ` + e.message,
              ),
            )
          })
        }
      })
      .catch((e) => {
        thunkAPI.dispatch(
          showErrorSnackbar(`Failed to add staff member: ` + e.message),
        )
      })
      .finally(() => {
        thunkAPI.dispatch(fetchProgramStaff(requestParams.programId))
        thunkAPI.dispatch(fetchClasses({ programId: requestParams.programId }))
      })
  },
)

export const editProgramStaffMemberPopupAccepted = createAppAsyncThunk(
  "program/editProgramStaffMember",
  async (
    requestParams: {
      programId: string
      userClientId: string
      roles: string[]
      properties: StaffMemberProperties | undefined
      studentsToAssign: string[]
      studentsToUnassign: string[]
    },
    thunkAPI,
  ) => {
    const { programId, userClientId, roles, properties } = requestParams
    const { studentsToAssign, studentsToUnassign } = requestParams
    try {
      if (roles.length === 0) {
        await ProgramStaffClient.removeProgramStaffMember(
          programId,
          userClientId,
        )
      } else {
        await ProgramStaffClient.editProgramStaffMember(
          programId,
          userClientId,
          roles,
          properties,
        )
      }
      if (studentsToUnassign.length > 0) {
        await MentorClient.unassignStudentsFromMentor(
          requestParams.studentsToUnassign,
          requestParams.userClientId,
        )
      }
      if (studentsToAssign.length > 0) {
        await MentorClient.assignStudentsToMentor(
          requestParams.studentsToAssign,
          requestParams.userClientId,
        )
      }
      thunkAPI.dispatch(
        showSuccessSnackbar("Staff member updated successfully"),
      )
    } catch (e: any) {
      thunkAPI.dispatch(
        showErrorSnackbar(`Failed to update staff member: ` + e.message),
      )
    } finally {
      thunkAPI.dispatch(fetchProgramStaff(programId))
      thunkAPI.dispatch(fetchClasses({ programId: programId }))
    }
  },
)

export const removeProgramStaffMember = createAppAsyncThunk(
  "program/removeProgramStaffMember",
  async (
    requestParams: {
      programId: string
      userClientId: string
    },
    thunkAPI,
  ) => {
    return ProgramStaffClient.removeProgramStaffMember(
      requestParams.programId,
      requestParams.userClientId,
    )
      .then(() => {
        thunkAPI.dispatch(fetchProgramStaff(requestParams.programId))
        thunkAPI.dispatch(fetchClasses({ programId: requestParams.programId }))
        thunkAPI.dispatch(
          showSuccessSnackbar("Staff member removed successfully"),
        )
      })
      .catch((e) => {
        thunkAPI.dispatch(
          showErrorSnackbar(`Failed to remove staff member: ` + e.message),
        )
      })
  },
)

export const connectSyllabusToProgram = createAppAsyncThunk(
  "program/connectSyllabusToProgram",
  async (
    requestParams: {
      programId: string
      syllabusId: string
    },
    thunkAPI,
  ) => {
    return ProgramClient.setSyllabusForProgram(
      requestParams.programId,
      requestParams.syllabusId,
    ).then(() => {
      thunkAPI.dispatch(fetchPrograms())
      thunkAPI.dispatch(fetchClasses({ programId: requestParams.programId }))
    })
  },
)

export const disconnectSyllabusFromProgram = createAppAsyncThunk(
  "program/disconnectSyllabusFromProgram",
  async (programId: string, thunkAPI) => {
    return ProgramClient.deleteSyllabusFromProgram(programId).then(() => {
      thunkAPI.dispatch(fetchPrograms())
      thunkAPI.dispatch(fetchClasses({ programId }))
    })
  },
)

const makeMultipleEditProgramClassesRequests = (
  classes: ProgramClassDto[],
  syllabusId: string | undefined,
) => {
  return Promise.allSettled(
    classes.map((c) =>
      ProgramClassClient.editProgramClass({
        classId: c.id,
        name: c.name,
        syllabusId,
      }),
    ),
  )
}

const getTargetSyllabusIdForClassEdit = (
  state: RootState,
  programId: string,
  syllabusId: string | undefined,
) => {
  const program = state.program.programs.find((p) => p.id === programId)
  return program?.syllabusId !== syllabusId ? syllabusId : undefined
}
