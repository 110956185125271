import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import TitleTextField from "../../components/titleTextField"
import UrlTextField from "../../components/urlTextField"
import { useEditCodioProject } from "../custom-hooks/useEditElementItem"
import { useSelectedCodioProject } from "../custom-hooks/useSelectedElementItem"
import { Topic } from "@masterschool/course-builder-api"
import EditProjectType from "./editProjectType"
import EditorTextField, {
  EditorTextFieldProps,
} from "../../components/editorTextField"
import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "@features/courseEditor/courseValidationsSelectors"

function CodioProjectEditor(props: { topic: Topic }) {
  const { topic } = props
  const codioProject = useSelectedCodioProject()
  const editCodioProject = useEditCodioProject()
  if (!codioProject) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "16px",
          }}
        >
          <TitleTextField
            elementId={codioProject.id}
            value={codioProject.title}
            debounce
            onChange={(e) => {
              editCodioProject(codioProject, "title", e.target.value, topic)
            }}
          />
          <EditProjectType topic={topic} />
        </Box>
        <TimeEstimationTextField
          elementId={codioProject.id}
          value={codioProject.estimatedDuration}
          debounce
          onChange={(e) => {
            editCodioProject(
              codioProject,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
        />
        <UrlTextField
          elementId={codioProject.id}
          value={codioProject.url}
          debounce
          label="Project URL"
          onChange={(e) => {
            const newValue = e.target.value
            editCodioProject(codioProject, "url", newValue, topic)
          }}
        />
        <UrlTextField
          elementId={codioProject.id}
          value={codioProject.taskUrl}
          debounce
          label="Task URL"
          onChange={(e) => {
            const newValue = e.target.value
            editCodioProject(codioProject, "taskUrl", newValue, topic)
          }}
        />
        <UrlTextField
          elementId={codioProject.id}
          value={codioProject.solutionUrl}
          debounce
          label="Solution URL"
          onChange={(e) => {
            const newValue = e.target.value
            editCodioProject(codioProject, "solutionUrl", newValue, topic)
          }}
        />
        <PassingGradeTextField
          elementId={codioProject.id}
          value={codioProject.passingGrade}
          debounce
          onChange={(e) => {
            editCodioProject(
              codioProject,
              "passingGrade",
              parseInt(e.target.value),
              topic,
            )
          }}
        />
      </Box>
    </Box>
  )
}

const PassingGradeTextField = (
  props: EditorTextFieldProps & {
    elementId: string
    value: number | undefined
  },
) => {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )
  const hasError = errors?.includes("INVALID_PASSING_GRADE")
  const errorMessage = hasError
    ? "Please enter a value between 0 and 100."
    : undefined

  return (
    <EditorTextField
      type="number"
      label="Passing grade"
      value={props.value}
      debounce
      onChange={props.onChange}
      error={errorMessage !== undefined}
      helperText={errorMessage}
    />
  )
}

export default CodioProjectEditor
