import HighlightableScrollRef from "@cmp/highlightableScrollRef"
import { DatacampLesson, Topic } from "@masterschool/course-builder-api"
import { Box, Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { useEffect, useRef, useState } from "react"
import appTheme from "../../../theme/appTheme"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import TitleTextField from "../../components/titleTextField"
import { useEditDatacampLesson } from "../custom-hooks/useEditElementItem"
import { useSelectedDatacampLesson } from "../custom-hooks/useSelectedElementItem"
import EditLessonType from "./EditLessonType"
import DatacampGroupsEditor from "./task-editors/datacampGroupsEditor"

function DatacampLessonEditor(props: { topic: Topic }) {
  const { topic } = props
  const lesson = useSelectedDatacampLesson()
  const editLesson = useEditDatacampLesson()

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <TitleTextField
          elementId={lesson.id}
          value={lesson.title}
          debounce
          onChange={(e) => {
            const newValue = e.target.value
            editLesson(lesson, "title", newValue, topic)
          }}
        />
        <EditLessonType topic={topic} />
      </Box>
      <TimeEstimationTextField
        elementId={lesson.id}
        value={lesson.estimatedDuration}
        debounce
        onChange={(e) => {
          editLesson(
            lesson,
            "estimatedDuration",
            parseInt(e.target.value),
            topic,
          )
        }}
      />
      <TasksEditorContainer lesson={lesson} topic={topic} />
    </Box>
  )
}

const TasksEditorContainer = (props: {
  lesson: DatacampLesson
  topic: Topic
}) => {
  const { lesson, topic } = props
  const editLesson = useEditDatacampLesson()
  const tasksEditorRef = useRef<HighlightableScrollRef>(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false)

  useEffect(() => {
    if (shouldScrollToBottom) {
      tasksEditorRef.current?.scrollToBottomAndHighlightLastTask()
    }
    setShouldScrollToBottom(false)
  }, [shouldScrollToBottom])

  const addGroupText =
    lesson.tasks.length === 0 ? "Add group (optional)" : "Add group"

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "start",
        gap: "16px",
        overflow: "hidden",
      }}
    >
      <DatacampGroupsEditor ref={tasksEditorRef} topic={topic} />
      <Button
        variant="text"
        size="small"
        onClick={() => {
          editLesson(
            lesson,
            "tasks",
            lesson.tasks.concat(
              SyllabusElementFactory.makeDatacampTasksGroup(),
            ),
            topic,
          )
          setShouldScrollToBottom(true)
        }}
        startIcon={
          <SvgIcon
            component={appIcons.plusCircle}
            inheritViewBox
            sx={{
              width: "20px",
              height: "20px",
              fill: "none",
              stroke: appTheme.palette.icon.black,
            }}
          />
        }
      >
        {addGroupText}
      </Button>
    </Box>
  )
}

export default DatacampLessonEditor
