import { useAppDispatch, useAppSelector } from "@app/hooks"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import {
  createProgramPopupOpened,
  deleteProgramPopupOpened,
  editProgramPopupOpened,
} from "@features/program/programSlice"
import { fetchClasses } from "@features/program/programSliceThunks"
import { selectMainPagePrograms } from "@features/search/searchSlice"
import { ProgramClassDto, ProgramDto } from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import { Link } from "react-router-dom"
import appTheme from "../../theme/appTheme"
import { LanguageChip } from "../program/components/languageChip"
import { ProgramSearchComponent } from "../program/components/programSearchComponent"
import ProgramTypeChip from "../program/components/programTypeChip"
import {
  programDisplayName,
  getProgramNoSyllabusWarning,
} from "../program/ui/program+ui"
import CreateProgramPopup from "./createProgramPopup"
import DeleteProgramPopup from "./deleteProgramPopup"
import CustomTooltip from "@cmp/customTooltip"
import EditProgramPopup from "./editProgramPopup"

type ProgramDisplayData = ProgramDto & { scope: ProgramScopeData }

type ProgramScopeData = {
  type: string
  language: string
}

function ProgramsMenu() {
  const dispatch = useAppDispatch()
  const programs = useAppSelector(selectMainPagePrograms)
  const programsWithScope = mapProgramScope(programs)
  const sortedPrograms = [...programsWithScope].sort((a, b) =>
    new Date(a.startDate) > new Date(b.startDate) ? -1 : 1,
  )

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingX: "24px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1240px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "80px",
          mb: "64px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h4">Programs</Typography>
          <Button
            variant="contained"
            onClick={() => dispatch(createProgramPopupOpened())}
          >
            Add program
          </Button>
        </Box>
        <ProgramSearchComponent />
        <ProgramsTable data={sortedPrograms} />
      </Box>
      <CreateProgramPopup />
      <DeleteProgramPopup />
      <EditProgramPopup />
    </Box>
  )
}

function mapProgramScope(programs: ProgramDto[]): ProgramDisplayData[] {
  return programs.map((program) => ({
    ...program,
    scope: {
      type: program.type,
      language: program.language,
    },
  }))
}

function ProgramsTable({ data }: { data: ProgramDisplayData[] }) {
  return (
    <TableContainer
      sx={{
        width: "100%",
        maxHeight: "100%",
        overflow: "auto",
        bgcolor: appTheme.palette.eTypes.sand15,
        border: `1px solid ${appTheme.palette.divider}`,
        borderRadius: "4px",
      }}
    >
      <Table>
        <TableHead>
          <TableRow
            sx={{
              bgcolor: appTheme.palette.primary.contrast,
              "&:hover": {
                bgcolor: appTheme.palette.action.hover,
              },
            }}
          >
            <TableCell />
            <TableCell>Program</TableCell>
            <TableCell>Scope</TableCell>
            <TableCell>Classes</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody sx={{ bgcolor: appTheme.palette.primary.contrast }}>
          {data.map((program) => (
            <ProgramRow program={program} key={program.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function ProgramScope({ scope }: { scope: ProgramScopeData }) {
  return (
    <Stack direction="row" gap="8px">
      <ProgramTypeChip type={(scope.type as string) ?? ""} />
      <LanguageChip language={scope.language} />
    </Stack>
  )
}

function ProgramRow({ program }: { program: ProgramDisplayData }) {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const classes = useAppSelector(
    (state) => state.program.programIdToClasses[program.id],
  )
  const disableButton = program.classesIds.length === 0

  const handleOpenClasses = () => {
    if (disableButton) return
    setOpen(!open)
    if (classes === undefined) {
      dispatch(fetchClasses({ programId: program.id }))
    }
  }

  const programNoSyllabusWarning = getProgramNoSyllabusWarning(program)

  return (
    <>
      <TableRow
        component={Link}
        to={`/program/${program.id}`}
        key={program.id}
        sx={{
          textDecoration: "none",
          "&:hover": {
            bgcolor: appTheme.palette.action.hover,
          },
        }}
      >
        <TableCell
          sx={{
            width: "20px",
            padding: "16px",
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              handleOpenClasses()
            }}
            disabled={disableButton}
            sx={{
              borderRadius: "50%",
              mr: "8px",
            }}
            size="small"
          >
            <SvgIcon
              component={appIcons.chevronRight}
              inheritViewBox
              sx={{
                fill: "none",
                stroke:
                  program.classesIds.length === 0
                    ? appTheme.palette.action.disabled
                    : appTheme.palette.icon.black,
                width: "20px",
                height: "20px",
                transform: open ? "rotate(90deg)" : "rotate(0deg)",
                transition: "all .35s ease-in-out",
              }}
            />
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            width: "360px",
          }}
        >
          <Stack direction="row" gap={1}>
            <span>{programDisplayName(program)}</span>
            {programNoSyllabusWarning && (
              <CustomTooltip title={programNoSyllabusWarning} leaveDelay={0}>
                <Box>
                  <SvgIcon
                    component={appIcons.alertTriangle}
                    inheritViewBox
                    sx={{
                      fill: "none",
                      color: "warning.main",
                      width: 20,
                      height: 20,
                    }}
                  />
                </Box>
              </CustomTooltip>
            )}
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            width: "360px",
          }}
        >
          <ProgramScope scope={program.scope} />
        </TableCell>
        <TableCell>
          {program.classesIds.length === 0
            ? "No classes"
            : program.classesIds.length === 1
            ? "1 class"
            : `${program.classesIds.length} classes`}
        </TableCell>
        <TableCell>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <ActionsMenu programId={program.id} />
            <Button
              variant="outlined"
              size="small"
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                textDecoration: "none",
                color: "inherit",
              }}
              component={Link}
              to={`/program/${program.id}`}
            >
              View
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} sx={{ p: "0px", border: "none" }}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              bgcolor: appTheme.palette.eTypes.sand15,
              border: "none",
              width: "100%",
            }}
          >
            {classes && <ClassesTable classes={classes} program={program} />}
            {!classes && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "16px",
                }}
              >
                <LoadingComponent loadedComponents="" />
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function ClassesTable({
  program,
  classes,
}: {
  program: ProgramDto
  classes: ProgramClassDto[]
}) {
  const syllabuses = useAppSelector((state) => state.syllabusesMenu.syllabuses)

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {classes.map(({ id, syllabusId, name, students }) => {
        const resolvedSyllabusId = syllabusId ?? program.syllabusId
        const syllabus = syllabuses.find((s) => s.id === resolvedSyllabusId)
        return (
          <Box
            component={Link}
            key={id}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              textDecoration: "none",
              "&:hover": {
                bgcolor: appTheme.palette.action.hover,
              },
              pl: "72px",
              color: "inherit",
            }}
            to={`/program/${program.id}/${id}`}
          >
            <Typography sx={{ padding: "16px", flex: 3 }}>
              {name || "unnamed class"}
            </Typography>
            <Typography sx={{ padding: "16px", flex: 1 }}>
              {students.length === 1
                ? "1 student"
                : `${students.length} students`}
            </Typography>
            <Typography sx={{ padding: "16px", flex: 7 }}>
              {syllabus?.name || "No syllabus"}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

const ActionsMenu = (props: { programId: string }) => {
  const dispatch = useAppDispatch()

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon
              component={appIcons.dotsHorizontal}
              inheritViewBox
              sx={{ color: "primary.main" }}
            />
          ),
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "edit-program",
            children: "Edit program",
            onClick: () => {
              dispatch(
                fetchClasses({
                  programId: props.programId,
                }),
              ).then(() => {
                dispatch(
                  editProgramPopupOpened({
                    programId: props.programId,
                  }),
                )
              })
            },
          },
        },
        {
          type: "menu-item",
          props: {
            key: "delete-program",
            children: "Delete program",
            onClick: () => {
              dispatch(
                fetchClasses({
                  programId: props.programId,
                }),
              ).then(() => {
                dispatch(
                  deleteProgramPopupOpened({
                    programId: props.programId,
                  }),
                )
              })
            },
          },
        },
      ]}
    />
  )
}

export default ProgramsMenu
