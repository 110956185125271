import { CampusClient } from "./campusClient"

export type AppFeatureFlag = {
  name: string
  enabled: boolean
  createdAt?: Date
  updatedAt?: Date
}

export class AppFeatureFlagClient {
  static async listFlags(): Promise<AppFeatureFlag[]> {
    return CampusClient.get<AppFeatureFlag[]>(`v1/app-feature-flags`)
  }

  static async createOrUpdate(flag: AppFeatureFlag): Promise<AppFeatureFlag> {
    return CampusClient.post<AppFeatureFlag>(`v1/app-feature-flags`, flag)
  }
}
