import { useAppSelector } from "@app/hooks"
import { useParams, useSearchParams } from "react-router-dom"
import { selectEditableSyllabus } from "@features/syllabus/syllabusSelectors"

function useActiveSyllabusUnit() {
  const [searchParams] = useSearchParams()
  const unitIdFromSearchParams = searchParams.get("unitId")
  const { unitId: unitIdFromParams } = useParams()
  const unitId = unitIdFromParams ?? unitIdFromSearchParams
  const syllabus = useAppSelector(selectEditableSyllabus)
  const element = syllabus?.units.find((u) => u.id === unitId)
  return element
}

export default useActiveSyllabusUnit
