import OptionsButton2 from "@cmp/buttons/optionsButton2"
import GenericTable from "@cmp/genericTable"
import { Box } from "@mui/material"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import { useNavigate } from "react-router-dom"

function AppIconsPage() {
  const navigate = useNavigate()
  const icons = Object.keys(appIcons).map((key) => ({
    icon: key,
    svg: (appIcons as any)[key],
    actions: key,
  }))

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        padding: "24px",
        paddingBottom: "48px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1240px",
          marginX: "auto",
        }}
      >
        <GenericTable
          onClick={(icon) => {
            navigate(`/icon/${icon.icon}`)
          }}
          data={icons}
          keys={["icon", "svg", "actions"]}
          renderHeader={{
            actions: () => undefined,
          }}
          render={{
            svg: (svg) => <AppIcon icon={svg} />,
            actions: (key) => (
              <OptionsButton2
                buttonModel={{
                  type: "icon",
                  props: {
                    sx: {
                      marginLeft: "auto",
                      display: "flex",
                    },
                    size: "small",
                    children: <AppIcon icon={appIcons.dotsHorizontal} />,
                  },
                }}
                items={[
                  {
                    type: "menu-item",
                    props: {
                      key: "view",
                      children: "View",
                      onClick: () => {
                        navigate(`/icon/${key}`)
                      },
                    },
                  },
                  {
                    type: "menu-item",
                    props: {
                      key: "copy",
                      children: "Copy",
                      onClick: () => {
                        navigator.clipboard.writeText(
                          `<AppIcon icon={appIcons.${key}} />`,
                        )
                      },
                    },
                  },
                ]}
              />
            ),
          }}
        />
      </Box>
    </Box>
  )
}

export default AppIconsPage
