import { useAppDispatch } from "@app/hooks"
import { Box, Typography } from "@mui/material"
import {
  saveSyllabus,
  editSprintLastElement,
} from "@features/syllabusEditor/syllabusEditorSlice"
import { useCallback, useEffect, useRef, useState } from "react"
import { SprintDto } from "@masterschool/course-builder-api"
import appTheme from "../../../../theme/appTheme"
import SprintTimelineElement from "./sprintTimelineElement"
import {
  PADDING_BETWEEN_SPRINTS,
  calcSnappedSprintHeights,
} from "./unit.sprint.utils"

export const SPRINTS_TIMELINE_HEADER_ID = "sprints-timeline-header"

const UnitSprintsTimeline = (props: {
  sprints: SprintDto[]
  unitId: string
  enableEdit: boolean
}) => {
  const { sprints, unitId, enableEdit } = props
  const dispatch = useAppDispatch()
  const [opacity, setOpacity] = useState(0.01)
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1)
    }, 150)
  }, [])

  const heightsBeforeDragging = useRef<number[]>([])
  const onDragStart = useCallback(() => {
    heightsBeforeDragging.current = calcSnappedSprintHeights(sprints)
  }, [sprints])

  const onDragEnded = useCallback(
    (lastTopicId: string, sprintIndex: number) => {
      dispatch(
        editSprintLastElement({
          unitId,
          sprintIndex,
          newLastTopicId: lastTopicId,
        }),
      )
      dispatch(saveSyllabus())
      setResizingIndex(undefined)
    },
    [dispatch, unitId],
  )

  const [heights, setHeights] = useState<number[]>([])
  const [indexOfSprintBeingResized, setResizingIndex] = useState<
    number | undefined
  >(undefined)
  const isResizingAnySprint = indexOfSprintBeingResized !== undefined
  useEffect(() => {
    if (!isResizingAnySprint) {
      setTimeout(() => {
        setHeights(calcSnappedSprintHeights(sprints))
      }, 150)
    }
  }, [sprints, isResizingAnySprint])

  const onAnchorDragged = useCallback(
    (
      movementY: number,
      sprintIndex: number,
      anchorPosition: DraggedAnchorPosition,
    ) => {
      setResizingIndex(sprintIndex)
      setHeights((heights) => {
        if (movementY === 0) return heights
        return calculateHeightsForAnchorMovement(
          heights,
          sprintIndex,
          anchorPosition,
          movementY,
          heightsBeforeDragging.current,
        )
      })
    },
    [],
  )

  const [bgColors, setBgColors] = useState<Record<string, string>>({})
  useEffect(() => {
    const COLOR_PALETTE = [
      appTheme.palette.eTypes.lilac25,
      appTheme.palette.eTypes.lightGreen,
      appTheme.palette.eTypes.pink,
      appTheme.palette.other.celestial,
      appTheme.palette.other.brightYellow,
      appTheme.palette.other.turquoise,
    ]
    setBgColors((colors) => {
      const newColors = { ...colors }
      sprints.forEach((sprint, index) => {
        if (newColors[sprint.id]) return

        newColors[sprint.id] = COLOR_PALETTE[index % COLOR_PALETTE.length]
        const newAssignedColor = newColors[sprint.id]
        if (
          (index > 0 &&
            newAssignedColor === newColors[sprints[index - 1]?.id]) ||
          (index < sprints.length - 1 &&
            newAssignedColor === newColors[sprints[index + 1]?.id])
        ) {
          newColors[sprint.id] =
            COLOR_PALETTE[(index + 3) % COLOR_PALETTE.length]
        }
      })
      return newColors
    })
  }, [sprints])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      sx={{
        opacity: opacity,
        transition: "opacity 0.4s ease-in-out",
      }}
    >
      <Typography
        variant="body3"
        color="text.disabled"
        id={SPRINTS_TIMELINE_HEADER_ID}
        alignSelf="end"
        paddingBottom="8px"
      >
        Sprint
      </Typography>
      {sprints.map((sprint, index) => {
        const isAnotherSprintResizing =
          isResizingAnySprint && index !== indexOfSprintBeingResized
        return (
          <SprintTimelineElement
            key={sprint.id}
            index={index}
            sprint={sprint}
            isLast={index === sprints.length - 1}
            bgColor={bgColors[sprint.id]}
            height={heights[index]}
            isAnotherSprintResizing={isAnotherSprintResizing}
            onDragStart={onDragStart}
            onAnchorDragged={onAnchorDragged}
            onDragEnded={onDragEnded}
            enableEdit={enableEdit}
          />
        )
      })}
    </Box>
  )
}

export type DraggedAnchorPosition = "top" | "bottom"

function calculateHeightsForAnchorMovement(
  heights: number[],
  indexOfResizedSprint: number,
  anchorPosition: DraggedAnchorPosition,
  yChange: number,
  heightsOnDragStart: number[],
): number[] {
  const anchorIsDraggedDown = yChange > 0
  const anchorIsDraggedUp = yChange < 0
  if (
    (anchorPosition === "top" && anchorIsDraggedUp) ||
    (anchorPosition === "bottom" && anchorIsDraggedDown)
  ) {
    return handleSprintExpanded(
      anchorPosition,
      heights,
      indexOfResizedSprint,
      Math.abs(yChange),
    )
  } else {
    return handleSprintShrinked(
      anchorPosition,
      heights,
      indexOfResizedSprint,
      Math.abs(yChange),
      heightsOnDragStart,
    )
  }
}

export default UnitSprintsTimeline

function handleSprintExpanded(
  from: DraggedAnchorPosition,
  originalHeights: number[],
  indexOfResizedSprint: number,
  absChange: number,
): number[] {
  const updatedHeights = [...originalHeights]

  const resizedSprintOriginalHeight = updatedHeights[indexOfResizedSprint]
  const resizedSprintNewHeight = resizedSprintOriginalHeight + absChange
  updatedHeights[indexOfResizedSprint] = resizedSprintNewHeight

  // When a sprint is expanded, sprints around it are shrinked
  // If 'from' is top, we loop from the sprint above the resized sprint back to the first sprint
  // else we resize from the sprint below the resized sprint to the last sprint
  const loopDirection = from === "top" ? -1 : 1
  function loopCondition(i: number) {
    return from === "top" ? i >= 0 : i < originalHeights.length
  }

  for (
    let i = indexOfResizedSprint + loopDirection;
    loopCondition(i);
    i += loopDirection
  ) {
    const newSprintHeight = originalHeights[i] - absChange
    if (newSprintHeight >= -PADDING_BETWEEN_SPRINTS) {
      updatedHeights[i] = newSprintHeight
      return updatedHeights // since this sprint is not fully shrinked, we don't need to continue to next sprints
    } else {
      updatedHeights[i] = -PADDING_BETWEEN_SPRINTS
    }
  }
  // if we're here all sprints above/below are shrinked to the minimum height
  return originalHeights
}

function handleSprintShrinked(
  from: DraggedAnchorPosition,
  originalHeights: number[],
  indexOfResizedSprint: number,
  absChange: number,
  heightsBeforeDragging: number[],
): number[] {
  const updatedHeights = [...originalHeights]
  const resizedSprintOriginalHeight = updatedHeights[indexOfResizedSprint]
  const resizedSprintNewHeight = resizedSprintOriginalHeight - absChange
  updatedHeights[indexOfResizedSprint] = resizedSprintNewHeight
  if (
    resizedSprintNewHeight <= -PADDING_BETWEEN_SPRINTS &&
    resizedSprintOriginalHeight <= -PADDING_BETWEEN_SPRINTS
  ) {
    return originalHeights
  }

  // When a sprint is shrinked, sprints around it are expanded
  let changeLeft = absChange
  const direction = from === "bottom" ? -1 : 1
  let i = from === "bottom" ? originalHeights.length - 1 : 0
  function loopCondition(i: number) {
    return from === "bottom"
      ? i > indexOfResizedSprint + 1
      : i < indexOfResizedSprint
  }
  for (; loopCondition(i); i += direction) {
    const heightBeforeDragging = heightsBeforeDragging[i]
    const currentHeight = updatedHeights[i]
    if (currentHeight >= heightBeforeDragging) {
      continue
    }
    const diff = heightBeforeDragging - currentHeight
    if (diff > changeLeft) {
      updatedHeights[i] = currentHeight + changeLeft
      return updatedHeights
    } else {
      updatedHeights[i] = heightBeforeDragging
      changeLeft -= diff
    }
  }
  updatedHeights[indexOfResizedSprint - direction] += changeLeft
  return updatedHeights
}
