import { IconButton, SvgIcon, SxProps, Theme } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

const DeleteButton = (props: { onClick: () => void; sx?: SxProps<Theme> }) => {
  return (
    <IconButton
      size="small"
      onClick={props.onClick}
      sx={{
        border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        borderRadius: "2px",
        padding: "4px",
        width: "32px",
        height: "32px",
        ...props.sx,
      }}
    >
      <DeleteIcon />
    </IconButton>
  )
}

export const DeleteIcon = (props: { onClick?: () => void }) => {
  return (
    <SvgIcon
      component={appIcons.trash03}
      inheritViewBox
      onClick={props.onClick}
      sx={{
        stroke: appTheme.palette.icon.default,
        fill: "none",
        width: "16px",
        height: "16px",
        margin: "8px",
        cursor: "pointer",
        ":hover": {
          stroke: appTheme.palette.error.main,
        },
      }}
    />
  )
}

export default DeleteButton
