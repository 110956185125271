import { ProgramClient } from "@clients/programClient"
import FormDateTimePicker from "@cmp/form-components/formDateTimePicker"
import GenericDialog from "@cmp/genericDialog"
import { ProgramProjectDto, UnitDto } from "@masterschool/course-builder-api"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { UrlFormField, isValidURL } from "../../../projects/manageProjectDialog"

export const ManageProgramProjectDialog = (props: {
  programId: string
  unitsInSyllabus: UnitDto[]
  project?: Partial<ProgramProjectDto>
  open: boolean
  onClose: () => void
}) => {
  const { programId, project, unitsInSyllabus, open, onClose } = props

  const [projectName, setProjectName] = useState(project?.name ?? "")
  const [detailsLink, setDetailsLink] = useState(project?.detailsLink ?? "")
  const [guidelinesLink, setGuidelinesLink] = useState(
    project?.guidelinesLink ?? "",
  )
  const [unitId, setUnitId] = useState<string | null>(project?.unitId ?? "")
  const [passingGrade, setPassingGrade] = useState<number | null>(
    project?.passingGrade ?? null,
  )
  const [startDateTime, setStartDateTime] = useState<string | null>(
    project?.startDate ?? null,
  )
  const [dueDateTime, setDueDateTime] = useState<string | null>(
    project?.dueDate ?? null,
  )
  const [finalDueDateTime, setFinalDueDateTime] = useState<string | null>(
    project?.dueDate ?? null,
  )

  const isValidDetailsLink = detailsLink.length > 0 && isValidURL(detailsLink)
  const isValidGuidelinesLink =
    guidelinesLink.length > 0 && isValidURL(guidelinesLink)
  const [isDueDateValid, setIsDueDateValid] = useState<boolean>(true)
  const [isFinalDueDateValid, setIsFinalDueDateValid] = useState<boolean>(true)

  const canSubmit =
    projectName &&
    isValidDetailsLink &&
    isValidGuidelinesLink &&
    unitId &&
    passingGrade !== null &&
    startDateTime !== null &&
    dueDateTime !== null &&
    isDueDateValid &&
    finalDueDateTime !== null &&
    isFinalDueDateValid
  const [isLoading, setIsLoading] = useState(false)

  const onSubmitClicked = async () => {
    setIsLoading(true)
    if (!canSubmit) return
    const fieldsFromDialog = {
      programId,
      name: projectName,
      detailsLink,
      guidelinesLink,
      unitId: unitId,
      passingGrade: passingGrade,
      startDate: startDateTime,
      dueDate: dueDateTime,
      finalDueDate: dueDateTime,
    }
    if (project && project.id) {
      await ProgramClient.updateProject({ id: project.id, ...fieldsFromDialog })
    } else {
      await ProgramClient.addProjectToProgram(fieldsFromDialog)
    }
    setIsLoading(false)
    onClose()
  }

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Add new project"
      subtitle="General details"
      content={
        <Stack gap={2}>
          <FormControl>
            <InputLabel
              sx={{
                fontSize: "17px",
              }}
            >
              Select Unit
            </InputLabel>
            <Select
              value={unitId ?? ""}
              label="Select Unit"
              onChange={(e) => setUnitId(e.target.value)}
            >
              {unitsInSyllabus.map((unit) => (
                <MenuItem key={unit.id} value={unit.id}>
                  {unit.name} ({unit.shortTitle}
                  {unit.unitNumber})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Project Title"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
          <Box display="flex" width="100%" gap="16px">
            <UrlFormField
              label="Project Details"
              value={detailsLink}
              onChange={(e) => setDetailsLink(e.target.value)}
            />
            <UrlFormField
              label="Project Guidelines"
              value={guidelinesLink}
              onChange={(e) => setGuidelinesLink(e.target.value)}
            />
          </Box>
          <Typography variant="body1_sb">Submission details</Typography>
          <GradeTextField value={passingGrade} onChange={setPassingGrade} />
          <Box display="flex" width="100%" gap="16px">
            <FormDateTimePicker
              label={"Start date"}
              value={startDateTime}
              onChange={setStartDateTime}
            />
            <FormDateTimePicker
              label={"Due date"}
              value={dueDateTime}
              onChange={setDueDateTime}
              minDateTime={startDateTime}
              onError={(error: boolean) => setIsDueDateValid(!error)}
              errorMessage="Due date must be after start date"
            />
            <FormDateTimePicker
              label={"Final due date"}
              value={finalDueDateTime}
              onChange={setFinalDueDateTime}
              minDateTime={dueDateTime}
              onError={(error: boolean) => setIsFinalDueDateValid(!error)}
              errorMessage="Final due date must be after due date"
            />
          </Box>
        </Stack>
      }
      buttons={[
        {
          type: "primary",
          text: project?.id ? "Save changes" : "Add project",
          onClick: onSubmitClicked,
          disabled: !canSubmit,
          loading: isLoading,
        },
      ]}
    />
  )
}

const GradeTextField = (props: {
  value: number | null
  onChange: (value: number | null) => void
}) => {
  const { value, onChange } = props

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      onChange(null)
      return
    }
    const value = parseInt(e.target.value)
    if (isNaN(value)) return
    onChange(value)
  }
  return (
    <TextField
      label="Passing Grade"
      type="number"
      value={value ?? ""}
      onChange={onFieldChange}
      error={value !== null && (isNaN(value) || value < 0)}
    />
  )
}
