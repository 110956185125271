import { useAppDispatch } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  discardChangesApproved,
  discardChangesConfirmationClosed,
} from "@features/courseEditor/courseEditorSlice"

function DiscardChangesConfirmationPopup() {
  const dispatch = useAppDispatch()

  const title = "Discard current changes?"
  const subtitle =
    "This will delete any changes you’ve made during this session."

  return (
    <GenericDialog
      open
      size="xs"
      title={title}
      subtitle={subtitle}
      onClose={() => dispatch(discardChangesConfirmationClosed())}
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: () => dispatch(discardChangesConfirmationClosed()),
        },
        {
          text: "Discard changes",
          type: "primary",

          onClick: () => {
            dispatch(discardChangesApproved())
          },
        },
      ]}
    />
  )
}

export default DiscardChangesConfirmationPopup
