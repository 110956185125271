import { User } from "@features/login/loginSlice"

export class LoginClient {
  static getPermanentToken(
    temporaryToken: string,
  ): Promise<{ accessToken: string }> {
    const BASE_URL = import.meta.env.VITE_LOGIN_BE_URL
    const requestInit = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${temporaryToken}`,
      },
    }
    return fetch(`${BASE_URL}/api/user/token`, requestInit).then((res) =>
      res.json(),
    )
  }

  static getUser(accessToken: string): Promise<User> {
    const BASE_URL = import.meta.env.VITE_LOGIN_BE_URL
    const requestInit = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    return fetch(`${BASE_URL}/api/user`, requestInit).then((res) => res.json())
  }
}
