import GenericDialog from "@cmp/genericDialog"
import { VersionItem, VersionsMenu } from "@cmp/version-management/versionsMenu"
import { Stack, Divider, CircularProgress } from "@mui/material"
import { useAppSelector } from "@app/hooks"
import { SyllabusView } from "../../view/syllabusView"
import {
  Syllabus,
  selectLastPublishedSyllabus,
  selectSyllabusVersionsList,
} from "@features/syllabus/syllabusSelectors"
import { useState } from "react"
import { RevertButton } from "@cmp/version-management/revertButton"

import { useRevertSyllabusToVersion } from "@features/syllabus/revertVersion"

export const SyllabusVersionHistoryPopup = (props: {
  syllabusId: string
  onClose: () => void
}) => {
  const { syllabusId, onClose } = props
  const versionsHistory = useAppSelector(selectSyllabusVersionsList(syllabusId))
  const [selectedVersionId, setSelectedVersionId] = useState<
    number | undefined
  >(versionsHistory[0].version)
  const lastPublishedVersion = useAppSelector(
    selectLastPublishedSyllabus(syllabusId),
  )
  const selectedVersionContent = versionsHistory.find(
    (version) => version.version === selectedVersionId,
  )
  const lastVersionId = versionsHistory[0]?.version || undefined

  const resetSelectedVersion = () =>
    setSelectedVersionId(versionsHistory[0].version)

  return (
    <GenericDialog
      open
      onClose={onClose}
      title="Version history"
      size="xl"
      fullHeight
      disableContentPadding
      content={
        <Stack overflow="auto" height={1}>
          <Divider />
          <Stack direction="row" flex={1} overflow="hidden">
            <VersionsMenu
              versionsHistory={versionsHistory.map((syllabus) =>
                toVersionItem(syllabus),
              )}
              onVersionSelected={(versionNumber) =>
                setSelectedVersionId(versionNumber)
              }
              selectedVersion={selectedVersionId}
              lastPublishedVersion={lastPublishedVersion?.version}
              width="230px"
            />
            {selectedVersionContent ? (
              <SelectedVersionPanel
                syllabus={selectedVersionContent}
                isLastVersion={
                  selectedVersionContent?.version === lastVersionId
                }
                resetSelection={resetSelectedVersion}
              />
            ) : (
              <CircularProgress />
            )}
          </Stack>
        </Stack>
      }
    />
  )
}

const SelectedVersionPanel = (props: {
  syllabus: Syllabus
  isLastVersion: boolean
  resetSelection: () => void
}) => {
  const { syllabus, isLastVersion, resetSelection } = props
  const showRevertButton = !isLastVersion
  const revertParams = useRevertSyllabusToVersion(syllabus?.id)

  if (revertParams === "error") return <CircularProgress />
  const { revert, shouldVerifyBeforeRevert } = revertParams
  const isSyllabusLoaded = !!syllabus

  return (
    <Stack alignItems="center" flex={1}>
      <SyllabusView
        syllabus={syllabus}
        viewMode="popup"
        hideStaleCourseSnackbar={true}
        embeddedHeader={
          <Stack
            sx={{
              alignItems: "flex-end",
            }}
          >
            {showRevertButton && isSyllabusLoaded && (
              <RevertButton
                doRevert={async () => {
                  await revert(syllabus)
                  resetSelection()
                }}
                shouldVerify={shouldVerifyBeforeRevert}
              />
            )}
          </Stack>
        }
      />
    </Stack>
  )
}

const toVersionItem = (syllabus: Syllabus): VersionItem => ({
  id: syllabus.id,
  version: syllabus.version,
  createdAtTimestamp: new Date(syllabus.createdAt).getTime(),
})
