import { RootState } from "@app/store"
import { Role } from "@features/login/loginSlice"
import ProgramsMenu from "../../programs/menu/programsMenu"
import { SyllabusMainPage } from "../../main/syllabuses-main-page/syllabusesMainPage"
import { CoursesMainPage } from "../../main/coursesMainPage"
import { SalesManagement } from "../../sales-management/salesManagement"
import { SyllabusViewContainer } from "../../view/syllabusViewContainer"
import { CourseViewContainer } from "../../editor/courseViewContainer"
import { SyllabusEditorContainer } from "../../editor/syllabus/syllabusEditor"
import { CourseEditorContainer } from "../../editor/courseEditor"
import { ProjectsTabView } from "../../projects/projectsTabView"
import ProgramViewContainer from "../../programs/program/components/programContainer"
import { DeeplinksView } from "../../main/deeplinks/deeplinksView"
import CalendarContainer from "../../main/calendar/CalendarContainer"
import AppFeatureFlags from "@features/app-feature-flags/appFeatureFlags"
import AccountManagement from "../../account-management/accountManagement"
import { ShiftManagement } from "../../sales-management/shiftsManagement"
import { createSelector } from "@reduxjs/toolkit"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { selectShowCalendarFeature } from "@features/account-management/accountManagementSelectors"

export const enum TabIdentifier {
  Syllabuses = "Syllabuses",
  Courses = "Courses",
  Programs = "Programs",
  Projects = "Projects",
  AccountManagement = "AccountManagement",
  Deeplinks = "Deeplinks",
  AppFeatureFlags = "AppFeatureFlags",
  Calendar = "Calendar",
  SalesManagement = "SalesManagement",
}

export const tabIdentifierToTitle: Record<TabIdentifier, string> = {
  Programs: "Programs",
  Syllabuses: "Syllabuses",
  Courses: "Courses",
  Projects: "Projects",
  AccountManagement: "Account Management",
  Deeplinks: "Deep Links",
  AppFeatureFlags: "App Feature Flags",
  Calendar: "Calendar",
  SalesManagement: "Sales Management",
}

export const tabIdentifierToRouteDescriptor: Record<
  TabIdentifier,
  { path: string; hideMainSideBar: boolean; element: JSX.Element }[]
> = {
  Programs: [
    { path: "programs", hideMainSideBar: false, element: <ProgramsMenu /> },
    {
      path: "program/:programId",
      hideMainSideBar: true,
      element: <ProgramViewContainer />,
    },
    {
      path: "program/:programId/:classId",
      hideMainSideBar: true,
      element: <ProgramViewContainer />,
    },
  ],
  Syllabuses: [
    {
      path: "syllabuses",
      hideMainSideBar: false,
      element: <SyllabusMainPage />,
    },
    {
      path: "syllabus/:syllabusId",
      hideMainSideBar: true,
      element: <SyllabusViewContainer />,
    },
    {
      path: "syllabus-edit/:syllabusId",
      hideMainSideBar: true,
      element: <SyllabusEditorContainer />,
    },
    {
      path: "syllabus-edit/:syllabusId/:unitId/:courseId",
      hideMainSideBar: true,
      element: <SyllabusEditorContainer />,
    },
  ],
  Courses: [
    { path: "courses", hideMainSideBar: false, element: <CoursesMainPage /> },
    {
      path: "course/:courseId",
      hideMainSideBar: true,
      element: <CourseViewContainer />,
    },

    {
      path: "course-edit/:courseId",
      hideMainSideBar: true,
      element: <CourseEditorContainer />,
    },
  ],
  Projects: [
    { path: "projects", hideMainSideBar: false, element: <ProjectsTabView /> },
  ],
  AccountManagement: [
    {
      path: "account-management",
      hideMainSideBar: false,
      element: <AccountManagement />,
    },
  ],
  Deeplinks: [
    { path: "deep-links", hideMainSideBar: false, element: <DeeplinksView /> },
  ],
  AppFeatureFlags: [
    {
      path: "app-feature-flags",
      hideMainSideBar: false,
      element: <AppFeatureFlags />,
    },
  ],
  Calendar: [
    {
      path: "calendar",
      hideMainSideBar: false,
      element: <CalendarContainer />,
    },
  ],
  SalesManagement: [
    {
      path: "sales-management",
      hideMainSideBar: false,
      element: <SalesManagement />,
    },
    {
      path: "sales-management/shifts/:consultantId",
      hideMainSideBar: false,
      element: <ShiftManagement />,
    },
  ],
}

const rolesToTabs: Record<Role, TabIdentifier[]> = {
  program_manager: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Programs,
    TabIdentifier.Deeplinks,
  ],
  ms_employee: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
  ],
  instructor: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
  ],
  account_manager: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.AccountManagement,
  ],
  lms_admin: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Programs,
    TabIdentifier.Deeplinks,
    TabIdentifier.AccountManagement,
    TabIdentifier.AppFeatureFlags,
    TabIdentifier.SalesManagement,
  ],
  school_master_admin: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
  ],
  career_success_advisor: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Programs,
  ],
  mentor: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
  ],
  professional_mentor: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
  ],
  sales_team_lead: [TabIdentifier.SalesManagement],
}

const TABS_ORDER = [
  TabIdentifier.Programs,
  TabIdentifier.Syllabuses,
  TabIdentifier.Courses,
  TabIdentifier.Projects,
  TabIdentifier.Calendar,
  TabIdentifier.AccountManagement,
  TabIdentifier.Deeplinks,
  TabIdentifier.AppFeatureFlags,
  TabIdentifier.SalesManagement,
]

const selectRoles = (state: RootState) => {
  const user = selectLoggedInUser(state)
  if (!user) {
    return "unknown"
  }
  return user.roles
}

export const tabsSelector = createSelector(
  [selectRoles, selectShowCalendarFeature],
  (roles, showCalendarTab) => {
    if (roles === "unknown") {
      return "unknown"
    }

    const tabs = roles.flatMap((role) => rolesToTabs[role])
    return TABS_ORDER.filter(
      (tab) =>
        tabs.includes(tab) ||
        (tab === TabIdentifier.Calendar && showCalendarTab),
    )
  },
)

export const routesSelector = createSelector([tabsSelector], (orderedTabs) => {
  if (orderedTabs === "unknown") {
    return "unknown"
  }

  return orderedTabs.flatMap((tab) => tabIdentifierToRouteDescriptor[tab])
})
