import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles"

const MuiTabOverrides: {
  defaultProps?: ComponentsProps["MuiTab"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiTab"]
  variants?: ComponentsVariants["MuiTab"]
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      lineHeight: "20px",
    }),
  },
}

export { MuiTabOverrides }
