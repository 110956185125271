import { useAppDispatch } from "@app/hooks"
import OverflowTooltip from "@cmp/overflowTooltip"
import { pickPublishedSyllabusPopupOpened } from "@features/program/programSlice"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Link as MuiLink, Stack, Typography } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import { useGetAllProgramSyllabusesData } from "../../syllabus/useGetAllProgramSyllabusesData"
import EmptyWidgetCard from "./emptyWidgetCard"
import WidgetCard from "./widgetCard"

function SyllabusWidget(props: { program: ProgramDto }) {
  const { programId, classId } = useParams()
  const dispatch = useAppDispatch()
  const programSyllabuses = useGetAllProgramSyllabusesData({
    programId: programId ?? "",
    classId,
  })
  const showSelectSyllabusPopup = () => {
    dispatch(pickPublishedSyllabusPopupOpened({ programId: programId ?? "" }))
  }

  const showEmptyWidget = programSyllabuses.length === 0
  if (showEmptyWidget) {
    return (
      <>
        <EmptyWidgetCard
          message="There are no syllabus to display."
          actionProps={{
            children: "Add syllabus",
            onClick: showSelectSyllabusPopup,
          }}
        />
      </>
    )
  }

  const title = `Syllabus (${programSyllabuses.length})`
  const content = programSyllabuses
    .map((s) => {
      const name = s.syllabus.name ?? ""
      return s.isDefault ? `${name} (Default)` : name
    })
    .join(", ")

  const linkToSyllabusTab = `/program/${programId}?tab=syllabus`

  return (
    <WidgetCard title={title} navigateTo={linkToSyllabusTab}>
      <Stack width={1} overflow="hidden">
        <MuiLink component={Link} to={linkToSyllabusTab} underline="hover">
          <OverflowTooltip>
            <Typography variant="body2">{content}</Typography>
          </OverflowTooltip>
        </MuiLink>
      </Stack>
    </WidgetCard>
  )
}

export default SyllabusWidget
