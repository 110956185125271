import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import {
  fetchProgramSlackChannels,
  removeClassesFromChannel,
  removeClassesFromChannelPopupClosed,
  removeClassesFromChannelUpdated,
} from "@features/slack/slackSlice"
import { selectSlackChannel } from "@features/slack/slackSliceSelector"
import { ClassesToCheck } from "./dialogSharedComponents"

export const RemoveClassesFromChannelDialog = () => {
  const model = useAppSelector(
    (state) => state.slack.popups.removeClassesFromChannelPopup,
  )
  const dispatch = useAppDispatch()
  const programClasses = useAppSelector(
    selectClassesForProgram(model?.programId),
  )
  const slackChannel = useAppSelector(
    selectSlackChannel(model?.programId, model?.channelId),
  )

  const programClassesInChannel = programClasses.filter(
    (programClass) =>
      slackChannel?.classes.find((c) => c.clientId === programClass.id) !==
      undefined,
  )

  const isDisabled = !model || model.checkedClassesIds.length === 0

  const onSubmit = () => {
    if (!model || model.checkedClassesIds.length === 0) {
      return
    }
    dispatch(removeClassesFromChannel()).then(() =>
      dispatch(fetchProgramSlackChannels(model.programId)),
    )
  }

  return (
    <GenericDialog
      open={model !== undefined}
      onClose={() => dispatch(removeClassesFromChannelPopupClosed())}
      size="sm"
      title={`Remove classes from ${model?.channelName}`}
      content={
        <ClassesToCheck
          classesToCheck={programClassesInChannel}
          checkedClassesId={model?.checkedClassesIds ?? []}
          handleCheck={(classId: string) =>
            dispatch(removeClassesFromChannelUpdated({ classId }))
          }
          title={undefined}
        />
      }
      buttons={[
        {
          type: "primary",
          text: !isDisabled
            ? model.checkedClassesIds.length === 1
              ? "Unassign 1 class"
              : `Unassign ${model.checkedClassesIds.length} classes`
            : "Unassign classes",
          onClick: onSubmit,
          disabled: isDisabled,
          loading: model?.loadingState === "loading",
        },
      ]}
    />
  )
}
