import { useAppDispatch, useAppSelector } from "@app/hooks"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { ProgramClassDto, ProgramDto } from "@masterschool/course-builder-api"
import { Box, Button, Divider, Link, SvgIcon, Typography } from "@mui/material"
import EmptyWidgetCard from "../emptyWidgetCard"
import WidgetCard from "../widgetCard"
import { openManageHomepageUrlPopup } from "@features/program/programSlice"
import { ManageProgramHomepageDialog } from "./manageProgramHomepageDialog"
import appIcons from "@utils/appIcons"

function HomepageUrlWidget({ program }: { program: ProgramDto }) {
  const programId = program.id
  const classes = useAppSelector(selectClassesForProgram(programId))

  const homepageToClasses = classes.reduce((acc, pclass) => {
    if (pclass.programHomepageUrl) {
      if (acc.get(pclass.programHomepageUrl)) {
        acc.get(pclass.programHomepageUrl)?.push(pclass)
      } else {
        acc.set(pclass.programHomepageUrl, [pclass])
      }
    }
    return acc
  }, new Map<string, ProgramClassDto[]>())

  const dispatch = useAppDispatch()

  const onAddProgramPage = () => {
    dispatch(
      openManageHomepageUrlPopup({ homepageUrl: undefined, classIds: [] }),
    )
  }

  const homepageURLs = Array.from(homepageToClasses.keys())
  if (homepageURLs.length === 0) {
    return (
      <>
        <ManageProgramHomepageDialog programId={programId} />
        <EmptyWidgetCard
          message="Program pages will show here"
          actionProps={{
            children: "Add program page",
            onClick: onAddProgramPage,
          }}
        />
      </>
    )
  }

  return (
    <WidgetCard
      title="Program pages"
      onEdit={onAddProgramPage}
      editLabel="Add"
      editIcon={appIcons.plus}
    >
      <ManageProgramHomepageDialog programId={programId} />
      <Box display="flex" flexDirection="column" gap="16px" minWidth="300px">
        {homepageURLs.map((homepageUrl, index) => {
          const classes = homepageToClasses.get(homepageUrl) || []
          const onEditClicked = () => {
            dispatch(
              openManageHomepageUrlPopup({
                homepageUrl,
                classIds: classes.map((pclass) => pclass.id),
              }),
            )
          }
          const isLast = index === homepageURLs.length - 1
          return (
            <Box
              display="flex"
              gap="8px"
              key={homepageUrl}
              flexDirection="column"
              overflow="hidden"
            >
              <Box
                display="flex"
                gap="8px"
                width="100%"
                justifyContent="space-between"
              >
                <Link
                  key={homepageUrl}
                  href={homepageUrl}
                  target="_blank"
                  variant="body2"
                  sx={{
                    wordBreak: "break-all",
                  }}
                >
                  {homepageUrl}
                </Link>
                <Button size="small" onClick={onEditClicked}>
                  <SvgIcon
                    component={appIcons.edit03}
                    inheritViewBox
                    sx={{
                      width: 20,
                      height: 20,
                      fill: "none",
                      color: "icon.black",
                      stroke: "currentColor",
                    }}
                  />
                </Button>
              </Box>
              <Link href={homepageUrl} target="_blank" />
              <Box display="flex" flexDirection="column">
                {classes.map((pclass) => (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    key={pclass.id}
                  >
                    {pclass.name}
                  </Typography>
                ))}
              </Box>
              {!isLast && <Divider sx={{ marginTop: "8px" }} />}
            </Box>
          )
        })}
      </Box>
    </WidgetCard>
  )
}

export default HomepageUrlWidget
