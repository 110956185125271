import React, { ChangeEvent, FormEvent, useState } from "react"
import { TextField, Button, Box, Typography } from "@mui/material"
import { CreateConsultantDto } from "@masterschool/course-builder-api"

const ContactForm = (props: {
  onCreate: (createConsultantModel: CreateConsultantDto) => void
  onClose: () => void
}) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    hubspotContactId: "",
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    props.onCreate({
      name: formData.fullName,
      email: formData.email,
      hubspotContactId: formData.hubspotContactId,
      acceptedQtfs: [1, 2, 3, 4, 5],
    })
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      display="flex"
      flexDirection="column"
      gap="24px"
      width="400px"
    >
      <TextField
        fullWidth
        id="full-name"
        name="fullName"
        label="Full Name"
        variant="outlined"
        value={formData.fullName}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        variant="outlined"
        type="email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        id="hubspot-contact-id"
        name="hubspotContactId"
        label="HubSpot Record ID"
        variant="outlined"
        value={formData.hubspotContactId}
        onChange={handleChange}
      />
      <Box display="flex" width="100%" justifyContent="flex-end" gap="16px">
        <Button type="reset" color="primary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          <Typography variant="body1_sb" color="white">
            Add consultant
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default ContactForm
