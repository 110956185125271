import { useAppDispatch, useAppSelector } from "@app/hooks"
import { Box } from "@mui/material"
import { useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { fetchCourse, unmounted } from "@features/courseView/courseViewSlice"
import { selectHasChanges } from "@features/coursesMenu/coursesSelectors"
import { SnackbarDuration, showSnackbarItem } from "@features/ui/uiSlice"
import CoursePreview from "./coursePreview"
import { CourseHeader } from "./courseViewHeader"
import appTheme from "../theme/appTheme"

export function CourseViewContainer() {
  const course = useAppSelector((state) => state.courseView.course)
  const dispatch = useAppDispatch()
  const { courseId } = useParams()

  const search = useLocation().search
  const version = new URLSearchParams(search).get("version")
  const hasChanges = useAppSelector(selectHasChanges(course?.id))

  useEffect(() => {
    if (!courseId) return
    dispatch(
      fetchCourse({ courseId: courseId, version: parseInt(version ?? "1") }),
    )
    return () => {
      dispatch(unmounted())
    }
  }, [dispatch, courseId, version])

  useEffect(() => {
    if (!course) {
      return
    }
    if (hasChanges) {
      dispatch(
        showSnackbarItem({
          message: "This course has unpublished changes",
          type: "success",
          duration: SnackbarDuration.short,
          anchorOrigin: "bottom",
        }),
      )
    }
  }, [dispatch, course, hasChanges])

  if (!course) return <div>Loading...</div>

  if (typeof course === "string") return <div>{course}</div>

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        bgcolor: appTheme.palette.eTypes.sand15,
      }}
    >
      <CourseHeader />
      <Box
        sx={{
          display: "flex",
          width: "40%",
          maxWidth: "600px",
          height: "100%",
          justifyContent: "center",
          overflow: "hidden",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <CoursePreview course={course} />
      </Box>
    </Box>
  )
}
