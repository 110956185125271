import { useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { Typography } from "@mui/material"
import useComputeStaleCourseContainers from "./useComputeStaleCourseContainers"

function SingleStaleCoursePopup(props: {
  staleCourse: {
    syllabusId: string
    unitId: string
    courseId: string
  }
  onClose: () => void
  onView: () => void
  onUpdate: () => void
}) {
  const { staleCourse, onClose, onView, onUpdate } = props

  const model = useSingleStaleCoursePopupViewModel(staleCourse)

  return (
    <GenericDialog
      open
      title={model?.title}
      subtitle="Course changes may impact the unit structure."
      onClose={onClose}
      size="sm"
      content={
        <Typography
          variant="body1"
          sx={{
            textDecoration: "underline",
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={onView}
        >
          View changes
        </Typography>
      }
      buttons={[
        {
          type: "secondary",
          text: "Keep current version",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Update course",
          onClick: onUpdate,
          loading: model?.isLoading,
        },
      ]}
    />
  )
}

function useSingleStaleCoursePopupViewModel(staleCourse: {
  syllabusId: string
  unitId: string
  courseId: string
}) {
  const staleCourseModel = useComputeStaleCourseContainers(staleCourse)
  const isLoading = useAppSelector(
    (state) =>
      state.syllabusEditor.reviewStaleCoursesPopup?.statuses[
        staleCourse.courseId
      ] === "pending",
  )

  if (!staleCourseModel) {
    return undefined
  }

  const { course, unit } = staleCourseModel

  return {
    title: `Update to latest version of “${course.title}” in unit ${unit.shortTitle}${unit.unitNumber}?`,
    isLoading,
  }
}

export default SingleStaleCoursePopup
