import { Box } from "@mui/material"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import TitleTextField from "../../components/titleTextField"
import UrlTextField from "../../components/urlTextField"
import { useEditNotionLesson } from "../custom-hooks/useEditElementItem"
import { useSelectedNotionLesson } from "../custom-hooks/useSelectedElementItem"
import EditLessonType from "./EditLessonType"
import { Topic } from "@masterschool/course-builder-api"

function NotionLessonEditor(props: { topic: Topic }) {
  const { topic } = props
  const lesson = useSelectedNotionLesson()
  const editLesson = useEditNotionLesson()

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "16px",
          }}
        >
          <TitleTextField
            elementId={lesson.id}
            value={lesson.title}
            debounce
            onChange={(e) => {
              editLesson(lesson, "title", e.target.value, topic)
            }}
          />
          <EditLessonType topic={topic} />
        </Box>
        <TimeEstimationTextField
          elementId={lesson.id}
          value={lesson.estimatedDuration}
          debounce
          onChange={(e) => {
            editLesson(
              lesson,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
        />
        <UrlTextField
          elementId={lesson.id}
          value={lesson.url}
          debounce
          onChange={(e) => {
            const newValue = e.target.value
            editLesson(lesson, "url", newValue, topic)
          }}
        />
      </Box>
    </Box>
  )
}

export default NotionLessonEditor
