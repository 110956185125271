import GenericDialog from "@cmp/genericDialog"
import appTheme from "../../../../theme/appTheme"
import StaleCourseCard from "./staleCourseCard"
import StaleCourseModel from "./staleCourseModel"

function MultiStaleCoursesPopup(props: {
  staleCourses: StaleCourseModel[]
  onClose: () => void
  onView: (staleCourse: StaleCourseModel) => void
  onUpdate: (staleCourse: StaleCourseModel) => void
  onUpdateAll: () => void
}) {
  const { staleCourses, onClose, onView, onUpdate, onUpdateAll } = props

  return (
    <GenericDialog
      open
      title="Update to latest course versions?"
      subtitle="Course changes may impact the unit structure."
      onClose={onClose}
      size="sm"
      buttons={[
        {
          type: "secondary",
          text: "Keep current version",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Update all",
          onClick: onUpdateAll,
          loading: false,
        },
      ]}
      disableContentGutters
      content={
        <>
          {staleCourses.flatMap((staleCourse, index) => (
            <StaleCourseCard
              key={staleCourse.courseId}
              staleCourse={staleCourse}
              onViewClicked={() => onView(staleCourse)}
              onUpdateClicked={() => onUpdate(staleCourse)}
              sx={{
                borderBottom:
                  index === staleCourses.length - 1
                    ? `1px solid ${appTheme.palette.other.divider}`
                    : undefined,
              }}
            />
          ))}
        </>
      }
    />
  )
}

export default MultiStaleCoursesPopup
