import { useAppDispatch, useAppSelector } from "@app/hooks"
import OptionsButton from "@cmp/buttons/optionsButton"
import GenericDialog from "@cmp/genericDialog"
import { createCourse } from "@features/coursesMenu/coursesMenuSlice"
import { selectUnitCourse } from "@features/syllabus/syllabusSelectors"
import { fetchUnitCourses } from "@features/syllabus/syllabusesMenuSlice"
import {
  editCourseClicked,
  editUnitCoursesClicked,
  removeCourseFromUnit,
} from "@features/syllabusEditor/syllabusEditorSlice"
import {
  Topic,
  UnitCourseDescriptor,
  UnitDto,
} from "@masterschool/course-builder-api"
import { Box, Button, Divider, Stack, SvgIcon, Typography } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import appIcons from "@utils/appIcons"
import { topicDuration } from "@utils/syllabus+duration"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import appTheme from "../../../../theme/appTheme"
import CourseSyllabusElementView from "../../../topic/courseSyllabusElementView"
import LinkCourseButton, { AddUnitItemType } from "./linkCourseButton"
import UnitSprintsTimeline from "./unitSprintsTimeline"
import { InstructorDetails } from "./InstructorDetails"
import { PopupType, popupItemClicked } from "@features/ui/uiSlice"

export const COURSE_VIEW_CLASS_NAME = "course-view"
export const TOPIC_DESCRIPTOR_CLASS_NAME = "topic-descriptor"

type ModeProps = { mode: "view"; domain: string | undefined } | { mode: "edit" }

function UnitContentEditor(props: { unit: UnitDto; modeProps: ModeProps }) {
  const { unit, modeProps } = props
  const isEditEnabled = modeProps.mode === "edit"
  const { courseDescriptors } = unit
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUnitCourses(props.unit))
  }, [props.unit, dispatch])

  if (courseDescriptors.length === 0) {
    return isEditEnabled ? <AddFirstCourseToUnit unit={unit} /> : <EmptyUnit />
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "24px",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          width: "100%",
        }}
      >
        <UnitSprintsTimeline
          sprints={unit.sprints}
          unitId={unit.id}
          enableEdit={isEditEnabled}
        />
        <Stack
          sx={{
            Display: "flex",
            flexDirection: "column",
            gap: "40px",
            width: isEditEnabled ? "480px" : "400px",
          }}
        >
          {courseDescriptors.map((courseDescriptor, index) => {
            return (
              <CourseView
                key={courseDescriptor.courseId}
                unitId={unit.id}
                unitCourseDescriptor={courseDescriptor}
                index={index}
                total={courseDescriptors.length}
                enableEdit={isEditEnabled}
              />
            )
          })}
        </Stack>
      </Box>
      {isEditEnabled && <AddOrManageCoursesButton unitId={props.unit.id} />}
      {!isEditEnabled && modeProps.domain !== undefined && (
        <InstructorDetails domain={modeProps.domain} />
      )}
    </Box>
  )
}

function CourseView(props: {
  unitId: string
  unitCourseDescriptor: UnitCourseDescriptor
  index: number
  total: number
  enableEdit: boolean
}) {
  const { unitId, unitCourseDescriptor, enableEdit } = props
  const { index, total } = props
  const courseDto = useAppSelector(selectUnitCourse(unitCourseDescriptor))

  if (!courseDto) {
    return (
      <CoursePlaceholderSkeleton
        unitId={unitId}
        courseId={unitCourseDescriptor.courseId}
      />
    )
  }
  const Title = () => {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="caption" color="text.secondary">
          {index + 1}/{total}
        </Typography>
        <Typography variant="h4">{courseDto.title ?? ""}</Typography>
      </Box>
    )
  }

  const Header = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        gap={enableEdit ? "8px" : 0}
        alignItems="end"
      >
        <Title />
        <CourseActionsMenu
          courseId={unitCourseDescriptor.courseId}
          courseVersion={unitCourseDescriptor.version}
          unitId={unitId}
          enableEdit={enableEdit}
        />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      className={COURSE_VIEW_CLASS_NAME}
    >
      <Header />
      {courseDto.syllabus.topics.map((topic, index) => (
        <TopicDescriptor topic={topic} index={index} key={topic.id} />
      ))}
    </Box>
  )
}

function CourseActionsMenu(props: {
  unitId: string
  courseId: string
  courseVersion: number
  enableEdit: boolean
}) {
  const dispatch = useAppDispatch()

  const onReplaceClicked = () => {
    dispatch(editUnitCoursesClicked(props))
  }
  const onEditClicked = () => {
    dispatch(editCourseClicked(props))
  }
  const onRemoveClicked = () => {
    dispatch(removeCourseFromUnit(props))
  }

  const onGoToCourseClicked = () => {
    window.open(
      `../../course/${props.courseId}?version=${props.courseVersion}`,
      "_blank",
    )
  }
  const onCourseVersionHistoryClicked = () => {
    dispatch(
      popupItemClicked({
        type: PopupType.CourseVersionsHistory,
        courseId: props.courseId,
        context: "syllabusEditor",
      }),
    )
  }
  const items = [
    { text: "Replace course", onSelect: onReplaceClicked },
    { text: "Remove course", onSelect: onRemoveClicked },
    { text: "Version history", onSelect: onCourseVersionHistoryClicked },
  ]
  return (
    <Box display="flex" gap="8px" width="100%">
      {props.enableEdit && (
        <Button variant="outlined" size="small" onClick={onEditClicked}>
          <Typography variant="body2_sb">Edit course</Typography>
        </Button>
      )}
      {props.enableEdit ? (
        <Button variant="outlined" size="small" onClick={onGoToCourseClicked}>
          View Course
        </Button>
      ) : (
        <Typography
          onClick={onGoToCourseClicked}
          variant="body3"
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          View course
        </Typography>
      )}
      {props.enableEdit && (
        <OptionsButton
          button={{
            sx: {
              padding: "3px",
              border: `1px solid ${appTheme.palette.other.outlineBorder}`,
            },
            leftIcon: appIcons.dots,
          }}
          items={items}
        />
      )}
    </Box>
  )
}

function TopicDescriptor(props: { topic: Topic; index: number }) {
  const { topic, index } = props
  const [showPreviewButton, setShowPreviewButton] = useState(false)
  const [showPreview, setShowPreview] = useState(false)

  const NumberCircle = () => {
    return (
      <Box
        display="flex"
        sx={{
          width: "20px",
          height: "20px",
          bgcolor: appTheme.palette.eTypes.sand50,
          borderRadius: "50%",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography color="text.secondary" variant="body3">
          {index + 1}
        </Typography>
      </Box>
    )
  }

  const TitleAndDuration = () => {
    return (
      <Box display="flex" flexDirection="column" gap={0}>
        <Typography variant="body2" color="text.primary">
          {topic.title}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {(topicDuration(topic) / 60).toFixed(1)} hrs
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        transition: "all .35s ease-in-out",
        padding: "24px",
        width: "100%",
        minWidth: "320px",
        backgroundColor: "white",
        borderRadius: "4px",
      }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={TOPIC_DESCRIPTOR_CLASS_NAME}
      id={topic.id}
      onMouseEnter={() => setShowPreviewButton(true)}
      onMouseLeave={() => setShowPreviewButton(false)}
    >
      <Box display="flex" gap="16px" alignItems="center">
        <NumberCircle />
        <TitleAndDuration />
      </Box>
      <Button
        variant="text"
        size="tiny"
        sx={{
          paddingX: "12px",
          opacity: showPreviewButton ? 1 : 0,
          transition: "all .15s ease",
        }}
        startIcon={
          <SvgIcon
            component={appIcons.eye}
            inheritViewBox
            sx={{
              width: "16px",
              height: "16px",
              fill: "none",
              stroke: appTheme.palette.icon.black,
            }}
          />
        }
        onClick={() => {
          setShowPreview(true)
          setShowPreviewButton(false)
        }}
      >
        Show items
      </Button>
      <TopicItemsPreviewDialog
        open={showPreview}
        topic={topic}
        onCloseClicked={() => setShowPreview(false)}
      />
    </Box>
  )
}

function TopicItemsPreviewDialog(props: {
  topic: Topic
  open: boolean
  onCloseClicked: () => void
}) {
  const { topic, open, onCloseClicked } = props
  return (
    <GenericDialog
      title={topic.title}
      open={open}
      size="sm"
      onClose={onCloseClicked}
      content={
        <Stack
          gap={1.5}
          borderTop="1px solid"
          borderColor="other.outlineBorder"
          divider={<Divider />}
          py={2}
        >
          {topic.elements.map((element, index) => {
            return (
              <CourseSyllabusElementView
                element={element}
                topic={topic}
                index={index}
                key={element.item.id}
                readonly={true}
                sx={{
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: 0,
                  padding: 0,
                  cursor: "default",
                }}
              />
            )
          })}
        </Stack>
      }
    />
  )
}

function AddFirstCourseToUnit(props: { unit: UnitDto }) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onLinkCourseButtonClicked = (itemType: AddUnitItemType) => {
    switch (itemType) {
      case AddUnitItemType.ExistingCourse:
        dispatch(editUnitCoursesClicked({ unitId: props.unit.id }))
        break
      case AddUnitItemType.NewCourse:
        onCreateCourseClicked()
        break
    }
  }

  const onCreateCourseClicked = () => {
    dispatch(createCourse())
      .then(unwrapResult)
      .then((course) => {
        navigate(`${props.unit.id}/${course.id}`)
      })
  }

  return (
    <Box width="100%" padding="32px">
      <LinkCourseButton onClick={onLinkCourseButtonClicked} />
    </Box>
  )
}

function AddOrManageCoursesButton(props: { unitId: string }) {
  const dispatch = useAppDispatch()
  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={
        <SvgIcon
          component={appIcons.plus}
          inheritViewBox
          sx={{
            stroke: appTheme.palette.icon.black,
          }}
        />
      }
      onClick={() => {
        dispatch(editUnitCoursesClicked(props))
      }}
    >
      Manage courses
    </Button>
  )
}

function CoursePlaceholderSkeleton(props: {
  unitId: string
  courseId: string
}) {
  const dispatch = useAppDispatch()
  const onRemoveClicked = () => {
    dispatch(removeCourseFromUnit(props))
  }
  const onReplaceClicked = () => {
    dispatch(editUnitCoursesClicked(props))
  }

  return (
    <Box
      width="100%"
      height="160px"
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: appTheme.palette.action.hover,
        border: "1px solid #00000009",
      }}
    >
      <Typography variant="body2">This course can't be found.</Typography>
      <Box display="flex" gap="16px" padding="16px">
        <Button
          variant="outlined"
          size="small"
          startIcon={
            <SvgIcon
              component={appIcons.trash03}
              inheritViewBox
              sx={{
                fill: "none",
                stroke: appTheme.palette.icon.black,
              }}
            />
          }
          onClick={onRemoveClicked}
        >
          Remove course
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={
            <SvgIcon
              component={appIcons.refreshCcw}
              inheritViewBox
              sx={{
                fill: "none",
                stroke: appTheme.palette.icon.black,
              }}
            />
          }
          onClick={onReplaceClicked}
        >
          Replace course
        </Button>
      </Box>
    </Box>
  )
}

const EmptyUnit = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body2">
        This unit is empty.
        <br /> Go to edit mode to add content
      </Typography>
    </Box>
  )
}

export default UnitContentEditor
