import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  backToIdleRequested,
  onboardPopupClosed,
  onboardStudentClassSelected,
} from "@features/program/programSlice"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { registerMultipleStudents } from "@features/program/programSliceThunks"
import { Box, Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useParams } from "react-router-dom"
import ProgramClassSelector from "../programClassSelector"
import MultipleStudentsRegistrationPreview from "./multipleStudentsRegistrationPreview"
import StudentsCSVFileDropzone from "./studentsCSVFileDropzone"
import UploadingCSVInstructions from "./uploadingCSVInstructions"
import UploadingProgressBar from "./uploadingProgressBar"

function OnboardMultipleStudentsPopup() {
  const model = useAppSelector(
    (state) => state.program.studentsPage.multipleStudentsOnboardPopup,
  )
  const { programId } = useParams()
  const dispatch = useAppDispatch()
  const processedStudents = Object.keys(model?.statuses ?? {})
  const totalStudents = model ? model.students.length : 0
  const preprocessingPercentages =
    totalStudents === 0 ? 0 : (processedStudents.length / totalStudents) * 100
  const studentsReadyForOnboarding = processedStudents.filter(
    (email) => model?.statuses[email] === "ready",
  ).length
  const classes = useAppSelector(selectClassesForProgram(programId))

  return (
    <GenericDialog
      open={model !== undefined}
      size={preprocessingPercentages === 100 ? "lg" : "sm"}
      onClose={() => dispatch(onboardPopupClosed())}
      title={
        model?.state === "idle" ? "Add multiple students (CSV)" : undefined
      }
      content={
        <Stack gap={3}>
          {model?.isClassEditable && model?.state === "idle" && (
            <ProgramClassSelector
              classes={classes}
              selectedClass={classes.find((c) => c.id === model.classId)}
              size="medium"
              allowNone={false}
              onClassChange={(classId) => {
                dispatch(onboardStudentClassSelected({ classId }))
              }}
              placeholder="Choose a class"
            />
          )}
          {preprocessingPercentages === 100 && (
            <Typography variant="h6">Onboard multiple students</Typography>
          )}
          <Stack gap={2}>
            {model?.classId &&
              preprocessingPercentages >= 0 &&
              preprocessingPercentages < 100 && (
                <LoadingStudentsView
                  classId={model.classId}
                  preprocessingPercentages={preprocessingPercentages}
                />
              )}
            {model && model?.classId && preprocessingPercentages === 100 && (
              <MultipleStudentsRegistrationPreview model={model} />
            )}
          </Stack>
        </Stack>
      }
      buttons={
        model && model.classId && preprocessingPercentages === 100
          ? [
              {
                type: "secondary",
                text: "Back",
                onClick: () => dispatch(backToIdleRequested()),
                icon: (
                  <SvgIcon
                    inheritViewBox
                    component={appIcons.arrowLeft}
                    sx={{
                      stroke: "currentcolor",
                    }}
                  ></SvgIcon>
                ),
              },
              {
                type: "primary",
                text: `Add ${studentsReadyForOnboarding} students`,
                onClick: () =>
                  dispatch(
                    registerMultipleStudents({ classId: model.classId ?? "" }),
                  ),
                loading: model.state === "registering",
                disabled: studentsReadyForOnboarding === 0,
              },
            ]
          : undefined
      }
    />
  )
}

function LoadingStudentsView(props: {
  classId: string
  preprocessingPercentages: number
}) {
  const { classId, preprocessingPercentages } = props

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {preprocessingPercentages === 0 ? (
        <StudentsCSVFileDropzone classId={classId} />
      ) : (
        <UploadingProgressBar progress={preprocessingPercentages} />
      )}
      <UploadingCSVInstructions />
    </Box>
  )
}

export default OnboardMultipleStudentsPopup
