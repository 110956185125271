export function startDateToCohortStyleDate(dateAsString: string): string {
  return new Date(dateAsString).toLocaleString("en-us", {
    month: "short",
    year: "numeric",
  })
}

export function stringAsCamelCase(type: string): string {
  return type
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (match) => match.toUpperCase())
    .replace("And", "and")
}
