import { useAppSelector } from "@app/hooks"
import CustomTooltip from "@cmp/customTooltip"
import GenericDialog from "@cmp/genericDialog"
import GenericTable from "@cmp/genericTable"
import { ErrorComponent } from "@cmp/loaders/errorComponent"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { SearchComponent } from "@cmp/searchComponent"
import { selectIsFeatureEnabled } from "@features/app-feature-flags/appFeatureFlagsSelectors"
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { isDevEnv, isLocalEnv } from "@utils/envUtils"
import { useEffect, useState } from "react"
import { DeeplinkData, DeeplinksClient } from "./deeplinksClient"

export const DeeplinksView = () => {
  const [links, setLinks] = useState<DeeplinkData[]>([])
  const [loading, setLoading] = useState(true)
  const [failedFetch, setFailedFetch] = useState(false)

  const refreshData = () => {
    setLoading(true)
    DeeplinksClient.listDeeplinks()
      .then((data) => {
        setLinks(data)
        setLoading(false)
      })
      .catch(() => {
        setFailedFetch(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    refreshData()
  }, [])

  if (failedFetch || loading) {
    return (
      <Stack width={1} px={3} pb={6}>
        <Stack
          width={1}
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          {failedFetch && <ErrorComponent loadedComponents="Deeplinks" />}
          {loading && <LoadingComponent loadedComponents="Deeplinks" />}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack width={1} height={1} px={3} pb={6}>
      <Content links={links} refreshData={refreshData} />
    </Stack>
  )
}

const Content = ({
  links,
  refreshData,
}: {
  links: DeeplinkData[]

  refreshData: () => void
}) => {
  const [linkToEdit, setLinkToEdit] = useState<DeeplinkData | null>(null)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [searchText, setSearchText] = useState("")
  const isEmbeddedLiveSessionFeatureFlagEnabled = useAppSelector((state) =>
    selectIsFeatureEnabled(state, "embedded-live-session"),
  )

  const filteredLinks = links.filter((link) =>
    [link.deepLinkId, link.redirectUrl, link.description].some((field) =>
      (field || "").toLowerCase().includes(searchText.toLowerCase()),
    ),
  )

  const copyDeepLink = (link: DeeplinkData) => {
    const path = `link/${link.deepLinkId}`
    const linkToCopy = isDevEnv()
      ? `https://app.dev.masterschool.com/${path}`
      : isLocalEnv()
      ? `http://localhost:3000/${path}`
      : `https://app.masterschool.com/${path}`

    navigator.clipboard.writeText(linkToCopy)
  }

  const headerKeys: (keyof DeeplinkData)[] = [
    "deepLinkId",
    "redirectUrl",
    "type",
    "description",
    "id",
  ]

  if (!isEmbeddedLiveSessionFeatureFlagEnabled) {
    headerKeys.splice(headerKeys.indexOf("type"), 1)
  }

  const closeCreateEditDialog = () => {
    setShowCreateDialog(false)
    setLinkToEdit(null)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      width="100%"
      maxWidth="1240px"
      height={1}
      mx="auto"
      pt={10}
    >
      {(showCreateDialog || linkToEdit) && (
        <EditOrCreateLinkDialog
          open
          link={linkToEdit}
          onClose={closeCreateEditDialog}
          onSuccess={refreshData}
        />
      )}
      <Header onCreateClicked={() => setShowCreateDialog(true)} />
      <SearchComponent
        onSearchTextChange={setSearchText}
        searchText={searchText}
        inputFilters={[]}
        onInputFiltersChange={() => undefined}
        searchPlaceholder="Search for a deep link"
      />
      <GenericTable
        data={filteredLinks}
        keys={headerKeys}
        renderHeader={{
          deepLinkId: () => (
            <Typography variant="body2_sb">Deeplink ID</Typography>
          ),
          redirectUrl: () => (
            <Typography variant="body2_sb">Redirect URL</Typography>
          ),
          description: () => (
            <Typography variant="body2_sb">Description</Typography>
          ),
          type: () => <Typography variant="body2_sb">Type</Typography>,
          id: () => undefined,
        }}
        render={{
          id: (_, row, hover) => (
            <Stack direction="row" gap={1}>
              <EditButton onClick={() => setLinkToEdit(row)} show={hover} />
              <CopyLinkButton onClick={() => copyDeepLink(row)} show={hover} />
            </Stack>
          ),
          type: (type) => {
            switch (type) {
              case "liveSession":
                return "Live session"
              case "other":
                return "Other"
            }
          },
        }}
        highlightText={searchText}
      />
    </Box>
  )
}

const Header = (props: { onCreateClicked: () => void }) => {
  const { onCreateClicked } = props

  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      <Typography variant="h4">Deeplinks</Typography>
      <Button variant="contained" onClick={onCreateClicked}>
        Create new
      </Button>
    </Box>
  )
}

const EditButton = (props: { onClick: () => void; show: boolean }) => {
  return (
    <IconButton
      size="small"
      onClick={props.onClick}
      sx={{ opacity: props.show ? 1 : 0 }}
    >
      <SvgIcon
        component={appIcons.edit03}
        inheritViewBox
        sx={{
          width: "20px",
          height: "20px",
          fill: "none",
          stroke: "black",
        }}
      />
    </IconButton>
  )
}

const CopyLinkButton = (props: { onClick: () => void; show: boolean }) => {
  return (
    <CustomTooltip title="Copy deeplink" leaveDelay={0}>
      <IconButton
        size="small"
        onClick={props.onClick}
        sx={{ opacity: props.show ? 1 : 0 }}
      >
        <SvgIcon
          component={appIcons.copy06}
          inheritViewBox
          sx={{
            width: "20px",
            height: "20px",
            fill: "none",
            color: "primary.main",
          }}
        />
      </IconButton>
    </CustomTooltip>
  )
}

const EditOrCreateLinkDialog = (props: {
  open: boolean
  link: DeeplinkData | null
  onClose: () => void
  onSuccess: () => void
}) => {
  const { open, link, onClose, onSuccess } = props

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [deepLinkId, setDeepLinkId] = useState<string>(link?.deepLinkId ?? "")
  const [type, setType] = useState<DeeplinkData["type"]>(link?.type ?? "other")
  const [redirectUrl, setRedirectUrl] = useState<string>(
    link?.redirectUrl ?? "",
  )
  const [description, setDescription] = useState<string | undefined>(
    link?.description,
  )
  const isEmbeddedLiveSessionFeatureFlagEnabled = useAppSelector((state) =>
    selectIsFeatureEnabled(state, "embedded-live-session"),
  )

  const canSubmit = deepLinkId.length > 0 && redirectUrl.length > 0

  const onSubmit = () => {
    setSubmitting(true)
    const data = {
      deepLinkId,
      redirectUrl,
      description,
      type,
    }

    if (link) {
      DeeplinksClient.editDeeplink({
        id: link.id,
        ...data,
      }).then(() => {
        onSuccess()
        setSubmitting(false)
        onClose()
      })
    } else {
      DeeplinksClient.createDeeplink(data).then(() => {
        onSuccess()
        setSubmitting(false)
        onClose()
      })
    }
  }

  const isLiveSessionLink = type === "liveSession"

  const onDeeplinkIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setDeepLinkId(value.replace(" ", ""))
  }

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      size="sm"
      title={link ? "Edit deeplink" : "Add a deep link"}
      content={
        <Stack gap={2} pt={1}>
          {isEmbeddedLiveSessionFeatureFlagEnabled && (
            <TypeSelector onChange={setType} type={type} />
          )}
          <TextField
            label={
              isLiveSessionLink ? "Enter a Zoom meeting URL" : "Redirect URL"
            }
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.target.value)}
            helperText={
              isLiveSessionLink
                ? "This is the actual meeting link that students will be redirected to, i.e. https://masterschool.zoom.us/j/84421401183"
                : ""
            }
          />
          <TextField
            label="Enter a deep link ID"
            value={deepLinkId}
            onChange={onDeeplinkIdChange}
            helperText={
              "This will appear at the end of the deep link (app.masterschool.com/link/deepLinkID)."
            }
          />
          <TextField
            label={
              isLiveSessionLink
                ? "Enter a session description (optional) "
                : "Description (Optional)"
            }
            value={description}
            multiline
            rows={2}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: link ? "Save changes" : "Add deep link",
          onClick: onSubmit,
          loading: submitting,
          disabled: !canSubmit,
        },
      ]}
    />
  )
}

const TypeSelector = ({
  onChange,
  type,
}: {
  onChange: (type: DeeplinkData["type"]) => void
  type: DeeplinkData["type"]
}) => {
  const onTypeChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => onChange(value as DeeplinkData["type"])

  return (
    <Stack gap={0.5}>
      <Stack>
        <Typography variant="body1_sb">Link type</Typography>
        <Typography variant="body2">
          Live sessions take place on campus and are followed by an NPS survey.
        </Typography>
      </Stack>
      <RadioGroup value={type} onChange={onTypeChange}>
        <Stack direction="row" gap={2}>
          <FormControlLabel
            sx={{ m: 0 }}
            value="liveSession"
            control={<Radio />}
            label="Live session"
          />
          <FormControlLabel
            sx={{ m: 0 }}
            value="other"
            control={<Radio />}
            label="Off-campus"
          />
        </Stack>
      </RadioGroup>
    </Stack>
  )
}
