import { useAppDispatch, useAppSelector } from "@app/hooks"
import ItemDescriptor from "@cmp/itemDescriptor"
import {
  CourseDto,
  DateCondition,
  SectionAccessCondition,
  UnitDto,
} from "@masterschool/course-builder-api"
import appIcons from "@utils/appIcons"
import {
  courseSyllabusDuration,
  durationFormatting,
} from "@utils/syllabus+duration"
import { useEffect } from "react"
import { isCourse } from "@features/courseEditor/courseEditorUtils"
import {
  selectIsUnitStale,
  selectUnitCourses,
} from "@features/syllabus/syllabusSelectors"
import { fetchUnitCourses } from "@features/syllabus/syllabusesMenuSlice"
import { syllabusUnitRemoved } from "@features/syllabusEditor/syllabusEditorSlice"
import appTheme from "../../../theme/appTheme"
import {
  Badge,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  SvgIconProps,
} from "@mui/material"
import { ItemModel } from "@cmp/buttons/optionsButton2"
import {
  UnlockStrategy,
  unlockStrategyFromCondition,
} from "./access.condition.utils"

function UnitDescriptorView(props: {
  syllabusId: string
  unit: UnitDto
  index: number
  editMode: boolean
  isSelected: boolean
  hide?: boolean
  hideBadge?: boolean
  hideHoverIndication?: boolean
  onClick?: () => void
}) {
  const {
    syllabusId,
    unit,
    index,
    editMode,
    hide,
    hideBadge,
    hideHoverIndication,
    onClick,
    isSelected,
  } = props
  const dispatch = useAppDispatch()
  const courses = useAppSelector(selectUnitCourses(syllabusId, unit.id))
  const descriptorModel = getDescriptorModel(
    unit,
    courses.filter(isCourse),
    isSelected,
    editMode,
  )

  const isUnitStale = useAppSelector(selectIsUnitStale(syllabusId, unit.id))
  const didCloseStaleCoursesPopup = useAppSelector((state) =>
    state.ui.sessionHistory.presentedStaleCoursesSnackbar.some(
      (model) => model.syllabusId === syllabusId,
    ),
  )
  const showBadge = !hideBadge && isUnitStale && !didCloseStaleCoursesPopup
  const isMissingUnitNumber = !unit.unitNumber || isNaN(unit.unitNumber)
  const isInvalid = isMissingUnitNumber && unit.courseDescriptors.length > 0

  useEffect(() => {
    dispatch(fetchUnitCourses(unit))
  }, [unit, dispatch])

  const items: ItemModel[] | undefined = editMode
    ? [
        {
          type: "menu-item",
          props: {
            key: "remove",
            children: (
              <>
                <ListItemIcon>
                  <SvgIcon
                    inheritViewBox
                    component={appIcons.trash03}
                    sx={{
                      stroke: appTheme.palette.icon.black,
                      fill: "none",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Remove" />
              </>
            ),
            onClick: () => {
              dispatch(
                syllabusUnitRemoved({
                  unitId: unit.id,
                }),
              )
            },
          },
        },
      ]
    : undefined

  return (
    <Badge
      variant="dot"
      color={hide ? undefined : showBadge ? "info" : undefined}
      sx={{
        "& .MuiBadge-badge": {
          right: 4,
          top: 2,
          zIndex: 1000,
        },
        width: "100%",
      }}
    >
      <ItemDescriptor
        title={descriptorModel.title}
        subtitle={descriptorModel.subtitle}
        onClick={onClick}
        leftIcon={editMode ? appIcons.dotsGrid : undefined}
        leftIconSx={{
          cursor: "grab",
          opacity: hide ? 0 : 1,
        }}
        index={editMode ? index : undefined}
        optionsItems={items}
        extraText={extraTextFromAccessCondition(unit.accessCondition)}
        extraIcons={extraIconsFromAccessCondition(unit.accessCondition)}
        sx={{
          opacity: hide ? 0 : 1,
          minHeight: "90px",
          border: isInvalid
            ? `2px solid ${appTheme.palette.error.main}`
            : `1px solid ${descriptorModel.borderColor}`,
          backgroundColor: descriptorModel.background,
          ":hover":
            hideHoverIndication === true
              ? undefined
              : {
                  backgroundColor: descriptorModel.hoverBackground,
                },
          overflow: "visible",
        }}
      />
    </Badge>
  )
}

type DescriptorModel = {
  title: string
  subtitle: string
  background: string | undefined
  hoverBackground: string
  borderColor: string
}

function getDescriptorModel(
  unit: UnitDto,
  courses: CourseDto[],
  isSelected: boolean,
  editMode: boolean,
): DescriptorModel {
  const borderColor = isSelected
    ? appTheme.palette.info.main
    : appTheme.palette.other.outlineBorder

  const titleFirstLine = `${unit.shortTitle || ""}${unit.unitNumber || ""}`
  const title =
    titleFirstLine.length > 0
      ? `${titleFirstLine}\n${unit.name || "Unnamed unit"}`
      : `${unit.name || "Unnamed unit"}`

  const coursesDuration = courses.map((c) => courseSyllabusDuration(c.syllabus))
  const totalDuration = coursesDuration.reduce((a, b) => a + b, 0)

  let subtitle = unit.conditionDescription
  if (!subtitle) {
    subtitle =
      courses.length > 0 ? durationFormatting(totalDuration) : "Empty unit"
  }

  if (editMode) {
    subtitle += ` (${durationFormatting(totalDuration)})`
  }

  const background = isSelected ? "#EDF7FE" : appTheme.palette.primary.contrast

  const hoverBackground = isSelected
    ? "rgba(33, 150, 243, 0.08)"
    : appTheme.palette.action.hover

  return {
    title,
    subtitle,
    background,
    hoverBackground,
    borderColor,
  }
}

function extraTextFromAccessCondition(
  condition: SectionAccessCondition | undefined,
): string | undefined {
  if (!condition) return undefined
  const unlockStrategy = unlockStrategyFromCondition(condition)
  switch (unlockStrategy) {
    case UnlockStrategy.OnSprintStart:
      return undefined
    case UnlockStrategy.OnPreviousSectionCompletion:
      return "On previous unit completion"
    case UnlockStrategy.AlwaysOpen:
      return "Always unlocked"
    case UnlockStrategy.OnCustomDate:
      return new Date(
        (condition as DateCondition).startDate ?? "",
      ).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      })
  }
}

function extraIconsFromAccessCondition(
  condition: SectionAccessCondition | undefined,
): (SvgIconProps & { id: string })[] {
  if (!condition) return []
  const unlockStrategy = unlockStrategyFromCondition(condition)
  if (unlockStrategy === UnlockStrategy.OnSprintStart) return []
  const icon = appIcons.lockUnlocked01
  return [
    {
      id: condition.type,
      component: icon,
      sx: {
        stroke: appTheme.palette.icon.black,
        width: "16px",
        height: "16px",
      },
    },
  ]
}

export default UnitDescriptorView
