import { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppDispatch } from "../app/hooks"
import { LoginClient } from "@clients/loginClient"
import { fetchedAccessToken } from "@features/login/loginSlice"

export function LoginProxyToken() {
  const { token, nextURL } = useParams()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!token) {
      console.error("Reached login proxy without token")
      navigate("/login", { replace: true })
      return
    }

    const redirectUrl = searchParams.get("redirectUrl")
    LoginClient.getPermanentToken(token)
      .then((res) => {
        dispatch(fetchedAccessToken(res.accessToken))
        if (redirectUrl) {
          navigate(redirectUrl, { replace: true })
        } else if (nextURL) {
          navigate(`${nextURL}`, { replace: true })
        } else {
          navigate("/", { replace: true })
        }
      })
      .catch((err) => {
        console.error("Error creating permanent token", err)
        navigate("/login", { replace: true })
      })
  }, [dispatch, navigate, nextURL, searchParams, token])

  return null
}
