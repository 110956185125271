import { Alert, Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import { Link } from "react-router-dom"

const ProgramIssueWarning = ({
  alertText,
  programId,
  targetTab,
}: {
  alertText: string
  programId: string
  targetTab: string
}) => {
  return (
    <Alert
      icon={
        <SvgIcon
          component={appIcons.alertTriangle}
          inheritViewBox
          sx={{ fill: "none", width: 20, height: 20 }}
        />
      }
      severity="warning"
      action={
        <Button
          LinkComponent={Link}
          size="small"
          color="inherit"
          {...{ to: `/program/${programId}?tab=${targetTab}` }}
        >
          View
        </Button>
      }
    >
      {alertText}
    </Alert>
  )
}

export default ProgramIssueWarning
