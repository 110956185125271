import { useAppSelector } from "@app/hooks"
import { useSearchParams } from "react-router-dom"
import { selectActiveCourse } from "../../../features/courseEditor/courseEditorSelectors"

function useSelectedLiveEvent() {
  const [searchParams] = useSearchParams()
  const elementId = searchParams.get("elementId")
  const activeCourse = useAppSelector(selectActiveCourse)

  if (!elementId || !activeCourse) {
    return undefined
  }

  return activeCourse.syllabus.topics
    .flatMap((topic) => topic.liveEvents)
    .find((liveEvent) => liveEvent.id === elementId)
}

export default useSelectedLiveEvent
