import { useAppDispatch, useAppSelector } from "@app/hooks"
import CourseViewStatus from "@cmp/courseViewStatus"
import {
  Box,
  ClickAwayListener,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import {
  clickedAwayHighlightedCourse,
  toggleIsCourseFavorite,
} from "@features/coursesMenu/coursesMenuSlice"
import appTheme from "../../theme/appTheme"
import CourseDescriptorMenu from "./courseDescriptorMenu"
import useCourseMenuActions from "./useCourseMenuActions"
import { CourseDto } from "@masterschool/course-builder-api"

function CourseDescriptorView(props: {
  course: CourseDto
  highlight?: boolean
}) {
  const { course } = props

  const dispatch = useAppDispatch()

  const { onOpenClicked } = useCourseMenuActions(course)

  const isFavorite = useAppSelector(
    (state) => state.coursesMenu.favoriteCourses,
  ).includes(course.id)

  const highlightCssProps = {
    border: `1px solid rgba(33, 150, 243, 0.5) `,
    background: `rgba(33, 150, 243, 0.08)`,
  }

  const onFavoriteClicked = () => {
    dispatch(
      toggleIsCourseFavorite({
        courseId: course.id,
      }),
    )
  }

  const titles = titlesToPresent(course)
  const hasTitle = titles.title.length > 0

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (props.highlight) {
          dispatch(clickedAwayHighlightedCourse())
        }
      }}
    >
      <Box
        onClick={() => onOpenClicked()}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          cursor: "pointer",
          padding: "24px",
          gap: "43px",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          height: "210px",
          ":hover": {
            backgroundColor: appTheme.palette.action.hover,
          },
          transition: "all 0.3s ease-in-out",
          bgcolor: appTheme.palette.primary.contrast,
          borderRadius: "4px",
          ...(props.highlight ? highlightCssProps : {}),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginRight: "16px",
              overflow: "hidden",
            }}
          >
            {titles.shortTitle.length > 0 && (
              <Typography
                variant="body1_sb"
                sx={{
                  maxWidth: "100%",
                  height: "24px",
                  whiteSpace: "nowrap",
                }}
              >
                {titles.shortTitle}
              </Typography>
            )}
            <Typography
              variant={hasTitle ? "h6" : "body1"}
              sx={{
                width: "100%",
                maxHeight: "48px",
                numberOfLines: 1,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              color={hasTitle ? "text.primary" : "text.secondary"}
            >
              {hasTitle ? titles.title : "Unnamed course"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                width: "100%",
                height: "22px",
                overflow: "hidden",
              }}
            >
              {titles.name}
            </Typography>
          </Box>
          <CourseDescriptorMenu course={course} />
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <CourseViewStatus course={course} />
          <IconButton
            sx={{ padding: "6px", borderRadius: "50%" }}
            onClick={(e) => {
              e.stopPropagation()
              onFavoriteClicked()
            }}
          >
            <SvgIcon
              sx={{
                color: "icon.default",
                width: 20,
                height: 20,
                fill: isFavorite ? "#44aaee" : "none",
              }}
              stroke={isFavorite ? "none" : "currentColor"}
              inheritViewBox
              component={appIcons.star01}
            />
          </IconButton>
        </Box>
      </Box>
    </ClickAwayListener>
  )
}

function titlesToPresent(course: CourseDto) {
  return {
    shortTitle: course.shortTitle ?? "",
    title: course.title ?? "",
    name: course.name !== course.title ? course.name : "",
  }
}

export default CourseDescriptorView
