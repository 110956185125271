import { useAppDispatch, useAppSelector } from "@app/hooks"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import {
  onboardMultipleStudentsOpened,
  onboardSingleStudentOpened,
} from "@features/program/programSlice"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { Box, Typography } from "@mui/material"

import { SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../../theme/appTheme"

function OnboardStudentsButton(props: {
  classId: string | undefined
  programId: string
  buttonText: string
  icon: "chevron" | "plus"
  variant: "outlined" | "contained"
  size: "small" | "medium" | "large"
}) {
  const dispatch = useAppDispatch()
  const classes = useAppSelector(selectClassesForProgram(props.programId))
  const programHasNoClasses = classes.length === 0
  return (
    <Box
      display="flex"
      gap="8px"
      alignItems="center"
      justifyItems="center"
      flexDirection="column"
    >
      <OptionsButton2
        buttonModel={{
          type: "button",
          props: {
            children: props.buttonText,
            variant: props.variant,
            disabled: programHasNoClasses,
            size: props.size,
            startIcon:
              props.icon === "plus" ? (
                <SvgIcon
                  inheritViewBox
                  component={appIcons.plus}
                  sx={{
                    stroke: programHasNoClasses
                      ? appTheme.palette.icon.disabled
                      : appTheme.palette.icon.black,
                  }}
                />
              ) : undefined,
            endIcon:
              props.icon === "chevron" ? (
                <SvgIcon
                  inheritViewBox
                  component={appIcons.chevronDown}
                  sx={{
                    stroke: programHasNoClasses
                      ? "none"
                      : appTheme.palette.icon.black,
                  }}
                />
              ) : undefined,
          },
        }}
        items={[
          {
            type: "menu-item",
            props: {
              key: "single",
              children: "Single student",
              onClick: () => {
                dispatch(
                  onboardSingleStudentOpened({
                    classId: props.classId,
                  }),
                )
              },
            },
          },
          {
            type: "menu-item",
            props: {
              key: "multiple",
              children: "Multiple Students (CSV)",
              onClick: () => {
                dispatch(
                  onboardMultipleStudentsOpened({
                    classId: props.classId,
                  }),
                )
              },
            },
          },
        ]}
      />
      {programHasNoClasses && (
        <Typography variant="body3" color="text.disabled" textAlign="center">
          Add classes to program to enable onboarding students.
        </Typography>
      )}
    </Box>
  )
}

export default OnboardStudentsButton
