import { formatDate } from "@masterschool/foundations"

export function formatDateAsMonthAndDay(date: string | Date): string {
  return formatDate(date, {
    month: "short",
    day: "numeric",
  })
}

export function formatDateAsYearAndLongMonth(date: string | Date): string {
  return formatDate(date, {
    month: "long",
    year: "numeric",
  })
}

export function formatDateAsShortNumeric(date: string | Date): string {
  return formatDate(date, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
}

export function formatDateAsDayLongMonthAndYear(date: string | Date): string {
  return formatDate(date, {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}
