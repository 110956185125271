import {
  ComponentsOverrides,
  ComponentsProps,
  Theme,
} from "@mui/material/styles"

const MuiFormHelperTextOverride: {
  defaultProps?: ComponentsProps["MuiFormHelperText"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiFormHelperText"]
} = {
  defaultProps: {
    sx: {
      textTransform: "none",
      marginLeft: 0,
      marginTop: "4px",
      letterSpacing: 0,
      color: "text.disabled",
    },
  },
}

export { MuiFormHelperTextOverride }
