import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { selectSyllabus } from "@features/syllabus/syllabusSelectors"
import {
  renameAccepted,
  renamePopupClosed,
  renamePopupNameChanged,
} from "@features/syllabus/syllabusesMenuSlice"
import { TextField } from "@mui/material"

export const RenameSyllabusPopup = () => {
  const popupState = useAppSelector((state) => state.syllabusesMenu.renamePopup)
  const currentName = useAppSelector(
    selectSyllabus(popupState?.syllabusId),
  )?.name
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(renamePopupClosed())
  }

  const onSaveClicked = () => dispatch(renameAccepted())

  if (!popupState) {
    return null
  }

  return (
    <GenericDialog
      onClose={onClose}
      open
      size="xs"
      title="Rename syllabus"
      content={
        <TextField
          label="Syllabus name"
          value={popupState.name || ""}
          multiline
          fullWidth
          onChange={(e) =>
            dispatch(
              renamePopupNameChanged({
                newName: e.target.value,
              }),
            )
          }
        />
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Save",
          onClick: onSaveClicked,
          disabled: !popupState.name || popupState.name === currentName,
        },
      ]}
    />
  )
}
