import { startDateToCohortStyleDate } from "@utils/syllabusTags"
import { ProgramClass } from "./slackChannelsPage"
import { Box, Chip, ChipProps } from "@mui/material"
import { ProgramClassDto } from "@masterschool/course-builder-api"
import { domainToDisplayName } from "@utils/domainUtils"

export const ClassChipList = (props: { classes: ProgramClass[] }) => {
  const classes = [...props.classes]

  classes.sort((a, b) => a.id - b.id)
  return (
    <Box display="flex" gap="8px" flexWrap={"wrap"}>
      {classes.map((programClass) => (
        <ClassChip key={programClass.id} programClass={programClass} />
      ))}
    </Box>
  )
}

export const ClassChip = (
  props: { programClass: ProgramClass | ProgramClassDto } & ChipProps,
) => {
  const { programClass, ...rest } = props
  return (
    <Chip
      key={props.programClass.id}
      label={
        isProgramClassDto(programClass)
          ? programClass.name
          : classDisplayName(programClass)
      }
      size="small"
      sx={{
        width: "fit-content",
      }}
      {...rest}
    />
  )
}

export function classDisplayName(programClass: ProgramClass): string {
  const { id, startDate, domain } = programClass
  const stylizedDate = startDateToCohortStyleDate(startDate)
  const domainDisplayName = domainToDisplayName(domain)
  let description = programClass.description.slice(0, 24)
  if (description.length < programClass.description.length) {
    description += "..."
  }
  return `${description}: ${domainDisplayName} ${stylizedDate} (${id})`
}

function isProgramClassDto(
  programClass: ProgramClass | ProgramClassDto,
): programClass is ProgramClassDto {
  return (programClass as ProgramClassDto)?.name !== undefined
}
