import { Autocomplete, Box, TextField, TextFieldProps } from "@mui/material"
import { ChangeEvent } from "react"
import countries from "./country"

function CountrySelect(props: TextFieldProps) {
  return (
    <Autocomplete
      id="country-select-demo"
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      onChange={(e, value) => {
        if (props.onChange)
          props.onChange({
            target: {
              value: value?.label ?? "",
            },
          } as ChangeEvent<HTMLInputElement>)
      }}
      renderInput={(params) => <TextField {...props} {...params} />}
    />
  )
}

export default CountrySelect
