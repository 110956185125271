import { Box, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { useAppSelector } from "@app/hooks"
import { selectActiveCourse } from "@features/courseEditor/courseEditorSelectors"
import {
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  Topic,
} from "@masterschool/course-builder-api"
import MasterschoolLessonEditor from "./elements/lesson/masterschoolLessonEditor"
import SurveyEditor from "./elements/survey/surveyEditor"
import TestEditor from "./elements/test/testEditor"
import NotionLessonEditor from "./elements/lesson/notionLessonEditor"
import ElementEditorHeader from "./elements/elementEditorHeader"
import EditLessonType from "./elements/lesson/EditLessonType"
import DatacampLessonEditor from "./elements/lesson/datacampLessonEditor"
import TryHackmeLessonEditor from "./elements/lesson/tryHackmeLessonEditor"
import OtherLessonEditor from "./elements/lesson/otherLessonEditor"
import UdacityLessonEditor from "./elements/lesson/udacityLessonEditor"
import EmptyLessonEditor from "./elements/lesson/emptyLessonEditor"
import RunestoneLessonEditor from "./elements/lesson/runestoneLessonEditor"
import EmptyProjectEditor from "./elements/project/emptyProjectEditor"
import CodioProjectEditor from "./elements/project/codioProjectEditor"
import appTheme from "../theme/appTheme"
import { findTopicByElementId } from "./topicFinderUtil"
import LiveEventEditorHeader from "./liveEvents/liveEventEditorHeader"
import { LiveEventEditor } from "./liveEvents/liveEventEditor"
import CodioLessonEditor from "./elements/lesson/codioLessonEditor"
import { ElementCompletionTypeControl } from "./elements/ElementCompletionType"

function CourseItemEditor() {
  const [searchParams] = useSearchParams()
  const course = useAppSelector(selectActiveCourse)
  const elementId = searchParams.get("elementId")

  if (!elementId || !course) {
    return null
  }

  const topic = findTopicByElementId(course, elementId)
  const element = topic?.elements.find((e) => e.item.id === elementId)
  const liveEvent = topic?.liveEvents.find((le) => le.id === elementId)
  if (!topic || (!element && !liveEvent)) {
    return <Typography>Item not found</Typography>
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        boxShadow:
          "0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      {element && (
        <>
          <ElementEditorHeader topic={topic} />
          <ElementBody element={element} topic={topic} />
        </>
      )}
      {liveEvent && (
        <>
          <LiveEventEditorHeader topic={topic} />
          <LiveEventEditor topic={topic} />
        </>
      )}
    </Box>
  )
}

function ElementBody({
  element,
  topic,
}: {
  element: CourseSyllabusElement
  topic: Topic
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        bgcolor: appTheme.palette.primary.contrast,
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <EditorBody element={element} topic={topic} />
      </Box>
      <Box
        sx={{
          width: "100%",
          p: "24px",
        }}
      >
        <ElementCompletionTypeControl element={element} topic={topic} />
      </Box>
    </Box>
  )
}

function EditorBody({
  element,
  topic,
}: {
  element: CourseSyllabusElement
  topic: Topic
}) {
  switch (element.type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
      return <DatacampLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.EmptyLesson:
      return <EmptyLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
      return <MasterschoolLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.NotionLesson:
      return <NotionLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.OtherLesson:
      return <OtherLessonEditor lessonType="other" topic={topic} />
    case CourseSyllabusElementTypeEnum.CourseraLesson:
      return <OtherLessonEditor lessonType="coursera" topic={topic} />
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
      return <TryHackmeLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.UdacityLesson:
      return <UdacityLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.CodioLesson:
      return <CodioLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return <RunestoneLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.EmptyProject:
      return <EmptyProjectEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.CodioProject:
      return <CodioProjectEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.Survey:
      return <SurveyEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.Test:
      return <TestEditor topic={topic} />
    default:
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <EditLessonType topic={topic} />
          <Typography>Unsupported item type {element.type}</Typography>
        </Box>
      )
  }
}

export default CourseItemEditor
