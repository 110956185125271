import { useAppSelector } from "@app/hooks"
import CBGrid, { ScrollableRef } from "@cmp/cbGrid"
import { Typography } from "@mui/material"
import { TabIdentifierEnumeration } from "@features/coursesMenu/coursesMenuSlice"
import CourseDescriptorView from "./descriptor/courseDescriptorView"
import { useEffect, useRef } from "react"
import { CourseDto } from "@masterschool/course-builder-api"

export function CoursesDescriptorsContainer(props: {
  courses: CourseDto[]
  highlightCourseId?: string
}) {
  const { courses } = props

  const gridRef = useRef<ScrollableRef>(null)

  useEffect(() => {
    if (!gridRef.current) {
      return
    }

    if (props.highlightCourseId) {
      const index = courses.findIndex(
        (course) => course.id === props.highlightCourseId,
      )
      if (index !== -1) {
        gridRef.current.scrollToChild(index)
      }
    }
  }, [props.highlightCourseId, courses])

  return (
    <>
      {courses.length > 0 ? (
        <CBGrid elementWidth={364} ref={gridRef}>
          {courses.map((course) => (
            <CourseDescriptorView
              key={course.id}
              course={course}
              highlight={props.highlightCourseId === course.id}
            />
          ))}
        </CBGrid>
      ) : (
        <EmptyCoursesDescriptorsContainer />
      )}
    </>
  )
}

function EmptyCoursesDescriptorsContainer() {
  const isFiltersApplied = useAppSelector(
    (state) =>
      state.search.courseFilters.text !== "" ||
      state.search.courseFilters.advanceFilters.length > 0,
  )
  const tabIdentifier = useAppSelector((state) => state.coursesMenu.tab)

  return (
    <Typography
      variant="body1"
      sx={{
        width: "100%",
        textAlign: "center",
        padding: "24px",
        color: "text.disabled",
      }}
    >
      {isFiltersApplied
        ? "We couldn't find any courses that match your search"
        : placeholderText(tabIdentifier)}
    </Typography>
  )
}

function placeholderText(tabIdentifier: TabIdentifierEnumeration) {
  switch (tabIdentifier) {
    case TabIdentifierEnumeration.All:
      return "There are no courses yet"
    case TabIdentifierEnumeration.Published:
      return "No courses have been published yet"
    case TabIdentifierEnumeration.Draft:
      return "You don't have any drafts"
    case TabIdentifierEnumeration.Archived:
      return "No courses have been archived yet"
    case TabIdentifierEnumeration.Favorites:
      return "You don't have any favorites"
  }
}
