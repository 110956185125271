import {
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  Topic,
} from "@masterschool/course-builder-api"
import { RadioGroup, Stack, FormControlLabel, Radio } from "@mui/material"
import useEditElement from "./custom-hooks/useEditElement"

export function ElementCompletionTypeControl(props: {
  element: CourseSyllabusElement
  topic: Topic
}) {
  const { element } = props
  const completionType = elementCompletionTypeValue(element)
  const setCompletionTypeValue = useSetCompletionType()
  return (
    <ElementCompletionTypeRadioButtons
      value={completionType}
      onSetValue={(value) =>
        setCompletionTypeValue(value, element, props.topic)
      }
      hideOptional={cannotBeOptional(props.element)}
    />
  )
}

function ElementCompletionTypeRadioButtons(props: {
  value: CompletionType
  onSetValue: (value: CompletionType) => void
  hideOptional: boolean
}) {
  const { value, onSetValue, hideOptional } = props
  const maxWidth = hideOptional ? "350px" : "522px"
  return (
    <RadioGroup
      row
      value={value}
      onChange={(e) => {
        onSetValue(e.target.value as CompletionType)
      }}
      sx={{
        maxWidth: maxWidth,
      }}
    >
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        sx={{
          width: "100%",
        }}
      >
        <CompletionTypeRadioButton value="mandatory" label="Mandatory" />
        {!hideOptional && (
          <CompletionTypeRadioButton value="optional" label="Optional" />
        )}
        <CompletionTypeRadioButton
          value="advancedLearning"
          label="Advanced learning"
        />
      </Stack>
    </RadioGroup>
  )
}

function CompletionTypeRadioButton(props: {
  value: CompletionType
  label: string
}) {
  return (
    <FormControlLabel
      value={props.value}
      control={<Radio />}
      label={props.label}
    />
  )
}

export type CompletionType = "mandatory" | "optional" | "advancedLearning"

export function elementCompletionTypeValue(
  element: CourseSyllabusElement,
): CompletionType {
  if (element.item.isMandatory) {
    return "mandatory"
  }

  if (element.item.isExtraCurricular) {
    return "advancedLearning"
  }
  return "optional"
}

function translateCompletionTypeToElementProperties(
  completionType: CompletionType,
) {
  switch (completionType) {
    case "mandatory":
      return {
        isMandatory: true,
        isExtraCurricular: false,
      }
    case "optional":
      return {
        isMandatory: false,
        isExtraCurricular: false,
      }
    case "advancedLearning":
      return {
        isExtraCurricular: true,
        isMandatory: false,
      }
  }
}

function useSetCompletionType() {
  const editElement = useEditElement()
  return (
    completionType: CompletionType,
    element: CourseSyllabusElement,
    topic: Topic,
  ) => {
    const newElement = {
      ...element,
      item: {
        ...element.item,
        ...translateCompletionTypeToElementProperties(completionType),
      },
    }
    editElement(newElement, topic)
  }
}

function cannotBeOptional(element: CourseSyllabusElement) {
  return (
    element.type === CourseSyllabusElementTypeEnum.Survey ||
    element.type === CourseSyllabusElementTypeEnum.Test
  )
}
