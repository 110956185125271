import GenericDialog from "@cmp/genericDialog"

export const PublishSyllabusPopup = (props: {
  onClose: () => void
  onPublishClicked: () => void
}) => {
  const { onClose, onPublishClicked } = props

  return (
    <GenericDialog
      onClose={onClose}
      open
      size="xs"
      title="Publish changes?"
      subtitle={
        "Your changes will be published to an active syllabus and will be " +
        "available for all the students in the cohort."
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Publish",
          onClick: onPublishClicked,
        },
      ]}
    />
  )
}
