import { useAppSelector } from "@app/hooks"
import { CourseClient } from "@clients/courseClient"
import GenericDialog from "@cmp/genericDialog"
import PendingResourceComponent from "@cmp/pendingResourceComponent"
import { selectLatestPublishedCourse } from "@features/coursesMenu/coursesSelectors"
import { CourseDto, CourseMetadataDto } from "@masterschool/course-builder-api"
import { Box, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import CoursePreview from "../../../coursePreview"
import StaleCourseModel from "../staleCoursesPopup/staleCourseModel"
import useComputeStaleCourseContainers from "../staleCoursesPopup/useComputeStaleCourseContainers"
import useCourseVersionHistory from "./useCourseVersionHistory"
import VersionButton from "./versionButton"

function SyllabusValidationVersionHistory(props: {
  staleCourse: StaleCourseModel
  onClose: () => void
}) {
  const { staleCourse, onClose } = props
  const model = useVersionHistoryViewModel(staleCourse)
  const courses = useCourseVersionHistory(staleCourse.courseId)
  const [selectedVersion, setSelectedVersion] = useState<
    CourseMetadataDto | undefined
  >(undefined)
  const [displayedCourse, setDisplayedCourse] = useState<
    CourseDto | "pending" | "rejected"
  >("pending")

  useEffect(() => {
    switch (courses) {
      case "pending":
      case "rejected":
        return
    }

    setSelectedVersion(courses[0])
  }, [courses])

  useEffect(() => {
    if (selectedVersion === undefined) return
    setDisplayedCourse("pending")
    CourseClient.getCourseByVersion(
      staleCourse.courseId,
      selectedVersion.version,
    )
      .then((course) => {
        setDisplayedCourse(course)
      })
      .catch(() => {
        setDisplayedCourse("rejected")
      })
  }, [staleCourse.courseId, selectedVersion])

  return (
    <GenericDialog
      open
      size="xl"
      onClose={(e) => {
        onClose()
        e.stopPropagation()
      }}
      title="Version History"
      disableContentPadding
      fullHeight
      content={
        <Stack
          direction="row"
          borderTop="1px solid"
          borderColor="other.outlineBorder"
          overflow="auto"
          height={1}
        >
          <PendingResourceComponent
            resource={courses}
            render={(courses) => (
              <>
                <Stack
                  width={320}
                  borderRight="1px solid"
                  borderColor="other.outlineBorder"
                  overflow="auto"
                >
                  {courses.map((course) => (
                    <VersionButton
                      key={course.version}
                      course={course}
                      isSelected={course.version === selectedVersion?.version}
                      onClick={() => setSelectedVersion(course)}
                      tag={
                        course.version === model?.latestVersion
                          ? "published"
                          : course.version === model?.currentVersion
                          ? "inUse"
                          : undefined
                      }
                    />
                  ))}
                </Stack>
                <Box flex={1} px={5}>
                  <PendingResourceComponent
                    resource={displayedCourse}
                    render={(course) => <CoursePreview course={course} />}
                  />
                </Box>
              </>
            )}
          />
        </Stack>
      }
    />
  )
}

function useVersionHistoryViewModel(staleCourse: StaleCourseModel):
  | {
      currentVersion: number
      latestVersion: number
    }
  | undefined {
  const containers = useComputeStaleCourseContainers(staleCourse)
  const latestPublishedVersion = useAppSelector(
    selectLatestPublishedCourse(staleCourse.courseId),
  )

  if (!containers || !latestPublishedVersion) {
    return undefined
  }

  return {
    currentVersion: containers.course.version,
    latestVersion: latestPublishedVersion?.version,
  }
}

export default SyllabusValidationVersionHistory
