import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { CampusClient } from "@clients/campusClient"
import { FeatureFlag, FeatureFlagClient } from "@clients/featureFlagClient"
import { UsersClient } from "@clients/usersClient"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { Role } from "@features/login/loginSlice"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { UserDto } from "@masterschool/course-builder-api"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import PendingDataState from "@utils/pendingDataState"

type OffboardStuffPopup = {
  user: UserDto
  requestStatus: "idle" | "pending" | "error"
}

type EditRolesPopup = {
  user: UserDto
  selectedRoles: Role[]
  requestStatus: "idle" | "pending" | "error"
}

type OnboardStaffForm = {
  email: string
  firstName: string
  lastName: string
  country?: string
  photoUrl?: string
  additionalRoles?: Role[]
}

type EditUserPopup = {
  user: UserDto
  requestStatus: "idle" | "pending" | "error"
}

type EditFeatureFlagsPopup = {
  userId: string
  flags: FeatureFlag[]
}

export type OnboardStaffPopup = {
  form: OnboardStaffForm
  requestStatus: "idle" | "pending" | "error"
}

type AccountManagementState = {
  accounts: PendingDataState<UserDto[]>
  userFeatureFlags: PendingDataState<FeatureFlag[]>
  search: string
  offboardStaffPopup?: OffboardStuffPopup
  editRolesPopup?: EditRolesPopup
  onboardStaffPopup?: OnboardStaffPopup
  editUserPopup?: EditUserPopup
  editFeatureFlagsPopup?: EditFeatureFlagsPopup
}

const initialState: AccountManagementState = {
  accounts: {
    status: "idle",
  },
  userFeatureFlags: { status: "idle" },
  search: "",
}

export const accountManagementSlice = createSlice({
  name: "accountManagement",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload
    },
    offboardStaffRequested: (
      state,
      action: PayloadAction<{ userId: string }>,
    ) => {
      if (state.accounts.status !== "success") {
        return
      }
      const user = state.accounts.data.find(
        (u) => u.id === action.payload.userId,
      )
      if (!user) {
        return
      }
      state.offboardStaffPopup = {
        user: user,
        requestStatus: "idle",
      }
    },
    offboardStaffPopupClosed: (state) => {
      state.offboardStaffPopup = undefined
    },
    editRolesRequested: (state, action: PayloadAction<{ userId: string }>) => {
      if (state.accounts.status !== "success") {
        return
      }
      const user = state.accounts.data.find(
        (u) => u.id === action.payload.userId,
      )
      if (!user) {
        return
      }
      state.editRolesPopup = {
        user: user,
        selectedRoles: user.roles as Role[],
        requestStatus: "idle",
      }
    },
    editUserRequested: (state, action: PayloadAction<{ userId: string }>) => {
      if (state.accounts.status !== "success") {
        return
      }
      const user = state.accounts.data.find(
        (u) => u.id === action.payload.userId,
      )
      if (!user) {
        return
      }
      state.editUserPopup = {
        user: user,
        requestStatus: "idle",
      }
    },
    roleCheckboxToggled: (state, action: PayloadAction<{ role: Role }>) => {
      if (state.editRolesPopup === undefined) {
        return
      }
      if (state.editRolesPopup.selectedRoles.includes(action.payload.role)) {
        state.editRolesPopup.selectedRoles =
          state.editRolesPopup.selectedRoles.filter(
            (r) => r !== action.payload.role,
          )
      } else {
        state.editRolesPopup.selectedRoles = [
          ...state.editRolesPopup.selectedRoles,
          action.payload.role,
        ]
      }
    },
    editRolesPopupClosed: (state) => {
      state.editRolesPopup = undefined
    },
    editFeatureFlagsPopupClosed: (state) => {
      state.editFeatureFlagsPopup = undefined
    },
    onboardStaffRequested: (state) => {
      state.onboardStaffPopup = {
        form: {
          email: "",
          firstName: "",
          lastName: "",
        },
        requestStatus: "idle",
      }
    },
    onboardStaffPopupClosed: (state) => {
      state.onboardStaffPopup = undefined
    },
    editUserPopupClosed: (state) => {
      state.editUserPopup = undefined
    },
    onboardStaffFormChanged: <K extends keyof OnboardStaffForm>(
      state: AccountManagementState,
      action: PayloadAction<{
        key: K
        value: OnboardStaffForm[K]
      }>,
    ) => {
      if (!state.onboardStaffPopup) {
        return
      }
      state.onboardStaffPopup.form[action.payload.key] = action.payload.value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersWithRoles.pending, (state, action) => {
        state.accounts = {
          status: "pending",
        }
      })
      .addCase(fetchUsersWithRoles.fulfilled, (state, action) => {
        state.accounts = {
          status: "success",
          data: action.payload,
        }
      })
      .addCase(fetchUsersWithRoles.rejected, (state, action) => {
        state.accounts = {
          status: "error",
          error: action.error.message,
        }
      })
      .addCase(fetchLoggedInUserFeatureFlags.pending, (state, action) => {
        if (state.userFeatureFlags.status !== "success") {
          state.userFeatureFlags = { status: "pending" }
        }
      })
      .addCase(fetchLoggedInUserFeatureFlags.fulfilled, (state, action) => {
        state.userFeatureFlags = {
          status: "success",
          data: action.payload || [],
        }
      })
      .addCase(fetchLoggedInUserFeatureFlags.rejected, (state, action) => {
        state.accounts = {
          status: "error",
          error: action.error.message,
        }
      })
      .addCase(editRolesPopupAccepted.pending, (state) => {
        if (state.editRolesPopup) {
          state.editRolesPopup.requestStatus = "pending"
        }
      })
      .addCase(editRolesPopupAccepted.fulfilled, (state) => {
        if (state.editRolesPopup && state.accounts.status === "success") {
          const editRolesPopup = state.editRolesPopup
          const rolesToRemove = state.editRolesPopup.user.roles.filter(
            (r) => !editRolesPopup.selectedRoles.includes(r as Role),
          )
          const rolesToAdd = state.editRolesPopup.selectedRoles.filter(
            (r) => !editRolesPopup.user.roles.includes(r),
          )
          state.accounts.data = state.accounts.data.map((u) =>
            u.id === state.editRolesPopup?.user.id
              ? {
                  ...u,
                  roles: u.roles
                    .concat(rolesToAdd)
                    .filter((r) => !rolesToRemove.includes(r)),
                }
              : u,
          )
        }
        state.editRolesPopup = undefined
      })
      .addCase(editRolesPopupAccepted.rejected, (state) => {
        if (state.editRolesPopup) {
          state.editRolesPopup.requestStatus = "error"
        }
      })
      .addCase(onboardStaffFormAccepted.pending, (state) => {
        if (state.onboardStaffPopup) {
          state.onboardStaffPopup.requestStatus = "pending"
        }
      })
      .addCase(onboardStaffFormAccepted.fulfilled, (state) => {
        state.onboardStaffPopup = undefined
      })
      .addCase(onboardStaffFormAccepted.rejected, (state) => {
        if (state.onboardStaffPopup) {
          state.onboardStaffPopup.requestStatus = "error"
        }
      })
      .addCase(offboardStaffConfirmed.pending, (state) => {
        if (state.offboardStaffPopup) {
          state.offboardStaffPopup.requestStatus = "pending"
        }
      })
      .addCase(offboardStaffConfirmed.fulfilled, (state) => {
        state.offboardStaffPopup = undefined
      })
      .addCase(offboardStaffConfirmed.rejected, (state) => {
        state.offboardStaffPopup = undefined
      })
      .addCase(editFeatureFlagsPopupOpened.pending, (state) => {
        state.editFeatureFlagsPopup = undefined
      })
      .addCase(editFeatureFlagsPopupOpened.fulfilled, (state, action) => {
        state.editFeatureFlagsPopup = action.payload
      })
      .addCase(editFeatureFlagsPopupOpened.rejected, (state) => {
        state.editFeatureFlagsPopup = undefined
      })
      .addCase(editFeatureFlagsPopupAccepted.fulfilled, (state) => {
        state.editFeatureFlagsPopup = undefined
      })
      .addCase(editFeatureFlagsPopupAccepted.rejected, (state) => {
        state.editFeatureFlagsPopup = undefined
      })
  },
})

export const fetchUsersWithRoles = createAppAsyncThunk(
  "accountManagement/fetchMSEmployees",
  async () => {
    return UsersClient.getUsersWithRoles()
  },
)

export const fetchLoggedInUserFeatureFlags = createAppAsyncThunk(
  "accountManagement/fetchUserFeatureFlags",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const user = selectLoggedInUser(state)
    if (!user) {
      return
    }
    const flags = await FeatureFlagClient.listUserFlags(user.clientId)
    return flags
  },
)

export const editRolesPopupAccepted = createAppAsyncThunk(
  "accountManagement/editRoles",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState().accountManagement.editRolesPopup
    if (!state) {
      return
    }

    const rolesToRemove = state.user.roles.filter(
      (r) => !state.selectedRoles.includes(r as Role),
    )
    const rolesToAdd = state.selectedRoles.filter(
      (r) => !state.user.roles.includes(r),
    )

    const requests = [
      rolesToAdd.length > 0
        ? CampusClient.put(`user/employees/${state.user.id}/roles`, {
            roles: rolesToAdd,
          })
        : undefined,
      rolesToRemove.length > 0
        ? CampusClient.delete(`user/employees/${state.user.id}/roles`, {
            roles: rolesToRemove,
          })
        : undefined,
    ].msCompact()

    return Promise.all(requests)
  },
)

export const onboardStaffFormAccepted = createAppAsyncThunk(
  "accountManagement/onboardStaff",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState().accountManagement.onboardStaffPopup
    if (!state) {
      return
    }

    return CampusClient.post(`add-masterschool-employee`, state.form).then(
      () => {
        thunkAPI.dispatch(fetchUsersWithRoles())
      },
    )
  },
)

export const offboardStaffConfirmed = createAppAsyncThunk(
  "accountManagement/offboardStaff",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState().accountManagement.offboardStaffPopup

    if (!state) {
      return
    }

    return CampusClient.delete(`user/employees/${state.user.id}/offboard`).then(
      () => {
        thunkAPI.dispatch(fetchUsersWithRoles())
      },
    )
  },
)

export const editFeatureFlagsPopupOpened = createAppAsyncThunk(
  "accountManagement/editFeatureFlagsPopupOpened",
  async (payload: { userId: string }, thunkAPI) => {
    const flags = await FeatureFlagClient.listUserFlags(payload.userId)
    return { userId: payload.userId, flags }
  },
)

export const editFeatureFlagsPopupAccepted = createAppAsyncThunk(
  "accountManagement/editFeatureFlagsPopupAccepted",
  async (
    payload: { userId: string; flags: { [key: string]: boolean } },
    thunkAPI,
  ) => {
    const dispatch = thunkAPI.dispatch
    const loggedInUser = selectLoggedInUser(thunkAPI.getState())
    return FeatureFlagClient.updateFlags(payload.userId, payload.flags)
      .then(() => {
        dispatch(showSuccessSnackbar("Feature flags updated successfully"))
        if (loggedInUser && loggedInUser.clientId === payload.userId) {
          dispatch(fetchLoggedInUserFeatureFlags())
        }
      })
      .catch(() =>
        dispatch(showErrorSnackbar("Failed to update feature flags")),
      )
  },
)

export const {
  setSearch,
  offboardStaffRequested,
  offboardStaffPopupClosed,
  editRolesRequested,
  editRolesPopupClosed,
  editUserRequested,
  editUserPopupClosed,
  editFeatureFlagsPopupClosed,
  roleCheckboxToggled,
  onboardStaffRequested,
  onboardStaffPopupClosed,
  onboardStaffFormChanged,
} = accountManagementSlice.actions

export default accountManagementSlice.reducer
