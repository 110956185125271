import { SprintDto, CourseDto, Topic } from "@masterschool/course-builder-api"
import { newSprintFromTopic } from "./syllabusEditorSlice"

export function getAdjustedSprints(data: {
  updatedCourses: CourseDto[]
  previousSprints: SprintDto[]
  previousCourses: CourseDto[]
}) {
  const { updatedCourses, previousSprints, previousCourses } = data
  const previousTopicToSprintMapping = mapTopicToSprint(
    previousSprints,
    previousCourses,
  )
  return new SprintAdjuster(
    updatedCourses,
    previousTopicToSprintMapping,
  ).adjustSprints()
}

class SprintAdjuster {
  private readonly sprintQueue = new SprintsQueue()
  private nextSprintDetails:
    | { startTopicIndex: number; sprint: SprintDto }
    | undefined = undefined
  private lastSprintId: string | undefined = undefined

  constructor(
    private readonly courses: CourseDto[],
    private readonly previousTopicToSprintMapping: Map<FullTopicId, SprintDto>,
  ) {}

  adjustSprints() {
    const allTopicsFlat = this.courses.flatMap((course) =>
      course.syllabus.topics.map((topic) => ({ course, topic })),
    )
    for (
      let currentTopicIndex = 0;
      currentTopicIndex < allTopicsFlat.length;
      currentTopicIndex++
    ) {
      const { course, topic } = allTopicsFlat[currentTopicIndex]
      const sprint = this.getSprintForTopic(topic, course, currentTopicIndex)
      this.lastSprintId = sprint.id
      this.sprintQueue.pushTopicWithSprint(topic, sprint)
    }
    return this.sprintQueue.sprints
  }

  private getSprintForTopic(
    topic: Topic,
    course: CourseDto,
    currentIndex: number,
  ): SprintDto {
    const sprintFromPreviousMapping = this.previousTopicToSprintMapping.get(
      makeFullTopicId(course.id, topic.id),
    )
    if (sprintFromPreviousMapping) {
      return sprintFromPreviousMapping
    }
    const deducedSprint = this.deduceSprintByContext(course, currentIndex)
    if (deducedSprint) {
      return deducedSprint
    }
    const newSprint = newSprintFromTopic(topic)
    return newSprint
  }

  private deduceSprintByContext(
    course: CourseDto,
    currentTopicIndex: number,
  ): SprintDto | undefined {
    const sprintOfCourse = this.getSprintIfCourseMappedToSingleSprint(course)
    if (sprintOfCourse) {
      return sprintOfCourse
    }
    const surroundingSprint =
      this.sprintIfCurrentTopicSurroundedBySameSprint(currentTopicIndex)
    if (surroundingSprint) {
      return surroundingSprint
    }
    return undefined
  }

  private getSprintIfCourseMappedToSingleSprint(course: CourseDto) {
    const courseTopics = course.syllabus.topics.map((t) => t.id)
    const courseSprints = courseTopics.msCompactMap((t) =>
      this.previousTopicToSprintMapping.get(makeFullTopicId(course.id, t)),
    )
    const courseSprintsIds = courseSprints.map((s) => s.id).msUnique()
    const isAllCourseMappedToSingleSprint = courseSprintsIds.length === 1
    if (isAllCourseMappedToSingleSprint) {
      return courseSprints[0]
    } else {
      return undefined
    }
  }
  private sprintIfCurrentTopicSurroundedBySameSprint(
    currentTopicIndex: number,
  ) {
    if (
      this.nextSprintDetails === undefined ||
      currentTopicIndex > this.nextSprintDetails.startTopicIndex
    ) {
      this.nextSprintDetails =
        this.findNextTopicWithSprintMapping(currentTopicIndex)
    }
    const isSurroundedBySameSprint =
      this.nextSprintDetails &&
      this.nextSprintDetails.sprint.id === this.lastSprintId
    if (isSurroundedBySameSprint) {
      return this.nextSprintDetails?.sprint
    }
    return undefined
  }

  private findNextTopicWithSprintMapping(startIndex: number) {
    const allTopicsFlat = this.courses.flatMap((course) =>
      course.syllabus.topics.map((topic) =>
        makeFullTopicId(course.id, topic.id),
      ),
    )
    const topicsAfterCurrent = allTopicsFlat.slice(startIndex)
    const indexOfNextTopicWithMapping = topicsAfterCurrent.findIndex((t) =>
      this.previousTopicToSprintMapping.get(t),
    )
    const nextSprint =
      indexOfNextTopicWithMapping > 0
        ? this.previousTopicToSprintMapping.get(
            topicsAfterCurrent[indexOfNextTopicWithMapping],
          )
        : undefined
    if (!nextSprint) {
      return undefined
    }
    return {
      startTopicIndex: indexOfNextTopicWithMapping,
      sprint: nextSprint,
    }
  }
}

type SprintsQueueSprint = SprintDto & { originalPreDuplicationId: string }

class SprintsQueue {
  private _sprints: SprintsQueueSprint[] = []

  get sprints(): SprintDto[] {
    return this._sprints.map((s) => {
      const { originalPreDuplicationId, ...sprintDto } = s
      return sprintDto
    })
  }

  pushTopicWithSprint(topic: Topic, sprint: SprintDto) {
    this.pushSprint({ ...sprint, lastTopicId: topic.id })
  }

  pushSprint(sprint: SprintDto) {
    const typeOfChange = this.typeOfChange(sprint.id)
    switch (typeOfChange) {
      case "newSprint":
        this._sprints.push({ ...sprint, originalPreDuplicationId: sprint.id })
        break
      case "continuationOfPreviousSprint":
        const previousSprint = this._sprints[this._sprints.length - 1]
        this._sprints[this._sprints.length - 1] = {
          ...previousSprint,
          lastTopicId: sprint.lastTopicId,
        }
        break
      case "splittedFromOtherSprintDueToReordering":
        const duplicatedSprint = {
          ...sprint,
          id: generateSprintId(),
          originalPreDuplicationId: sprint.id,
          title: `${sprint.title} (copy)`,
        }
        this._sprints.push(duplicatedSprint)
        break
    }
  }

  typeOfChange(
    sprintId: string,
  ):
    | "newSprint"
    | "continuationOfPreviousSprint"
    | "splittedFromOtherSprintDueToReordering" {
    const isContinuationOfPreviousSprint =
      this._sprints[this._sprints.length - 1]?.originalPreDuplicationId ===
      sprintId
    const isNewSprint =
      this._sprints.filter((s) => s.id === sprintId).length === 0

    if (isNewSprint) {
      return "newSprint"
    } else if (isContinuationOfPreviousSprint) {
      return "continuationOfPreviousSprint"
    } else {
      return "splittedFromOtherSprintDueToReordering"
    }
  }
}

function generateSprintId() {
  return window.crypto.randomUUID()
}

type FullTopicId = `${string}#${string}`
const makeFullTopicId = (courseId: string, topicId: string): FullTopicId =>
  `${courseId}#${topicId}`

function mapTopicToSprint(
  sprints: SprintDto[],
  courses: CourseDto[],
): Map<FullTopicId, SprintDto> {
  const topicToSprint = new Map<FullTopicId, SprintDto>()
  let currentSprintIndex = 0

  courses.forEach((course) =>
    course.syllabus.topics.forEach((topic) => {
      topicToSprint.set(
        makeFullTopicId(course.id, topic.id),
        sprints[currentSprintIndex],
      )
      const isLastTopicInSprint = areTopicIdsEqual(
        sprints[currentSprintIndex].lastTopicId,
        topic.id,
      )
      if (isLastTopicInSprint) {
        currentSprintIndex++
      }
    }),
  )
  sprintSanityCheck([], topicToSprint)
  return topicToSprint
}

function sprintSanityCheck(
  sprints: SprintDto[],
  topicToSprintMap: Map<FullTopicId, SprintDto>,
) {
  const allOriginalSprints = sprints.map((s) => s.id)
  const allSprintsInMap = Array.from(topicToSprintMap.values())
    .map((s) => s.id)
    .msUnique()
  if (allOriginalSprints.length !== allSprintsInMap.length) {
    console.error(
      "Problem with mapping topics to sprints: sprint count mismatch " +
        JSON.stringify({
          allOriginalSprints,
          allSprintsInMap,
        }),
    )
  }
}

function areTopicIdsEqual(topicId1: string, topicId2: string): boolean {
  const areTopicIdsEqualWithSupportOnLegacyNumericId =
    topicId1.toString() === topicId2.toString()
  return areTopicIdsEqualWithSupportOnLegacyNumericId
}
