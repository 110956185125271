import AppObserver from "@app/middlewares/appObserver"
import { store } from "@app/store"
import { SnackbarDuration, showSnackbarItem } from "@features/ui/uiSlice"

const snackbarObserver: AppObserver = {
  didUpdate: (previousState, currentState, action) => {
    switch (action.type) {
      case "coursesMenu/archiveCourse/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "The course was archived",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "coursesMenu/archiveCourse/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to archive course",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "coursesMenu/duplicateCourse/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "The course was duplicated",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "coursesMenu/duplicateCourse/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to duplicate course",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "syllabusesMenu/duplicateSyllabus/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "The syllabus was duplicated",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "syllabusesMenu/duplicateSyllabus/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to duplicate syllabus",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "syllabusesMenu/revertSyllabusToVersion/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to revert",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "syllabusEditor/archiveSyllabus/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "The syllabus was archived",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "syllabusEditor/archiveSyllabus/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to archive syllabus",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/deleteProgram/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Program deleted",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/createProgram/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Program added",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/editProgramProperties/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Changes saved",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/editProgramProperties/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to save changes",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/registerStudent/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Student onboarded",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/registerMultipleStudents/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Students onboarded",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "slackSlice/addClassesToChannel/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Classes added to Slack channel",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "slackSlice/removeClassesFromChannel/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Classes removed from Slack channel",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/createProgramClass/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Class added",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/createProgramClass/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to add class",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/unregisterStudent/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Student off-boarded",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/deleteProgramClass/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Class removed",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "appFeatureFlags/createAppFeatureFlagPopupConfirmed/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Feature flag created",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "appFeatureFlags/createAppFeatureFlagPopupConfirmed/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to create feature flag",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "appFeatureFlags/turnOnOffAppFeatureFlag/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Feature flag updated",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "appFeatureFlags/turnOnOffAppFeatureFlag/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to update feature flag",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/connectSyllabusToProgram/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Syllabus Connected",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/connectSyllabusToProgram/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to connect syllabus",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/disconnectSyllabusFromProgram/fulfilled":
        store.dispatch(
          showSnackbarItem({
            message: "Syllabus Disconnected",
            type: "success",
            duration: SnackbarDuration.short,
          }),
        )
        break
      case "program/disconnectSyllabusFromProgram/rejected":
        store.dispatch(
          showSnackbarItem({
            message: "Failed to disconnect syllabus",
            type: "error",
            duration: SnackbarDuration.short,
          }),
        )
        break
    }
  },
}

export default snackbarObserver
