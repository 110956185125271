import { useAppDispatch } from "@app/hooks"
import { FULL_SCREEN_COMPONENT_Z_INDEX } from "@cmp/layouts/fullScreenComponent"
import { User, logoutUser } from "@features/login/loginSlice"
import { Menu, Box, Typography, Divider, Button, Avatar } from "@mui/material"
import React from "react"
import appTheme from "../../theme/appTheme"

const UserMenu = (props: { user: User | null }) => {
  const user = props.user
  const userImage = user?.photoURL

  const onAvatarClicked = () => {
    setIsMenuOpen(true)
  }
  const avatarRef = React.useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const dispatch = useAppDispatch()
  const logout = () => {
    dispatch(logoutUser())
  }

  return (
    <div style={{ zIndex: FULL_SCREEN_COMPONENT_Z_INDEX + 1 }}>
      <Menu
        anchorEl={avatarRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "none",
              marginRight: "16px",
              marginTop: "8px",
              border: "1px solid",
              paddingX: "16px",
              paddingY: "8px",
              borderColor: appTheme.palette.divider,
              backgroundColor: appTheme.palette.eTypes.sand25,
            },
          },
        }}
        MenuListProps={{
          sx: {
            width: "250px",
          },
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Box display="flex" gap="8px" flexDirection="column">
          <Typography variant="body2_sb">
            Welcome {user?.firstName + " " + user?.lastName}!
          </Typography>
          <Typography variant="body3">
            You are currently logged in as <b>{user?.email}</b>
          </Typography>
          <Divider sx={{ marginY: "8px" }} />
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              logout()
              setIsMenuOpen(false)
            }}
          >
            Logout
          </Button>
        </Box>
      </Menu>
      <Avatar
        ref={avatarRef}
        sx={{
          position: "absolute",
          top: "20px",
          right: "16px",
          width: "32px",
          height: "32px",
          cursor: "pointer",
        }}
        alt={user?.email}
        src={userImage}
        onClick={onAvatarClicked}
      />
    </div>
  )
}

export default UserMenu
