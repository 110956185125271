import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

const SortableItem = ({
  item,
  id,
  style,
  disabled,
}: {
  item: JSX.Element
  id: string
  style?: (isDragging: boolean) => React.CSSProperties
  disabled?: boolean
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled: disabled })

  const additionalStyle = style ? style(isDragging) : {}

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
    ...additionalStyle,
  }

  return (
    <div style={itemStyle} ref={setNodeRef} {...attributes} {...listeners}>
      {item}
    </div>
  )
}

export default SortableItem
