import EditStudentDetailsPopup from "../students/editStudentDetailsPopup"
import OnboardMultipleStudentsPopup from "../students/multiple/onboardMultipleStudentsPopup"
import OffboardStudentPopup from "../students/offboardStudentPopup"
import OnboardSingleStudentPopup from "../students/onboardSingleStudentPopup"
import ProgramTransferStudentPopup from "../students/transferStudentPopups/programTransferStudentPopup"
import ClassTransferStudentPopup from "../students/transferStudentPopups/classTransferStudentPopup"
import EditStudentMentorPopup from "../students/editStudentMentorPopup"
import EditStudentCareerSuccessAdvisorPopup from "../students/editStudentCareerSuccessAdvisorPopup"
import EditStudentDatacampCredentialsPopup from "../students/editStudentDatacampCredentialsPopup"

function OnboardStudentsDialogPresenter() {
  return (
    <>
      <OnboardSingleStudentPopup />
      <OnboardMultipleStudentsPopup />
      <OffboardStudentPopup />
      <ProgramTransferStudentPopup />
      <ClassTransferStudentPopup />
      <EditStudentDetailsPopup />
      <EditStudentMentorPopup />
      <EditStudentCareerSuccessAdvisorPopup />
      <EditStudentDatacampCredentialsPopup />
    </>
  )
}

export default OnboardStudentsDialogPresenter
