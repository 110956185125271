import PendingDataState from "@utils/pendingDataState"
import FullScreenErrorComponent from "./fullScreenErrorComponent"
import FullScreenLoadingComponent from "./fullScreenLoadingComponent"

type PendingDataPresenterProps<T> = {
  state: PendingDataState<T>
  component: React.ComponentType<{ data: T }>
  loadedComponents: string
}

const PendingDataPresenter = <T,>({
  state,
  component: Component,
  loadedComponents,
}: PendingDataPresenterProps<T>) => {
  switch (state.status) {
    case "idle":
    case "pending":
      return <FullScreenLoadingComponent loadedComponents={loadedComponents} />
    case "error":
      return <FullScreenErrorComponent loadedComponents={loadedComponents} />
    case "success":
      return <Component data={state.data} />
    default:
      return null
  }
}

export default PendingDataPresenter
