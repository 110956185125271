import { useAppSelector } from "@app/hooks"
import { ProgramClassDto, ProgramDto } from "@masterschool/course-builder-api"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { ProgramClassesTable } from "./programClassesTable"
import { Box, Button, Typography } from "@mui/material"
import appTheme from "../../../theme/appTheme"
import ManageProgramClassPopup from "./manageProgramClassDialog"
import { useState } from "react"
import { EmptyClassesView } from "./emptyClassesView"
import DeleteProgramClassDialog from "./deleteProgramClassDialog"

export const ProgramClassesView = (props: { program: ProgramDto }) => {
  const { program } = { ...props }
  const programId = program.id
  const classes = useAppSelector(selectClassesForProgram(programId))
  const [managedClass, setManagedClass] = useState<ProgramClassDto | undefined>(
    undefined,
  )
  const [isManageClassDialogOpen, setIsManageClassDialogOpen] = useState(false)
  const [isDeleteClassDialogOpen, setIsDeleteClassDialogOpen] = useState(false)
  const onAddNewClassClick = () => {
    setManagedClass(undefined)
    setIsManageClassDialogOpen(true)
  }
  const onEditClassClick = (programClass: ProgramClassDto) => {
    setManagedClass(programClass)
    setIsManageClassDialogOpen(true)
  }
  const onDeleteClassClick = (programClass: ProgramClassDto) => {
    setManagedClass(programClass)
    setIsDeleteClassDialogOpen(true)
  }

  return (
    <>
      {classes.length === 0 ? (
        <EmptyClassesView onAddNewClassClick={onAddNewClassClick} />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              Classes{" "}
              <span
                style={{
                  color: appTheme.palette.text.disabled,
                }}
              >
                ({classes.length})
              </span>
            </Typography>
            <Button
              variant="contained"
              size="small"
              onClick={onAddNewClassClick}
            >
              Create new
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              pb: "16px",
            }}
          >
            <ProgramClassesTable
              programId={programId}
              classes={classes}
              onEditClassClick={onEditClassClick}
              onDeleteClassClick={onDeleteClassClick}
            />
          </Box>
        </Box>
      )}
      <ManageProgramClassPopup
        open={isManageClassDialogOpen}
        onClose={() => {
          setIsManageClassDialogOpen(false)
        }}
        program={program}
        programClass={managedClass}
      />
      {managedClass && (
        <DeleteProgramClassDialog
          open={isDeleteClassDialogOpen}
          programId={programId}
          programClass={managedClass}
          onClose={() => {
            setIsDeleteClassDialogOpen(false)
          }}
        />
      )}
    </>
  )
}
