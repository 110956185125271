import { useAppSelector } from "@app/hooks"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import UserMenu from "./userMenu"

function AppHeader() {
  const user = useAppSelector(selectLoggedInUser)

  return <UserMenu user={user} />
}

export default AppHeader
