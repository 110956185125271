import { useAppSelector, useAppDispatch } from "@app/hooks"
import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"
import { selectLastSyllabusVersion, Syllabus } from "./syllabusSelectors"
import { revertSyllabusToVersion } from "./syllabusesMenuSlice"

export function useRevertSyllabusToVersion(syllabusId: string) {
  const dispatch = useAppDispatch()
  const syllabusLastVersion = useAppSelector(
    selectLastSyllabusVersion(syllabusId),
  )

  if (!syllabusLastVersion) {
    console.error("Syllabus last version not found for id " + syllabusId)
    return "error"
  }

  const hasUnpublishedChanges =
    syllabusLastVersion.status !== SyllabusDtoStatusEnum.Published

  const revert = async (targetVersion: Syllabus) => {
    await dispatch(revertSyllabusToVersion(targetVersion))
  }
  return {
    shouldVerifyBeforeRevert: hasUnpublishedChanges,
    revert,
  }
}
